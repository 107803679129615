import React, { useContext } from 'react';
import {v1 as uuidv1 } from 'uuid'
import UserContext from '../../../../UserContext';
import { useAuthState } from "react-firebase-hooks/auth";
import { uploadImage } from "../../../../utilities/files";
import { auth } from '../../../../utilities/firebaseConfig';
import { EditorContext } from '../../../../pages/editor/EditorContext';
import ImgSearchUnsplash from './ImgSearchUnsplash';
import { MediaImage, Xmark } from 'iconoir-react';
const idCount = 10

function ImageUpload({ onImageUpload, altText }) {
    const { user } = useContext(UserContext);
    const [firebaseUser] = useAuthState(auth);

    const { project } = useContext(EditorContext)
    const projectId = project.id

    const handleImageUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            try {
                const uploadedImageUrl = await uploadImage(file, file.name);

                const assetData = {
                    id: uuidv1(),
                    file_url: uploadedImageUrl,
                    file_type: "image",
                    owner_id: user.id,
                    project_id: projectId, // replace with the correct project id
                    design_system_id: project.ds, // replace with the correct design system id
                    asset_source: "user_upload",
                };

                await addAsset(assetData);

                onImageUpload(uploadedImageUrl);

            } catch (err) {
                console.error("Failed to upload image:", err);
            }
        }
    };

    const addAsset = async (assetData) => {
        const userToken = await firebaseUser?.getIdToken();

        if (userToken) {
            try {
                const response = await fetch("/assets/add", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userToken}`,
                    },
                    body: JSON.stringify(assetData),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
            } catch (error) {
                console.error("Failed to add asset:", error);
            }
        } else {
            console.error("Failed to get user token");
        }
    };

    return (
        <div className="relative select-none flex flex-col items-center text-sm justify-start w-full">
        <div className='flex flex-row w-full justify-between gap-2'>
        </div>
        <ImgSearchUnsplash onSubmit={onImageUpload} 
        initialPrompt={altText} 
        onClose={onImageUpload}
        handleImageUpload={handleImageUpload}/>
        
        
        </div>
    );
}

export default ImageUpload;
