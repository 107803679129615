import { allIconNames } from 'ui-kit/local/components/iconMap.ts';

export default function SelectObjectArray({ propKey, label, value, onChange, defaultValue }) {
    const keys = Object.keys(defaultValue[0]);
    const hasIcon = keys.includes('icon'); // Check if 'icon' exists

    // Handle changes on input and select fields
    const handleBlur = (e, index, key) => {
        const newValue = [...value];
        newValue[index][key] = e.target.value;
        onChange(propKey, newValue);
    };

    const handleSelectChange = (e, index) => {
        const newValue = [...value];
        newValue[index].icon = e.target.value || null;
        onChange(propKey, newValue);
    };

    const handleAddOption = () => {
        const newOption = hasIcon ? { label: 'label', value: 'value', icon: null } : { label: 'label', value: 'value' };
        const newValue = [...value, newOption];
        onChange(propKey, newValue);
    };

    const handleRemoveOption = (index) => {
        const newValue = value.filter((_, i) => i !== index);
        onChange(propKey, newValue);
    };

    return (
        <div className="text-xs border border-base-100">
            <h3>{label}</h3>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        <th>Label</th>
                        <th>Value</th>
                        {hasIcon && <th>Icon</th>}
                        <th> </th>
                    </tr>
                </thead>
                <tbody>
                    {value?.map((item, index) => (
                        <tr key={index}>
                            <td>
                                <input
                                    type="text"
                                    defaultValue={item?.label}
                                    onBlur={(e) => handleBlur(e, index, 'label')}
                                    className="w-full py-0.5 px-1 ring-1 ring-gray-300 focus:bg-current-5"
                                />
                            </td>
                            <td>
                                <input
                                    type="text"
                                    defaultValue={item?.value}
                                    onBlur={(e) => handleBlur(e, index, 'value')}
                                    className="w-full py-0.5 px-1 ring-1 ring-gray-300 focus:bg-current-5"
                                />
                            </td>
                            {hasIcon && (
                                <td>
                                    <select
                                        value={item?.icon || ''}
                                        onChange={(e) => handleSelectChange(e, index)}
                                        className="w-full py-0.5 px-1 ring-1 ring-gray-300"
                                    >
                                        <option value="">None</option>
                                        {allIconNames.map((icon) => (
                                            <option key={icon} value={icon}>
                                                {icon}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                            )}
                            <td>
                                <button
                                    onClick={() => handleRemoveOption(index)}
                                    className="px-2 text-red-500 hover:text-red-700"
                                >
                                    x
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <button
                onClick={handleAddOption}
                className="mt-2 px-2 w-full py-0.5 bg-base-content text-base-0 rounded-sm"
            >
                Add Option
            </button>
        </div>
    );
}