import { convertJSXToObjectsSync } from "../../../utilities/AIhelpers";
import { getDescendants } from "./helpers";

export function handleSectionDragOver({object, dndState,frameObjects, setDnDState}) {
    
    const currentSection = frameObjects?.find(obj => obj.componentAPIName == dndState.draggingItem?.componentAPIName) 
    const appShell = frameObjects?.find(obj => obj.componentAPIName == 'AppShell') 
    const dropParent = currentSection || appShell || null
    if (!dropParent) {
        // no section or appshell to drop into
        setDnDState({
            ...dndState,
            draggingOverItem: null, 
            offFrame: false
        }) 
    } else if (dropParent != dndState?.draggingOverItem) {
        setDnDState({
            ...dndState,
            draggingOverItem: dropParent, 
            offFrame: false
        })  
    }
}

export function handleSectionDragEnd({dndState, dragRefs, handleAction, frameObjects, lastOverItem}) {
            
    const newParent = lastOverItem; // or its parent
            const draggingItem = dndState?.draggingItem;
            let objects = [];
            if (draggingItem.objects && draggingItem.objects.length > 0) {
                objects = draggingItem.objects;
            } else {
                const jsx = draggingItem.jsx;
                objects = convertJSXToObjectsSync(jsx) || [];
            }
            if (objects.length === 0) return;
            const rootObj = objects.find((obj) => obj.parent === "rootObject");
            if (!rootObj) return;
            const descendants = objects.filter((obj) => obj.id !== rootObj.id) || [];
            
            let action = null
            if (dndState.draggingOverItem?.componentAPIName == 'AppShell') {
                // FOR APPSHELL WE INSERT
                action = {
                    type: "INSERT_OBJECT",
                    object: rootObj,
                    parent: newParent,
                    index: dragRefs.current.dropIndex,
                    descendants,
                }

              
            } else if (dndState.draggingOverItem?.componentAPIName == dndState.draggingItem.componentAPIName) {
            // IF WE CURRENTLY HAVE THIS OBJECT THEN WE REPLACE
            const currentSection = frameObjects?.find(obj => obj.componentAPIName == dndState.draggingItem?.componentAPIName) 
            action = {
                type: 'REPLACE_OBJECT',
                oldObject: currentSection,
                oldDescendants: getDescendants(currentSection.id, frameObjects) || [], 
                newObject: rootObj,
                newDescendants: descendants
            }}
            
            handleAction(action)
}