import { useContext } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from '../../../../../utilities/firebaseConfig';
import { XmarkSquare } from "iconoir-react";
import { EditorContext } from "../../../EditorContext";

export default function DeleteLogo({color='#111111', assetId=null}){
    
    const { project, setProject, assets } = useContext(EditorContext);
    const [firebaseUser] = useAuthState(auth);

    
    const deleteAsset = async (assetId) => {
        if (!assetId) return;
        const userToken = await firebaseUser?.getIdToken();

        if (userToken) {
            try {
                const response = await fetch("/assets/delete", {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${userToken}`,
                    },
                    body: JSON.stringify({assetId}),
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                } else {
                    console.log('Assets:', assets)
                    setProject({...project, assets: assets.filter(asset => asset.id !== assetId) })
                }
            } catch (error) {
                console.error("Failed to delete asset:", error);
            }
        } else {
            console.error("Failed to get user token");
        }
    };

    return (
        <div className='flex flex-row items-center font-medium transition-all duration-75 hover:scale-110 text-xs gap-2
                    opacity-50 hover:opacity-100 cursor-pointer px-[6px] py-1 rounded-md'
                    onClick={() => deleteAsset(assetId)}
                    style={{backgroundColor: `color-mix(in srgb, var(--${color}-content) 12%, transparent)`}}
                    >
                    <XmarkSquare className='scale-75'/>
                    Delete
                    
                    </div>
    )
}