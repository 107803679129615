import { useContext, useEffect, useRef, useState } from 'react';
import { EditorContext } from '../EditorContext';
import axios from 'axios';
import { Button } from '../../../ui-kit/exports/react';

export default function ShareButton() {
    const { project, selector } = useContext(EditorContext);
    const [isOpen, setIsOpen] = useState(false);  // State to manage dropdown visibility
    
    const dropdownRef = useRef(null);  // Ref to help with detecting outside clicks

    useEffect(() => {
        // Function to handle clicking outside the dropdown
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            // Attach the listener if the dropdown is open
            document.addEventListener("mousedown", handleClickOutside);
        }

        // Clean up
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [isOpen]);

    const isEmail = selector.frame?.objects?.find(obj => obj.componentAPIName == 'Email');
    return (
        <div className='relative flex flex-row items-center font-medium text-xs transition-all duration-75 rounded-lg'
        ref={dropdownRef}
        >
            <Button 
            state={isOpen ? 'active' : 'default'}
            text={'Share'} color={'base-200'} onClick={() => setIsOpen(!isOpen)} 
                
                size={'small'} 
                />
            {isOpen && (  
                <div className='absolute -bottom-3 translate-y-full min-w-full bg-white right-0 flex flex-col p-1 rounded-lg
                border border-slate-200 text-gray-800 font-medium'
                
                >
                    
                    <Button 
                        text={`Preview ${isEmail ? 'Email' : 'Page'}`}
                        onClick={() => window.open(`/share/frame/${selector.frame.id}`, '_blank')}
                        disabled={!selector.frame}
                        size={'small'}
                        
                        style={'ghost'}
                        rightIcon={'page'}
                        width={'full'}
                    />
                    <Button
                        text={`Preview Feature`}
                        onClick={() => window.open(`/share/feature/${selector.page.id}`, '_blank')}
                        disabled={!selector.page}
                        size={'small'}
                        style={'ghost'}
                        rightIcon={'send'}
                        width={'full'}
                    />
                    

                    
                    <div className='w-full h-0 border-t border-slate-100 mx-auto my-1'></div>
                    <SaveImage />
                    <Button 
                        text='Open in Figma'
                        disabled={!selector.frame}  
                        size={'small'}
                        style={'ghost'}
                        state='disabled'
                        rightIcon={'figma'}
                        width={'full'}
                    />

                    
                </div>
            )}
        </div>
    );
}


function SaveImage() {
    const {selector} = useContext(EditorContext);
    const [generationStatus, setGenerationStatus] = useState('idle');  // 'loading', 'success', 'error', 'idle'

    const frameId = selector.frame ? selector.frame.id : null;
    const frameName = selector.frame ? selector.frame.name : 'default';
    
    const takeScreenshot = async () => {
        if (!frameId) return;
        setGenerationStatus('loading');

        const payload = { frameId };
    
        try {
            const response = await axios.post('/save-frame-image', payload, {
                responseType: 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data], { type: 'image/jpeg' }));
            const link = document.createElement('a');
            const fileName = `frame_${frameName.replace(/\s+/g, '_')}.jpeg`; // File extension as .jpeg
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
            
            setGenerationStatus('success');
            setTimeout(() => {
                setGenerationStatus('idle');
            }, 2000);
        } catch (error) {
            console.error('Error taking screenshot:', error);
            setGenerationStatus('error');
            setTimeout(() => {
                setGenerationStatus('idle');
            }, 2000);
        }
    };
    
    return (
        <Button 
            text={'Save as PNG'}
            onClick={takeScreenshot}
            size={'small'}
            style={'ghost'}
            state='disabled'
            // state={generationStatus === 'loading' ? 'loading' : 'default'}
            rightIcon={generationStatus === 'success' ? 'check' : generationStatus === 'error' ? 'sad' : 'image'}
            width={'full'}
        />
        
    );
}
