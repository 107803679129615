import { nanoid } from 'nanoid';

export const gridDescendants = [
    {
      id: nanoid(10),
      APIName: 'div',
      componentAPIName: 'FlexBox',
      index: 1, 
      parent: null,
      frame: null,
      mobile_props: {},
      text: '',
      object_props: {background: 'base-0', corners: 'base', paddingX: '12px', paddingY: '12px', gap: '8px'}
    }, 
    {
      id: nanoid(10),
      APIName: 'div',
      componentAPIName: 'FlexBox',
      index: 2, 
      parent: null,
      frame: null,
      mobile_props: {},
      text: '',
      object_props: {background: 'base-0', corners: 'base', paddingX: '12px', paddingY: '12px', gap: '8px'}
    },
    {
        id: nanoid(10),
        APIName: 'div',
        componentAPIName: 'FlexBox',
        index: 3, 
        parent: null,
        frame: null,
        mobile_props: {},
        text: '',
        object_props: {background: 'base-0', corners: 'base', paddingX: '12px', paddingY: '12px', gap: '8px'}
      }
  ]
  