import { NavArrowLeft, NavArrowRight } from "iconoir-react";

export default function DesktopNav({currentIndex, allFrames, selectNextFrame, selectPreviousFrame, nextFrame, previousFrame}) {
    return (
      (
        <div className="h-6 flex flex-row items-center  hover:bg-base-100 rounded-md text-xs transition-all select-none">
          <button
            className={`${previousFrame && "hover:scale-110 opacity-100"} 
            opacity-60 cursor-pointer justify-center items-center flex flex-row transition-all rounded-md text-base-700 h-full font-medium w-5 `}
            onClick={selectPreviousFrame}
          >
            <NavArrowLeft  style={{opacity: previousFrame ? 1 : 0.5}} />
          </button>
          <span className="w-10 justify-center flex flex-row">
            {currentIndex + 1}
            /
            {allFrames?.length}
          </span>
          <button
            className={`${nextFrame && "hover:scale-110 opacity-100"} 
            opacity-60 cursor-pointer justify-center items-center flex flex-row transition-all rounded-md text-base-700 h-full font-medium w-5`}
            onClick={selectNextFrame}
          >
            <NavArrowRight style={{opacity: nextFrame ? 1 : 0.5}} />
          </button>
       </div>
      )
    )
  }