import React, { useMemo } from 'react';
import { getDescendants } from '../../../utilities/helpers';
import LightRouter from '../../../components/LightRouter';
import { convertThemeVariablesToStyles } from '../canvas/helpers';
import { MediaImage } from 'iconoir-react';

export default function FramePreview({
  frame,
  width = '100%',
  height = '100%',
  scaleFactor = 1,
  isMobile,
  assets = [],
  effectiveVariables = []
}) {
  const thumbnail = frame?.thumbnail;
  /*/ If thumbnail is provided, render the Thumbnail component
  if (thumbnail) {
      return <Thumbnail width={width} height={height} frame={frame} />;
  } 
  // Otherwise, render the Miniature component
  else { */
      return (
          <Miniature 
              frameObjects={frame?.objects || []}
              width={width}
              height={height} 
              assets={assets} 
              isMobile={isMobile}
              effectiveVariables={effectiveVariables}
          />
      );

}

function Miniature ({ 
  frameObjects, 
  isMobile,
  width, 
  height,
  assets, 
  effectiveVariables }){
  const rootObject = frameObjects.find(obj => obj.parent === obj.frame);
  const objects = frameObjects.filter(obj => obj.id !== rootObject?.id) || [];

  const themeStyles = convertThemeVariablesToStyles(effectiveVariables);

  return (
    <div
      className="relative flex flex-grow flex-col items-center justify-center"
      style={{
        ...themeStyles,
        width,
        height,
        color: 'var(--base-content)',
        fontFamily: `var(--mainFont), ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
        userSelect: 'none',
        pointerEvents: 'none',
      }}>
      {rootObject &&
        <LightRouter
          key={rootObject.id}
          self={rootObject}
          assets={assets}
          isMobile={isMobile}
          descendants={getDescendants(rootObject.id, objects)}
        />
      }
    </div>
  );
};

