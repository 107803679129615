const layouts = ['Email', 'AppShell', 'Slide'] // slide is deprecated
const sections = ['Main', 'Footer', 'IconBar', 'Sidebar', 'Header', 'FeaturePanel', 'SidePanel', 'Hero', 'CollapsibleSidebar', 'AppWrapper']
const containers = [
    'Modal', 'Drawer', // some dialogs
    'Module',
    'FlexBox', 'Grid', 'Accordion', 'Form', 'KebabMenu',  // pure containers        
    'SocialPost'
];

const containersCantAccept = [
    'AppShell', 'Email', 'Slide', // layout elements
    'Modal', 'Toast', 'Banner', 'Drawer', 'Popover', // dialogs
    'Main', 'Footer', 'IconBar', 'Sidebar', 'Header', 'FeaturePanel', 'Hero', 'SidePanel', 'AppWrapper' // sections
];


export function canPaste(parent, child) {
    
    if (!parent || !child) {
        return false;
    }
    
    const parentAPIName = parent.componentAPIName || 'Frame'
    const childAPIName = child.componentAPIName;


    return canPasteSimplified(parentAPIName, childAPIName)
}

export function canPasteSimplified(parentAPIName, childAPIName) {
    // console.log('parent', parent)
    // console.log('child', child)



    if (!parentAPIName || !childAPIName) {
        return false;
    }

    if (parentAPIName === 'Email' && childAPIName === 'Module') return true
    
    // console.log(childAPIName, parentAPIName)
    
    
    
    // Determine if the parent is a container from the containerList
    const isContainer = containers.includes(parentAPIName);

    if (isContainer) {
        // If it's a container, check if the child is NOT in the containersCantAccept list
        return !containersCantAccept.includes(childAPIName);
    }

    // Specific conditions based on parent type for elements not in the containerList
    let canPaste = false;
    

    if (parentAPIName === 'Frame') {
        canPaste = layouts.includes(childAPIName); // Frame can only accept layouts
    } else if (parentAPIName === 'AppShell') {
        canPaste = sections.includes(childAPIName); // AppShell can only directly accept sections
    } else if ([...sections, ...containers, 'Email'].includes(parentAPIName)) { 
        canPaste = true; // then we ask whether the parent is a container
    } else {
        canPaste = false; // if the parent is not a container, then it can't accept anything
    }

    return canPaste;
}