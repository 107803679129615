import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { EditorContext } from "../../EditorContext";

export default function PageName({frame, columnWidth, handleFrameSelection, showNotes=false}) {
    const [name, setName] = useState(frame.name);
    useEffect(() => {
      // Update the notes state when the frame prop changes
      setName(frame.name);
    }, [frame.name]);
  
    const [editable, setEditable] = useState(false);
    
    const { handleAction } = useContext(EditorContext);
  
    const handleBlur = () => {
      const newName = name.trim();
  
      if (newName.length === 0) {
        // If newName is empty, revert back to the original name
        setName(frame.name);
      } else if (newName !== frame.name) {
        // Update the flow name only if it's not empty and has changed
        updateFrameName(frame, newName);
      }
  
      setEditable(false);
    };
  
    useEffect(() => {
      setName(frame.name);
    }, [frame.name]);
  
  
    const handleKeyPress = (e) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
      }
    };
    
    const updateFrameName = (currentFrame, newName) => {
      
      const action = {
        type: 'UPDATE_FRAME',
        currentFrame: currentFrame,
        newFrame: { ...currentFrame, name: newName }
      };
      handleAction(action);
    };
  
  
    if (showNotes) return editable ? (
      <input
        type="text"
        value={name}
        onBlur={handleBlur}
        onChange={e => setName(e.target.value)}
        
        onKeyDown={handleKeyPress}
        autoFocus
        className="flex py-0.5 cursor-text text-left flex-grow truncate bg-white w-auto"
      />
    ) : (
      <div 
        onClick={(e)=> {handleFrameSelection(e, frame); setEditable(true)}}
        className="flex py-0.5 cursor-default text-left flex-grow truncate overflow-hidden w-auto transition-all"
        style={{ userSelect: 'none', minWidth: 0 }}
      >
        {name}
      </div>)
  }