export const safelist = `safelist: [

/* add to safelist 

bg-base-0/75 bg-base-50/75 bg-base-100/75 bg-base-200/75 bg-base-300/75 bg-base-400/75 bg-base-500/75 bg-base-600/75 bg-base-700/75 bg-base-800/75 bg-base-900/75 bg-base-content/75
bg-primary/75 bg-primary-focus/75 bg-primary-content/75 
bg-accent/75 bg-accent-focus/75 bg-accent-content/75
bg-info/75 bg-info-focus/75 bg-info-content/75
bg-success/75 bg-success-focus/75 bg-success-content/75
bg-warning/75 bg-warning-focus/75 bg-warning-content/75
bg-error/75 bg-error-focus/75 bg-error-content/75

md:bg-base-0/75 md:bg-base-50/75 md:bg-base-100/75 md:bg-base-200/75 md:bg-base-300/75 md:bg-base-400/75 md:bg-base-500/75 md:bg-base-600/75 md:bg-base-700/75 md:bg-base-800/75 md:bg-base-900/75 md:bg-base-content/75
md:bg-primary/75 md:bg-primary-focus/75 md:bg-primary-content/75 
md:bg-accent/75 md:bg-accent-focus/75 md:bg-accent-content/75
md:bg-info/75 md:bg-info-focus/75 md:bg-info-content/75
md:bg-success/75 md:bg-success-focus/75 md:bg-success-content/75
md:bg-warning/75 md:bg-warning-focus/75 md:bg-warning-content/75
md:bg-error/75 md:bg-error-focus/75 md:bg-error-content/75

@md:bg-base-0/75 @md:bg-base-50/75 @md:bg-base-100/75 @md:bg-base-200/75 @md:bg-base-300/75 @md:bg-base-400/75 @md:bg-base-500/75 @md:bg-base-600/75 @md:bg-base-700/75 @md:bg-base-800/75 @md:bg-base-900/75 @md:bg-base-content/75
@md:bg-primary/75 @md:bg-primary-focus/75 @md:bg-primary-content/75 
@md:bg-accent/75 @md:bg-accent-focus/75 @md:bg-accent-content/75
@md:bg-info/75 @md:bg-info-focus/75 @md:bg-info-content/75
@md:bg-success/75 @md:bg-success-focus/75 @md:bg-success-content/75
@md:bg-warning/75 @md:bg-warning-focus/75 @md:bg-warning-content/75
@md:bg-error/75 @md:bg-error-focus/75 @md:bg-error-content/75

hover:bg-base-0/75 hover:bg-base-50/75 hover:bg-base-100/75 hover:bg-base-200/75 hover:bg-base-300/75 hover:bg-base-400/75 hover:bg-base-500/75 hover:bg-base-600/75 hover:bg-base-700/75 hover:bg-base-800/75 hover:bg-base-900/75 hover:bg-base-content/75
hover:bg-primary/75 hover:bg-primary-focus/75 hover:bg-primary-content/75
hover:bg-accent/75 hover:bg-accent-focus/75 hover:bg-accent-content/75
hover:bg-info/75 hover:bg-info-focus/75 hover:bg-info-content/75
hover:bg-success/75 hover:bg-success-focus/75 hover:bg-success-content/75
hover:bg-warning/75 hover:bg-warning-focus/75 hover:bg-warning-content/75
hover:bg-error/75 hover:bg-error-focus/75 hover:bg-error-content/75
-left-2 -left-4 -right-2 -right-4 -top-2 -top-4 -bottom-2 -bottom-4

// hover:ring-primary/50 hover:ring-accent/50 hover:ring-base-0/50 hover:ring-base-100/50 hover:ring-base-200/50 hover:ring-base-300/50 hover:ring-base-700/50 hover:ring-error/50 hover:ring-success/50 hover:ring-warning/50

// ring-info/50 ring-success/50 ring-base-200/50 ring-base-700/50 ring-warning/50 ring-error/50
    // bg-info/10 bg-success/10 bg-base-200/10 bg-base-700/10 bg-warning/10 bg-error/10
    // bg-info/20 bg-success/20 bg-base-200/20 bg-base-700/20 bg-warning/20 bg-error/20

// tailwindcss safelist
// gap-0.5 gap-1 gap-1.5 gap-2 gap-2.5 gap-3 gap-3.5 gap-4 gap-5 gap-6 gap-8 gap-10 gap-12 
// px-0.5 px-1 px-1.5 px-2 px-2.5 px-3 px-3.5 px-4 px-5 px-6 px-8 px-10 px-12
// py-0.5 py-1 py-1.5 py-2 py-2.5 py-3 py-3.5 py-4 py-5 py-6 py-8 py-10 py-12

// pr-0.5 pr-1 pr-1.5 pr-2 pr-2.5 pl-0.5 pl-1 pl-1.5 pl-2 pl-2.5
    // ml-0.5 ml-1 ml-1.5 ml-2 ml-2.5 mr-0.5 mr-1 mr-1.5 mr-2 mr-2.5

// 'mt-0.5', 'mt-1', 'mt-1.5', 'mt-2', 'mt-3', 'mt-4', 'mt-6', 'mt-8', 'mt-12', 'mt-16',
    // mt-[1px] mt-[2px] mt-[6px] mt-[8px] mt-[12px] mt-[16px] mt-[24px] mt-[32px] mt-[40px] mt-[48px] mt-[56px] mt-[64px]
    // bg-base-200/20 bg-base-700/20 bg-base-content/20 bg-current-20 bg-accent/20 bg-primary/20 bg-info/20 bg-error/20 bg-warning/20 bg-success/20
    // ring-current
*/
    // pr-0.5 pr-1 pr-1.5 pr-2 pr-2.5 pl-0.5 pl-1 pl-1.5 pl-2 pl-2.5
    // ml-0.5 ml-1 ml-1.5 ml-2 ml-2.5 mr-0.5 mr-1 mr-1.5 mr-2 mr-2.5

    
    // Colors
    'bg-primary', 'bg-primary-content', 'bg-primary-focus', 'bg-primary-surface',
    'bg-secondary', 'bg-secondary-content', 'bg-secondary-focus', 'bg-secondary-surface',
    'bg-accent', 'bg-accent-content', 'bg-accent-focus', 'bg-accent-surface',
    'bg-base-0', 'bg-base-100', 'bg-base-200', 'bg-base-300', 'bg-base-400', 'bg-base-500', 'bg-base-600', 'bg-base-700', 'bg-base-900', 'bg-base-content',
    'bg-error', 'bg-error-content', 'bg-error-focus', 'bg-error-surface',
    'bg-info', 'bg-info-content', 'bg-info-focus', 'bg-info-surface',
    'bg-success', 'bg-success-content', 'bg-success-focus', 'bg-success-surface',
    'bg-warning', 'bg-warning-content', 'bg-warning-focus', 'bg-warning-surface',

    'border-primary', 'border-primary-content', 'border-primary-focus',
    'border-secondary', 'border-secondary-content', 'border-secondary-focus',
    'border-accent', 'border-accent-content', 'border-accent-focus',
    'border-base-0', 'border-base-100', 'border-base-200', 'border-base-300', 'border-base-400', 'border-base-500', 'border-base-600', 'border-base-700', 'border-base-900',
    'border-error', 'border-error-content', 'border-error-focus',
    'border-info', 'border-info-content', 'border-info-focus',
    'border-success', 'border-success-content', 'border-success-focus',
    'border-warning', 'border-warning-content', 'border-warning-focus',
    
    'outline-primary', 'outline-primary-content', 'outline-primary-focus',
    'outline-secondary', 'outline-secondary-content', 'outline-secondary-focus',
    'outline-accent', 'outline-accent-content', 'outline-accent-focus',
    'outline-base-0', 'outline-base-100', 'outline-base-200', 'outline-base-300', 'outline-base-400', 'outline-base-500', 'outline-base-600', 'outline-base-700', 'outline-base-900',
    'outline-error', 'outline-error-content', 'outline-error-focus',
    'outline-info', 'outline-info-content', 'outline-info-focus',
    'outline-success', 'outline-success-content', 'outline-success-focus',
    'outline-warning', 'outline-warning-content', 'outline-warning-focus',

    'ring-primary', 'ring-primary-content', 'ring-primary-focus',
    'ring-secondary', 'ring-secondary-content', 'ring-secondary-focus',
    'ring-accent', 'ring-accent-content', 'ring-accent-focus',
    'ring-base-0', 'ring-base-100', 'ring-base-200', 'ring-base-300', 'ring-base-400', 'ring-base-500', 'ring-base-600', 'ring-base-700', 'ring-base-900',
    'ring-error', 'ring-error-content', 'ring-error-focus',
    'ring-info', 'ring-info-content', 'ring-info-focus',
    'ring-success', 'ring-success-content', 'ring-success-focus',
    'ring-warning', 'ring-warning-content', 'ring-warning-focus',
    
    // Gaps
    'gap-3xs', 'gap-2xs', 'gap-xs', 'gap-sm', 'gap-md', 'gap-lg', 'gap-xl', 'gap-2xl', 'gap-3xl', 'gap-base',

    // Padding
    'p-3xs', 'p-2xs', 'p-xs', 'p-sm', 'p-md', 'p-lg', 'p-xl', 'p-2xl', 'p-3xl', 'p-base', 
    'px-3xs', 'px-2xs', 'px-xs', 'px-sm', 'px-md', 'px-lg', 'px-xl', 'px-2xl', 'px-3xl', 'px-base',
    'py-3xs', 'py-2xs', 'py-xs', 'py-sm', 'py-md', 'py-lg', 'py-xl', 'py-2xl', 'py-3xl', 'py-base',

    // Margins
    'm-3xs', 'm-2xs', 'm-xs', 'm-sm', 'm-md', 'm-lg', 'm-xl', 'm-2xl', 'm-3xl', 'm-base',
    'mx-3xs', 'mx-2xs', 'mx-xs', 'mx-sm', 'mx-md', 'mx-lg', 'mx-xl', 'mx-2xl', 'mx-3xl', 'mx-base',
    'my-3xs', 'my-2xs', 'my-xs', 'my-sm', 'my-md', 'my-lg', 'my-xl', 'my-2xl', 'my-3xl', 'my-base',
    'mt-3xs', 'mt-2xs', 'mt-xs', 'mt-sm', 'mt-md', 'mt-lg', 'mt-xl', 'mt-2xl', 'mt-3xl', 'mt-base',
    'mb-3xs', 'mb-2xs', 'mb-xs', 'mb-sm', 'mb-md', 'mb-lg', 'mb-xl', 'mb-2xl', 'mb-3xl', 'mb-base',

    // Text
    'text-xs', 'text-sm', 'text-base', 'text-lg', 'text-xl', 'text-2xl', 'text-3xl', 'text-4xl', 'text-5xl', 'text-6xl', 'text-7xl', 'text-8xl', 'text-9xl',

// Radius
rounded-none rounded-2xs rounded-xs rounded-sm rounded-base rounded rounded-md rounded-lg rounded-xl rounded-2xl rounded-3xl rounded-iphone
md:rounded-none md:rounded-2xs md:rounded-xs md:rounded-sm md:rounded-base md:rounded md:rounded-md md:rounded-lg md:rounded-xl md:rounded-2xl md:rounded-3xl md:rounded-iphone
@md:rounded-none @md:rounded-2xs @md:rounded-xs @md:rounded-sm @md:rounded-base @md:rounded @md:rounded-md @md:rounded-lg @md:rounded-xl @md:rounded-2xl @md:rounded-3xl @md:rounded-iphone
    

Colors

bg-error-surface
bg-current-5 hover:bg-current-10 hover:bg-current-5

bg-primary bg-primary-content bg-primary-focus
bg-secondary bg-secondary-content bg-secondary-focus
bg-accent bg-accent-content bg-accent-focus
bg-base-0 bg-base-100 bg-base-200 bg-base-300 bg-base-400 bg-base-500 bg-base-600 bg-base-700 bg-base-900 bg-base-content
bg-error bg-error-content bg-error-focus
bg-info bg-info-content bg-info-focus
bg-success bg-success-content bg-success-focus
bg-warning bg-warning-content bg-warning-focus

text-primary text-primary-content text-primary-focus text-primary-surface
text-accent text-accent-content text-accent-focus text-accent-surface
text-base-0 text-base-50 text-base-100 text-base-200 text-base-300 text-base-400 text-base-500 text-base-600 text-base-700 text-base-800 text-base-900 text-base-content
text-error text-error-content text-error-focus text-error-surface
text-info text-info-content text-info-focus text-info-surface
text-success text-success-content text-success-focus text-success-surface
text-warning text-warning-content text-warning-focus text-warning-surface

@md:text-primary @md:text-primary-content @md:text-primary-focus @md:text-primary-surface
@md:text-accent @md:text-accent-content @md:text-accent-focus @md:text-accent-surface
@md:text-base-0 @md:text-base-50 @md:text-base-100 @md:text-base-200 @md:text-base-300 @md:text-base-400 @md:text-base-500 @md:text-base-600 @md:text-base-700 @md:text-base-800 @md:text-base-900 @md:text-base-content
@md:text-error @md:text-error-content @md:text-error-focus @md:text-error-surface
@md:text-info @md:text-info-content @md:text-info-focus @md:text-info-surface
@md:text-success @md:text-success-content @md:text-success-focus @md:text-success-surface
@md:text-warning @md:text-warning-content @md:text-warning-focus @md:text-warning-surface

md:text-primary md:text-primary-content md:text-primary-focus md:text-primary-surface
md:text-accent md:text-accent-content md:text-accent-focus md:text-accent-surface
md:text-base-0 md:text-base-50 md:text-base-100 md:text-base-200 md:text-base-300 md:text-base-400 md:text-base-500 md:text-base-600 md:text-base-700 md:text-base-800 md:text-base-900 md:text-base-content
md:text-error md:text-error-content md:text-error-focus md:text-error-surface
md:text-info md:text-info-content md:text-info-focus md:text-info-surface
md:text-success md:text-success-content md:text-success-focus md:text-success-surface
md:text-warning md:text-warning-content md:text-warning-focus md:text-warning-surface

border-primary border-primary-content border-primary-focus
border-secondary border-secondary-content border-secondary-focus
border-accent border-accent-content border-accent-focus
border-base-0 border-base-100 border-base-200 border-base-300 border-base-400 border-base-500 border-base-600 border-base-700 border-base-900
border-error border-error-content border-error-focus
border-info border-info-content border-info-focus
border-success border-success-content border-success-focus
border-warning border-warning-content border-warning-focus

outline-primary outline-primary-content outline-primary-focus
outline-secondary outline-secondary-content outline-secondary-focus
outline-accent outline-accent-content outline-accent-focus
outline-base-0 outline-base-100 outline-base-200 outline-base-300 outline-base-400 outline-base-500 outline-base-600 outline-base-700 outline-base-900
outline-error outline-error-content outline-error-focus
outline-info outline-info-content outline-info-focus
outline-success outline-success-content outline-success-focus
outline-warning outline-warning-content outline-warning-focus

ring-primary ring-primary-content ring-primary-focus
ring-secondary ring-secondary-content ring-secondary-focus
ring-accent ring-accent-content ring-accent-focus
ring-base-0 ring-base-100 ring-base-200 ring-base-300 ring-base-400 ring-base-500 ring-base-600 ring-base-700 ring-base-900
ring-error ring-error-content ring-error-focus
ring-info ring-info-content ring-info-focus
ring-success ring-success-content ring-success-focus
ring-warning ring-warning-content ring-warning-focus

Gaps

gap-3xs gap-2xs gap-xs gap-sm gap-md gap-lg gap-xl gap-2xl gap-3xl gap-base

Padding

p-0.5 p-1 p-1.5 p-2 p-2.5 p-3 p-3.5 p-4 p-5 p-6 p-8 p-10 p-12 p-16 p-20 p-24 p-32 p-40 p-48 p-56 p-64
px-0.5 px-1 px-1.5 px-2 px-2.5 px-3 px-3.5 px-4 px-5 px-6 px-8 px-10 px-12 px-16 px-20 px-24 px-32 px-40 px-48 px-56 px-64
py-0.5 py-1 py-1.5 py-2 py-2.5 py-3 py-3.5 py-4 py-5 py-6 py-8 py-10 py-12 py-16 py-20 py-24 py-32 py-40 py-48 py-56 py-64

md:p-0.5 md:p-1 md:p-1.5 md:p-2 md:p-2.5 md:p-3 md:p-3.5 md:p-4 md:p-5 md:p-6 md:p-8 md:p-10 md:p-12 md:p-16 md:p-20 md:p-24 md:p-32 md:p-40 md:p-48 md:p-56 md:p-64
md:px-0.5 md:px-1 md:px-1.5 md:px-2 md:px-2.5 md:px-3 md:px-3.5 md:px-4 md:px-5 md:px-6 md:px-8 md:px-10 md:px-12 md:px-16 md:px-20 md:px-24 md:px-32 md:px-40 md:px-48 md:px-56 md:px-64
md:py-0.5 md:py-1 md:py-1.5 md:py-2 md:py-2.5 md:py-3 md:py-3.5 md:py-4 md:py-5 md:py-6 md:py-8 md:py-10 md:py-12 md:py-16 md:py-20 md:py-24 md:py-32 md:py-40 md:py-48 md:py-56 md:py-64

@md:p-0.5 @md:p-1 @md:p-1.5 @md:p-2 @md:p-2.5 @md:p-3 @md:p-3.5 @md:p-4 @md:p-5 @md:p-6 @md:p-8 @md:p-10 @md:p-12 @md:p-16 @md:p-20 @md:p-24 @md:p-32 @md:p-40 @md:p-48 @md:p-56 @md:p-64
@md:px-0.5 @md:px-1 @md:px-1.5 @md:px-2 @md:px-2.5 @md:px-3 @md:px-3.5 @md:px-4 @md:px-5 @md:px-6 @md:px-8 @md:px-10 @md:px-12 @md:px-16 @md:px-20 @md:px-24 @md:px-32 @md:px-40 @md:px-48 @md:px-56 @md:px-64
@md:py-0.5 @md:py-1 @md:py-1.5 @md:py-2 @md:py-2.5 @md:py-3 @md:py-3.5 @md:py-4 @md:py-5 @md:py-6 @md:py-8 @md:py-10 @md:py-12 @md:py-16 @md:py-20 @md:py-24 @md:py-32 @md:py-40 @md:py-48 @md:py-56 @md:py-64

Margins

m-3xs m-2xs m-xs m-sm m-md m-lg m-xl m-2xl m-3xl m-base
mx-3xs mx-2xs mx-xs mx-sm mx-md mx-lg mx-xl mx-2xl mx-3xl mx-base
my-3xs my-2xs my-xs my-sm my-md my-lg my-xl my-2xl my-3xl my-base
mt-3xs mt-2xs mt-xs mt-sm mt-md mt-lg mt-xl mt-2xl mt-3xl mt-base
mb-3xs mb-2xs mb-xs mb-sm mb-md mb-lg mb-xl mb-2xl mb-3xl mb-base

Text

text-xs text-sm text-base text-lg text-xl text-2xl text-3xl text-4xl text-5xl text-6xl text-7xl text-8xl text-9xl

Radius

rounded-2xs rounded-xs rounded-sm rounded-md rounded-lg rounded-xl rounded-2xl rounded-3xl rounded-base




RING VARIATIONS
// ring-base-0 ring-base-100 ring-base-200 ring-base-300 ring-primary ring-accent ring-success ring-error
// hover:ring-base-0 hover:ring-base-100 hover:ring-base-200 hover:ring-base-300 hover:ring-primary hover:ring-accent hover:ring-success hover:ring-error
// ring-base-0/50 ring-base-100/50 ring-base-200/50 ring-base-300/50 ring-primary/50 ring-accent/50 ring-success/50 ring-error/50
// hover:ring-base-0/50 hover:ring-base-100/50 hover:ring-base-200/50 hover:ring-base-300/50 hover:ring-primary/50 hover:ring-accent/50 hover:ring-success/50 hover:ring-error/50

bg-base-0/75 bg-base-50/75 bg-base-100/75 bg-base-200/75 bg-base-300/75 bg-base-400/75 bg-base-500/75 bg-base-600/75 bg-base-700/75 bg-base-800/75 bg-base-900/75 bg-base-content/75
bg-primary/75 bg-primary-focus/75 bg-primary-content/75 
bg-accent/75 bg-accent-focus/75 bg-accent-content/75
bg-info/75 bg-info-focus/75 bg-info-content/75
bg-success/75 bg-success-focus/75 bg-success-content/75
bg-warning/75 bg-warning-focus/75 bg-warning-content/75
bg-error/75 bg-error-focus/75 bg-error-content/75

hover:bg-base-0/75 hover:bg-base-50/75 hover:bg-base-100/75 hover:bg-base-200/75 hover:bg-base-300/75 hover:bg-base-400/75 hover:bg-base-500/75 hover:bg-base-600/75 hover:bg-base-700/75 hover:bg-base-800/75 hover:bg-base-900/75 hover:bg-base-content/75
hover:bg-primary/75 hover:bg-primary-focus/75 hover:bg-primary-content/75
hover:bg-accent/75 hover:bg-accent-focus/75 hover:bg-accent-content/75
hover:bg-info/75 hover:bg-info-focus/75 hover:bg-info-content/75
hover:bg-success/75 hover:bg-success-focus/75 hover:bg-success-content/75
hover:bg-warning/75 hover:bg-warning-focus/75 hover:bg-warning-content/75
hover:bg-error/75 hover:bg-error-focus/75 hover:bg-error-content/75
-left-2 -left-4 -right-2 -right-4 -top-2 -top-4 -bottom-2 -bottom-4

bg-base-0 bg-base-50 bg-base-100 bg-base-200 bg-base-300 bg-base-400 bg-base-500 bg-base-600 bg-base-700 bg-base-800 bg-base-900 bg-base-content
bg-primary bg-primary-content bg-primary-focus bg-primary-surface
bg-accent bg-accent-content bg-accent-focus bg-accent-surface
bg-info bg-info-content bg-info-focus bg-info-surface
bg-success bg-success-content bg-success-focus bg-success-surface
bg-warning bg-warning-content bg-warning-focus bg-warning-surface
bg-error bg-error-content bg-error-focus bg-error-surface

md:bg-base-0 md:bg-base-50 md:bg-base-100 md:bg-base-200 md:bg-base-300 md:bg-base-400 md:bg-base-500 md:bg-base-600 md:bg-base-700 md:bg-base-800 md:bg-base-900 md:bg-base-content
md:bg-primary md:bg-primary-content md:bg-primary-focus md:bg-primary-surface
md:bg-accent md:bg-accent-content md:bg-accent-focus md:bg-accent-surface
md:bg-info md:bg-info-content md:bg-info-focus md:bg-info-surface
md:bg-success md:bg-success-content md:bg-success-focus md:bg-success-surface
md:bg-warning md:bg-warning-content md:bg-warning-focus md:bg-warning-surface
md:bg-error md:bg-error-content md:bg-error-focus md:bg-error-surface

@md:bg-base-0 @md:bg-base-50 @md:bg-base-100 @md:bg-base-200 @md:bg-base-300 @md:bg-base-400 @md:bg-base-500 @md:bg-base-600 @md:bg-base-700 @md:bg-base-800 @md:bg-base-900 @md:bg-base-content
@md:bg-primary @md:bg-primary-content @md:bg-primary-focus @md:bg-primary-surface
@md:bg-accent @md:bg-accent-content @md:bg-accent-focus @md:bg-accent-surface
@md:bg-info @md:bg-info-content @md:bg-info-focus @md:bg-info-surface
@md:bg-success @md:bg-success-content @md:bg-success-focus @md:bg-success-surface
@md:bg-warning @md:bg-warning-content @md:bg-warning-focus @md:bg-warning-surface
@md:bg-error @md:bg-error-content @md:bg-error-focus @md:bg-error-surface



// hover:ring-primary/50 hover:ring-accent/50 hover:ring-base-0/50 hover:ring-base-100/50 hover:ring-base-200/50 hover:ring-base-300/50 hover:ring-base-700/50 hover:ring-error/50 hover:ring-success/50 hover:ring-warning/50

// ring-info/50 ring-success/50 ring-base-200/50 ring-base-700/50 ring-warning/50 ring-error/50
    // bg-info/10 bg-success/10 bg-base-200/10 bg-base-700/10 bg-warning/10 bg-error/10
    // bg-info/20 bg-success/20 bg-base-200/20 bg-base-700/20 bg-warning/20 bg-error/20

// tailwindcss safelist
// gap-0.5 gap-1 gap-1.5 gap-2 gap-2.5 gap-3 gap-3.5 gap-4 gap-5 gap-6 gap-8 gap-10 gap-12 
// px-0.5 px-1 px-1.5 px-2 px-2.5 px-3 px-3.5 px-4 px-5 px-6 px-8 px-10 px-12
// py-0.5 py-1 py-1.5 py-2 py-2.5 py-3 py-3.5 py-4 py-5 py-6 py-8 py-10 py-12

// pr-0.5 pr-1 pr-1.5 pr-2 pr-2.5 pl-0.5 pl-1 pl-1.5 pl-2 pl-2.5
    // ml-0.5 ml-1 ml-1.5 ml-2 ml-2.5 mr-0.5 mr-1 mr-1.5 mr-2 mr-2.5

// 'mt-0.5', 'mt-1', 'mt-1.5', 'mt-2', 'mt-3', 'mt-4', 'mt-6', 'mt-8', 'mt-12', 'mt-16',
    // mt-[1px] mt-[2px] mt-[6px] mt-[8px] mt-[12px] mt-[16px] mt-[24px] mt-[32px] mt-[40px] mt-[48px] mt-[56px] mt-[64px]
    // bg-base-200/20 bg-base-700/20 bg-base-content/20 bg-current-20 bg-accent/20 bg-primary/20 bg-info/20 bg-error/20 bg-warning/20 bg-success/20
    // ring-current

    // pr-0.5 pr-1 pr-1.5 pr-2 pr-2.5 pl-0.5 pl-1 pl-1.5 pl-2 pl-2.5
    // ml-0.5 ml-1 ml-1.5 ml-2 ml-2.5 mr-0.5 mr-1 mr-1.5 mr-2 mr-2.5

    
    // Colors
    'bg-primary', 'bg-primary-content', 'bg-primary-focus', 'bg-primary-surface',
    'bg-secondary', 'bg-secondary-content', 'bg-secondary-focus', 'bg-secondary-surface',
    'bg-accent', 'bg-accent-content', 'bg-accent-focus', 'bg-accent-surface',
    'bg-base-0', 'bg-base-100', 'bg-base-200', 'bg-base-300', 'bg-base-400', 'bg-base-500', 'bg-base-600', 'bg-base-700', 'bg-base-900', 'bg-base-content',
    'bg-error', 'bg-error-content', 'bg-error-focus', 'bg-error-surface',
    'bg-info', 'bg-info-content', 'bg-info-focus', 'bg-info-surface',
    'bg-success', 'bg-success-content', 'bg-success-focus', 'bg-success-surface',
    'bg-warning', 'bg-warning-content', 'bg-warning-focus', 'bg-warning-surface',

    'border-primary', 'border-primary-content', 'border-primary-focus',
    'border-secondary', 'border-secondary-content', 'border-secondary-focus',
    'border-accent', 'border-accent-content', 'border-accent-focus',
    'border-base-0', 'border-base-100', 'border-base-200', 'border-base-300', 'border-base-400', 'border-base-500', 'border-base-600', 'border-base-700', 'border-base-900',
    'border-error', 'border-error-content', 'border-error-focus',
    'border-info', 'border-info-content', 'border-info-focus',
    'border-success', 'border-success-content', 'border-success-focus',
    'border-warning', 'border-warning-content', 'border-warning-focus',
    
    'outline-primary', 'outline-primary-content', 'outline-primary-focus',
    'outline-secondary', 'outline-secondary-content', 'outline-secondary-focus',
    'outline-accent', 'outline-accent-content', 'outline-accent-focus',
    'outline-base-0', 'outline-base-100', 'outline-base-200', 'outline-base-300', 'outline-base-400', 'outline-base-500', 'outline-base-600', 'outline-base-700', 'outline-base-900',
    'outline-error', 'outline-error-content', 'outline-error-focus',
    'outline-info', 'outline-info-content', 'outline-info-focus',
    'outline-success', 'outline-success-content', 'outline-success-focus',
    'outline-warning', 'outline-warning-content', 'outline-warning-focus',

    'ring-primary', 'ring-primary-content', 'ring-primary-focus',
    'ring-secondary', 'ring-secondary-content', 'ring-secondary-focus',
    'ring-accent', 'ring-accent-content', 'ring-accent-focus',
    'ring-base-0', 'ring-base-100', 'ring-base-200', 'ring-base-300', 'ring-base-400', 'ring-base-500', 'ring-base-600', 'ring-base-700', 'ring-base-900',
    'ring-error', 'ring-error-content', 'ring-error-focus',
    'ring-info', 'ring-info-content', 'ring-info-focus',
    'ring-success', 'ring-success-content', 'ring-success-focus',
    'ring-warning', 'ring-warning-content', 'ring-warning-focus',
    
    // Gaps
    'gap-3xs', 'gap-2xs', 'gap-xs', 'gap-sm', 'gap-md', 'gap-lg', 'gap-xl', 'gap-2xl', 'gap-3xl', 'gap-base',

    // Padding
    'p-3xs', 'p-2xs', 'p-xs', 'p-sm', 'p-md', 'p-lg', 'p-xl', 'p-2xl', 'p-3xl', 'p-base', 
    'px-3xs', 'px-2xs', 'px-xs', 'px-sm', 'px-md', 'px-lg', 'px-xl', 'px-2xl', 'px-3xl', 'px-base',
    'py-3xs', 'py-2xs', 'py-xs', 'py-sm', 'py-md', 'py-lg', 'py-xl', 'py-2xl', 'py-3xl', 'py-base',

    // Margins
    'm-3xs', 'm-2xs', 'm-xs', 'm-sm', 'm-md', 'm-lg', 'm-xl', 'm-2xl', 'm-3xl', 'm-base',
    'mx-3xs', 'mx-2xs', 'mx-xs', 'mx-sm', 'mx-md', 'mx-lg', 'mx-xl', 'mx-2xl', 'mx-3xl', 'mx-base',
    'my-3xs', 'my-2xs', 'my-xs', 'my-sm', 'my-md', 'my-lg', 'my-xl', 'my-2xl', 'my-3xl', 'my-base',
    'mt-3xs', 'mt-2xs', 'mt-xs', 'mt-sm', 'mt-md', 'mt-lg', 'mt-xl', 'mt-2xl', 'mt-3xl', 'mt-base',
    'mb-3xs', 'mb-2xs', 'mb-xs', 'mb-sm', 'mb-md', 'mb-lg', 'mb-xl', 'mb-2xl', 'mb-3xl', 'mb-base',

    // Text
    'text-xs', 'text-sm', 'text-base', 'text-lg', 'text-xl', 'text-2xl', 'text-3xl', 'text-4xl', 'text-5xl', 'text-6xl', 'text-7xl', 'text-8xl', 'text-9xl',

    // Radius
    'rounded-2xs', 'rounded-xs', 'rounded-sm', 'rounded-md', 'rounded-lg', 'rounded-xl', 'rounded-2xl', 'rounded-3xl', 'rounded-base', 


    // md:py-12 md:px-12 md:py-8 md:px-8
    // md:py-6 md:px-6 md:py-4 md:px-4 md:py-3 md:px-3 md:py-2 md:px-2 md:py-1 md:px-1

    // border-current border-current-50
    // border-r-primary/20 border-t-primary/20 
    // border-r-accent/20 border-t-accent/20
    // border-r-error/20 border-t-error/20
    // border-r-warning/20 border-t-warning/20
    // border-r-success/20 border-t-success/20
    // border-r-info/20 border-t-info/20


    // grid-cols-1 grid-cols-2 grid-cols-3 grid-cols-4 grid-cols-5 grid-cols-6 
    // sm:grid-cols-1 sm:grid-cols-2 sm:grid-cols-3 sm:grid-cols-4 sm:grid-cols-5 sm:grid-cols-6
    // md:grid-cols-1 md:grid-cols-2 md:grid-cols-3 md:grid-cols-4 md:grid-cols-5 md:grid-cols-6
    // lg:grid-cols-1 lg:grid-cols-2 lg:grid-cols-3 lg:grid-cols-4 lg:grid-cols-5 lg:grid-cols-6
*/

]`.trim();