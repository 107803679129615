
import { Icon } from '../';
import {IconType, allIconNames} from '../iconMap';


type DataCardProps = {
    title?: string,
    value?: string,
    changeValue?: string,
    changeColor?: 'primary' | 'accent' | 'base-content' | 'success' | 'warning' | 'error' | 'info',
    textSize?: 'small' | 'medium',
    icon?: Extract<IconType, 'chart-up' | 'chart-down' | 'chart-up-down' | 'chart-down-up'>;
    helpText?: string,
    __juno?: any;
}

export default function DataCard({
        title = "metric",
        value = "value",
        changeValue,
        changeColor,
        textSize = 'small',
        icon,
        helpText,
        __juno = {}
    }: DataCardProps) {

    const sizeStyles = `w-full h-auto ${textSize == 'small' ? 'text-sm' : 'text-base'}`
    const truncateStyle = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}

    const classes = `flex flex-row items-stretch justify-start gap-3 ${sizeStyles}`

    const titleFont = textSize == 'small' ? 'text-sm' : 'text-base';
    const smallerFont = textSize == 'small' ? 'text-xs' : 'text-sm';
    
    const IconComponent = icon ? <Icon icon={icon} /> : null;

    return (
        <div 
        className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
        {...__juno?.attributes}
        >
        {IconComponent}

        {/* CONTENT BLOCK */}
        <div className={`flex flex-col flex-grow items-start ${smallerFont} gap-1.5`} style={truncateStyle}>
            
            {title && <div className={`flex-shrink-0 flex flex-row items-center relative group`}>
                {title}
            </div>}

            {/* Value */}
            <div className='flex flex-row items-baseline gap-2' >
                    <h3 className={`${titleFont} flex flex-row font-semibold `} style={truncateStyle}>
                        {value}
                    </h3>
                    <span className={`${smallerFont} font-semibold text-${changeColor}`} style={truncateStyle}>
                        {changeValue}
                    </span>
            </div>
            

            {/* Description */}
            {helpText && <div className={`${smallerFont} -mt-1`} style={truncateStyle}>
                {helpText}
            </div>}
        </div>
        </div>
    );
}


/* ignore rest */

DataCard.definitions = {
    apiName: 'DataCard',
    displayName: 'DataCard',
    description: `Small card to visualize one piece of important data, useful for KPIs and other important metrics`,
    ai_instructions: `Small horizontal to visualize one piece of important data, useful for KPIs and other important metrics`,
    type: 'cards',
    relativeSize: 'medium',
    acceptedChildren: 'none',
    status: 'prototype',
    package: 'Pro',
    propDefinitions: {
        title: {
            type: 'string',
            default: "metric",
            displayName: 'Title', 
            editable: true
        }, 
        value: {
            type: 'string',
            default: "value", 
            displayName: 'Value',
            tile: '1/2',
            required: true, 
            editable: true
        }, 
        changeValue: {
            type: 'string',
            default: null, 
            displayName: 'Change Value',
            tile: '1/2',
            ai_instructions: 'optional short stirng, can represent change in value', 
            editable: true
        }, 
        changeColor: {
            type: 'colors',
            options: ['primary', 'accent', 'base-content', 'success', 'warning', 'error', 'info'],
            displayName: 'Change Color',
            default: null
        },
        helpText: {
            type: 'string',
            displayName: 'Help Text',
            default: null,
            ai_instructions: 'Helpful text no more than 40 characters', 
            editable: true
        },
        
        
        textSize: {
            type: 'size',
            options: ['small', 'medium'],
            displayName: 'Text Size',
            ai_instructions: 'small sets title, price to text-sm and description to text-xs, medium sets title and price to text-base and description to text-sm',
            default: 'small',
            tile: '1/2'
        },
        icon: {
            type: 'icon',
            options: ['none', ...allIconNames],
            displayName: 'left icon',
            ai_instructions: 'icon that appears on the left side of the card to illustrate',
            default: null
        },
        
        

    }
};

/*&DataCard.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    changeValue: PropTypes.string,
    hasOutline: PropTypes.bool,
    changeColor: PropTypes.oneOf(['primary', 'accent', 'base-content', 'success', 'warning', 'error', 'info']),
    textSize: PropTypes.oneOf(['small', 'medium']),
    icon: PropTypes.oneOf(['none', ...allIconNames]),
    helpText: PropTypes.string,
};*/