// editorAPI.js
import axios from 'axios';
import { getSelectorFromLocalStorage } from './localStorage';

export const fetchProjectData = async (projectID, user, dispatch, selector) => {
  try {
    const token = user ? user.token : null;
    const response = await axios.get(`/api/project/${projectID}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Dispatch the SET_PROJECT action
    dispatch({ type: 'SET_PROJECT', payload: response.data });
    
    // by default nothing is selected
    let newSelector = {
      folder: null,
      page: null,
      frame: null
    }
    
    const localStorageSelector = getSelectorFromLocalStorage(response.data)
    if (localStorageSelector) {
      newSelector = localStorageSelector
    }

    // Dispatch the SET_SELECTOR action
    dispatch({ 
      type: 'SET_SELECTOR', 
      payload: { ...selector, 
        folder: newSelector.folder,
        page: newSelector.page,
        frame: newSelector.frame
      }
    });

    if (response.data.designSystem && response.data.designSystem.id) {
      fetchDesignSystemData(response.data.designSystem.id, token, dispatch, user, projectID);
    }
    
  } catch (error) {
    console.error("Error fetching project data:", error);
  }
};

export const fetchDesignSystemData = async (designSystemID, token, dispatch, user, projectId) => {
    try {
      
      const designResponse = await axios.post(`/design-system/${designSystemID}`, {
        userId: user?.id || null, 
        projectId: projectId || null
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const componentsResponse = await axios.get(`/design-system/${designSystemID}/components`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      // Combine the design system and components into one object
      const designSystemWithComponents = {
        ...designResponse.data,
        components: componentsResponse.data,
      };
  
      // Dispatch the SET_DESIGN_SYSTEM action
      dispatch({ type: 'SET_DESIGN_SYSTEM', payload: designSystemWithComponents });
  
    } catch (error) {
      console.error("Error fetching design system and components data:", error);
    }
  };
  