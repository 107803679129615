import { Icon } from '../';
import { useMemo } from 'react';
import { allIconNames, IconType } from '../iconMap';

type MiniCardHorizontalProps = {
    description?: string,
    textSize?: 'small' | 'medium',
    imageSize?: '40px' | '80px' | '92px' | '120px',
    title: string,
    imageSrc?: string,
    secondaryText?: string,
    icon?: IconType,
    corners?: 'none' | 'sm' | 'md' | 'lg' | 'xl',
    __juno?: any
}

export default function MiniCardHorizontal({
        description,
        textSize = 'small',
        imageSize = '80px',
        title = "Card Title",
        imageSrc,
        secondaryText = "Jun 2, 2023",
        icon = 'calendar',
        corners = 'md',
        __juno = {}
    }: MiniCardHorizontalProps) {
    

    const sizeStyles = `w-full h-auto ${textSize == 'small' ? 'text-sm' : 'text-base'}`
    const truncateStyle = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}

    const classes = `flex flex-row items-stretch justify-start gap-3 ${sizeStyles}`

    const IconComponent = icon  ? <Icon icon={icon} className='flex-shrink-0' /> : null;

    const noImage = !imageSrc || imageSrc === '';
    const imageStyles = useMemo(() => ({
        background: !noImage && `linear-gradient(rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.12)), url(${imageSrc}) no-repeat center center / cover`, 
        backgroundSize: 'cover', 
        minHeight: '100%', 
        minWidth: imageSize, 
        flexShrink: 0
    }), [imageSrc, noImage, imageSize]);

    const cornerStyles = corners === 'none' ? '' : `rounded-${corners}`;
    const titleFont = textSize == 'small' ? 'text-base' : 'text-lg';
    const smallerFont = textSize == 'small' ? 'text-xs' : 'text-sm';
    
    return (
        <div 
        className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
        {...__juno?.attributes}
        >
        {/* IMAGE */}
        {<div className={`relative flex flex-shrink-0 aspect-square h-full items-center justify-center bg-current-10 ${cornerStyles}`} style={imageStyles}>
            {noImage && <Icon icon={'image'}  className='flex-shrink-0' />}
        </div>}
        
        {/* CONTENT BLOCK */}
        <div style={truncateStyle} className={`flex flex-col flex-grow gap-1.5 overflow-hidden`}>
            
            {/* Title */}
            <div className={`flex flex-col justify-between gap-2 items-start`}>
                <h3 className={`font-semibold ${titleFont}`} style={truncateStyle}>
                    {title}
                </h3>
                {(secondaryText) && 
                <div className='flex-shrink-0 flex flex-row items-center gap-2 '>
                    {IconComponent}
                    {secondaryText}
                </div>}
            </div>

            {/* Description */}
            {description && <div className={`${smallerFont}`} style={truncateStyle}>
                {description}
            </div>}
        </div>
        </div>
    );
}



/* ignore rest */

MiniCardHorizontal.definitions = {
    apiName: 'MiniCardHorizontal',
    displayName: 'Mini Card Horizontal',
    description: '...',
    ai_instructions: '...',
    type: 'cards',
    relativeSize: 'medium',
    acceptedChildren: 'none',
    status: 'prototype',
    package: 'Pro',
    propDefinitions: {
        title: {
            type: 'string',
            default: "Card Title", 
            required: true, 
            displayName: 'Title',
            editable: true
        }, 
        secondaryText: {
            type: 'string',
            default: "Jun 2, 2023",
            ai_instructions: 'appears below title, can be used for rating, date, number reviews or similar',
            displayName: 'Secondary Text', 
            editable: true
        }, 
        description: {
            type: 'string',
            default: "Short, ~50 chars long.",
            ai_instructions: 'max 100 chatacters, will be trimmed if longer', 
            displayName: 'Description', 
            editable: true
        },

        textSize: {
            type: 'size',
            options: ['small', 'medium'],
            ai_instructions: 'small sets title, price to text-sm and description to text-xs, medium sets title and price to text-base and description to text-sm',
            default: 'small', 
            displayName: 'Text Size', 
            tile: '1/2'
        },
        corners: {
            type: 'corners',
            options: ["none", "sm", "md", "lg", 'xl'],
            default: 'md',
            ai_instructions: 'rounded corners for the thumbnail image', 
            displayName: 'Corners',
            tile: '1/2'
        }, 
        icon: {
            type: 'oneOf',
            options: ['none', ...allIconNames],
            displayName: 'top right icon',
            ai_instructions: 'icon that appears in next to secondary text, not clickable',
            default: 'calendar', 
        },
        imageSrc: {
            type: 'imageURL',
            default: null, 
            
        },
        imageSize: {
            type: 'oneOf',
            options: ['40px', '80px', '92px', '120px'],
            default: '80px'
        },

    }
};

/*MiniCardHorizontal.propTypes = {
    imageSrc: PropTypes.string,
    textSize: PropTypes.oneOf(['small', 'medium']),
    title: PropTypes.string.isRequired,
    hasMedia: PropTypes.bool,
    icon: PropTypes.oneOf(['none', ...allIconNames]),
    secondaryText: PropTypes.string,
    description: PropTypes.string,
    corners: PropTypes.oneOf(['none', 'sm', 'md', 'lg', 'xl']),
    imageSize: PropTypes.oneOf(['80px', '92px', '120px']),
};*/