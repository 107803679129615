import React, { useEffect, useRef, useState } from 'react';

export default function BaseColorPicker({ theme, setTheme, baseColorPresets, functionColorsPresets }) {
    const [isDropdownOpen, setDropdownOpen] = useState(false);
    const currentSet= theme?.baseColorSet
    const [savedSet, setSavedSet] = useState(null);
    const elementRef = useRef(null);

    const baseColorNames = ['base-0', 'base-50', 'base-100', 'base-200', 'base-300', 'base-400', 'base-500', 'base-600', 'base-700', 'base-content', 'base-800', 'base-900'];

    const [customSet, setCustomSet] = useState(currentSet === 'custom' ? baseColorNames.reduce((obj, colorKey) => {
        if (theme.colors.hasOwnProperty(colorKey)) {
          obj[colorKey] = theme.colors[colorKey];
        }
        return obj;
      }, {}) : null);
    
      // Determine the paletteObject based on currentSet
      let paletteObject
      if (currentSet === 'custom') {
        paletteObject = customSet; // Use state if 'custom'
      } else {
        paletteObject = baseColorPresets[currentSet]; // Use a preset object
      }

      const allPalettes = {
        ...baseColorPresets,
        ...customSet && Object.keys(customSet).length > 0 ? { custom: customSet } : {}
      }

    const handleSelect = (setName) => {
        const darkPalettes = ['ash', 'raisin', 'slytherin']
        const newIsDark = darkPalettes.includes(setName);
        const currentIsDark = theme.isDark;

        let newFunctionalColors = {}
        if (newIsDark !== currentIsDark) {
            const pullSet = newIsDark ? functionColorsPresets.dark : functionColorsPresets.light;
            newFunctionalColors = pullSet['default'];
        }

        setTheme({
            ...theme, 
            baseColorSet: setName,
            isDark: newIsDark,
            colors: {
                ...theme.colors, 
                ...allPalettes[setName],
                ...newFunctionalColors
                
            }
        });
    };

    
    useEffect(() => {
        function handleClickOutside(event) {
            if (elementRef.current && !elementRef.current.contains(event.target)) {
                setDropdownOpen(false);
                if (savedSet) { // Apply the saved font when dropdown closes
                    handleSelect(savedSet);
                    setSavedSet(null); // Clear the saved font after applying
                }
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [savedSet, theme, setTheme]); // Dependencies are added to ensure closure has the latest state

    const handleDropdownToggle = () => {
        setDropdownOpen(!isDropdownOpen);
        if (!isDropdownOpen) { // If we are about to open the dropdown
            setSavedSet(currentSet); // Save the current font when dropdown opens
        }
    };

    const displayFilter = ['base-100', 'base-300', 'base-500', 'base-content']    
    const circleSize = 28

    
    return (
        <div className="relative flex flex-col"
        ref={elementRef}
        >
            {/* Selected item display */}
            <div className="flex flex-row gap-0 items-center hover:scale-105 transition-all rounded-md justify-between"
                onClick={handleDropdownToggle}
            >
                
                
                {Object.entries(paletteObject)
                    .filter(([shade, color]) => displayFilter.includes(shade))
                    .map(([shade, color], index) => ( // Include the index parameter here
                        <div
                            key={shade}
                            className="rounded-full"
                            style={{
                                backgroundColor: color,
                                width: circleSize,
                                height: circleSize,
                                marginLeft: index > 0 ? `-${circleSize / 3}px` : 0
                            }}
                            title={`${currentSet} - ${shade}`}
                            aria-label={`Select ${currentSet} shade ${shade}`}
                        ></div>
                    ))}
                    </div>

            {/* Dropdown for other options */}
            {isDropdownOpen && (
                <div className="absolute -bottom-2 left-1/2 -translate-x-2/3 translate-y-full
                bg-base-0 border border-base-200 rounded-lg p-1 shadow-lg z-10">
                    {Object.keys(allPalettes)
                        // .filter(setName => setName !== currentSet)
                        .map(setName => (
                            <div key={setName} 
                                className={`flex flex-row gap-2 items-center p-1 justify-between w-full rounded-md transition-all
                                ${setName == currentSet ? 'bg-base-100' : 'bg-base-0 hover:bg-base-100'}`}
                                // onMouseOver={() => currentSet !== setName && handleSelect(setName)}
                                // onMouseLeave={() => currentSet !== setName && handleSelect(savedSet || currentSet)}
                                onClick={() => {
                                    setSavedSet(setName);
                                    setDropdownOpen(false);
                                    handleSelect(setName);
                                }}
                            >
                                <div className="flex flex-row gap-0 overflow-hidden flex-grow">
                                    {Object.entries(allPalettes[setName])
                                    .filter(([shade, color]) => displayFilter.includes(shade))
                                    .map(([shade, color], index) => ( // Include the index parameter here
                                        <div
                                            key={shade}
                                            className="rounded-full"
                                            style={{
                                                backgroundColor: color,
                                                width: circleSize * 0.8,
                                                height: circleSize * 0.8,
                                                marginLeft: index > 0 ? `-${(circleSize * 0.8 )/ 3}px` : 0
                                            }}
                                            title={`${setName} - ${shade}`}
                                            aria-label={`Select ${setName} shade ${shade}`}
                                        ></div>
                                    ))}
                                </div>
                                <span className='truncate'>
                                    {setName}
                                </span>
                            </div>
                        ))}
                </div>
            )}
        </div>
    );
}