import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import UserContext from '../../UserContext';
import logo from './juno-new.svg'; // Adjust path as needed
import * as IconoirIcons from 'iconoir-react';

export default function LogoutPage() {
  const { setUser, user, isAuthenticated, isGuestUser, handleLogout } = useContext(UserContext);
  const navigate = useNavigate();
  const Arrow = IconoirIcons['ArrowRight'];
  return (
    <div className='flex flex-col items-center justify-center h-screen bg-white'>
      
      <div className='max-w-[420px] w-full bg-white rounded-lg p-6 pb-10 flex flex-col gap-10 items-center'>
        <img src={logo} alt="Logo" width={180} className='mx-auto'/>
        {isAuthenticated ? 
        
        <><span>You are currently logged in as {isGuestUser ? 'a guest' : user.firstname}</span>
        <button onClick={handleLogout} className='rounded-xl text-center bg-orange-600 text-white p-4 hover:bg-orange-700 font-medium transition-colors pointer duration-200' >Log Out</button>  
        </> 
        : <>
        You aren't logged in.
        <div className='w-full flex flex-row gap-3'>
        
        <button onClick={() => navigate('/')} className='w-full rounded-xl text-center p-4 transition-colors duration-200 bg-slate-50 pointer hover:bg-slate-100'>Home</button>
        <button onClick={() => navigate('/login')} className='w-full flex flex-row gap-2 justify-center items-center rounded-xl text-center bg-orange-600 text-white p-4 hover:bg-orange-700 font-medium transition-colors pointer duration-200' >Log In
        <Arrow height={20} width={20} style={{strokeWidth: 1.5}}/>
        </button> 
        </div>
        
        
        </>}
        
      </div>
      
    </div>
  );
}
