import { nanoid } from "nanoid"
import {v1 as uuidv1 } from 'uuid'

export function deletePage({ page, handleAction }) {

    const isConfirmed = window.confirm(`Are you sure you want to delete the page: ${page.name}?`);

    if (isConfirmed) {
        const action = {
            type: 'DELETE_PAGE',
            page
        };
        handleAction(action);
    } else {
        console.log('Page deletion was canceled.');
    }
}
export function duplicatePage({page, handleAction}) {
    
    const newPage = {
        ...page,
        name: `${page.name} Copy`,
        index: page.index + 1,
        id: uuidv1()}

    const action = {
            type: 'INSERT_PAGE',
            newPage
        }
    handleAction(action);
    
}