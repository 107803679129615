export function getDescendants(componentId, componentsArray=[]) {
    // returns a list of children and their children for a component
    let list = []
    for (let item of componentsArray ) {
            item.parent === componentId && list.push(item, ...getDescendants(item.id, componentsArray))
    }
    return list
}

export function addCoordinates(array, bounds) {
  let newArray = array.map((obj) => {
      // Find the corresponding item from bounds only once per object
      const boundItem = bounds.find((i) => i.id === obj.id);

      return {
          ...obj,
          xLeft: boundItem?.left,
          xRight: boundItem?.right,
          yTop: boundItem?.top,
          yBottom: boundItem?.bottom,
          xCenter: boundItem?.x,
          yCenter: boundItem?.y,
          direction: boundItem?.direction,
          isClosest: false,       
      };
  });

  return newArray;
}

export function addCoordinatesV2(dropTargetChildren, refs) {
  let newArray = dropTargetChildren.map((obj) => {
      // Find the corresponding ref for the object
      const refItem = refs.find(ref => ref.id === obj.id);
      
      // Get the bounds for the object
      const bounds = refItem ? getBounds(refItem.node, refItem.id, refItem.index) : null;
      
      return {
          ...obj,
          xLeft: bounds?.left,
          xRight: bounds?.right,
          yTop: bounds?.top,
          yBottom: bounds?.bottom,
          xCenter: bounds?.x,
          yCenter: bounds?.y,
          direction: bounds?.direction,
          isClosest: false, // You can modify this as needed
      };
  });

  return newArray;
}
//
export function findClosestObject(array, cursorX, cursorY) {
    if (!array || array.length === 0) return null;

    // console.log('hi')
    // make an array of all objects and their distance to cursor
    const arrayWithDistance = array.map((obj) => ({
        ...obj,
        distance: getShortestDistance(
          cursorX,
          cursorY,
          obj.xLeft,
          obj.xRight,
          obj.yTop,
          obj.yBottom,
        ),
      }));

    // find the object with the shortest distance
    const closestObject = arrayWithDistance.reduce(function (prev, curr) {
        return prev.distance < curr.distance ? prev : curr;
      })
    // console.log('closest object', closestObject)
    
    return closestObject
}


export function getInternalDirection(node) {
    const display = getComputedStyle(node).getPropertyValue("display");
      if (display === "block") {
        return "column";
      } else if (display === "inline") {
        return "row";
      } else {
        const direction = getComputedStyle(node).getPropertyValue("flex-direction");
        // console.log('direction', direction)
        return direction
      }
}


export function getPseudoIndex(direction, closestObject, cursorX, cursorY) {
    
    const index = direction === "row"
          ? cursorX >= closestObject.xCenter
            ? closestObject.index + 0.5
            : closestObject.index - 0.5
          : cursorY >= closestObject.yCenter
          ? closestObject.index + 0.5
          : closestObject.index - 0.5
    return index 
}

export function getNewIndex(pseudoIndex, closestObject, draggedItem) {
    const moveUp =
        closestObject.parent === draggedItem.parent
            ? draggedItem.index < pseudoIndex
              ? true
              : false
            : false;
        
    const newIndex =
          closestObject.id === draggedItem.id
            ? closestObject.index
            : pseudoIndex + 0.5 - moveUp;
    
    return newIndex
}


export function getInsertParameters(direction, closestObject, secondClosestObject, pseudoIndex) {
  let style = {}
  
  const drawBetweenObjects = (
    secondClosestObject &&
    ((pseudoIndex > closestObject.index && pseudoIndex < secondClosestObject.index) ||
    (pseudoIndex < closestObject.index && pseudoIndex > secondClosestObject.index))
  );

  const thickness = 6
  if (direction === "row") {
    // vertical line
    style.position = "absolute";
    style.side = "left";
    style.top = closestObject.yTop;
    style.height = closestObject.yBottom - closestObject.yTop;
    style.width = thickness;

    if (drawBetweenObjects) {
      if (closestObject.index < secondClosestObject.index) {
        style.left = (closestObject.xRight + secondClosestObject.xLeft - thickness) / 2;
      } else {
        style.left = (closestObject.xLeft + secondClosestObject.xRight - thickness) / 2;
      }
    } else {
      style.left = pseudoIndex > closestObject.index ? closestObject.xRight - thickness / 2 : closestObject.xLeft - thickness / 2;
    }
  } else {
    // horizontal line
    style.position = "absolute";
    style.side = "top";
    style.left = closestObject.xLeft;
    style.width = closestObject.xRight - closestObject.xLeft;
    style.height = thickness;
    
    if (drawBetweenObjects) {
      if (closestObject.index < secondClosestObject.index) {
        style.top = (closestObject.yBottom + secondClosestObject.yTop - thickness) / 2;
      } else {
        style.top = (closestObject.yTop + secondClosestObject.yBottom - thickness) / 2;
      }
    } else {
      style.top = pseudoIndex > closestObject.index ? closestObject.yBottom - thickness / 2 : closestObject.yTop - thickness / 2;
    }
  }

  return style;
}

export function getInsertParameters2(direction, closestObject, secondClosestObject, pseudoIndex, draggingItemRef, parentBounds) {
  let dimensions = { width: 0, height: 0 };

  if (draggingItemRef && draggingItemRef.current) {
      const rect = draggingItemRef.current.getBoundingClientRect();
      dimensions.width = rect.width;
      dimensions.height = rect.height;
  }

  // Choose the smaller size between draggingItemRef and dropParentDimensions
  if (parentBounds) {
      dimensions.width = Math.min(parentBounds.width, 48) //dimensions.width);
      dimensions.height = Math.min(parentBounds.height, 48) //dimensions.height);
  }

  let style = {}
  
  const drawBetweenObjects = (
    secondClosestObject &&
    ((pseudoIndex > closestObject.index && pseudoIndex < secondClosestObject.index) ||
    (pseudoIndex < closestObject.index && pseudoIndex > secondClosestObject.index))
  );

  const thickness = 6
  if (direction === "row") {
    // vertical line
    style.position = "absolute";
    style.side = "left";
    style.top = (closestObject.yTop + closestObject.yBottom) / 2
    style.height = dimensions?.height || 10
    style.width = thickness;
    style.transform = 'translateY(-50%)'

    if (drawBetweenObjects) {
      if (closestObject.index < secondClosestObject.index) {
        style.left = (closestObject.xRight + secondClosestObject.xLeft - thickness) / 2;
      } else {
        style.left = (closestObject.xLeft + secondClosestObject.xRight - thickness) / 2;
      }
    } else {
      style.left = pseudoIndex > closestObject.index ? closestObject.xRight - thickness / 2 : closestObject.xLeft - thickness / 2;
    }
  } else {
    // horizontal line
    style.position = "absolute";
    style.side = "top";
    style.left = (closestObject.xLeft + closestObject.xRight)/2
    style.width = dimensions?.width || 10
    style.height = thickness;
    style.transform = 'translateX(-50%)'
    
    if (drawBetweenObjects) {
      if (closestObject.index < secondClosestObject.index) {
        style.top = (closestObject.yBottom + secondClosestObject.yTop - thickness) / 2
      } else {
        style.top = (closestObject.yTop + secondClosestObject.yBottom - thickness) / 2
      }
    } else {
      style.top = pseudoIndex > closestObject.index ? closestObject.yBottom - thickness / 2 : closestObject.yTop - thickness / 2;
    }
  }

  return style;
}


export function getObjectBounds(node, scrollX, scrollY, id=null, index=null, ) {
    let bounds = {}
    
    bounds.id = id
    bounds.index = index 
    bounds.left = Math.round(node.getBoundingClientRect().left + scrollX);
    bounds.right = Math.round(node.getBoundingClientRect().right + scrollX);
    bounds.top = Math.round(node.getBoundingClientRect().top + scrollY);
    bounds.bottom = Math.round(node.getBoundingClientRect().bottom + scrollY);
    bounds.x = Math.round((node.getBoundingClientRect().left + node.getBoundingClientRect().right)/2 + scrollX)
    bounds.y = Math.round((node.getBoundingClientRect().top + node.getBoundingClientRect().bottom)/2 + scrollY)
    bounds.width = Math.round(node.getBoundingClientRect().width);
    bounds.height = Math.round(node.getBoundingClientRect().height);
    bounds.direction = getInternalDirection(node);
    
    return bounds

}

export function getBounds(objectRef, id = null, index = null) {
  let bounds = {};
  const node = objectRef
  
  if (!node) return null; // Ensure the node exists
  
  const scrollX = window.scrollX;
  const scrollY = window.scrollY;

  bounds.id = id;
  bounds.index = index;
  bounds.left = Math.round(node.getBoundingClientRect().left + scrollX);
  bounds.right = Math.round(node.getBoundingClientRect().right + scrollX);
  bounds.top = Math.round(node.getBoundingClientRect().top + scrollY);
  bounds.bottom = Math.round(node.getBoundingClientRect().bottom + scrollY);
  bounds.x = Math.round((node.getBoundingClientRect().left + node.getBoundingClientRect().right) / 2 + scrollX);
  bounds.y = Math.round((node.getBoundingClientRect().top + node.getBoundingClientRect().bottom) / 2 + scrollY);
  bounds.width = Math.round(node.getBoundingClientRect().width);
  bounds.height = Math.round(node.getBoundingClientRect().height);
  // If you need to calculate direction, you can add it here
  bounds.direction = getInternalDirection(node);

  return bounds;
}

export function getNamePlateBounds(node, scrollX, scrollY) {
    let bounds = {}
    
    bounds.left = node.getBoundingClientRect().left + scrollX;
    bounds.top = node.getBoundingClientRect().top + scrollY - 16;

    return bounds

}

export function getDescendantIds(componentId, componentsArray=[]) {
    // returns a list of Ids of children and their children for a component
    let list = []
    for (let item of componentsArray ) {
            item.parent === componentId && list.push(item.id, ...getDescendantIds(item.id, componentsArray))
    }
    return list
}

export function getChildren(parentId, componentsArray=[]) {
    // returns an array of components from componentsArray that belong to this parent. same fields
    //console.log(componentsArray)
    let output = []
    if (componentsArray !== null && componentsArray.length>0) {
        output = componentsArray.filter(item => parentId === item.parent)
    }
    return output
}


export function getShortestDistance(x, y, left, right, top, bottom) {
    let distance = null
    if (x >= left && x <= right) { distance = Math.min(Math.abs(y-top), Math.abs(y-bottom)) }
    else if (y >= bottom && y <= top) { distance = Math.min(Math.abs(x-left), Math.abs(x-right)) }
    else {
        function getDistance (x1, y1, x2, y2) {
            var a = x1 - x2;
            var b = y1 - y2;
            var c = Math.sqrt(a*a + b*b);
            return c
        }
        distance = Math.min(
            getDistance(x,y,left,top),
            getDistance(x,y,left,bottom),
            getDistance(x,y,right,top),
            getDistance(x,y,right,bottom)
        )}
    return Math.round(distance)
} 

export function findClosestObjectByCorners(array, cursorX, cursorY) {
  if (!array || array.length === 0) return null;

  const arrayWithDistance = array.map((obj) => ({
      ...obj,
      distance: getShortestDistanceToCorners(
          cursorX,
          cursorY,
          obj.xLeft,
          obj.xRight,
          obj.yTop,
          obj.yBottom,
      ),
  }));

  const closestObject = arrayWithDistance.reduce((prev, curr) =>
      prev.distance < curr.distance ? prev : curr
  );

  return closestObject;
}

export function getShortestDistanceToCorners(x, y, left, right, top, bottom) {
  function getDistance(x1, y1, x2, y2) {
      const a = x1 - x2;
      const b = y1 - y2;
      return Math.sqrt(a * a + b * b);
  }

  const distances = [
      getDistance(x, y, left, top),    // Top-left corner
      getDistance(x, y, right, top),   // Top-right corner
      getDistance(x, y, left, bottom), // Bottom-left corner
      getDistance(x, y, right, bottom) // Bottom-right corner
  ];

  return Math.min(...distances);
}


export function findClosestObjectByCenter(array, cursorX, cursorY) {
  if (!array || array.length === 0) return null;

  const arrayWithCenterDistance = array.map((obj) => ({
      ...obj,
      centerDistance: getDistanceToCenter(
          cursorX,
          cursorY,
          obj.xLeft,
          obj.xRight,
          obj.yTop,
          obj.yBottom,
      ),
  }));

  const closestObject = arrayWithCenterDistance.reduce((prev, curr) =>
      prev.centerDistance < curr.centerDistance ? prev : curr
  );

  return closestObject;
}

export function getDistanceToCenter(cursorX, cursorY, left, right, top, bottom) {
  const centerX = (left + right) / 2;
  const centerY = (top + bottom) / 2;

  return getDistance(cursorX, cursorY, centerX, centerY);
}

function getDistance(x1, y1, x2, y2) {
    const a = x1 - x2;
    const b = y1 - y2;
    return Math.sqrt(a * a + b * b);
}



export function arrayMove(array, relocateObject) {
  // Clone the array to avoid mutating the original array
  let newArray = [...array];

  // Find the object to be moved
  const foundIndex = newArray.findIndex(obj => obj.id === relocateObject.id);

  if (foundIndex > -1) {
      // Remove the object
      newArray.splice(foundIndex, 1);

      // Update indices for objects after the removed object
      newArray = newArray.map(obj => {
          if (obj.index > relocateObject.index) {
              return { ...obj, index: obj.index - 1 };
          }
          return obj;
      });

      // Insert the object at its new position
      newArray.splice(relocateObject.index - 1, 0, relocateObject);

      // Update indices for objects after the inserted object
      newArray = newArray.map(obj => {
          if (obj.index >= relocateObject.index && obj.id !== relocateObject.id) {
              return { ...obj, index: obj.index + 1 };
          }
          return obj;
      });
  }

  return newArray;
}

export function findSectionParent(object, objectsArray = []) {
  const sections = ['Main', 'Header', 'Sidebar', 'IconBar', 'Footer', 'FeaturePanel', 'Email', 'SidePanel'];

  // Helper function to find an object by ID in the array
  const findObjectById = (id) => objectsArray.find(obj => obj.id === id);

  // Function to recursively search for the section parent
  const findParent = (currentObject) => {
      if (!currentObject) {
          return null;  // No object provided, return null
      }

      if (sections.includes(currentObject.componentAPIName)) {
          return currentObject;  // Current object is a section
      }

      if (!currentObject.parent) {
          return null;  // No more parents to check, return null
      }

      const parentObject = findObjectById(currentObject.parent);
      if (!parentObject) {
          return null;  // Parent object not found
      }

      return findParent(parentObject);  // Continue searching up the hierarchy
  };

  return findParent(object);  // Start the search with the given object
}

