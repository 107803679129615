import { AppShell, Main, Header, Logo, HeaderLink, UserMenu } from '../';

export default function AppWrapper({ 
        templateOjects, // render as LightObject
        children, 
        __juno = {}
    }) {

    const nonMainChildren = []
    const main = {}
    
    return (
        <AppShell pageBackground='base-50' __juno={__juno}>
            {nonMainChildren}
            
            <Main {...main?.object_props}
             __juno={{...__juno, tagStyle: null}}
            >
            {children}
            </Main>
        </AppShell> 
    );
}


/* ignore rest */


AppWrapper.definitions = {
    apiName: 'AppWrapper',
    displayName: 'Application Wrapper',
    description: 'component used in App.js to wrap the entire application',
    ai_instructions: 'this component is used to wrap the entire application. it is the parent of all other components',
    type: 'layout',
    acceptedChildren: '',
    relativeSize: 'large',
    package: null,
    propDefinitions: {
        
    }
};

