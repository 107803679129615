import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyAXL79ZcurAyLC5w1_U57vbyjS4__6wNEY",
  authDomain: "juno-v1.firebaseapp.com",
  projectId: "juno-v1",
  storageBucket: "juno-v1.appspot.com", 
  messagingSenderId: "906890967319",
  appId: "1:906890967319:web:6a681605de92284d929f52",
  measurementId: "G-9CG4TP2DWE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const auth = getAuth(app);
const db = getFirestore(app);

export { storage, auth, db };
