
// ADD OBJECTS
export function add(body, token) {
    const cleanedBody = cleanBody(body);
    
    // Call database with token
    return fetch('/objects/add', {
        method: 'POST', 
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, 
        body: JSON.stringify(cleanedBody)
    })
    .then(response => response.json());
}

// UPDATE OBJECTS
export function update(body, token) {
    const cleanedBody = cleanBody(body);
    // Call database with token
    
    return fetch('/objects/update', {
        method: 'POST', 
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, 
        body: JSON.stringify(cleanedBody)
    });
}

// DELETE OBJECTS
export function remove(body, token) {
    const cleanedBody = cleanBody(body);
    // Call database with token
    return fetch('/objects/remove', {
        method: 'POST', 
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, 
        body: JSON.stringify(cleanedBody)
    });
}

function cleanBody(body) {
    if (Array.isArray(body)) {
        // If body is an array, map over the array and remove the 'ref' from each object
        return body.map(item => {
            const { ref, ...cleanedItem } = item;
            return cleanedItem;
        });
    } else {
        // If body is a single object, remove the 'ref' property
        const { ref, ...cleanedBody } = body;
        return cleanedBody;
    }
}
