import React, { useContext } from "react";
import { EditorContext } from "../../EditorContext";
import { Folder, NavArrowRight, Plus } from "iconoir-react";
import { v1 as uuidv1 } from 'uuid'
import FramePreview from "../../../../components/FramePreview";

export default function ProjectDisplay({draggedFrame, refreshKey}) {
    const { view, selector, project, setProject, setView, effectiveVariables, assets, setSelector, 
      handleAction

     } = useContext(EditorContext);
  
    const columnWidth = view.columnWidth
    

    const allFolders = project?.folders.filter( f => !f.is_archived).sort((a, b) => a.index - b.index)
    const allFlows = project?.pages.filter(p => allFolders.map(f=>f.id).includes(p.folder_id)).sort((a, b) => a.index - b.index);
    const allFrames = allFlows?.flatMap(f => f.frames).filter(frame => !frame.isArchived).sort((a, b) => a.index - b.index);

    const recentFrames = allFrames.sort((a, b) => Date.parse(b.modified_at) - Date.parse(a.modified_at)).slice(0, 3)

    function openFrame(frame) {
      
      const selectPage = allFlows.find(p => p.id === frame.page_id)
      const selectFolder = allFolders.find(f => f.id === selectPage.folder_id)

      setSelector({...selector, folder: selectFolder, page: selectPage, frame: frame})
      setView({...view, overview: false})
    }


    function addFolder() {
        const maxIndex = allFolders.reduce((max, folder) => Math.max(max, folder.index), -1);
        const newFolder = {
            id: uuidv1(),
            name: 'new folder',
            index: maxIndex + 1,
            project_id: project.project.id
          };
        handleAction({type: 'INSERT_FOLDER', newFolder, keepSelector: true })
        handleAction({type: 'APPEND_PAGE', folder_id: newFolder.id, name: 'user flow'})
        
        
    }
    
    function handleFolderClick(folder) {
      
      const pages = project?.pages?.filter(p => p.folder_id == folder.id).filter(p => !p.is_archived)
      
      // Find the page with the lowest index in the folder
      const selectPage = pages.length > 0 
        ? pages.reduce((lowest, page) => (!lowest || page.index < lowest.index) ? page : lowest) 
        : null;
    
      // Assuming you have a method to get frames from a page
      let selectFrame = null;
      if (selectPage) {
        // This is placeholder logic. Replace it with your actual method of retrieving frames
        const frames = selectPage.frames || []
        selectFrame = frames && frames.length > 0 
          ? frames.reduce((lowest, frame) => (!lowest || frame.index < lowest.index) ? frame : lowest) 
          : null;
      }
      
      // Update the selector with the selected folder, page, and frame
      setSelector({ 
        ...selector, 
        folder: folder, 
        page: selectPage, 
        frame: selectFrame, 
        object: null
      });
      
      setView({...view, showAllFolders: true, inOverview: '/folder'})
    }


    return (
      <>
        <div className="pl-5 pr-3 w-full max-w-full overflow-hidden flex flex-col py-3">
          <div className="flex flex-row gap-4 py-3 flex-wrap">
            {allFolders.map(folder => (
              
            <FolderThumb 
            key={folder.id}
            folder={folder}
            columnWidth={columnWidth}
            onClick={handleFolderClick}
            />
            
            )
            )}

            <AddFolder onClick={addFolder} columnWidth={columnWidth} />
          </div>
        </div>
        <div className="pl-5 pr-3 w-full max-w-full overflow-hidden flex flex-col py-3 select-none">
          <h2 className="text-sm font-medium">Recently edited</h2>
          <div className="flex flex-row gap-4 py-2">
            {recentFrames.map(frame => (
            <div className="flex flex-col gap-1 relative group w-auto"
            key={frame.id}
            onClick={() => openFrame(frame)}
            >
            <span className="text-xs opacity-50"
            >{frame.name}</span>
            <PageThumb
              frame={frame}
              columnWidth={columnWidth}
              isMobile={false}
              isSelected={false}
              addNextFrame={()=>{}}
              showNotes={false}
              assets={assets}
              effectiveVariables={effectiveVariables}
            />
            <div className="bg-base-100 w-auto gap-1 px-3 pr-2 h-7 absolute bottom-3 right-3 opacity-0 group-hover:opacity-100 transition-all
        rounded-full duration-150 flex items-center justify-center text-base-500 hover:scale-110 bg-base-0
        ring-[0.5px] ring-base-300 hover:ring-base-400 text-sm
        drop-shadow-sm"
        
        >
          Edit
          <NavArrowRight height={16} width={16} className="stroke-[1.6px]"/>
        </div>
            </div>
            )
            )} 
            
          </div>
        </div>  
        
      </>
    );
    }  

//<ProjectName project={project} setProject={setProject} />

function PageThumb({
    frame,
    assets=[],
    effectiveVariables=[],
    columnWidth, 
  }) {
  
    // Calculate the aspect ratio based height and scale factor
    const originalWidth = 1440
    const originalHeight = 820
    const aspectRatio = originalWidth / originalHeight;
    
    // Calculate the height to maintain the aspect ratio
    const thumbnailHeight = columnWidth / aspectRatio 
    
    return (
          <div className="h-auto transition-all duration-75" >
          <div className={`flex transition-all relative flex-col flex-shrink-0 flex-grow-0 group `}
            style={{ 
            flexBasis: columnWidth - 12, 
            maxWidth: `${columnWidth - 12}px`,
            }}>
            
          <div
            className={`overflow-hidden relative transition-all flex-shrink-0 duration-75 shadow-sm border-[0.5px] border-base-300 hover:border-base-400 rounded-md `}
            style={{
              width: `${columnWidth - 10}px`,
              height: `${thumbnailHeight - 6}px`, // here - 12 vs -16 as we account for border width
              boxSizing: 'border-box', 
            }}>
            <div 
              style={{width: 1440, height: 820, transform: `scale(${columnWidth / 1440})`, transformOrigin: 'top left'}}
              className="bg-base-0 w-full h-full flex items-start justify-start "
              >
            {<FramePreview
            frame={frame}
            assets={assets}
            effectiveVariables={effectiveVariables} 
          />}
          </div>
        
        </div>
          </div>
      </div>
    );
  }


  function FolderThumb({
    folder, 
    columnWidth, 
    onClick,
  }) {
    const originalWidth = 1440
    const originalHeight = 820
    const aspectRatio = originalWidth / originalHeight;
    
    // Calculate the height to maintain the aspect ratio
    const thumbnailHeight = columnWidth / aspectRatio 

    return (

      <div className={`flex bg-base-100 relative flex-col flex-shrink-0 flex-grow-0 group 
      rounded-md ring-[0.5px] ring-base-300 items-start justify-end p-3 text-base-600 hover:text-base-700
      transition-all duration-150 hover:ring-base-400 hover:bg-base-50
      `}
          onClick={() => onClick(folder)} 
            style={{ 
             
            height: thumbnailHeight / 3 * 2,
            width: columnWidth - 12,
            maxWidth: `${columnWidth - 12}px`,
            }}>
          
          <span className="text-base">{folder.name}</span>

          <Folder className="absolute bottom-3 right-3" width={24} height={24}
          style={{strokeWidth: 1}}
          />
      </div>
    )

  }

  function AddFolder({
    columnWidth,
    onClick
  }) {

    const originalWidth = 1440
    const originalHeight = 820
    const aspectRatio = originalWidth / originalHeight;
    
    // Calculate the height to maintain the aspect ratio
    const thumbnailHeight = columnWidth / aspectRatio 
    
    return (
      <div 
            className={`pointer gap-0 flex-shrink-0 flex flex-col items-center justify-center transition-all
            rounded-lg text-base-500 hover:text-base-600  transition-all duration-75
            opacity-100 bg-base-50 ring-[0.5px] ring-base-300 hover:ring-base-400`}
            style={{
                width: columnWidth - 12,
                height: thumbnailHeight / 3 * 2,
            }} 

            onClick={onClick}
        >
         
                <Plus  height={28} width={28} style={{strokeWidth: 1}} 
                className='hover:scale-110 transition-all duration-75 hover:stroke-[1.2px]' />
        </div>
    )
  }


  