import React, { useEffect, useRef, useState } from "react";
import hljs from "highlight.js";
import "highlight.js/styles/github.css";
import { Check, Copy } from "iconoir-react";

export default function FormattedSnippet({ jsx }) {
  const codeRef = useRef(null);

  useEffect(() => {
    if (codeRef.current) {
      const codeBlock = codeRef.current;
      setTimeout(() => {
        hljs.highlightElement(codeBlock);
      }, 0); // Timeout set to 0 to allow the stack to clear and DOM to update
    }
  }, [jsx]);

  const [copied, setCopied] = useState(false)

    const copyToClipboard = () => {
      navigator.clipboard.writeText(jsx).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 3000); 
      })
      .catch(err => {
        console.log('Something went wrong', err);
      });
    }

  return (
    <div className="w-full flex flex-col relative group overflow-scroll">
      <div  className={`sticky w-full flex flex-col items-end bg-transparent p-1 transition-all flex z-50`}>
                <button
                onClick={copyToClipboard} 
                className="bg-white p-1 rounded-md hover:scale-110 transition-all">
                {copied ? <Check width={16} height={16}/> :  <Copy  width={16} height={16}/>}
                </button>
            </div>
    <pre 
    
    className="relative" style={{
      marginTop: '-32px',
      overflowX: 'auto',
      maxWidth: '100%',
      width: '100%',
      boxSizing: 'border-box',
      whiteSpace: 'pre',
      wordWrap: 'break-word',
      userSelect: 'text',
    }}>
      
      <code
        key={jsx} // Force rerender of this element when jsx changes
        ref={codeRef}
        className="language-javascript text-xs"
        style={{ padding: 8, minHeight: '8em' }}
      >
        {jsx}
      </code>
    </pre>
    </div>
  );}