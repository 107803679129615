import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../auth/juno-new.svg'; // Ensure the path to the logo is correct
import * as IconoirIcons from 'iconoir-react';
import UserContext from '../../UserContext';
import { prepareProject } from '../home/helpers';
import setFolders from './sampleSet.json'
import { createUserWithEmailAndPassword, getAuth, updateProfile } from 'firebase/auth';
import axios from 'axios';


export default function EditorBeta({ handleLogout }) {
  const { user, setUser, setIsAuthenticated, isAuthenticated, isGuestUser, setIsGuestUser } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const Project = IconoirIcons['OpenBook'];

  const [sampleProjects, setSampleProjects] = useState([]);

  const getUserToken = async () => {
    if (user) {
      return user.token;
    }
    return null;
  };

  // LOAD PROJECTS
  useEffect(() => {
    const fetchProject = async () => {
      if (user && user?.id) {
        try {
          const response = await axios.get(`/users/${user.id}/getSampleProject`, {
            headers: {
              Authorization: `Bearer ${await getUserToken()}`,
            },
          });
          setSampleProjects(response.data);
        } catch (error) {
          console.error('Error fetching projects:', error);
        }
      }
    };
    fetchProject();
  }, [user]);

  // ADD PROJECT ENDPOINT
  async function addProject(payload) {
    const token = await getUserToken();

    try {
      const response = await axios.post(
        '/api/projects/add',
        payload,
        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` } }
      );

      if (response.status === 201) {
        const newProject = response.data;
        navigate(`/project/${newProject.id}`);
      } else {
        console.error('Error creating project');
      }
    } catch (error) {
      console.error('Error creating project:', error.response ? error.response.data : error.message);
    }
  }

  function addSet() { // Accept userData as parameter
    const userData = user;
    const projectName = 'Sample Project';
    const set = { name: 'betaPageEditor', folders: setFolders };
    // console.log('userData in addSet', userData);
    const payload = prepareProject(userData?.id, set, 'betaPageEditor', projectName);
    const modifiedPayload = payload && { ...payload, project: { ...payload.project, is_sample_project: true } };
    
    modifiedPayload && addProject(modifiedPayload).finally(() => {
      setIsLoading(false); // Stop loading after addProject is done
    });
  }

  function openProject(e, projectId) {
    e.stopPropagation();
    navigate(`/project/${projectId}`);
  }

  const sampleProject = sampleProjects[0];
  const auth = getAuth();
  
  async function createUser({isGuestUser}, callback) {
    
    const submitEmail = !isGuestUser ? email : `guest_${new Date().getTime()}@example.com`;
    const password = `guest_password_${Math.random().toString(36).slice(-8)}`;

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, submitEmail, password);
      
      const user = userCredential.user;
      console.log(user)
      const token = await user.getIdToken();
      localStorage.setItem("token", token);

      const userResponse = await fetch('/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: submitEmail, firebaseId: user.uid, is_guest: true })
      });

      const userData = await userResponse.json();
      setUser({ ...userData, token: token });
      setIsGuestUser(isGuestUser);
      setIsAuthenticated(true);
  
      callback(userData);

    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setErrorMessage('Email is taken. [login] or provide another email.');
      } else {
        console.error("Error creating guest user:", error);
      }
    }
  }

  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  
  const handleEmailUser = () => {
    setIsLoading(true);
    createUser({ isGuestUser: false }, addSet); // addSet is called after user is set
  }
  
  
  function handleClick(e) {
    if (!email) {
      // Check if the email input is empty
      setErrorMessage('Please enter an email address');
      return;
    }
    if (email) {
      // Check email validity
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setErrorMessage('Invalid email format');
        return;
      }
      handleEmailUser();
    } else {
      if (sampleProject) {
        openProject(e, sampleProject.id);
      } else {
        addSet();
      }
    }
  }
  
  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      handleClick(e);
    }
  }

  return (
    <div className='flex flex-col items-center bg-white justify-between  h-[calc(100dvh)] overflow-hidden'
      style={{ fontFamily: 'Inter' }}
    >
      <div></div>
      <div className='max-w-[480px] w-full bg-white rounded-lg p-6 pb-10 flex flex-col gap-5'>
        <img src={logo} alt="Logo" width={240} className='mx-auto' />
        <div className='flex flex-col gap-5 items-center'>
          <div className='flex flex-col gap-3 text-md mx-auto text-slate-700 mb-5 items-center'>
            <h3 className='text-center text-2xl font-semibold'>Limited beta</h3>
            <span className='text-center mt-2 balance'>Currently we are testing JUNO internally<br/> but you're welcome to check it out.</span>
            <span className='flex flex-row gap-3 items-center mt-2'> <IconoirIcons.Sparks height={16} width={16} />AI features are getting a makeover</span>
            <span className='flex flex-row gap-3 items-center mt-2'><IconoirIcons.DragHandGesture height={16} width={16} />Try out manual editing on canvas</span>
          </div>
          {(!isAuthenticated || isLoading)?
            <>
              <div className='flex flex-row gap-2 w-full'>
                <input
                  className='bg-slate-100 rounded-xl flex-grow p-4 hover:bg-slate-100 focus:bg-slate-200 transition-colors duration-200 text-left font-medium select-none'
                  type={'text'}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder={'Email'}
                  onKeyDown={handleKeyDown}
                />
                <button className='rounded-xl text-center bg-primary text-white p-4 hover:bg-orange-700 font-medium transition-colors pointer duration-200'
                  onClick={(e) => handleClick(e)}
                >
                  Start Project
                </button>

              </div>
              {errorMessage && (
                <p className="px-3 -mt-3 rounded-lg py-1 bg-accent-100 w-full text-center text-sm text-primary">
                  {errorMessage.includes('[login]') ? (
                    <>
                      {errorMessage.split('[login]')[0]}
                      <a href="/login" className="text-primary underline font-medium">Sign in</a>
                      {errorMessage.split('[login]')[1]}
                    </>
                  ) : (
                    errorMessage
                  )}
                </p>
              )}
            </>
            :
            <button className='rounded-xl text-center bg-orange-600 text-white p-4 hover:bg-orange-700 font-medium transition-colors pointer duration-200'
              onClick={(e) => {
                if (sampleProject) {
                  openProject(e, sampleProject.id);
                } else {
                  addSet();
                }
              }}
            >
              Open Project
            </button>
          }

        </div>
      </div>

      <div>
      
      <a href="mailto:sam@junodesign.app"
            className='mx-auto flex flex-row items-center gap-2 hidden rounded-xl w-full text-center p-4 transition-all duration-200 hover:underline text-sm text-slate-400 hover:text-slate-500 mb-10'
            >
            <IconoirIcons.Lifebelt height={16} width={16} />
            sam@junodesign.app
          </a>
        
      </div>

    </div>
  );
}