import React, { useEffect, useState } from 'react';

function AdminPage() {
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/admin/apiusage/byuser');
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setUserData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="my-4 w-full text-sm p-5">
      <h1>Admin API Usage</h1>
      {userData.length > 0 ? (
        userData.map(user => {
          // Initialize total sums for each user
          let totalCostPerUser = 0;
          let totalPromptTokensPerUser = 0;
          let totalCompletionTokensPerUser = 0;

          return (
            <div key={user.id} className="my-6">
              <h2 className='bg-blue-200 font-medium p-2'>{user.email}</h2>
              <table className="w-full text-left border-collapse bg-slate-50" style={{ borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                  <th className='border border-slate-300' style={{ width: '10%' }}>Conversation Start</th>
                  <th className='border border-slate-300' style={{ width: '10%' }}>Job Start</th>
                  <th className='border border-slate-300' style={{ width: '10%' }}>Agent</th>
                  <th className='border border-slate-300' style={{ width: '10%' }}>Model</th>
                  <th className='border border-slate-300' style={{ width: '10%' }}>Started</th>
                  <th className='border border-slate-300' style={{ width: '5%' }}>Cost</th>
                  <th className='border border-slate-300' style={{ width: '5%' }}>Prompt Tkns</th>
                  <th className='border border-slate-300' style={{ width: '5%' }}>Gen Tkns</th>
                  </tr>
                </thead>
                <tbody >
                  {user.conversations?.map((conversation, convIndex) =>
                    conversation.jobs?.map((job, jobIndex) => {
                      return job.agent_requests?.map((request, reqIndex) => {
                        // Safe parsing of numeric values
                        const cost = parseFloat(request.cost);
                        const promptTokens = parseInt(request.prompt_tokens, 10);
                        const completionTokens = parseInt(request.completion_tokens, 10);

                        // Accumulate totals
                        if (!isNaN(cost)) totalCostPerUser += cost;
                        if (!isNaN(promptTokens)) totalPromptTokensPerUser += promptTokens;
                        if (!isNaN(completionTokens)) totalCompletionTokensPerUser += completionTokens;

                        return (
                          <tr key={`${user.id}-${convIndex}-${jobIndex}-${reqIndex}`}>
                            {reqIndex === 0 && (
                              <>
                                <td className='border border-slate-300' style={{ verticalAlign: 'top' }} rowSpan={job.agent_requests.length} >
                                  {new Date(conversation.created_time).toLocaleString()}
                                </td>
                                <td className='border border-slate-300' style={{ verticalAlign: 'top' }} rowSpan={job.agent_requests.length}>
                                  {new Date(job.created_at).toLocaleString()}
                                </td>
                              </>
                            )}
                            <td className='border border-slate-300'>{request.agent}</td>
                            <td className='border border-slate-300'>{request.model}</td>
                            <td className='border border-slate-300'>{new Date(request.created_at).toLocaleTimeString()}</td>
                            <td className='border border-slate-300'>${cost.toFixed(2)}</td>
                            <td className='border border-slate-300'>{promptTokens}</td>
                            <td className='border border-slate-300'>{completionTokens}</td>
                          </tr>
                        );
                      });
                    })
                  )}
                  <tr className="font-semibold bg-gray-200">
                    <td colSpan="5">Total for {user.email}</td>
                    <td>${totalCostPerUser.toFixed(2)}</td>
                    <td>{totalPromptTokensPerUser}</td>
                    <td>{totalCompletionTokensPerUser}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          );
        })
      ) : (
        <p>No data available.</p>
      )}
    </div>
  );
}

export default AdminPage;
