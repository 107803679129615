import { convertJSXToObjectsSync } from "../../../utilities/AIhelpers";
import { getDescendants } from "./helpers";

const dialogs = ['Drawer', 'Popover', 'Modal', 'Toast', 'Banner']
export function handleDialogDragOver({ frameObjects, dndState, setDnDState}) {
    const currentDialog = frameObjects?.find(obj => dialogs.includes(obj.componentAPIName)) 
    const appShell = frameObjects?.find(obj => obj.componentAPIName == 'AppShell') 
    const dropParent = currentDialog || appShell || null
    
    if (!dropParent) {
        // no section or appshell to drop into
        setDnDState({
            ...dndState,
            draggingOverItem: null, 
            offFrame: false
        }) 
    } else if (dropParent != dndState?.draggingOverItem) {
        setDnDState({
            ...dndState,
            draggingOverItem: dropParent, 
            offFrame: false
        })  
    }

}

export function handleDialogDragEnd({ selector, dndState, handleAction, frameObjects}) {
          const twin = frameObjects.find(obj => dialogs.includes(obj.componentAPIName))
          
          let action = null
          const draggingItem = dndState?.draggingItem;
          let objects = [];

          if (draggingItem.objects && draggingItem.objects.length > 0) {
            objects = draggingItem.objects;
        } else {
            const jsx = draggingItem.jsx;
            objects = convertJSXToObjectsSync(jsx) || [];
        }
        if (objects.length === 0) return;
        const rootObj = objects.find((obj) => obj.parent === "rootObject");
        
        if (!rootObj) return;
        const descendants = objects.filter((obj) => obj.id !== rootObj.id) || [];
          const newParent = dndState?.draggingOverItem;
          
          if (twin) {
            
            let proceed = true;

            if (proceed) {
                action = {
                    type: 'REPLACE_OBJECT',
                    oldObject: twin,
                    oldDescendants: getDescendants(twin.id, frameObjects) || [], 
                    newObject: rootObj,
                    newDescendants: descendants
                }
              }
                
          } else {
            action = {
              type: "INSERT_OBJECT",
              object: rootObj,
              parent: newParent,
              index: 2, // DIALOG IS ALWAYS ON SECOND LAYER
              descendants,}
          }
          console.log(action)
          handleAction(action)
}