
// ADD OBJECTS
export function add(body, token) {
    // Call database with token
    return fetch('/api/themes/add', {
        method: 'POST', 
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, 
        body: JSON.stringify(body)
    })
    .then(response => response.json());
}

// DELETE OBJECTS
export function remove(body, token) {
    // Call database with token
    return fetch('/api/themes/delete', {
        method: 'POST', 
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, 
        body: JSON.stringify(body)
    });
}


export async function saveThemeToDB(theme, token) {

    if (!token) return

    try {
        const response = await fetch('/api/themes/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(theme)
        });

        const data = await response.json(); // Parsing the JSON response

    } catch (error) {
        console.error('Error saving the theme:', error);
    }
}

export async function deleteThemeInDB(themeId, token) {
    try {
        const response = await fetch('/api/themes/delete', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({ themeId })  // Ensure the themeId is part of an object
        });

        const data = await response.json(); // Parsing the JSON response
        console.log('Delete response:', data); // Optionally log the response data

    } catch (error) {
        console.error('Error deleting the theme:', error);
    }
}

export async function updateThemeInDB(theme, token) {
    try {
        const response = await fetch('/api/themes/update', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(theme)  // Ensure the themeId is part of an object
        });

        const data = await response.json(); // Parsing the JSON response
        console.log('Delete response:', data); // Optionally log the response data

    } catch (error) {
        console.error('Error deleting the theme:', error);
    }
}