import { ArrowLeft, ArrowRight } from "iconoir-react";

export function Arrows({index, length, onPrev, onNext}) {
    return (
        <div className='absolute top-1/2 w-full px-4 opacity-0 transition-all group-hover:opacity-100 z-50 justify-between flex-row'>
        <div className={`flex items-center justify-center w-12 h-12 scale-75 bg-base-0 rounded-md shadow-md cursor-pointer transition-all 
        ${index == 0 ? 'opacity-30' : 'opacity-100 hover:scale-100'}`}
        onClick={onPrev}
        >
        <ArrowLeft className="scale-75"/>
        </div>
        <div className={`flex items-center justify-center w-12 h-12 scale-75 bg-base-0 rounded-md shadow-md cursor-pointer transition-all 
        ${index == length - 1 ? 'opacity-30' : 'opacity-100 hover:scale-100'}`}
        onClick={onNext}
        >
        <ArrowRight className="scale-75"/>
        </div>
        </div>
    )
}