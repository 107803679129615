/** @jsxImportSource @emotion/react */
import * as IconoirIcons from 'iconoir-react';

export default function DNDPreview({ insert }) {
    
  const Plus = IconoirIcons['Plus'];
    if (insert === null) {
      return null;
    } else {
      return (
        <div 
        className="bg-success-content rounded-full relative"
        style={{zIndex: 1000}}
        css={{...insert}}> 
        </div>
      );
    }
  }

  //