export const routerTemplates = {
    noAuth: `import { ReactNode, useEffect, useState } from "react";
import { RouterProvider, useNavigate, createBrowserRouter, createRoutesFromElements, Route, Outlet, Navigate, useLocation } from "react-router-dom";
import { AppShell, Main, Search, SidebarLink, UserMenu } from "junokit"

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AppWrapper />}>
      /* routes go here */
    </Route>
  )
);

export default function App() {
  return (
      <RouterProvider router={router} />
  );
}`,
    pocketbase: `import { ReactNode, useEffect, useState } from "react";
import { RouterProvider, useNavigate, createBrowserRouter, createRoutesFromElements, Route, Outlet, Navigate, useLocation } from "react-router-dom";
import { AppShell, Button, Header, Logo, Main, Search, UserMenu } from "junokit";
import { PBContext, PocketBaseProvider, usePocketBase } from "./contexts/PocketBase";

function Root() {
  const { pb } = usePocketBase();
  const isLoggedIn = pb?.authStore.isValid;
  return isLoggedIn ? <Navigate to="/home" /> : <Navigate to="/login" />;
}

function AuthLayout() {
  const { pb } = usePocketBase();
  const isLoggedIn = pb?.authStore.isValid;

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  return (
    <Outlet />
  );
}


const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<AppWrapper />}>
      <Route path="*" element={<Error error="NotFound" />} />
      <Route path="/" element={<Root />} errorElement={<Error />} />
      <Route path="/login" element={<Login />} />
      
    {/* Authenticated routes grouped under a single layout */}
    <Route element={<AuthLayout />}>
        <Route path="/home" element={<HomePage />} />
        /* protected routes go here */
    </Route>

      
    </Route>,
  ),
);

export default function App() {
  return (
    <PocketBaseProvider>
      <RouterProvider router={router} />
    </PocketBaseProvider>
  );
}
`   
}


