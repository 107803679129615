import React from 'react';

const TypingDots = () => {
const dotStyle =   `dot w-[6px] h-[6px] bg-gray-600`
  return (
    <div className="flex flex-row gap-[3px]"
      
    >
      <div className={dotStyle}></div>
      <div className={dotStyle}></div>
      <div className={dotStyle}></div>
    </div>
  );
};

export default TypingDots;
