import React from 'react';
import { spacingMap } from '../helpers';

type IconBarProps = {
    paddingX?: '6px' | '8px' | '12px' | '16px' | '24px' | '32px' | '48px' | '64px',
    paddingY?: '6px' | '8px' | '12px' | '16px' | '24px' | '32px' | '48px' | '64px',
    gap?: '4px' | '6px' | '8px' | '10px' | '12px' | '16px' | '24px' | '32px' | '48px',
    background?: 'base-0' | 'base-50' | 'base-100' | 'base-200' | 'base-700' | 'base-content' | 'primary' | 'accent' | string,
    alignItems?: 'start' | 'end' | 'center' | 'baseline' | 'stretch',
    justifyContent?: 'start' | 'end' | 'center' | 'between' | 'around' | 'evenly',
    position?: 'left' | 'right',
    width?: '48px' | '64px' | '80px' | '96px',
    display?: boolean,
    textSize?: 'sm' | 'base' | 'md',
    children?: React.ReactNode,
    __juno?: any,
}

export default function IconBar({
        paddingX = null,
        paddingY = null,
        gap = null,

        background = 'base-0',        
        alignItems = 'stretch',
        justifyContent = 'start',
        position = 'left',
        width = '64px',
        display = true,
        textSize = 'base',
        children,
        __juno = {}
      }: IconBarProps) {
    
      const bgStyles = background ?`bg-${background}` : '';
      const fontColor = (!background || background == 'none') ? '' : (background?.startsWith('base') && background != 'base-content' && background != 'base-700') ? 'text-base-content' : `text-base-0`
      const paddingStyles = `${paddingX ? ` px-${spacingMap[paddingX]}` : ''}${paddingY ? ` py-${spacingMap[paddingY]}` : ''}`;
      const gapStyles = gap ? `gap-${spacingMap[gap]}` : '';
    
    
    const fontSize = `text-`+textSize
    const alignItemsStyles = alignItems ? `items-${alignItems}` : '';
    const justifyContentStyles = justifyContent ? `justify-${justifyContent}` : '';
    

    const classes = `flex flex-col flex-shrink-0 flex-grow-0 z-50 relative ${fontColor} ${fontSize} ${paddingStyles} ${bgStyles} ${gapStyles} ${alignItemsStyles} ${justifyContentStyles} transition-all`

    if (display) return (
        <div 
        className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
        {...__juno?.attributes}
        style={{
            order: position == 'left' ? '-2' : 2,
            minWidth: width, 
            minHeight: '100%', 
        }}
        >
        {children}
        </div>
    );
}


/* ignore rest */

IconBar.definitions = {
    apiName: 'IconBar',
    displayName: 'Icon Bar',
    description: 'A versatile, vertical layout component ideal for displaying a series of icons or small widgets. Supports customization options including padding, background color, border, alignment, and minimum width. Can include a variety of child elements.',
    ai_instructions: 'vertical navigation bar with icon buttons. has flex-col property',
    type: 'section',
    relativeSize: 'large',
    package: null,
    acceptedChildren: 'everything except table components, layouts, sections, dialogs',
    propDefinitions: {
        width: {
            type: 'oneOf',
            options: ['48px', '64px', '80px', '96px'],
            displayName: 'Minimum Width',
            default: '64px', 
            tile: '1/2',
        },
        position: {
            type: 'oneOf',
            options: ['left', 'right'],
            displayName: 'Position',
            default: 'left', 
            tile: '1/2',
        },
        background: {
            type: 'colors',
            options: ['base-0',  'base-50', 'base-100', 'base-200', 'base-700', 'base-content', 'primary', 'accent'],
            displayName: 'Background',
            default: 'base-0'
        },
        paddingX: {
            type: "spacing",
            tile: '1/2',
            options: ["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px'],
            displayName: "Padding X",
            ai_instructions: 'adds padding on left and right. in addition to overall padding property',
            default: null
        },
        paddingY: {
            type: "spacing",
            tile: '1/2',
            options: ["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px'],
            displayName: "Padding Y",
            ai_instructions: 'adds padding on top and bottom. in addition to overall padding property',
            default: null
        },
        gap: {
            type: 'gap',
            options: ["4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Gap",
            default: null,
            tile: '1/2',
        },
        textSize: {
            type: 'oneOf',
            options: ['sm', 'base', 'md'],
            default: 'base'
        },
        alignItems: {
            type: 'alignItems',
            options: ['start', 'end', 'center', 'baseline', 'stretch'],
            displayName: 'Vertical Alignment',
            default: 'stretch', 
            tile: '1/2',
        },
        justifyContent: {
            type: 'justifyContent',
            options: ['start', 'end', 'center', 'between', 'around', 'evenly'],
            displayName: 'Horizontal Alignment',
            default: 'start', 
            tile: '1/2',
        },
        display: {
            type: 'bool',
            default: true,
            displayName: 'Display',
            defaultOnMobile: false
        },

        hasOutline: {
            type: 'bool',
            ai_instructions: 'adds subtle outline on one side of the sidebar, e.g. if iconbar position is left the outline will be on the right',
            displayName: 'Border',
            default: true
        },
    }
};

/*IconBar.propTypes = {
    background: PropTypes.oneOfType([
        PropTypes.oneOf(['base-0', 'base-50',  'base-100', 'base-200', 'base-700', 'base-content', 'primary', 'accent']),
        PropTypes.string]),
    position: PropTypes.oneOf(['left', 'right']),
    width: PropTypes.oneOf(['48px', '64px', '80px', '96px']),
    paddingX: PropTypes.oneOf(["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px']),
    paddingY: PropTypes.oneOf(["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px']),
    gap: PropTypes.oneOf(["4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"]),
    alignItems: PropTypes.oneOf(['start', 'end', 'center', 'baseline', 'stretch']),
    justifyContent: PropTypes.oneOf(['start', 'end', 'center', 'between', 'around', 'evenly']),
    hasOutline: PropTypes.bool,
    textSize: PropTypes.oneOf(['sm', 'base', 'md']),
    display: PropTypes.bool,
    children: PropTypes.node
};
*/