import { NavArrowLeft } from "iconoir-react";
import FrameTemplate from "./FrameTemplate";

export default function FramesPerGroup({
    flow, 
    assets,
    view,
    insertNewFrame,
    variables,
    handleDragEnd,
    handleDragStart
  }) {
    const frames = flow.frames || [];
    return (
      <div className='grid grid-cols-2 gap-2 px-1.5'>
        {frames.map((frame, index) => (
          <FrameTemplate
            key={index} 
            view={view}
            frame={frame} 
            assets={assets}
            insertNewFrame={insertNewFrame}
            variables={variables}
            handleDragEnd={handleDragEnd}
            handleDragStart={handleDragStart}
          />
        ))}
      </div>
    );
  }
  
  