// ADD A PAGE
export function add(body, token) {
  fetch("/pages/add", {
    method: "POST",
    headers: { 
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
    body: JSON.stringify(body),
  })
  .then((response) => {
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  })
  .then((data) => {
    // console.log(data);
  })
  .catch((error) => {
    console.error('Error adding page:', error);
  });
}


// UPDATE A PAGE
export function update(body, token) {
  fetch("/api/pages/update", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
    body: JSON.stringify(body),
  })
  .then((response) => {
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  })
  .then((data) => {
    // console.log(data);
  })
  .catch((error) => {
    console.error('Error updating page:', error);
  });
}

export function remove(ids, token) {
  // Ensure ids is always an array
  const idsArray = Array.isArray(ids) ? ids : [ids];

  fetch("/pages/delete", {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
    body: JSON.stringify({ ids: idsArray }),
  })
  .then((response) => {
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
  })
  .then((data) => {
    // console.log('Page(s) deleted:', data);
  })
  .catch((error) => {
    console.error('Error deleting page(s):', error);
  });
}
