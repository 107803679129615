import { spacingMap } from '../helpers';

type FormProps = {
    direction?: 'flex-col' | 'flex-row',
    width?: 'full' | 'auto' | '1/2' | '1/3' | '1/4' | '2/3' | '3/4',
    height?: 'h-full' | 'h-1/2' | 'h-1/3' | 'h-1/4' | 'h-2/3' | 'h-[integer]px' | 'h-auto',
    paddingX?: '2px' | '4px' | '6px' | '8px' | '10px' | '12px' | '16px' | '24px' | '32px' | '48px',
    paddingY?: '2px' | '4px' | '6px' | '8px' | '10px' | '12px' | '16px' | '24px' | '32px' | '48px',
    gap?: '2px' | '4px' | '6px' | '8px' | '10px' | '12px' | '16px' | '24px' | '32px' | '48px',
    fontColor?: 'base-0' | 'base-100' | 'base-200' | 'base-300' | 'base-500' | 'base-700' | 'primary' | 'accent' | 'base-900' | 'base-content' | 'auto' | 'success' | 'error' | 'warning' | 'info' | 'success-content' | 'error-content' | 'warning-content' | 'info-content',
    bgImageSrc?: string,
    background?: 'base-0' | 'base-50' | 'base-100' | 'base-200' | 'primary' | 'accent' | 'base-900' | 'none',
    bgOpacity?: '10' | '20' | '30' | '40' | '50' | '60' | '70' | '80' | '90' | '100',
    hasOutline?: boolean,
    flexWrap?: 'nowrap' | 'wrap' | 'wrap-reverse',
    corners?: 'none' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'full',
    alignItems?: 'start' | 'center' | 'end' | 'stretch',
    justifyContent?: 'start' | 'center' | 'end' | 'between' | 'around' | 'evenly',
    textSize?: 'auto' | 'xs' | 'sm' | 'base' | 'md',
    maxWidth?: number,
    minWidth?: number,
    maxHeight?: number,
    minHeight?: number,
    selfAlign?: 'auto' | 'start' | 'center' | 'end',
    display?: boolean,
    onSubmit?: any,
    children?: any,
    
    __juno?: any
}

export default function Form({
        direction = "flex-col",
        width = "full",
        height,
        
        paddingX,
        paddingY,
        gap,

        fontColor,
        bgImageSrc,
        background,
        bgOpacity = "100",

        hasOutline = false,
        flexWrap = "nowrap",
        corners = "none",
        
        alignItems = "start",
        justifyContent = "start",
        textSize = "auto",
        maxWidth,
        minWidth,
        maxHeight,
        minHeight,
        selfAlign,
        display = true,
        
        
        onSubmit=()=>{},
        children,
        __juno = {}
      }: FormProps) {

    const bgStyles = background ? `bg-${background}` : '';
    const inheritFontStyle = (background == 'none' || !background) ? '' : (background?.startsWith('base') && background != 'base-900') ? `text-base-content` : `text-base-0`;
    const fontStyles = (fontColor == 'auto' || !fontColor) ? inheritFontStyle : `text-${fontColor}`
    
    const gapStyles = gap ? `gap-${spacingMap[gap]}` : '';
    const paddingStyles = `${paddingX ? ` px-${spacingMap[paddingX]}` : ''}${paddingY ? ` py-${spacingMap[paddingY]}` : ''}`;
    
    const cornerStyles = corners === 'none' ? '' : `rounded-${corners}`;
    const widthStyles = `w-${width}`;
    const heightStyles = height || ''
    const fontSize = textSize != 'auto' ? 'text-'+textSize : '';
    const borderStyles = hasOutline ? 'ring-1 ring-inset ring-base-200' : '';
    
    const wrapStyles = `flex-${flexWrap}`;
    const alignItemsStyles = alignItems ? `items-${alignItems}` : '';
    const justifyContentStyles = justifyContent ? `justify-${justifyContent}` : '';
    const selfAlignStyles = selfAlign ? `self-${selfAlign}` : '';
    
    const classes = `flex ${direction} ${wrapStyles} ${widthStyles} ${selfAlignStyles} ${fontStyles} ${fontSize} ${bgStyles} ${borderStyles} ${gapStyles} ${cornerStyles} ${alignItemsStyles} ${justifyContentStyles} ${heightStyles} ${paddingStyles}`
    
    const inLineStyles = {
        background: bgImageSrc && `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5)), url(${bgImageSrc}) no-repeat center center / cover`, 
        backgroundColor: (background && background != 'none') ? bgOpacity ? `color-mix(in srgb, var(--${background}) ${bgOpacity}%, transparent)` : `var(--${background})` : '',
        
        ...(maxWidth && { maxWidth }),
        ...(minWidth && { minWidth }),
        ...(maxHeight && { maxHeight }),
        ...(minHeight && { minHeight }),
    };

    const filteredInLineStyles = Object.fromEntries(
        Object.entries(inLineStyles).filter(([_, value]) => value != null)
    );


    if (display) return (
        <form
        /* replace to '' */
        onSubmit={(e) => {e.preventDefault(); onSubmit && onSubmit(e)}}
        className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
        {...__juno?.attributes}
        style={Object.keys(filteredInLineStyles).length > 0 ? filteredInLineStyles : undefined}>
        {children}
        </form>
    );
}






/* ignore rest */

Form.definitions = {
    apiName: "Form",
    displayName: "Form Component",
    description: "A flexible form component for building various types of forms, supporting a wide range of layouts and styles.",
    ai_instructions: 'flex form container. has no children by default',
    type: 'input',
    relativeSize: 'small',
    acceptedChildren: 'everything except table components, layouts, sections, dialogs',
    status: 'stable',
    package: null,
    propDefinitions: {
        direction: {
            type: "direction",
            options: ["flex-col", "flex-row"],
            displayName: "Direction",
            default: "flex-col", 
            tile: '1/2',
            required: true
        },
        flexWrap: {
            type: "oneOf",
            options: ["nowrap", "wrap", "wrap-reverse"],
            displayName: "Flex Wrap",
            default: "nowrap", 
            tile: '1/2',
            required: true
        },   
        alignItems: {
            type: "alignItems",
            options: ["start", "center", "end", "stretch"],
            default: "start", 
            required: true,
            tile: '1/2',
        },
        justifyContent: {
            type: "justifyContent",
            options: ["start", "center", "end", "between"],
            default: "start",
            required: true, 
            tile: '1/2',
        },
        background: {
            type: "colors",
            options: ["base-0", 'base-50', "base-100", "base-200", "primary", "accent", "base-900", "none"],
            displayName: "Background",
            default: "none", 
            tile: '1/2',
        },
        bgOpacity: {
            type: "oneOf",
            displayName: "BG Opacity",
            options: ["10", "20", "30", "40", "50", "60", "70", "80", "90", "100"],
            default: '100',
            tile: '1/2',
        },
        fontColor: {
            type: 'colors',
            options: ['base-0', 'base-100', 'base-200', 'base-300', 'base-500', 'base-700', 'primary', 'accent', 'base-900', 'base-content', 'auto', 
                'success', 'error', 'warning', 'info', 'success-content', 'error-content', 'warning-content', 'info-content'],
            displayName: 'Font Color',
            default: null,
            tile: '1/2',
        },
        textSize: {
            type: "oneOf",
            options: ["auto", 'xs', "sm", "base", "md"],
            default: "auto", 
            tile: '1/2',
        },
        paddingX: {
            type: "spacing",
            options: ["2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Padding X",
            ai_instructions: 'adds padding on left and right. in addition to overall padding property',
            default: 'base', 
            tile: '1/2',
        },
        paddingY: {
            type: "spacing",
            options: ["2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Padding Y",
            ai_instructions: 'adds padding on top and bottom. in addition to overall padding property',
            default: null,
            tile: '1/2',
        },
             
        corners: {
            type: "corners",
            options: ["none", "sm", "md", "lg", 'xl', '2xl', "full"],
            displayName: "Corners",
            default: null, 
            tile: '1/2',
        },
        gap: {
            type: "gap",
            options: ["2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Gap",
            default: null,
            tile: '1/2',
        },
        width: {
            type: "oneOf",
            options: ["full", "auto", "1/2", "1/3", "1/4", "2/3", '3/4'],
            displayName: "Width",
            default: "full", 
            tile: '1/2',
        },
        height: {
            type: 'oneOf',
            options: ['h-full', 'h-1/2', 'h-1/3', 'h-1/4', 'h-2/3', 'h-[integer]px', 'h-auto'],
            default: null,
            displayName: "Height",
            tile: '1/2', 
            ai_instructions: 'for custom height use "h-[integer]px" syntax but replace integer with pixel height, e.g. "h-[200px]", i.e. follow tailwindcss utilities approach'
        },
        minWidth: {
            type: "number",
            default: null, 
            displayName: "Min Width",
            tile: '1/2',
        },
        minHeight: {
            type: "number",
            default: null,
            displayName: "Min Height",
            tile: '1/2',
        },

        maxWidth: {
            type: "number",
            default: null, 
            displayName: "Max Width",
            tile: '1/2',
        },

        maxHeight: {
            type: "number",
            default: null, 
            displayName: "Max Height",
            tile: '1/2',
        }
        ,selfAlign: {
            type: 'oneOf',
            options: ['auto', 'start', 'center', 'end'],
            default: 'auto'
        }, 
        hasOutline: {
            type: "bool",
            displayName: "Outline",
            default: false, 
            ai_instructions: 'adds base-300 1px outline',
            tile: '1/2',
        },
        display: {
            type: "bool",
            displayName: "Display",
            default: true
        },
        bgImageSrc: {
            type: 'imageURL',
            displayName: 'Image',
            ai_instructions: 'adds background image as cover, use rarely, make sure font color of the objects inside is readable',
            default: null
        }

    }
};
