import { useState, useEffect, useRef, useMemo, useContext } from "react";
import * as localUIKit from "../../../../../ui-kit/local/index";
import Fuse from 'fuse.js';
import { ArrowLeft, ArrowRight, Code, Eye, Settings, Xmark, XmarkCircle, XmarkCircleSolid } from "iconoir-react";
import FormattedSnippet from "./FormattedSnippet";
import * as ExportedKit from "../../../../../ui-kit/exports/text";
import ComponentList from "./ComponentList";
import { Arrows } from "../Arrows";
import StoryDisplay from "./StoryDisplay";
import { EditorContext } from "../../../EditorContext";
import { Badge, Button } from "../../../../../ui-kit/exports/react";

function retrieveStories(component) {
    const localComponent = localUIKit[component];
    if (!localComponent) return [];
    return localComponent.stories || [];
}

export default function UIKitDisplay() {
    const filterOut = ['AppShell', 'Main', 'Sidebar', 'Hero', 'Header', 'Footer', 
    'Module', 'FlexBox', 'Grid', 'IconoirIcon', 
    'SidePanel', 'FeaturePanel', 'Email', 'IconBar', 'NotFound']
    const allComponents = Object.keys(localUIKit)
    const {view, setView} = useContext(EditorContext);

    const allDefinitions = allComponents.map(componentKey => {
        const component = localUIKit[componentKey];
        return component.definitions || []; 
    });

    const components = allComponents.filter(component => !filterOut.includes(component))
    
    const [displayComponent, setDisplayComponent] = useState('Button');
    const [searchQuery, setSearchQuery] = useState('');
    

    const fuseUIKit = useMemo(() => new Fuse(components, {
        keys: [''],
        includeScore: true,
        threshold: 0.2,
        tokenize: true
    }), [components]);

    const filteredComponents = useMemo(() => {
        if (searchQuery.trim() === '') return components;
        return fuseUIKit.search(searchQuery).map(result => result.item);
    }, [components, fuseUIKit, searchQuery]);


    const stories = retrieveStories(displayComponent);

    const [ showCode, setShowCode ] = useState(false);
    const [ jsx, setJsx ] = useState('');
    const fetchComponentContent = (componentName) => {
        try {
          const content = ExportedKit[componentName];
          fetch(content)
            .then(response => response.text())
            .then(text => {
              if (text.includes("<!DOCTYPE html>") || text.includes("<html")) {
                setJsx(`Export for component '${componentName}' not available.`);
              } else {
                setJsx(text);
              }
            })
            .catch(error => {
              setJsx(`Export for component '${componentName}' not available.`);
            });
        } catch (error) {
          setJsx(`Export for component '${componentName}' not available.`);
        }
      };
    
      useEffect(() => {
        fetchComponentContent(displayComponent);
      }
      , [displayComponent]);

    function handleShowCode(value) {
        setShowCode(value)
        view.overviewSidebar && value && setView({...view, overviewSidebar: false}) // close sidebar to accomodate code view
    }

    const displayComponentDefinitions = allDefinitions.find(def => def.apiName === displayComponent);
    
    return (
        <div className="w-full flex flex-row h-full">
            <div className={`flex flex-col gap-2 flex-shrink-0 mr-3 transition-all duration-100
            ${showCode ? 'w-[240px]' : 'w-[320px]'} `}>
            {/* Search box container, remains fixed at the top */}
            <div className={`flex flex-row relative w-full pl-3 mb-1`}>
                <input
                    type='text'
                    className={`flex-grow text-left border-transparent font-medium text-sm
                    placeholder-base-500 text-base-content bg-transparent w-full truncate ellipsis
                    rounded-md px-3 py-1 ${searchQuery ? 'ring-1 ring-accent' : 'ring-1 ring-base-200 focus:ring-1 focus:ring-accent'}`}
                    value={searchQuery}
                    onChange={(e) => {e.stopPropagation(); setSearchQuery(e.target.value)}}
                    placeholder={'Search'}
                />
                <Xmark className={`flex-shrink-0 text-xs absolute right-2 top-1/2 transform cursor-pointer -translate-y-1/2 
                    ${searchQuery ? 'opacity-30 hover:opacity-60 hover:scale-105 flex ' : 'hidden'}`}
                    onClick={() => setSearchQuery('')} />
            </div>
            
            {/* Scrollable list of components */}
            
                <ComponentList 
                    filteredComponents={filteredComponents} 
                    setDisplayComponent={setDisplayComponent}
                    displayComponent={displayComponent} 
                    definitions={allDefinitions}
                    
                    />
            
            </div>
            <div className="relative flex flex-col flex-shrink-0 flex-grow h-full items-center justify-center overflow-hidden  
            max-w-full gap-2 px-2">
                <Controls component={displayComponent} showCode={showCode} setShowCode={handleShowCode} displayComponentDefinitions={displayComponentDefinitions} />
                <div className="flex flex-row w-full h-full overflow-hidden">
                <StoryDisplay stories={stories} displayComponent={displayComponent} />
                <div className={`relative flex flex-col h-full overflow-hidden transition-all ${showCode ? 'flex ml-3 w-[480px] flex-shrink-0 border border-base-100 bg-base-0 rounded-lg' : 'hidden w-0'}`}>
                <FormattedSnippet jsx={jsx} />
                </div>
            </div>
            </div>                
            
            
            
        </div>
    );
}




function Controls({component, showCode, setShowCode, displayComponentDefinitions}) {
    const status = displayComponentDefinitions?.status;
    const colorMap = {
        'stable': 'success',
        'in review': 'error',
        'prototype': 'warning'
    }
    return (
        <div className="w-full justify-between flex flex-row gap-2 font-medium text-lg">
                <div className="flex flex-row gap-2 items-baselind">
                  {component}
                  {/*status &&
                  <Badge 
                    text={status}
                    color={colorMap[status.toLowerCase()]}
                    size="small" 
                    type='light'
                    alignSelf="start"

                  />*/}
                </div>
                {/*<Button 
                style={'ghost'}
                color='primary'
                size='small'
                text={showCode ? 'Hide Code' : 'Show Code'}
                leftIcon={!showCode ? 'arrow-left' : 'arrow-right'}
                onClick={() => setShowCode(!showCode)}
                />*/}
        </div>
    )
}

