import { FastArrowLeft, FastArrowRight, Minus, NavArrowDown, Plus } from 'iconoir-react';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

export default function CustomSize({ propKey, options, currentOption, onSelect }) {
  const initialValue = parseInt(currentOption);
  const initialUnit = currentOption?.includes('%') ? '%' : 'px';

  const [value, setValue] = useState(isNaN(initialValue) ? 0 : initialValue);
  const [unit, setUnit] = useState(initialUnit);

  const pxScale = [1, 2, 4, 6, 8, 12, 16, 20, 24, 36, 48, 64, 80, 120, 160, 200, 240];
  const perctScale = [8, 10, 20, 25, 33, 40, 50, 67, 75, 80, 100, 120, 150, 200];

  useEffect(() => {
    const newValue = parseInt(currentOption);
    const newUnit = currentOption?.includes('%') ? '%' : 'px';
    setValue(isNaN(newValue) ? 0 : newValue);
    setUnit(newUnit);
  }, [currentOption]);

  const handleSelect = (value) => {
    onSelect(propKey, value);
  };

  const handleValueChange = (e) => {
    const newValue = Math.max(0, Math.floor(e.target.value)); // No negative values and no decimals
    setValue(newValue);
    onSelect(propKey, `${newValue}${unit}`);
  };

  const handleUnitChange = (e) => {
    const newUnit = e.target.value;
    setUnit(newUnit);
    onSelect(propKey, `${value}${newUnit}`);
  };

  const incrementValue = () => {
    const newValue = value + 1;
    setValue(newValue);
    onSelect(propKey, `${newValue}${unit}`);
  };

  const decrementValue = () => {
    const newValue = Math.max(0, value - 1);
    setValue(newValue);
    onSelect(propKey, `${newValue}${unit}`);
  };

  const fastDecrementValue = () => {
    const scale = unit === '%' ? perctScale : pxScale;
    const currentIndex = scale.findIndex(val => val >= value);
    if (currentIndex > 0) {
      const newValue = scale[currentIndex - 1];
      setValue(newValue);
      onSelect(propKey, `${newValue}${unit}`);
    }};

    const fastIncrementValue = () => {
      const scale = unit === '%' ? perctScale : pxScale;
      const currentIndex = scale.findIndex(val => val >= value);
      if (currentIndex < scale.length - 1) {
        const newValue = scale[currentIndex + 1];
        setValue(newValue);
        onSelect(propKey, `${newValue}${unit}`);
      }
    };
  
    const buttonClasses = "text-sm bg-base-100 px-2 py-1 rounded-md border border-transparent hover:border-base-200 transition-all cursor-pointer flex flex-row gap-1 items-center";

  return (
      <div className='flex justify-between items-stretch mt-2 gap-1 w-full text-xs'>
        <button
          onClick={fastDecrementValue}
          className={buttonClasses}
        >
          <FastArrowLeft className='text-xs' />
        </button>
        <button
          onClick={decrementValue}
          className={buttonClasses}
        >
          <Minus className='text-xs' />
        </button>
        <div className='bg-base-100 p-1 rounded-md flex items-center gap-1'>
        <input
          type='number'
          value={value}
          onChange={handleValueChange}
          className='w-[40px] p-1 bg-transparent focus:bg-base-0 rounded text-center'
        />
        <select
          value={unit}
          onChange={handleUnitChange}
          className='bg-transparent text-base-700 p-1 rounded hover:bg-base-0 transition-all'
        >
          <option value='%'>%</option>
          <option value='px'>px</option>
        </select>
        </div>
        <button
          onClick={incrementValue}
          className={buttonClasses}
        >
          <Plus className='text-xs' />
        </button>
        <button
          onClick={fastIncrementValue}
          className={buttonClasses}
        >
          <FastArrowRight className='text-xs' />
        </button>
        <div className="relative w-6">
        <select
          value={currentOption}
          onChange={(e) => handleSelect(e.target.value)}
          className="appearance-none w-full p-1 rounded-lg bg-transparent text-xs text-white text-center cursor-pointer"
        >
          {options.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <div>
          <NavArrowDown className="absolute w-full h-full top-0 left-0 bg-base-200 px-1 py-1 rounded-md transition-all cursor-pointer pointer-events-none" />
        </div>
      </div>
      </div>
  );
}
