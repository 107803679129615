import React, { useEffect, useRef, useState } from 'react';
import { Icon, Loader } from '../'
import { IconType, allIconNames } from '../iconMap'

export type ButtonStateType = "default" | "disabled" | "loading" | "active";

type Props = {
    width?: 'auto' | '1/2' | 'full';
    text?: string;
    state?: ButtonStateType;
    color?: 'current' | 'base-200' | 'base-700' | 'primary' | 'accent' | 'warning' | 'info' | 'success' | 'error' | string;
    style?: 'filled' | 'outlined' | 'ghost' | 'link' | 'light';
    size?: 'small' | 'medium' | 'large';
    leftIcon?: IconType;
    rightIcon: IconType;
    isPill?: boolean;
    children?: React.ReactNode;
    marginTop?: '4px' | '6px' | '8px' | '12px' | '16px' | '24px' | '32px';
    closeOnOutsideClick?: boolean;
    openByDefault?: boolean;
    hideOnMobile?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    __juno?: any;
  };

  export default function SplitButton({
        leftIcon,
        rightIcon = 'chevron-down',
        text = 'Button',
        
        color = 'current',
        style = 'light',

        size = 'medium',
        width = 'auto',
        isPill = false,
        
        marginTop,
        children,
        state = 'default',
        openByDefault = false,
        onClick = () => {},
        closeOnOutsideClick = true,
        hideOnMobile=false,
        __juno = {},
}: Props) {

    const isDisabled = state == 'disabled'
    const isLoading = state == 'loading'
    const isActive = state == 'active'

    // bg-current ring-current ring-current-10 ring-current-5
    
    const borderSize = size == 'small' ? '0.5' : size == 'large' ? '2' : '1'
    /* Filled */
    const textColor = color== 'current' ? 'current' : color == 'base-200' ? 'base-content' : 'base-0'
    const statusStyles = (isDisabled || isLoading || color =='current') ? '' : isActive ? 'brightness-90' : 'hover:brightness-110 active:brightness-90'
    const bgStyles = color == 'current' ? 'bg-current' : `bg-${color}`
    const filledStyle = `${bgStyles} text-${textColor} ${statusStyles} `

    /* Outlined */
    const outlineStatusStyles = (isDisabled || isLoading )  ? '' : isActive ? 'bg-current-10' : 'hover:bg-current-10 active:bg-transparent'
    const outlinedStyle = `ring-1 ring-inset ring-${color} text-${color} ${outlineStatusStyles}`

    /* Light */
    const lightColor = color == 'current' ? 'bg-current-5' : color == 'base-200' ? 'bg-base-100' : color == 'base-700' ? 'bg-base-500' : 'bg-'+color+'-surface'
    const lightTextColor =  color == 'base-700' ? 'base-0' : color == 'base-200' ? 'base-content' : color+'-content'
    const lightStatusStyles = (isDisabled || isLoading ) ? '' : isActive ? `bg-${lightColor}/75` : `hover:bg-${lightColor}/75`
    const lightStyle = `text-${lightTextColor} ${lightColor}  ${lightStatusStyles}`

    /* Ghost */
    const ghostStatusStyles = (isDisabled || isLoading ) ? '' : isActive ? `bg-current-5` : `hover:bg-current-5`
    const ghostStyle = `text-${color} ${ghostStatusStyles}`

    /* Link */
    const linkStatusStyles = !(isDisabled || isLoading || isActive)  ? `hover:underline opacity-80 hover:opacity-100` : ''
    const linkStyle = `text-${color} ${linkStatusStyles}`
    
    const fontStyles = `font-medium` 
    
    const styleMap = {
        filled: filledStyle,
        outlined: outlinedStyle,
        ghost: ghostStyle,
        link: linkStyle,
        light: lightStyle
    }
    let typeStyles = styleMap[style]
    
    const paddingUnit = size == 'small' ? '1.5' : size == 'large' ? '3' : '2.5'

    // -botton-1 -bottom-1.5 -bottom-2 
    const gapUnit = size == 'small' ? '1' : size == 'large' ? '2' : '1.5'
    const heightStyles = size == 'small' ? 'h-7' : size == 'large' ? 'h-12' : 'h-9'
    let sizeStyles = `h-9 pl-2.5 pr-0 gap-${gapUnit} text-sm`;  // default size
    sizeStyles = size == 'small' ? `h-7 pl-1.5 pr-0 gap-${gapUnit} text-xs` : size == 'large'  ? `h-12 pl-3 pr-0 gap-${gapUnit} text-base` : sizeStyles

    const widthStyle = width == 'auto' ? `w-auto` : `w-${width}`
    const cornerStyles = isPill ? 'rounded-full' : size == "small" ? "rounded" : size == "large" ? "rounded-lg" : "rounded-md"
    
    const classes = `flex-shrink-0 select-none
        relative flex flex-row items-center transition-all duration-75 box-border cursor-pointer justify-between 
        ${fontStyles} ${typeStyles} ${sizeStyles} ${cornerStyles} w-full
        ${isDisabled ? 'opacity-50 saturate-50 !cursor-not-allowed' : ''}`
    
     const iconSize = size == 'small' ? '16px' : size == 'large' ? '24px' : '20px'
     const iconWidth = size == 'small' ? 'w-4 h-4' : size == 'large' ? 'w-6 h-6' : 'w-5 h-5'
     const LeftIconComponent = leftIcon ? <Icon icon={leftIcon} size={iconSize} className={`scale-90 ${iconWidth}`} /> : null;
     
    // border-l-[1px] border-l-[2px] border-l-[1.5px] 
     // {leftIcon as IconNameType}
    const loaderColor = 'current'
    

    const [ isOpen, setIsOpen ] = useState(openByDefault);
    
    const dropdownRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (!closeOnOutsideClick) return;
        const handleClickOutside = (event: MouseEvent) => {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setIsOpen(false);
          }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [dropdownRef]);
    
    function handleClick(e: React.MouseEvent<HTMLDivElement>) {
        e.stopPropagation();
        setIsOpen(!isOpen);
    }
    const RightIconComponent = rightIcon ? <Icon icon={rightIcon} size={iconSize} className={`active:translate-y-0.5 transition-all 
        duration-150 ${isOpen && (rightIcon == 'chevron-down') ? 'rotate-180' : ''}
        ${iconWidth}`} /> : null;
    //bg-base-0 shadow p-1.5 border-[0.5px] border-base-200 rounded-md 
    return (
        <div className={`relative ${widthStyle} ${hideOnMobile ? 'hidden md:flex' : ''} 
        ${heightStyles}
        ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
        {...__juno?.attributes}
            ref={dropdownRef}
        style={{marginTop: marginTop}}
        >
        <div className={`${classes}`}>
            {LeftIconComponent &&
            <div className={`flex flex-row items-center justify-end flex-grow ${isLoading ? 'invisible' : ''}`}>
                {LeftIconComponent}
            </div>}
            <div className='flex-shrink-0 max-w-full box-border flex-grow'>
            {isLoading && <div className={`absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2
                ${color == 'current' && style == 'filled' ? 'text-base-0 mix-blend-difference' : ''}`}>
                <Loader 
                size={size == 'small' ? '12px' : '16px'}
                color={loaderColor}
                type='spinner'
                opacity={(style == 'filled') ? '50' : '100'}
                /></div>}
             <button className={`${isLoading ? 'opacity-0' : ''} w-full flex-grow flex flex-row items-center justify-center gap-2 whitespace-nowrap 
             ${color == 'current' && style == 'filled' ? 'text-base-0 mix-blend-difference' : ''}
             truncate max-w-full`}
            onClick={(e)=> !isDisabled && onClick(e)}
             
             >
                {text}
                
            </button>
            </div>
            <div className={`flex flex-row flex-grow-0 
            
            border-l-[${borderSize}px] border-current-10 pl-${gapUnit} pr-${paddingUnit} items-center justify-end flex-grow ${isLoading && 'invisible'}
            ${color == 'current' && style == 'filled' ? 'text-base-0 mix-blend-difference' : ''}
            `}
            onClick={handleClick}
            >
                {RightIconComponent}
            </div>

            
        </div>
        {isOpen && children &&
            <div 
                className={`absolute -bottom-${gapUnit} translate-y-full right-0 z-10 
                flex flex-col items-stretch w-full animate-fadeInDown transition-all duration-150`}
            >
                {children}
            </div>}
        </div>
    ); 
}


