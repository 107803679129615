// ADD A FRAME
export function add(body, token) {
  return fetch("/frames/add", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${token}`
    },
    body: JSON.stringify(body),
  }).then((response) => response.json());
}

// UPDATE A FRAME
export function update(body, token) {
  
  return fetch("/frames/update", {
    method: "POST",
    headers: {"Content-Type": "application/json","Authorization": `Bearer ${token}`},
    body: JSON.stringify(body),
  }).then((response) => response.json());
}

// DELETE FRAME
export function remove(body, token) {
  return fetch('/frames/remove', {
      method: 'POST', 
      headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, 
      body: JSON.stringify(body)
  }).then((response) => response.json());
}