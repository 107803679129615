import { AppShell, Main, Header, Logo, HeaderLink, UserMenu } from '../';

export default function AppWrapper({ 
        templateOjects, // render as LightObject
        children, 
        __juno = {}
    }) {

    const nonMainChildren = []
    const main = {}
    
    return (
        <AppShell pageBackground='base-50' __juno={__juno}>
            {nonMainChildren}
            
            <Main {...main?.object_props}
             __juno={{...__juno, tagStyle: null}}
            >
            {children}
            </Main>
        </AppShell> 
    );
}


