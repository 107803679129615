import { Icon } from "../../ui-kit/exports/react/index"
import { allIconNames, iconMap } from "ui-kit/exports/react/components/iconMap"
import { AppShell, Main } from "../../ui-kit/local/index"

export default function IconsPage() {
    const headerClasses = "text-sm font-medium text-left py-2"
    const cellClasses = "text-sm font-normal text-left"

    const columns = ['key', 'feather', 'ionic', 'material', 'heroicons', 'iconoir']

    return (
        <AppShell>
            <Main width={'stretch'} background={'base-0'}>
                <table className="w-full relative">
                    <thead className="font-medium sticky top-0">
                        <tr className="border-b bg-base-0/75 backdrop-blur-sm border-base-200">
                            {columns.map((col, index) => (
                                <th key={index} className={headerClasses}>{col}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {allIconNames.map((key, index) => (
                            <tr key={index} className="border-b hover:bg-blue-50 select-none py-1">
                                <td className={`${cellClasses}`}>
                                {key}</td>
                                {columns.slice(1).map((col, index) => (
                                    <td key={index} className={cellClasses}>
                                        <div className="flex items-center justify-start gap-1">
                                            <div className="w-6 h-6 flex items-center justify-center bg-gray-300">
                                            <Icon icon={key} library={col} className='bg-white' />
                                            </div>  
                                        <span className="text-xs opacity-50">
                                        {/* iconMap[key][col] */}
                                        </span>
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </Main>
        </AppShell>
    )
}