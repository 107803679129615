import { useState, useEffect } from 'react';
import { Icon } from '../../../../../ui-kit/local/index';
import { Xmark } from 'iconoir-react';
import Fuse from 'fuse.js';

export default function SmartIcon({ propKey, icons, currentIcon, onChange, label }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredIcons, setFilteredIcons] = useState(icons);

  // Fuse.js configuration
  const options = {
    includeScore: true,
    keys: ['name'],
    threshold: 0.2,
  };

  const fuse = new Fuse(icons.filter(icon => icon !== 'none' && icon !== 'auto'), options);

  useEffect(() => {
    if (searchTerm !== '') {
      const results = fuse.search(searchTerm).map(result => result.item);
      setFilteredIcons(results);
    } else {
      setFilteredIcons(icons.filter(icon => icon !== 'none' && icon !== 'auto'));
    }
  }, [searchTerm, icons]);

  return (
    <div className='flex flex-col w-full gap-1 items-end text-xs'>
      <div className='flex flex-row gap-1 justify-between items-end w-full'>
        {label}
        
        <button className='hover:underline opacity-70 hover:opacity-100 transition-all duration-75'
          onClick={() => onChange(propKey, null)}
        ><Xmark /></button>
      </div>
      
      <div className='flex flex-col w-full text-xs items-start ring-1 ring-base-200 rounded-md transition-all duration-150 overflow-hidden' 
      style={{ height: '100px' }}>
        <div className='w-full flex-row p-1 gap-2 items-center border-b'>
        {currentIcon != 'none' && currentIcon && <Icon icon={currentIcon} className='flex-shrink-0' />}
        <input
          type="text"
          placeholder="Search icons..."
          className="bg-transparent w-full rounded p-1"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        </div>
        <div className='flex flex-wrap w-full gap-1.5 text-xs items-start overflow-y-scroll h-full flex-grow p-1' >
        {filteredIcons.map((icon, index) => (
          <div className={`w-5 h-5 ${icon === currentIcon ? 'text-primary bg-white rounded scale-110 ring-1 ring-base-300' : 'text-base-700'} flex items-center justify-center hover:scale-110
          transition-all duration-75 hover:z-10 relative group 
          `}
            key={index}
            onClick={() => onChange(propKey, icon)}>
            <Icon
              icon={icon}
              key={index}
            />
            <div className='absolute -top-2 left-1/2 transform -translate-x-1/2 -translate-y-full opacity-0 group-hover:opacity-100 
            text-xs whitespace-nowrap bg-base-100 text-base-700 p-1 rounded-md pointer-events-none
            '
            >{icon}</div>
          </div>

        ))}
        </div>
      </div>

    </div>
  );
};