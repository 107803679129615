import React, { useMemo } from 'react';
import { convertJSXToObjectsSync } from '../utilities/AIhelpers';
import { getDescendants } from '../utilities/helpers';
import LightRouter from './LightRouter';
import { convertThemeVariablesToStyles } from '../pages/editor/canvas/helpers';

export default function ComponentPreviewObjects({
    objects,
    width = '100%',
    height = '100%',
    thumbnail,
    assets = [],
    themeVariables = []
  }) {

    return thumbnail ? (
      <Thumbnail width={width} height={height} thumbnail={thumbnail} />
    ) : (
      <Miniature objects={objects} width={width} height={height} assets={assets} themeVariables={themeVariables} />
    );
  }
  
const Thumbnail = ({ width, height, thumbnail }) => (
  <div
    draggable={false}
    style={{
      width,
      height,
      backgroundImage: `url(${thumbnail})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      overflow: 'hidden'
    }}
  />
);

const Miniature = ({ objects, width, height, assets, themeVariables }) => {
  
  const rootObject = objects?.find(obj => obj.parent === 'rootObject');
  // console.log(objects)
  const themeStyles = convertThemeVariablesToStyles(themeVariables);

  return (
    <div
      className="relative flex flex-grow flex-col items-center justify-center w-full"
      style={{
        ...themeStyles,
        width,
        height,
        color: 'var(--base-content)',
        fontFamily: `var(--mainFont), ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
        userSelect: 'none',
        pointerEvents: 'none',
      }}>
      {rootObject &&
        <LightRouter
          key={rootObject.id}
          self={rootObject}
          assets={assets}
          descendants={getDescendants(rootObject.id, objects)}
        />
      }
    </div>
  );
};

