type Props = {
    name?: string,
    time?: string,
    avatar?: string,
    content?: string,
    maxWidth?: '75%' | '80%' | '100%',
    bgColor?: 'base-50' | 'base-100' | 'info-surface' | 'info' | 'primary' | 'current-5' | 'base-800',
    position?: 'left' | 'right',
    isTyping?: boolean,
    tailStyle?: 'bubble' | 'simple' | 'mouse',
    tailPosition?: 'top' | 'bottom',
    style?: 'flat' | 'bubble',
    __juno?: any
}



export default function MessageBubble({
        time,
        content,
        bgColor,
        isTyping,
        style = 'bubble',
        position = 'left',
        tailPosition = 'bottom',
        tailStyle,
        __juno = {}

    }: Props) {
    

    const selfAlign = position === 'left' ? 'self-start' : 'self-end'
    const miniTailStyles = position === 'left' ? tailPosition === 'top' ? 'rounded-t-xs' : 'rounded-bl-xs' : tailPosition === 'top' ? 'rounded-tr-xs' : 'rounded-br-xs'
    const bubbleStyle = style === 'bubble' ? `px-2 py-1 rounded-lg ${tailStyle == 'simple' ? miniTailStyles : ''}` : ''
    const fontColor = (bgColor == 'primary' || bgColor == 'info' || bgColor == 'base-800') && style === 'bubble' ? 'text-base-0' : ''
    const classes = `flex relative max-w-full h-auto min-h-6 text-sm ${selfAlign} ${fontColor} gap-2 ${bubbleStyle} bg-${bgColor}`
    

    //${style != 'bubble' ? 'bg-'+bgColor : ''}//
    // rounded-bl-sm rounded-br-sm rounded-bl-xs rounded-br-xs rounded-tl-sm rounded-tr-sm rounded-tl-xs rounded-tr-xs


    return (
        <div className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`} {...__juno?.attributes} 
        style={{minHeight: '1.5rem'}}
        >    
                {isTyping ? <TypingDots /> : content}
                {tailStyle == 'mouse' && <MouseTail position={position} bgColor={bgColor} />}
                {tailStyle == 'bubble' && <BubbleTail position={position} bgColor={bgColor} />}
                
        </div>
    );
}

const TypingDots = () => {
    const dotStyle =  `dot w-[6px] h-[6px] bg-gray-600 `
      return (
        <div className="flex flex-row gap-[3px] self-center">
          <div className={dotStyle}></div>
          <div className={dotStyle}></div>
          <div className={dotStyle}></div>
        </div>
    )
};

const MouseTail = ({position, bgColor}) => {
    // -left-[6px] -right-[6px] 
    const flipStyle = position == 'left' ? 'scale-x-[-1]' : ''
    return (
        <svg className={`absolute -bottom-px -z-10 -${position}-[6px] ${flipStyle}`} width="17" height="17" viewBox="0 0 17 17">
            <path d="M11.5 10.5C12.0014 13.5086 14.8333 16.3333 16.5 17C10.1 17 6 14.8333 5 13.5L0 15L0.5 0H11V2V4V4.5C11 5.5 11 7.5 11.5 10.5Z" 
            fill={'var(--'+bgColor+')'} 
            />
        </svg>
    )
}

const BubbleTail = ({position, bgColor}) => {
    
    const flipStyle = position == 'left' ? '-translate-x-full -left-0.5' : '-right-0.5 scale-x-[-1]'
    return (
        <div className={`absolute bottom-4 -z-10   translate-y-full ${flipStyle}`} >
            <div className={`w-3 h-3 bg-${bgColor} rounded-full absolute`}>
                <div className={`absolute w-[5px] h-[5px] bg-${bgColor} rounded-full -translate-x-1 -bottom-1 left-px`} />
            </div>
        </div>
    )
}

/* ignore rest */

MessageBubble.definitions = {
    apiName: 'MessageBubble',
    displayName: 'Message Bubble',
    description: '',
    ai_instructions: '',
    type: 'chat',
    relativeSize: 'small',
    acceptedChildren: 'none',
    status: 'stable',
    package: 'Starter',
    propDefinitions: {
        content: {
            type: 'longString',
            displayName: 'Name',
            default: null, 
            editable: true,
        },
        time: {
            type: 'string',
            displayName: 'Time',
            default: null
        },
        bgColor: {
            type: 'colors',
            options: ['primary', 'info', 'info-surface', 'base-50', 'base-100', 'current-5', 'base-800'],
            displayName: 'Color',
            default: 'base-50',
            miniEditor: true,
        },
        style: {
            type: 'oneOf',
            options: ['flat', 'bubble'],
            default: 'bubble',
            displayName: 'Style',
            tile: '1/2',
            miniEditor: true,
        },
        position: {
            type: 'oneOf',
            options: ['left', 'right'],
            default: 'left',
            displayName: 'Position',
            tile: '1/2',
            miniEditor: true,
        },
        tailPosition: {
            type: 'oneOf',
            options: ['top', 'bottom'],
            default: 'bottom',
            displayName: 'Tail Position',
            tile: '1/2',
            miniEditor: true,
        },
        tailStyle: {
            type: 'oneOf',
            options: ['bubble', 'simple', 'mouse'],
            default: 'bubble',
            displayName: 'Tail Style',
            tile: '1/2',
            miniEditor: true,
        }, 
        isTyping: {
            type: 'bool',
            displayName: 'Is Typing',
            default: false
        },
    }
}