import React from 'react';
import { spacingMap } from '../helpers';

type HeaderProps = {
    width?: '100%' | '560px' | '780px' | '960px' | '1020px' | '1080px' | '1200px' | '1440px',
    paddingX?: '6px' | '8px' | '12px' | '16px' | '20px' | '24px' | '32px' | '48px' | '64px',
    paddingY?: '6px' | '8px' | '12px' | '16px' | '20px' | '24px' | '32px' | '48px' | '64px',
    gap?: '4px' | '6px' | '8px' | '10px' | '12px' | '16px' | '24px' | '32px' | '48px',
    background?: 'base-0' | 'base-100' | 'base-200' | 'base-700' | 'base-content' | string,
    hasBorder?: boolean,
    alignItems?: 'start' | 'end' | 'center' | 'stretch',
    justifyContent?: 'start' | 'center' | 'end' | 'between',
    fontSize?: 'xs' | 'sm' | 'base' | 'md' | 'lg' | 'xl',
    minHeight?: number,
    children?: React.ReactNode,

    __juno?: any,
}

function Header({
        width = '100%',
        
        paddingX,
        paddingY,
        gap,

        background,
        hasBorder = false,
        
        alignItems = 'center',
        justifyContent = 'between',
        fontSize = 'base',
        minHeight = 64,
        children,

        __juno = {}
      }: HeaderProps) {
    
    const bgStyles = background ?`bg-${background}` : '';
    // font color function needs to be more robust and isolated
    const fontColor = (!background || background == 'none') ? '' : (background?.startsWith('base') && background != 'base-content' && background != 'base-700') ? 'text-base-content' : `text-base-0`
    const paddingStyles = `${paddingX ? ` px-${spacingMap[paddingX]}` : ''}${paddingY ? ` py-${spacingMap[paddingY]}` : ''}`;
    const gapStyles = gap ? `gap-${spacingMap[gap]}` : '';

    const borderStyles = hasBorder ? "border-b border-current-10" : "";
    const alignItemsStyles = alignItems ? `items-${alignItems}` : '';
    const fontSizeStyles = `text-${fontSize}`;
    const justifyContentStyles = justifyContent ? `justify-${justifyContent}` : '';
    
    
    const innerClasses = `relative flex flex-row w-full border-box ${fontSizeStyles} ${fontColor} ${paddingStyles} ${gapStyles} ${alignItemsStyles} ${justifyContentStyles}`

    const outerClasses = `w-full flex flex-col items-center ${bgStyles} ${borderStyles} relative backrop-blur`;

    return (
        <div 
        className={`${outerClasses} ${__juno?.outlineStyle}`}
        {...__juno?.attributes}
        >
            <div 
            className={`${innerClasses} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
            data-tag={__juno?.attributes?.['data-tag']}   
            
            style={{width: '100%', maxWidth: width, minHeight: minHeight ? `${minHeight}px` : 'auto'}}>
                {children}
                </div>
        </div>
    );
}

Header.__juno_name = "Header";
export default Header;


/* ignore rest */

Header.definitions = {
    apiName: 'Header',
    displayName: 'Header',
    description: 'A customizable header component designed for web and app interfaces. Supports various customization options including padding, background, border, gap, alignment, and font size. Can contain a variety of child elements for diverse header designs.',
    ai_instructions: 'header component for web and app interfaces with customizable features. can only be used in Main or AppShell. has flex-col property',
    type: 'section',
    package: 'Starter',
    relativeSize: 'large',
    acceptedChildren: 'everything except table components, layouts, sections, dialogs',
    propDefinitions: {
        width: {
            type: 'oneOf',
            options: ['stretch', '780', '960', '1200', '1440'],
            tile: '1/2',
            displayName: 'Width',
            default: 'stretch'
        },
        minHeight: {
            type: 'number',
            displayName: 'Min Height',
            default: null, 
            tile: '1/2',
            ai_instructions: 'should not be too high'
        }, 
        background: {
            type: 'colors',
            options: ['base-0', 'base-50', 'base-100', 'base-200', 'none', 'base-700', 'base-content'],
            displayName: 'Background',
            default: 'none'
        },
        paddingX: {
            type: "spacing",
            tile: '1/2',
            options: ["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px'],
            displayName: "Padding X",
            ai_instructions: 'adds padding on left and right. in addition to overall padding property',
            default: null
        },
        paddingY: {
            type: "spacing",
            tile: '1/2',
            options: ["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px'],
            displayName: "Padding Y",
            ai_instructions: 'adds padding on top and bottom. in addition to overall padding property',
            default: null
        },
        gap: {
            type: 'gap',
            options: ["4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Gap",
            default: null,
            tile: '1/2',
        },
        fontSize: {
            type: 'oneOf',
            options: ['xs', 'sm', 'base', 'md', 'lg', 'xl'],
            default: 'base',
            tile: '1/2',
            displayName: 'Font Size'
        },
        alignItems: {
            type: 'alignItems',
            options: ['start', 'end', 'center', 'stretch'],
            displayName: 'Align',
            tile: '1/2',
            default: 'center'
        },
        justifyContent: {
            type: 'justifyContent',
            options: ['start', 'center', 'end', 'between'],
            tile: '1/2',
            displayName: 'Justify Content',
            default: 'between'
        },
        
        hasBorder: {
            type: 'bool',
            default: false,
            displayName: 'Bottom Border', 
            ai_instructions: 'adds thin base-300 border at the bottom', 
        },

    }
};

/*Header.propTypes = {
    background: PropTypes.oneOfType([
        PropTypes.oneOf(['base-0', 'base-100', 'base-200', 'base-700', 'base-content']),
        PropTypes.string]),
    width: PropTypes.oneOf(['stretch', '780', '960', '1200', '1440']),
    paddingX: PropTypes.oneOf(["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px']),
    paddingY: PropTypes.oneOf(["6px", "8px", "12px", "16px", "24px", "32px", "48px", '64px']),
    gap: PropTypes.oneOf(["4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"]),
    
    hasBorder: PropTypes.bool,
    alignItems: PropTypes.oneOf(['start', 'end', 'center', 'stretch']),
    justifyContent: PropTypes.oneOf(['start', 'center', 'end', 'between']),
    fontSize: PropTypes.oneOf(['xs', 'sm', 'base', 'md', 'lg', 'xl']),
    minHeight: PropTypes.number,
    children: PropTypes.node
};
*/