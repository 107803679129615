export const mergeThemeVariables = (defaultTheme, theme) => {
    // Create a map from the defaultTheme for quick lookup

    const defaultVarsMap = new Map(defaultTheme.variables.map(v => [v.api_name, v]));
  
    // Iterate over the theme variables
    theme.variables.forEach(themeVar => {
        // If a matching variable is found in the theme, replace or add it in the map
        defaultVarsMap.set(themeVar.api_name, themeVar);
    });
  
    // Convert the map back to an array
    return Array.from(defaultVarsMap.values());
  };