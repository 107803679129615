
import { Base64 } from 'js-base64';

export async function uploadImage(imageData, imageName) {
  try {
    const arrayBuffer = await imageData.arrayBuffer();
    const uint8Array = new Uint8Array(arrayBuffer);
    const base64String = Base64.fromUint8Array(uint8Array);

 
    const response = await fetch("/uploadImage", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        imageData: base64String,
        imageName,
      }),
    });

    if (!response.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await response.json();
    return data.imageUrl;
  } catch (error) {
    console.error("Error uploading preview image:", error);
    throw error;
  }
}

export async function uploadVideo(videoData, videoName) {
  try {
      const arrayBuffer = await videoData.arrayBuffer();
      const uint8Array = new Uint8Array(arrayBuffer);
      const base64String = Base64.fromUint8Array(uint8Array);

      const response = await fetch("/uploadVideo", {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
          },
          body: JSON.stringify({
              videoData: base64String,
              videoName,
          }),
      });

      if (!response.ok) {
          throw new Error("Network response was not ok");
      }

      const data = await response.json();
      return data.videoUrl;
  } catch (error) {
      console.error("Error uploading video:", error);
      throw error;
  }
}

/*/ Function to convert Data URI to Blob, same as for images
export function dataURItoBlobVideo(dataURI) {
  const binary = atob(dataURI.split(",")[1]);
  const array = [];
  for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
  }
  return new Blob([new Uint8Array(array)], { type: "video/mp4" }); // Assuming MP4, change type as needed
}
 

  export function dataURItoBlob(dataURI) {
    const binary = atob(dataURI.split(",")[1]);
    const array = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: "image/png" });
  }*/