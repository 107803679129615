import { nanoid } from "nanoid"
import {v1 as uuidv1 } from 'uuid'

export function deleteFolder({ folder, handleAction }) {

    const isConfirmed = window.confirm(`Are you sure you want to delete the folder: ${folder.name}?
    It will be archived for 30 days before being permanently deleted.`);

    if (isConfirmed) {
        const action = {
            type: 'ARCHIVE_FOLDER',
            folder: folder
        };
        handleAction(action);
    } else {
        console.log('Folder deletion was canceled.');
    }
}

export function duplicateFolder({folder, pagesToCopy, handleAction}) {
    const newFolder = {
        ...folder,
        id: uuidv1(),
        name: folder.name + ' Copy',
        index: folder.index + 1
    }

    const pages = pagesToCopy?.map(page => ({
        ...page,
        id: uuidv1(),
        folder_id: newFolder.id,
    })) || [];

    const action = {
        type: 'INSERT_FOLDER',
        newFolder,
        pages: pages,
    };
    handleAction(action);

    for (let newPage of pages) {
        const action = {
            type: 'INSERT_PAGE',
            newPage
        }
        handleAction(action);
    }
}