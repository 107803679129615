// src/UserContext.js
import { createContext } from 'react';

const UserContext = createContext({
  user: null,
  setUser: () => {},
  isAuthenticated: false,
  setIsAuthenticated: () => {}, 
});

export default UserContext;
 