import logo from './juno-new.svg';
import { Link, useNavigate } from 'react-router-dom';

export default function NotFoundPage() {
    const navigate = useNavigate();
  return (
    <div className='flex flex-col items-center justify-center h-screen bg-white'>
      <div className='max-w-[420px] w-full bg-white rounded-lg p-6 pb-10 flex flex-col gap-5'>
      <img src={logo} alt="Logo" width={160} className='mx-auto mb-10'/>
      <p className='text-center text-5xl font-bold text-slate-700'>404</p>
        <p className='text-center text-md w-3/4 mx-auto text-slate-700 mb-10'>Oh no, the page you're looking for was not found.</p>
          <button onClick={() => navigate('/')} className='rounded-xl w-full text-center bg-orange-600 text-white p-4 hover:bg-orange-700 font-medium transition-colors pointer duration-200' >Go to Main Page</button>
      </div>
    </div>
  );
  
}
