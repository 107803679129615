import { useContext, useRef, useState } from "react";
import { EditorContext } from "../../EditorContext";
import UIKit from "./UIKit";
import Search from "./search/Search";
import { makePrimitives, makeTemplates } from "./helpers";
import Results from "./search/Results";
import ComponentTemplates from "./templates/ComponentTemplates";
import { TemplatesContext } from "../../TemplatesContext";
import { SegmentedSwitch } from "../../../../ui-kit/exports/react";


export default function Library() {
    const { view, setView  } = useContext(EditorContext)
    const { templates } = useContext(TemplatesContext)
    
    const [selectedOption, setSelectedOption] = useState('components');
    const switchOptions = 
      [
        // { label: 'Popular', value: 'uikit', icon: 'flash' },
        { label: 'Components', value: 'components'},
        { label: 'Templates', value: 'templates'}
      ] 
      
      function handleSelect(value) {
        setSelectedOption(value)
        setView({ ...view, uikit: value })
      }
      const [showSearch, setShowSearch] = useState(false)
      const [searchResults, setSearchResults] = useState([])
    
    const templateCategories = makeTemplates(templates);
    const primitiveCategories = makePrimitives(templates)
    const searchItems = [...templateCategories, ...primitiveCategories].flatMap(cat => cat.items)
    const combinedCategories = [...primitiveCategories, ...templateCategories]
    return (
        <div className="w-full h-full flex flex-col gap-3 p-2">
          <div className="w-full flex-shrink-0">
          {<SegmentedSwitch
              value={selectedOption}
              options={switchOptions}
              size={'small'}
              hasOutline={false}
          onChange={handleSelect}/> }
          </div>
        {<Search
                searchResults={searchResults} 
                setSearchResults={setSearchResults} 
                showSearch={showSearch} 
                setShowSearch={setShowSearch} 
                items={searchItems}
          />}
          {
            showSearch ? (
              <Results items={searchResults} />
            ) : selectedOption === 'components' ? (
              <ComponentTemplates categories={primitiveCategories}  />
            ) : selectedOption === 'templates' ? (
              <ComponentTemplates categories={templateCategories}  />
            ) : null
          }
            
            </div>
            )

}

