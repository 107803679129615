import React, { useState } from 'react';
import { MiniSnippet, Select } from 'ui-kit/exports/react';
import { routerTemplates } from './templates';

export default function RouterSetup({ project }) {
    const authOptions = [
      { label: 'none', value: 'noAuth' },
      // { label: 'Supabase', value: 'supabase' },
      // { label: 'Firebase', value: 'firebase' },
      { label: 'Pocketbase', value: 'pocketbase' },
    ];
    const [selectedAuth, setSelectedAuth] = useState(authOptions[0]?.value);
  
    const folders = project.folders;
    const pages = project.pages;
  
    // Create a project tree with sorted folders and pages
    let projectTree = folders.reduce((acc, folder) => {
      acc[folder.id] = {
        ...folder,
        pages: pages.filter(page => page.folder_id === folder.id)
      };
      return acc;
    }, {});
  
    // Sort folders by name
    
  
    const routerTemplate = routerTemplates[selectedAuth] || `template not found for ${selectedAuth}`;
    return (
      <>
        <div className="pl-5 pr-3 w-full max-w-full h-full gap-2 overflow-hidden items-start justify-start flex flex-row py-3">
          <div className='w-1/3 flex flex-col gap-3'>
          <Select
              label='Auth Provider'
              size='small'
              width='auto'
              options={authOptions}
              value={selectedAuth}
              onChange={(val) => setSelectedAuth(val)}
            />
  
          <ProjectTree projectTree={projectTree} />
        </div>
  
        <div className="flex flex-col flex-grow gap-2 pb-8  items-start text-sm w-full max-w-[720px] overflow-y-scroll"> 
            <MiniSnippet
              whiteSpace={'pre'}
              bgColor={'base-50'}
              hightlightSyntax={true}
              
              text={routerTemplate}
              />
        </div>
        </div>
      </>
    );
  }
  
  

  function ProjectTree({projectTree}) {
    const sortedFolders = Object.values(projectTree).sort((a, b) => a.name.localeCompare(b.name));
    return (
<>
<h2>Project Structure</h2>
          <div className='font-mono text-xs'>
            /src
            {sortedFolders.map((folder) => (
              <div key={folder.id}>
                <h3>|-- {folder.name}</h3>
                <ul>
                  {folder.pages.sort((a, b) => a.name.localeCompare(b.name)).map(page => (
                    <li key={page.id}>|&nbsp;&nbsp;&nbsp;|-- {page.name}</li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          </>
    )
  }