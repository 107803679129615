
import * as Icons from 'ionicons/icons';
import * as IconoirIcons from 'iconoir-react';
import { getChildren } from "../../../utilities/helpers";

export function prepareFrameJSXforMicroEditor(selector) {
    const frameObjects = selector.frame.objects || [];
    const rootObject = frameObjects.find(obj => obj.parent == obj.frame)
    const selectedObjectId = selector.object?.id || null
    
    function generateProps(objectProps, mobileProps) {
      let propsArray = Object.entries(objectProps).map(([key, value]) => {
        // Same logic as before for object_props
        if (typeof value === "string") {
          return `${key}="${value}"`;
        } else if (typeof value === "boolean") {
          return value ? key : '';
        } else if (typeof value === "number") {
          return `${key}={${value}}`;
        } else {
          // Handle more complex types as needed, for simplicity we'll stringify them
          return `${key}={${JSON.stringify(value)}}`;
        }
      }).filter(Boolean);
  
      // Add mobile props with "mob_" prefix
      if (mobileProps) {
        const mobilePropsArray = Object.entries(mobileProps).map(([key, value]) => {
          if (typeof value === "string") {
            return `mob_${key}="${value}"`;
          } else if (typeof value === "boolean") {
            return value ? `mob_${key}` : '';
          } else if (typeof value === "number") {
            return `mob_${key}={${value}}`;
          } else {
            // Handle more complex types as needed, for simplicity we'll stringify them
            return `mob_${key}={${JSON.stringify(value)}}`;
          }
        });
        propsArray = propsArray.concat(mobilePropsArray);
      }
  
      return propsArray.join(' ');
    }
  
    function generateNode(object, indentation = 0, insideSelectedPart = false) {
        const tag = object.componentAPIName;
        const propsString = generateProps(object.object_props || {}, object.mobile_props);
        const indent = '  '.repeat(indentation);
        let childObjects = getChildren(object.id, frameObjects).sort((a, b) => a.index - b.index);
        let nodeHTML = '';
      
        // Check if the current object is the selected object
        if (object.id === selectedObjectId) {
          insideSelectedPart = true;
          nodeHTML += `${indent}/* Selected part */\n`;
        }
      
        // Include id attribute only if inside selected part
        const idString = insideSelectedPart ? `id="${object.id}"` : '';
      
        if (childObjects.length === 0) {
          nodeHTML += `${indent}<${tag} ${idString} ${propsString} />\n`;
        } else {
          nodeHTML += `${indent}<${tag} ${idString} ${propsString}>\n`;
          for (let child of childObjects) {
            nodeHTML += generateNode(child, indentation + 1, insideSelectedPart);
          }
          nodeHTML += `${indent}</${tag}>\n`;
        }
      
        // End Highlighted part
        if (object.id === selectedObjectId) {
          nodeHTML += `${indent}/* End of Selected part */\n`;
          insideSelectedPart = false;
        }
      
        return nodeHTML;
      }      
    
    return generateNode(rootObject);
  }


  export function prepareFrameJSXwithIDs(selector) {
    const frameObjects = selector.frame.objects || [];
    if (frameObjects.length === 0) {
      return "empty page";
    }

    const rootObject = frameObjects.find(obj => obj.parent == obj.frame)
    if (!rootObject) {return "empty page"}

    const selectedObjectId = selector.object?.id || null
    
    function generateProps(objectProps, mobileProps) {
      let propsArray = Object.entries(objectProps).map(([key, value]) => {
        // Same logic as before for object_props
        if (typeof value === "string") {
          return `${key}="${value}"`;
        } else if (typeof value === "boolean") {
          return value ? key : '';
        } else if (typeof value === "number") {
          return `${key}={${value}}`;
        } else {
          // Handle more complex types as needed, for simplicity we'll stringify them
          return `${key}={${JSON.stringify(value)}}`;
        }
      }).filter(Boolean);
  
      // Add mobile props with "mob_" prefix
      if (mobileProps) {
        const mobilePropsArray = Object.entries(mobileProps).map(([key, value]) => {
          if (typeof value === "string") {
            return `mob_${key}="${value}"`;
          } else if (typeof value === "boolean") {
            return value ? `mob_${key}` : '';
          } else if (typeof value === "number") {
            return `mob_${key}={${value}}`;
          } else {
            // Handle more complex types as needed, for simplicity we'll stringify them
            return `mob_${key}={${JSON.stringify(value)}}`;
          }
        });
        propsArray = propsArray.concat(mobilePropsArray);
      }
  
      return propsArray.join(' ');
    }
  
    function generateNode(object, indentation = 0) {
      const tag = object.componentAPIName;
      const propsString = generateProps(object.object_props || {}, object.mobile_props);
      const indent = '  '.repeat(indentation);
      let childObjects = getChildren(object.id, frameObjects).sort((a, b) => a.index - b.index);
      let nodeHTML = '';
  
      // Include id attribute
      const idString = `id="${object.id}"`;
  
      // Start Highlighted part
      if (object.id === selectedObjectId) {
        nodeHTML += `${indent}/* Selected part */\n`;
      }
  
      // Check if hide_from_ai is true
      if (object.object_props?.hide_from_ai) {
        nodeHTML += `${indent}<${tag} ${idString} ${propsString}>\n`;
        nodeHTML += `${indent}  ...content hidden...\n`;
        nodeHTML += `${indent}</${tag}>\n`;
      } else {
        if (childObjects.length === 0) {
          nodeHTML += `${indent}<${tag} ${idString} ${propsString} />\n`;
        } else {
          nodeHTML += `${indent}<${tag} ${idString} ${propsString}>\n`;
          for (let child of childObjects) {
            nodeHTML += generateNode(child, indentation + 1);
          }
          nodeHTML += `${indent}</${tag}>\n`;
        }
      }
  
      // End Highlighted part
      if (object.id === selectedObjectId) {
        nodeHTML += `${indent}/* End of Selected part */\n`;
      }
  
      return nodeHTML;
    }
    
    return generateNode(rootObject);
  }

  export function getPrimitiveProps({list, UIKit}) {
    const ionicIconNames = Object.keys(Icons);
    const ionicIconNamesRegex = new RegExp(JSON.stringify(ionicIconNames).replace(/[[\]]/g, ''), 'g');

    const iconoirIconNames = Object.keys(IconoirIcons);
    const iconoirIconNamesRegex = new RegExp(JSON.stringify(iconoirIconNames).replace(/[[\]]/g, ''), 'g');

    const excludeComponents = ['Vimeo', 'Video', 'Slide', 'RichText', 'NotFound'];

    const transformedComponents = Object.entries(UIKit)
      .filter(([componentName, Component]) => !excludeComponents.includes(Component.definitions.apiName))
      .filter(([componentName, Component]) => list.includes(Component.definitions.apiName))
      .map(([componentName, Component]) => {
        const { apiName, displayName, type, ai_instructions, propDefinitions } = Component.definitions;
        let propDefinitionsString = JSON.stringify(propDefinitions);

        // Replace the specific parts in the string
        propDefinitionsString = propDefinitionsString.replace(ionicIconNamesRegex, `"select from Ionic Icons library"`);
        propDefinitionsString = propDefinitionsString.replace(iconoirIconNamesRegex, `"select from Iconoir Icons library"`);

        return {
          apiName,
          displayName,
          type,
          ai_instructions,
          propDefinitions: JSON.parse(propDefinitionsString)
        };
      });

    
    return transformedComponents;
}

export function prepareAdjacentFrames(pageFrames, currentFrameId) {
  
  if (!Array.isArray(pageFrames) || pageFrames.length === 0 || !currentFrameId) {
    return [];  // Return empty array for invalid input
  }

  const currentIndex = pageFrames.findIndex(frame => frame.id === currentFrameId);

  if (currentIndex === -1) {
    return [];  // Return empty array if current frame ID not found
  }

  const beforeFrame = currentIndex > 0 
    ? [{
        description: 'frame before',
        page_id: pageFrames[currentIndex - 1].id,
        displayName: pageFrames[currentIndex - 1].name,
        notes: pageFrames[currentIndex - 1].notes,
        // index: pageFrames[currentIndex - 1].index, 
        jsx: prepareFrameJSXwithIDs({ frame: pageFrames[currentIndex - 1] }, false)
      }]
    : [];

  const afterFrame = currentIndex < pageFrames.length - 1
    ? [{
        description: 'frame after',
        page_id: pageFrames[currentIndex + 1].id,
        displayName: pageFrames[currentIndex + 1].name,
        notes: pageFrames[currentIndex + 1].notes,
        // index: pageFrames[currentIndex + 1].index,
        jsx: prepareFrameJSXwithIDs({ frame: pageFrames[currentIndex + 1] }, false)
      }]
    : [];
  /*
  const currentFrame = currentIndex >= 0 
    ? {
        description: 'current frame',
        id: pageFrames[currentIndex].id,
        displayName: pageFrames[currentIndex].name,
        notes: pageFrames[currentIndex].notes,
        index: pageFrames[currentIndex].index
      }
    : null;*/

  // const adjacentFrames = [...beforeFrame, ...(currentFrame ? [currentFrame] : []), ...afterFrame];
  const adjacentFrames = [...beforeFrame, ...afterFrame];

  return adjacentFrames;
}