import { useState } from "react";
import { RecorderButton, ButtonIcon } from "../";

type Props = {
    state?: 'default' | 'disabled' | 'error' | 'success',
    hasOutline?: boolean,    
    mini?: boolean,
    acceptFiles?: boolean,
    acceptAudio?: boolean,
    isPill?: boolean,
    buttonColor?: 'primary' | 'accent',

    placeholder?: string,    
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void,
    onSend?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void,

    __juno?: any,
    
}

// extra props to consider: styling (bgcolor, outline, size)

export default function ChatInput({

    mini, 
    acceptFiles, 
    acceptAudio,
    isPill,
    buttonColor, 
    placeholder,
    onFocus,
    onSend,
    __juno,
    
}: Props) {

    const [content, setContent] = useState('');
    const [files, setFiles] = useState([]);

    const alignStyles = mini ? 'items-center' : 'items-end'
    const cornerStyles = isPill ? mini ? 'rounded-full' : 'rounded-2xl' : 'rounded-lg'
    const direction = mini || (!acceptFiles && !acceptAudio) ? 'flex-row' : 'flex-col items-stretch'
    const bgStyles = 'bg-current-5 focus-within:bg-current-10'
    const sizeStyles = mini ? acceptFiles ? 'py-2.5 px-2.5' : 'py-2.5 pl-3 pr-2.5' : 'py-3 px-3'
    const gapStyles = 'gap-2'

    const classes = `flex ${direction} w-full text-auto bg-current-5 ${alignStyles} justify-start ${cornerStyles} relative
        ${bgStyles} ${sizeStyles} ${gapStyles}`

    return (
        <div className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`} {...__juno?.attributes}>
        {acceptFiles && mini &&
            <div className="flex flex-shrink-0 text-auto gap-2 items-start justify-end">
                <ButtonIcon icon="add" size="small" style="light" isPill />
            </div>}
        
        {mini ? <input 
            type="text" 
            className="w-full flex-grow focus:outline-none text-base bg-red-200" 
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="Write a message" />
            :
        <textarea 
            className="w-full flex-grow focus:outline-none resize-none text-base" 
            value={content}
            style={{minHeight: '1.5em'}}
            onChange={(e) => setContent(e.target.value)}
            placeholder="Write a message" />}
        
        <div className="flex flex-row flex-nowrap flex-shrink-0 text-auto gap-2 items-start justify-between">
            {acceptFiles && !mini &&
            <div className="flex flex-shrink-0 text-auto gap-2 items-start justify-end">
                <ButtonIcon icon="add" size="small" style="link" isPill />
            </div>}
            
            <div className="flex flex-row flex-nowrap w-full text-auto gap-2 items-start justify-end">
                {acceptAudio && <RecorderButton size="small" state="default" style="ghost" timer={5000} showTimer />}
                <ButtonIcon icon="arrow-up" size="small" style="filled" isPill 
                    color={buttonColor}
                    state={content.length > 0 ? 'default' : 'disabled'}
                />
            </div>
        </div>
        </div>
    )
}

