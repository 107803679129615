import { spacingMap } from '../helpers'; 

type DotProps = {
    size?: '4px' | '6px' | '8px' | '12px' | '16px',
    color?: 'base-0' | 'base-100' | 'base-200' | 'base-300' | 'base-content' | 'primary' | 'accent' | 'success' | 'warning' | 'error' |
    'info' | 'success-surface' | 'warning-surface' | 'error-surface' | 'info-surface' | 'current-10' | 'current-50',
    marginX?: '6px' | '8px' | '12px' | '16px' | '24px',
    marginY?: '6px' | '8px' | '12px' | '16px' | '24px',
    __juno?: any 
}

export default function Dot({
    size = '8px',
    color = 'current-50',
    
    marginX,
    marginY,

    __juno = {}
  }: DotProps) {


const marginHorizontal = marginX ? 'py-'+spacingMap[marginX] : ''
const marginVertical = marginY ? 'px-'+spacingMap[marginY] : ''
const classes = `${marginHorizontal} ${marginVertical}`
    
    const inlineStyles = {
        width: size, 
        height: size,
    }

    return (
        <div 
            className={`${classes} ${__juno?.outlineStyle}`}
            {...__juno?.attributes}
        >
            <div className={`bg-${color} rounded-full`} style={inlineStyles} />
        </div>)
}



/* ignore rest */  

Dot.definitions = {
    apiName: 'Dot',
    displayName: 'Dot',
    description: 'A simple dot component used for indicators or decorations, with customizable size, color, and margins. Adapts to horizontal or vertical orientations based on the parent container’s direction.',
    ai_instructions: 'dot is sed to separate small pieces of content like text',
    type: 'separators',
    relativeSize: 'tiny',
    acceptedChildren: 'none',
    package: 'Starter',
    propDefinitions: {
        size: {
            type: 'oneOf',
            options: ['4px', '6px', '8px', '12px', '16px'],
            displayName: 'Size',
            default: '8px', 
        },
        color: {
            type: 'colors',
            options: ['base-0', 'base-100', 'base-200', 'base-300', 'base-content', 'primary', 'accent', 'success', 'warning', 'error', 
    'info', 'success-surface', 'warning-surface', 'error-surface', 'info-surface'],
            displayName: 'Color',
            default: null,
        },
        marginX: {
            type: 'spacing',
            options: ['6px', '8px', '12px', '16px', '24px'],
            displayName: 'Margins',
            default: null,
            tile: '1/2'
        },
        marginY: {
            type: 'spacing',
            options: ['6px', '8px', '12px', '16px', '24px'],
            displayName: 'Margins',
            default: null,
            tile: '1/2'
        },
        
    }
};

/*Dot.propTypes = {
    color: PropTypes.oneOfType([
        PropTypes.oneOf(['base-0', 'base-100', 'base-200', 'base-300', 'base-content', 'primary', 'accent', 'success', 'warning', 'error', 
    'info', 'success-surface', 'warning-surface', 'error-surface', 'info-surface']),
        PropTypes.string]),
    marginX: PropTypes.oneOf(['6px', '8px', '12px', '16px', '24px']),
    marginY: PropTypes.oneOf(['6px', '8px', '12px', '16px', '24px']),
    size: PropTypes.oneOf(['4px', '6px', '8px', '12px', '16px']),
};*/