import React, { useContext, useState } from 'react';
import {v1 as uuidv1 } from 'uuid' // for everything else
import { EditorContext } from '../../EditorContext';
import * as IconoirIcons from 'iconoir-react';

export default function AddFolderPlus({folders}) {
    
    const {handleAction, project } = useContext(EditorContext)

    function addFolder() {
        const maxIndex = folders.reduce((max, folder) => Math.max(max, folder.index), -1);
        const newFolder = {
            id: uuidv1(),
            name: 'new folder',
            index: maxIndex + 1,
            project_id: project.project.id
          };
        handleAction({type: 'INSERT_FOLDER', newFolder })
        handleAction({type: 'APPEND_PAGE', folder_id: newFolder.id, name: 'user flow'})
        
    }
    
    // opacity-0 group-hover:opacity-100 
    return (
            <button className={`p-0.5 rounded hover:scale-110 transition-all duration-75 hover:bg-current-10 bg-transparent`}
            onClick={()=>addFolder()}
            title='Add folder'
            >
          <IconoirIcons.Plus  height={16} width={16} className='hover:scale-110'/>
        </button>
    );
};


