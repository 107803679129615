import { useState } from "react";
import { InputText } from "../../../../../ui-kit/exports/react";

export default function ShortString({ propValue, onSave, propKey, label }) {
    const [value, setValue] = useState(propValue);

    function handleBlur(e) {
        onSave(propKey, e.target.value);
    }

    function handleInputChange(e) {
        setValue(e.target.value);
    }

    return (
        <><InputText
            label={label}
            placeholder={''}
            value={value || undefined}
            width={'full'}
            onChange={(e) => handleInputChange(e)}
            onBlur={(e) => handleBlur(e)}
            size={'small'}

    />

            </>
    );
}

/*
            <input
                className='bg-base-100 w-full rounded-md px-2.5 py-0.5 border focus:ring-1 focus:ring-primary focus:bg-white transition-all'
                value={value || ''}
                onChange={handleInputChange}
                onBlur={handleBlur}
            />
            */