import { Icon } from '../';
import { allIconNames, IconType } from '../iconMap';

type DatePickerProps = {
    state?: 'default' | 'error' | 'disabled' | 'success';
    bgColor?: string;
    size?: 'small' | 'medium' | 'large';
    label?: string;
    helperText?: string;
    rightIcon?: IconType;
    textAlign?: 'left' | 'center' | 'right';
    width?: 'auto' | '1/2' | 'full';
    hasOutline?: boolean;
    defaultValue?: string;
    value?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    placeholder?: string;
    
    __juno?: any;
};

export default function DatePicker({
    state = 'default',

    bgColor = 'current-5',
    size = 'medium',
    label = '',
    helperText = '',
    rightIcon,
    textAlign = 'left',
    width = 'auto',
    hasOutline = false,        
    __juno = {},

    /* React Input Props (subset) */
    defaultValue,
    value,
    onChange,
    onBlur,
    onFocus,
    placeholder = 'hello',
    ...extraProps

  }: DatePickerProps) {
    
    let reactProps = {...extraProps, defaultValue, value, onChange, onBlur, onFocus, placeholder}
    
    // const sizeStyles = size == 'small' ? `py-1 px-2 gap-1.5` : size == 'large' ? `py-2 px-3 gap-3` : `py-1.5 px-2 gap-3`;
    const paddingX = size == 'small' ? `px-2.5` : size == 'large' ? `px-4` : `px-3.5`;
    const paddingY = size == 'small' ? `py-1.5` : size == 'large' ? `py-3` : `py-2`;

    const textSize = size == 'small' ? 'text-xs' : size == 'large' ? 'text-base' : 'text-sm';
    const cornerStyles = size == "small" ? "rounded" : size == "large" ? "rounded-lg" : "rounded-md"
    
    const ringSize = size == 'small' ? '1' : size == 'large' ? '2' : '1.5'
    // default
    let stateStyles = hasOutline ? `ring-1 ring-inset ring-base-200 focus-within:ring-[${ringSize}px] focus-within:ring-primary` : `ring-1 ring-inset ring-transparent focus-within:ring-[${ringSize}px] focus-within:ring-primary`;
    switch (state) {
        case 'disabled':
            stateStyles = `bg-base-100 opacity-70 cursor-not-allowed ${hasOutline ? 'ring-1 ring-inset ring-base-200' : ''}`
            break;
        case 'error':
            stateStyles = `text-warning ${hasOutline ? 'ring-1 ring-inset ring-warning' : ''}`
            break;
        case 'success':
            stateStyles = `text-success ${hasOutline ? 'ring-1 ring-inset ring-success' : ''}`
            break;
    }
    
    const bgStyles = (bgColor && bgColor !== 'none') ? `bg-${bgColor} ${!hasOutline && 'focus-within:brightness-95'}` : '';
    
    const heightStyle = size == 'small' ? 'h-7' : size == 'large' ? 'h-12' : 'h-9';
    let inputWrapper = `w-full relative flex flex-row items-center ${heightStyle} ${paddingX} ${textSize} ${cornerStyles} ${bgStyles} ${stateStyles} `

    const labelTextSize = size == 'small' ? `text-xs` :  size == 'large' ? `text-lg`: `text-sm`;
    const labelClasses = `${labelTextSize} font-normal`

    const messageTextColor = state == 'error' ? stateStyles = 'text-warning' : state == 'success' ? stateStyles = 'text-success' : ''
    const messageClasses = size == 'large' ? `text-base  ${messageTextColor}` : `text-sm ${messageTextColor}`
    const widthStyle = width != 'auto' ? `w-${width}` : size == 'small' ? '' : size == 'large' ? 'min-w-[200px]' : 'min-w-[160px]'
    
    
    const iconSize = size == 'small' ? '16px' : size == 'large' ? '24px' : '20px'
    const iconStyle = size == 'small' ? 'w-4' : size == 'large' ? 'w-6' : 'w-5' // temporary before we fix Icon
    
    const RightIconComponent = rightIcon ? <Icon icon={rightIcon} size={iconSize} className={`flex-shrink-0 scale-90 ${iconStyle}`}/> : null;

    const gapStyles = size == 'small' ? 'gap-0.5' : size == 'large' ? 'gap-1.5' : 'gap-1'
    const classes = `flex flex-col ${widthStyle} ${gapStyles}`

    const textColor = (state == 'disabled' || state == 'default') ? '' : `text-${state}-content`

    return (
        <div 
        className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
            {...__juno?.attributes}
        >
            {label && ( <label className={labelClasses}>{label}</label>)}
            <div className={inputWrapper} >
            <div className='flex-grow relative'> <div className='absolute right-0 w-full h-full' />  {/* remove line */}
            <input
            {...reactProps}
            type='date'
            disabled={state == 'disabled'}
            className={`w-full text-${textAlign} ${paddingY} border-0 border-transparent focus:outline-none focus:ring-0 font-medium placeholder:font-normal
            placeholder-current-70 bg-transparent ${textColor} ${state == 'disabled' && 'cursor-not-allowed'}`}
            />  
            
            </div> {/* remove line */}
            {RightIconComponent}
            </div>
            {helperText && <span className={messageClasses}>{helperText}</span>}    
        </div>
        
        
    );
}



/* ignore rest */

DatePicker.definitions = {
    apiName: 'DatePicker',
    displayName: 'Date Picker',
    description: 'A component for selecting a range of dates. It includes options for labeling, hinting, and displaying a calendar. The component can be customized in size and background color, and supports various states.',
    ai_instructions: 'data range with an optional label, hint. on click opens a calendar view',
    type: 'inputs',
    relativeSize: 'small',
    acceptedChildren: 'none',
    package: 'Starter',
    propDefinitions: {
        state: {
            type: 'oneOf',
            options: ['default', 'disabled', 'error', 'success'],
            default: 'default', 
            displayName: 'State',
            tile: '1/2',
        },
        bgColor: {
            type: 'colors',
            options: ['none', 'base-0', 'base-50', 'base-100', 'current-5', 'current-10'],
            default: 'base-50',
            displayName: 'Background',
            tile: '1/2',
        },
        width: {
            type: "width",
            options: ["auto", "1/2", "full"],
            displayName: "Width",
            default: "auto", 
            defaultOnMobile: 'full', 
            tile: '1/2',
        },
        
        size: {
            type: 'size',
            options: ['small', 'medium', 'large'],
            default: 'medium', 
            displayName: 'Size',
            tile: '1/2',
        },        
        label: {
            type: 'string',
            default: '', 
            displayName: 'Label',
            tile: '1/2',
            editable: true
        },

        textAlign: {
            type: 'oneOf',
            options: ['left', 'right', 'center'],
            default: 'left', 
            displayName: 'Align Text',
            tile: '1/2',
        },
        defaultValue: {
            type: 'string',
            default: '', 
            displayName: 'Default Value',
        },
        placeholder: {
            type: 'string',
            default: 'placeholder text', 
            displayName: 'Placeholder Text',
            required: true,
            ai_instructions: 'value example, e.g. "email@example.com" or "Acme Corp Inc". do not write placeholder like action "Enter text". limit to 3 words.'
        },
        helperText: {
            type: 'string',
            default: '', 
            displayName: 'Help Text (hint/error)',
            ai_instructions: 'the text under the field', 
            editable: true
        },
        name: {
            type: 'string',
            default: '', 
            displayName: 'Name',
            tile: '1/2',
        },
        rightIcon: {
            type: 'icon',
            options: ['none', ...allIconNames],
            displayName: 'Right Icon',
            default: 'none' 
        },
        hasOutline: {
            type: "bool",
            displayName: "Outline",
            default: false, 
            ai_instructions: 'adds 1px outline',
        }, 
    }
};