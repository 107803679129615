import { v1 as uuidv1 } from "uuid";
import { nanoid } from "nanoid";
import { convertJSXToObjectsSync } from "../../../utilities/AIhelpers";
import { getDescendants } from "../../../utilities/helpers";

// processResponse.js
export function processResponse(props) {
    const { 
        action, 
        payload, 
        currentDisplayMessages, 
        setDisplayMessages, 

        currentModelMessages, 
        setModelMessages,
        displayMessagesRef, modelMessagesRef,
        streaming, 
        setStreaming, 
        sectionTemplates,
        handleAction, 
        currentFrameObjects, 
        currentFrame,
        generatedTemplatesRef, 
        idPairsRef, 
        savedImagesRef,
        setNotifications,
        notifications
    } = props;

    const sections = ['Header', 'Footer', 'AppShell', 'Main', 'Sidebar', 'IconBar', 'FeaturePanel', 'Email', 'Drawer', 'Popover', 'Modal', 'SidePanel']
    const appShellSections = ['Header', 'Footer', 'Sidebar', 'IconBar', 'FeaturePanel', 'SidePanel', 'Main']
    function addNotification(message) {
        const newNotification = {
            id: uuidv1(), 
            message: message, 
            timeout: 3000
          };
        
          setNotifications([...notifications, newNotification]);
        }
    
    if (action === 'messageUser') {
        if (payload) {
            const updatedDisplayMessages = [...currentDisplayMessages, payload];
            displayMessagesRef.current = updatedDisplayMessages
            setDisplayMessages(updatedDisplayMessages)

            const updatedModelMessages = [...currentModelMessages, payload];
            modelMessagesRef.current = updatedModelMessages
            setModelMessages(updatedModelMessages)
            
        }
    } else if (action === 'lockCanvas') {
        setStreaming(true)
    } else if (action === 'unlockCanvas') {
        setStreaming(false)
    } else if ((action === "saveTemplate" || action == "saveTemplates") && payload) {
        generatedTemplatesRef.current.push(...payload);
    } else if ((action === "saveImages") && payload) {
        savedImagesRef.current.push(...payload);
    } else if ((action === "clearIdPairs")) {
        idPairsRef.current = [];
    } else if (action === "deleteObjects" && payload) {
    const { objectIds } = payload;
    

    const message = {
        role: 'assistant',
        content: objectIds.length === 1 
          ? `Deleted ${objectIds[0].componentAPIName}` 
          : `Deleted ${objectIds.length} elements`
      };      
    // setDisplayMessages([...currentDisplayMessages, message])
    addNotification(message.content)
    
    
    if (Array.isArray(objectIds)) {
        objectIds.forEach(objectId => {
            const objectToDelete = currentFrameObjects.find(obj => obj.id === objectId);
            if (objectToDelete) {
                handleAction({ type: "DELETE_OBJECT", object: objectToDelete, keepSelector: true });
            }
        });
    } else {
        // Assuming objectIds is a single ID here
        const objectToDelete = currentFrameObjects.find(obj => obj.id === objectIds);
        if (objectToDelete) {
            handleAction({ type: "DELETE_OBJECT", object: objectToDelete, keepSelector: true });
        }
    }
    } else if (action === "insertAppShell" && payload) {
        const { id, props } = payload;
        // console.log('app shell id', id)
        // Check if props has a src key and if it does not start with http
        if (props.src && !props.src.startsWith('http')) {
            const foundImage = savedImagesRef.current.find(image => image.imageId === props.src);
            if (foundImage) { props.src = foundImage.src; } else { delete props.src;}
        }

        // Iterate over all properties in props
        Object.keys(props).forEach(key => {
            if (key.includes('mageSrc')) {
                if (props[key] && !props[key].startsWith('http')) {
                    const foundImage = savedImagesRef.current.find(image => image.imageId === props[key]);
                    if (foundImage) {
                        props[key] = foundImage.src;
                    } else {
                        delete props[key];
                    }
                }
            }
        });

        // generate object Id and remember its match
        const newObject = {
            id,
            APIName: "div",
            type: "object",
            styles: [],
            text: "",
            parent: currentFrame.id,
            frame: currentFrame,
            object_props: props,
            componentAPIName: 'AppShell',
            index: 1
        };
        let action = null
        const currentLayout = currentFrameObjects.find(obj => obj.componentAPIName === 'AppShell' || obj.componentAPIName === 'Email')

        if (currentLayout) {
            const oldDescendants = getDescendants(currentLayout.id, currentFrameObjects) || []
            action = {
                type: "REPLACE_OBJECT",
                oldObject: currentLayout,
                oldDescendants,
                newObject, 
                newDescendants: []
            }
        } else {
            action = {
                type: "INSERT_OBJECT",
                object: newObject,
                parent: currentFrame, 
                index: 1,
                frame: currentFrameObjects.frame,
                descendants: [],
                useId: true,
                keepSelector: true
            };
        }
        if (action) {
            handleAction(action)
            const message = {role: 'assistant', content: `Added AppShell`}
            addNotification(message.content)
        }

    } else if (action === "insertObject" && payload) {
        const { parentId, props, index, objectId, apiName } = payload;
        const message = {role: 'assistant', content: `Added ${apiName}`}
        addNotification(message.content)
        // setDisplayMessages([...currentDisplayMessages, message])
        
        // Check if props has a src key and if it does not start with http
        if (props.src && !props.src.startsWith('http')) {
            const foundImage = savedImagesRef.current.find(image => image.imageId === props.src);
            if (foundImage) { props.src = foundImage.src; } else { delete props.src;}
        }

        // Iterate over all properties in props
        Object.keys(props).forEach(key => {
            if (key.includes('mageSrc')) {
                if (props[key] && !props[key].startsWith('http')) {
                    const foundImage = savedImagesRef.current.find(image => image.imageId === props[key]);
                    if (foundImage) {
                        props[key] = foundImage.src;
                    } else {
                        delete props[key];
                    }
                }
            }
        });

        // generate object Id and remember its match
        const newId = nanoid(10);

        idPairsRef.current.push({ modelId: objectId, localId: newId });
        // console.log('new id', newId, 'for', objectId)
        
        // find correct parent (if it's a new object, we need to modify the id)
        const isSection = appShellSections.includes(apiName)
        const appShell = currentFrameObjects.find(obj => obj.componentAPIName === 'AppShell')
        
        const useParentId = isSection ? appShell.id : !parentId ? currentFrame?.id : 
            idPairsRef.current.find(o => o.modelId === parentId)?.localId || parentId
        
        // console.log(useParentId)
        
        const parent = currentFrameObjects.find((obj) => obj.id == useParentId)
        const newObject = {
            id: newId,
            APIName: "div",
            type: "object",
            styles: [],
            text: "",
            parent: useParentId,
            frame: currentFrame,
            object_props: props,
            componentAPIName: apiName,
            index,
        };
        let action = null
        const currentSectionObj = currentFrameObjects.find(obj => obj.componentAPIName === apiName && sections.includes(apiName))
        if (currentSectionObj) {
            const oldDescendants = getDescendants(currentSectionObj.id, currentFrameObjects) || []
            action = {
                type: "REPLACE_OBJECT",
                oldObject: currentSectionObj,
                oldDescendants,
                newObject, 
                newDescendants: []
            }
        } else {
            action = {
                type: "INSERT_OBJECT",
                object: newObject,
                parent: parent,
                index,
                frame: currentFrameObjects.frame,
                descendants: [],
                useId: true,
                keepSelector: true
            };
        }
        action && handleAction(action)
    } else if (action === "insertTemplate" && payload) {
    const { parentId, index, pasteId } = payload;
    const message = {role: 'assistant', content: `Added template`}
    addNotification(message.content)
    // setDisplayMessages([...currentDisplayMessages, message])
    
    // find correct parent (if it's a new object, we need to modify the id)
    const useParentId = !parentId ? currentFrame?.id : 
        parentId.startsWith('object') ? idPairsRef.current.find(o => o.modelId === parentId)?.localId : parentId
        
    const parent = currentFrameObjects.find((obj) => obj.id == useParentId) || currentFrame
    const template = generatedTemplatesRef.current.find(t => t.pasteId == pasteId)
    const objs = convertJSXToObjectsSync(template?.jsx, true) || [];
    const rootObj = objs.find((obj) => obj.parent === "rootObject");
    const descendants = objs.filter((obj) => obj.id !== rootObj.id) || [];
    
    const currentSectionObj = currentFrameObjects.find(obj => obj.componentAPIName === rootObj?.componentAPIName && sections.includes(rootObj?.componentAPIName))
    
    let action = null
    if (currentSectionObj) {
        const oldDescendants = getDescendants(currentSectionObj.id, currentFrameObjects) || []
        action = {
            type: "REPLACE_OBJECT",
            oldObject: currentSectionObj,
            oldDescendants,
            newObject: rootObj,
            newDescendants: descendants
        }
    } else { 
        action = {
        type: "INSERT_OBJECT",
        object: rootObj,
        parent: parent,
        index,
        frame: currentFrame,
        descendants,
        useId: true
    }}
    // hello
    template && action && handleAction(action)

    } else if (action === "insertModuleTemplate" && payload) {
    const { pasteId, index, section } = payload;
    // setDisplayMessages([...currentDisplayMessages, message])
        
    const parent = currentFrameObjects.find(obj => obj.componentAPIName === section)
    const template = generatedTemplatesRef.current.find(t => t.pasteId == pasteId)
    const objs = convertJSXToObjectsSync(template?.jsx, true) || [];
    const rootObj = objs.find((obj) => obj.parent === "rootObject");
    const descendants = objs.filter((obj) => obj.id !== rootObj.id) || [];
    if (template && parent && rootObj) {
        const editorAction = {
            type: "INSERT_OBJECT",
            object: rootObj,
            parent: parent,
            index,
            frame: currentFrame,
            descendants,
            useId: true
        }
        action && handleAction(editorAction)
        const message = {role: 'assistant', content: `Added module`}
        addNotification(message.content)
    }

    } else if (action === "addLayoutAndSections" && payload) {
        const { layout, sections } = payload;
        console.log('adding layout',layout, 'width sections', sections)
        // Layout template
        const template = generatedTemplatesRef.current.find(t => t.apiName === layout);
        
        // Form layout
        const objs = convertJSXToObjectsSync(template?.jsx, true) || [];
        const rootObj = objs.find((obj) => obj.parent === "rootObject");
        let descendants = []
        const parent = currentFrame

        // For each section look up 
        sections.forEach(apiName => {
            const template = sectionTemplates?.find(t => t.apiName == apiName)
            if (template) {
                // console.log(template)
            let sectionObjects = convertJSXToObjectsSync(template?.jsx) || [];
            // Connect the section to the appshell & frame
            const sectionRootObj = sectionObjects.find(obj => obj.parent === "rootObject");

            if (!descendants.find(obj => obj.componentAPIName === sectionRootObj.componentAPIName)) {
                // console.log(sectionRootObj.componentAPIName, 'has not been pasted')
            sectionObjects = [...sectionObjects].map(obj => obj.parent === "rootObject" ? { ...obj, parent: rootObj.id, frame: currentFrame.id } : {...obj, frame: currentFrame.id})
            descendants = [...descendants, ...sectionObjects]}
            else {
                console.log(sectionRootObj.componentAPIName, 'has been pasted')
            }
            }
        })
        
        let editorAction = null
        const currentLayout = currentFrameObjects.find(obj => obj.componentAPIName === 'AppShell' || obj.componentAPIName === 'Email')
        if (currentLayout) {
            editorAction = {
                type: "REPLACE_OBJECT",
                oldObject: currentLayout,
                oldDescendants: getDescendants(currentLayout.id, currentFrameObjects) || [],
                newObject: rootObj,
                newDescendants: descendants
            };
        } else {
            editorAction = {
                type: "INSERT_OBJECT",
                object: rootObj,
                parent: parent,
                index: 1,
                frame: currentFrame,
                descendants,
                useId: true
            };
        }
        
        editorAction && handleAction(editorAction)
        
    } else if (action === "createApplicationPage" && payload) {
        const { layout, main, header, sidebar, footer, iconbar, hero } = payload;
        const sections = [main, header, sidebar, footer, iconbar, hero]
        const template = generatedTemplatesRef.current.find(t => t.apiName === layout);
        // Form layout
        const objs = convertJSXToObjectsSync(template?.jsx, true) || [];
        const rootObj = objs.find((obj) => obj.parent === "rootObject");
        let descendants = []
        const parent = currentFrame

        // For each section look up 
        sections.forEach(apiName => {
            const template = sectionTemplates?.find(t => t.apiName == apiName)
            if (template) {
                
            let sectionObjects = convertJSXToObjectsSync(template?.jsx) || [];
            // Connect the section to the appshell & frame
            const sectionRootObj = sectionObjects.find(obj => obj.parent === "rootObject");

            if (!descendants.find(obj => obj.componentAPIName === sectionRootObj.componentAPIName)) {
                sectionObjects = [...sectionObjects].map(obj => obj.parent === "rootObject" ? { ...obj, parent: rootObj.id, frame: currentFrame.id } : {...obj, frame: currentFrame.id})
                descendants = [...descendants, ...sectionObjects]}
            else {
                // console.log(sectionRootObj.componentAPIName, 'has been pasted')
            }
            }
        })
        
        let editorAction = null
        const currentLayout = currentFrameObjects.find(obj => obj.componentAPIName === 'AppShell' || obj.componentAPIName === 'Email')
        if (currentLayout) {
            editorAction = {
                type: "REPLACE_OBJECT",
                oldObject: currentLayout,
                oldDescendants: getDescendants(currentLayout.id, currentFrameObjects) || [],
                newObject: rootObj,
                newDescendants: descendants
            };
        } else {
            editorAction = {
                type: "INSERT_OBJECT",
                object: rootObj,
                parent: parent,
                index: 1,
                frame: currentFrame,
                descendants,
                useId: true
            };
        }
        
        editorAction && handleAction(editorAction)
        
    } else if (action === "createEmail" && payload) {
        const { email_template } = payload;
        
        const template = generatedTemplatesRef.current.find(t => t.apiName === email_template);
        // Form layout
        const objs = convertJSXToObjectsSync(template?.jsx, true) || [];
        const rootObj = objs.find((obj) => obj.parent === "rootObject");
        let descendants = objs.filter((obj) => obj.id !== rootObj.id) || [];
        const parent = currentFrame

        let editorAction = null
        const currentLayout = currentFrameObjects.find(obj => obj.componentAPIName === 'AppShell' || obj.componentAPIName === 'Email')
        if (currentLayout) {
            editorAction = {
                type: "REPLACE_OBJECT",
                oldObject: currentLayout,
                oldDescendants: getDescendants(currentLayout.id, currentFrameObjects) || [],
                newObject: rootObj,
                newDescendants: descendants
            };
        } else {
            editorAction = {
                type: "INSERT_OBJECT",
                object: rootObj,
                parent: parent,
                index: 1,
                frame: currentFrame,
                descendants,
                useId: true
            };
        }
        
        editorAction && handleAction(editorAction)
        
    } else if ((action === "addPageTemplate" || action ===  'addLayout' || action === 'copyAdjacentPage' || (action === "addSectionTemplate" && payload?.apiName?.startsWith('email'))) && payload) {
    let message, template;
    
    
    if (action === 'addPageTemplate' || action == 'addLayout' || action == 'addSectionTemplate') {
        const { apiName } = payload;
        // message = { role: 'assistant', content: `Added page template ${apiName}` };
        template = generatedTemplatesRef.current.find(t => t.apiName === apiName);
    } else if (action === 'copyAdjacentPage') {
        
        const { copyId } = payload;
        
        //console.log('templates', generatedTemplatesRef.current)
        template = generatedTemplatesRef.current.find(t => t.id === copyId);
        message = { role: 'assistant', content: `Copied content from adjacent page` };
        addNotification(message.content)
        // setDisplayMessages([...currentDisplayMessages, message])
        }
        if (!template) {
            console.log('template not found')
            return;
        }

        const currentLayout = currentFrameObjects.find(obj => obj.componentAPIName === 'AppShell' || obj.componentAPIName === 'Email')
        // find correct parent (if it's a new object, we need to modify the id)
        const parent = currentFrame
        const objs = convertJSXToObjectsSync(template?.jsx, true) || [];
        const rootObj = objs.find((obj) => obj.parent === "rootObject");
        const descendants = objs.filter((obj) => obj.id !== rootObj.id) || [];
        let editorAction = null
        if (currentLayout) {
            editorAction = {
                type: "REPLACE_OBJECT",
                oldObject: currentLayout,
                oldDescendants: getDescendants(currentLayout.id, currentFrameObjects) || [],
                newObject: rootObj,
                newDescendants: descendants
            };
        } else {
            editorAction = {
                type: "INSERT_OBJECT",
                object: rootObj,
                parent: parent,
                index: 1,
                frame: currentFrame,
                descendants,
                useId: true
            };
        }
        
        editorAction && handleAction(editorAction)
    
    } else if (action === "relocateObject" && payload) {
    const { objectId, parentId, index } = payload;
    
    const objectToRelocate = currentFrameObjects.find((obj) => obj.id === objectId)
    const message = {role: 'assistant', content: `Moved ${objectToRelocate?.componentAPIName}`}
    addNotification(message.content)
    // setDisplayMessages([...currentDisplayMessages, message])
    
    // find correct parent (if it's a new object, we need to modify the id)
    const useParentId = parentId.startsWith('object') ? idPairsRef.current.find(o => o.modelId === parentId)?.localId : parentId
    const parentObj = currentFrameObjects.find((obj) => obj.id == useParentId)

    const action = {
        type: "RELOCATE_OBJECT",
        object: objectToRelocate,
        newParent: parentObj,
        newIndex: index,
    };

    objectToRelocate && handleAction(action);
    } else if (action === "updateObjects" && payload) {
    const { objectIds, property, value } = payload;
    const message = {role: 'assistant', content: `Updated multiple elements.`}
    addNotification(message.content)
    // setDisplayMessages([...currentDisplayMessages, message])
    
    objectIds.forEach(objectId => {
        const objectToUpdate = currentFrameObjects.find((obj) => obj.id === objectId);
        if (objectToUpdate) {
            let newObjectProps = { ...objectToUpdate.object_props };
            // Check if the property being updated is 'src' and doesn't start with http
            if ((property === 'src' || property === 'imageSrc') && !value.startsWith('http')) {
                // console.log('looking for', value, 'in ',savedImagesRef.current)
                const foundImage = savedImagesRef.current.find(image => image.imageId === value);

                if (foundImage) {
                    newObjectProps[property] = foundImage.src; // Use the found image URL
                } else {
                    return; // If not found, do not update and exit the function
                }
            } else {
                newObjectProps[property] = value; // Update with the given value for other properties
            }
            const updateAction = {
                type: "UPDATE_OBJECT",
                currentObject: objectToUpdate,
                newObject: { ...objectToUpdate, object_props: newObjectProps },
            };

            handleAction(updateAction);
        }
    });
    } else if (action === "updateProperties" && payload) {
        const { objectId, propsToUpdate } = payload;

        const objectToUpdate = currentFrameObjects.find(obj => obj.id === objectId);
        if (objectToUpdate) {
            let newObjectProps = { ...objectToUpdate.object_props };

            // Iterate over keys in propsToUpdate object
            Object.keys(propsToUpdate).forEach(property => {
                const value = propsToUpdate[property];

                // Generalize handling for image source properties
                if ((property === 'src' || property.includes('mageSrc')) && value && !value.startsWith('http')) {
                    const foundImage = savedImagesRef.current.find(image => image.imageId === value);
                    if (foundImage) {
                        newObjectProps[property] = foundImage.src; // Use the found image URL if available
                    } else {
                        return; // Skip update if the image is not found
                    }
                } else {
                    newObjectProps[property] = value; // Update with the given value for other properties
                }
            });

            const updateAction = {
                type: "UPDATE_OBJECT",
                currentObject: objectToUpdate,
                newObject: { ...objectToUpdate, object_props: newObjectProps },
            };

            handleAction(updateAction);
            
            const message = { role: 'assistant', content: `Updated ${objectToUpdate.componentAPIName}` };
            addNotification(message.content);
        }
    } else if (action === "updateMainProps" && payload) {
        
        const objectToUpdate = currentFrameObjects.find(obj => obj.componentAPIName === 'Main');

        if (objectToUpdate) {
            // Check if props has a src key and if it does not start with http
            const props = payload;
            if (props.src && !props.src.startsWith('http')) {
                const foundImage = savedImagesRef.current.find(image => image.imageId === props.src);
                if (foundImage) { props.src = foundImage.src; } else { delete props.src;}
            }

            // Iterate over all properties in props
            Object.keys(props).forEach(key => {
                if (key.includes('mageSrc')) {
                    if (props[key] && !props[key].startsWith('http')) {
                        const foundImage = savedImagesRef.current.find(image => image.imageId === props[key]);
                        if (foundImage) {
                            props[key] = foundImage.src;
                        } else {
                            delete props[key];
                        }
                    }
                }
            });

            const updateAction = {
                type: "UPDATE_OBJECT",
                currentObject: objectToUpdate,
                newObject: { ...objectToUpdate, object_props: props },
            };

            handleAction(updateAction);
            
            const message = { role: 'assistant', content: `Updated ${objectToUpdate.componentAPIName}` };
            addNotification(message.content);
        }
    } else if (action === "updatePropertiesOld" && payload) {
    const { objectId, propertyValuePairs } = payload;

    const objectToUpdate = currentFrameObjects.find(obj => obj.id === objectId);
    if (objectToUpdate) {
        let newObjectProps = { ...objectToUpdate.object_props };

        propertyValuePairs.forEach(pair => {
            const { property, value } = pair;

            // Generalize handling for image source properties
            if ((property === 'src' || property.includes('mageSrc')) && value && !value.startsWith('http')) {
                const foundImage = savedImagesRef.current.find(image => image.imageId === value);
                if (foundImage) {
                    newObjectProps[property] = foundImage.src; // Use the found image URL if available
                } else {
                    return; // Skip update if the image is not found
                }
            } else {
                newObjectProps[property] = value; // Update with the given value for other properties
            }
        });

        const updateAction = {
            type: "UPDATE_OBJECT",
            currentObject: objectToUpdate,
            newObject: { ...objectToUpdate, object_props: newObjectProps },
        };

        handleAction(updateAction);
        
        const message = { role: 'assistant', content: `Updated ${objectToUpdate.componentAPIName}` };
        addNotification(message.content);
        }
    }  else if (action === "updateObject" && payload) {
        const { objectId, property, value } = payload;
    
        const objectToUpdate = currentFrameObjects.find(obj => obj.id === objectId);
    
        if (objectToUpdate) {
            let newObjectProps = { ...objectToUpdate.object_props };
    
            // Check if the property being updated should be treated as an image source
            if ((property === 'src' || property.includes('mageSrc')) && value && !value.startsWith('http')) {
                console.log('looking for', value, 'in ', savedImagesRef.current);
                const foundImage = savedImagesRef.current.find(image => image.imageId === value);
                console.log('found', foundImage);
    
                if (foundImage) {
                    newObjectProps[property] = foundImage.src; // Use the found image URL
                } else {
                    return; // If no image is found, do not update and exit the function
                }
            } else {
                newObjectProps[property] = value; // Update with the given value for other properties
            }
    
            console.log(newObjectProps);
            const updateAction = {
                type: "UPDATE_OBJECT",
                currentObject: objectToUpdate,
                newObject: { ...objectToUpdate, object_props: newObjectProps },
            };
    
            const message = { role: 'assistant', content: `Updated ${objectToUpdate?.componentAPIName}` };
            addNotification(message.content);
            // setDisplayMessages([...currentDisplayMessages, message]);
    
            handleAction(updateAction);
        }    
    } else if (action === "updateTextProperty" && payload) {
    const { objectId, text } = payload;

    const objectToUpdate = currentFrameObjects.find((obj) => obj.id === objectId);

    if (objectToUpdate && text) {
        const updateAction = {
            type: "UPDATE_OBJECT",
            currentObject: objectToUpdate,
            newObject: { ...objectToUpdate, object_props: { ...objectToUpdate.object_props, text } },
        };
        
        const message = {role: 'assistant', content: `Updated text content`}
        addNotification(message.content)
        handleAction(updateAction);}

    } else if (action === "notifyUser" && payload) {
        const updatedDisplayMessages = [...currentDisplayMessages, payload];
        displayMessagesRef.current = updatedDisplayMessages
        setDisplayMessages(updatedDisplayMessages)
        
    } else if (action === "wrapElements" && payload) {
        const { wrapperProps, wrapperApiName, wrapperId, parentId, childIds, wrapperIndex } = payload;

        // Insert the wrapper component
        const newWrapperId = nanoid(10);
        idPairsRef.current.push({ modelId: wrapperId, localId: newWrapperId });
    
        const useParentId = parentId ? (parentId.startsWith('object') ? idPairsRef.current.find(o => o.modelId === parentId)?.localId : parentId) : currentFrame?.id;
        const parentObject = currentFrameObjects.find(obj => obj.id === useParentId);
    
        const wrapperObject = {
            id: newWrapperId,
            APIName: "div", // Or any other default API name you wish to use
            type: "object",
            styles: [],
            text: "",
            parent: useParentId,
            index: wrapperIndex, 
            frame: currentFrame,
            object_props: wrapperProps,
            componentAPIName: wrapperApiName
            // Any other properties needed for the object
        };
    
        handleAction({
            type: "INSERT_OBJECT",
            object: wrapperObject,
            parent: parentObject,
            index: wrapperIndex, 
            frame: currentFrame,
            descendants: [],
            useId: true,
            keepSelector: true
        });

        childIds.forEach((childId, index) => {
            const objectToRelocate = currentFrameObjects.find(obj => obj.id === childId);
            if (objectToRelocate) {
                handleAction({
                    type: "RELOCATE_OBJECT",
                    object: objectToRelocate,
                    newParent: wrapperObject,
                    newIndex: index + 1 // Adjust index as needed
                });
            }
        });

        const message = {role: 'assistant', content: `Wrapped elements`};
        addNotification(message.content);

    } else if ((action === "describeFrame" || action == 'updateFrameNotes') && payload) {
        const { notes, name } = payload;
        if (name) {
            handleAction({ 
                type: "UPDATE_FRAME", 
                currentFrame,
                newFrame: { ...currentFrame, name, notes }
            });
            const message = {role: 'assistant', content: `Added page name and notes`};
            addNotification(message.content);
        }
    } else if (action === "log" && payload) {
        console.log(payload)
    } else if (action == 'initiateEditor') {
        // do nothing
    } else {
        // Handle unknown action
        console.log(action, payload);
    }
};




/*const { apiNames } = payload;
        apiNames.forEach(apiName => {
        
        const template = generatedTemplatesRef.current.find(t => t.apiName == apiName)
        const objs = convertJSXToObjectsSync(template?.jsx, true) || [];
        const rootObj = objs.find((obj) => obj.parent === "rootObject");
        const descendants = objs.filter((obj) => obj.id !== rootObj.id) || [];
        
        const currentSectionObj = currentFrameObjects.find(obj => obj.componentAPIName === rootObj.componentAPIName && sections.includes(rootObj?.componentAPIName))
        
        let editorAction
        if (currentSectionObj) {
            const oldDescendants = getDescendants(currentSectionObj.id, currentFrameObjects) || []
            
            editorAction = {
            type: "REPLACE_OBJECT",
            oldObject: currentSectionObj,
            oldDescendants,
            newObject: rootObj,
            newDescendants: descendants
            }
            
        } else {
            editorAction = {
            type: "INSERT_OBJECT",
            object: rootObj,
            parent: appShellObject,
            index: null,
            frame: currentFrame,
            descendants,
            useId: true
        }}
        
        if (template && appShellObject) {
        handleAction(editorAction)
        const message = {role: 'assistant', content: `Added ${rootObj.componentAPIName}`}
        addNotification(message.content)
        // setDisplayMessages([...currentDisplayMessages, message])
        }}) */