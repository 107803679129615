import { useState } from "react"
import { getDescendants } from "../../../../utilities/helpers"
import Checkbox from "./Checkbox";

export default function ExtendChanges({selector, handleAction}) {
    
    const [scope, setScope] = useState('page')

    function extendContentChanges() {
        if (!selector.object || !scope) return;
    
        if (selector.object?.componentAPIName === 'Main' && scope === 'project') {
            window.alert('Cannot extend Main changes to the project. Limit scope.');
            return; // message - cannot extend Main changes to project. limit scope. nothing on ok or cancel
        }
    
        const object = selector.object;
        const descendants = getDescendants(object.id, selector.frame.objects);
    
        const action = {
            type: 'EXTEND_CHANGES',
            object,
            descendants,
            updateDescendants: includeDescendants,
            scope
        };
    
        // console.log(action);
        handleAction(action)
    }

    const [includeDescendants, setIncludeDescendants] = useState(false)
    return (
        <div className="text-xs flex flex-col gap-2">
            
            <div className="flex flex-row gap-2 items-end">
            Update all {selector.object.componentAPIName}s
            <select className="bg-white/5 px-2 py-[2px] rounded "
            value={scope} onChange={(e) => setScope(e.target.value)}>
                <option value="page">in feature</option>
                <option value="folder">in folder</option>
                <option value="project">in project</option>
            </select>
            </div>
            <button className="bg-blue-500 hover:bg-blue-600 text-white px-2 py-1 rounded" 
                onClick={() => extendContentChanges()}>
                Extend Changes
            </button>
            <div className="flex flex-row gap-1">
            <Checkbox checked={includeDescendants} onChange={() => setIncludeDescendants(!includeDescendants)} />
            update content
            </div>
        </div>
    )
}