import axios from 'axios';

// ADD A PROJECT
export async function add(body) {
  try {
    const response = await axios.post('/projects/add', body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });
    console.log(response.data);
  } catch (error) {
    console.error(error);
  }
}

// UPDATE A PROJECT
export async function update(body) {
  try {
    const response = await axios.put('/projects/update', body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });
    
  } catch (error) {
    console.error(error);
  }
}

