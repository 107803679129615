export const saveToLocalStorage = ({projectID, state}) => {
    const { selector, view } = state;
    
    const savedSelector = {
        folderId: selector?.folder?.id,
        pageId: selector?.page?.id,
        frameId: selector?.frame?.id
    }

    localStorage.setItem(`project-${projectID}-selector`, JSON.stringify(savedSelector));
  
    // Save the view state to local storage
    localStorage.setItem(`project-${projectID}-viewState`, JSON.stringify(view));
  };


export const getSelectorFromLocalStorage = (projectData) => {
    // console.log('getting selector from local storage or defaulting to first frame')
    if (!projectData) return
    const projectID = projectData.project?.id;
    if (!projectID) return

    let newSelector = {
        folder: null,
        page: null,
        frame: null
    }

    const firstFolder = projectData.folders?.filter(folder => !folder.is_archived)[0] || null
    const firstPage = projectData.pages?.filter(page => !page.is_archived && page.folder_id === firstFolder?.id)[0] || null
    const firstFrame = firstPage?.frames?.filter(frame => !frame.is_archived)[0] || null

    const defaultSelector = {
        folder: firstFolder,
        page: firstPage,
        frame: firstFrame
    }
    
    newSelector = defaultSelector
    // console.log('default selector', defaultSelector)
    const savedSelector = localStorage.getItem(`project-${projectID}-selector`)
    
    if (!savedSelector) return newSelector

    try {
        const selectorObject = JSON.parse(savedSelector); // Attempt to parse the JSON
        // You can use selectorObject for further processing here
    

        const { folderId, pageId, frameId } = JSON.parse(savedSelector)
        if (!folderId) return 
        // Form new selector
        const selectFolder = projectData.folders?.find(folder => folder.id === folderId);
        
        if (!selectFolder) {
            return
        } else {
            newSelector.folder = selectFolder
        }
    
        if (!pageId) { return newSelector }
        const selectPage = projectData.pages?.filter(page => page.folder_id === selectFolder?.id).find(page => page.id === pageId);
        if (!selectPage) {
            return newSelector
        } else {
            newSelector.page = selectPage
        }
        
        if (!frameId) { return newSelector }
        const selectFrame = selectPage.frames?.find(frame => frame.id === frameId);
    
        if (!selectFrame) {
            return newSelector
        } else {
            newSelector.frame = selectFrame
            return newSelector
        }
    } catch (error) {
        console.error('Failed to parse saved selector:', error); // Log the error
        return newSelector
    }
  };