import { useState } from "react";

export function ProjectName({project, renameProject}) {
  
    const [editable, setEditable] = useState(false);
    const [name, setName] = useState(project?.name || '');
    
    const handleDoubleClick = () => {
      setEditable(true);
    };
  
    const handleBlur = () => {
      setEditable(false);
      
      renameProject(name, project)
    };
  
    
    return (
      <input 
      type="text"
      value={name}
      onChange={(e) => setName(e.target.value)}
      onBlur={(e) => editable && handleBlur(e)}
      onDoubleClick={handleDoubleClick}
      onKeyDown={(e) => e.key === 'Enter' && e.target.blur()}
      readOnly={!editable}
      className={`flex-grow cursor-default w-full rounded ${editable ? 'bg-slate-50' : 'bg-transparent'} overflow-hidden whitespace-nowrap ellipsis truncate`}
      style={{
        cursor: editable ? 'text' : 'default',
        userSelect: !editable && 'none',
        minWidth: 0,
      }}
    />
  
    )
  }