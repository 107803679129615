import { Check, Link } from "iconoir-react";
import { useState } from "react";

export default function CopyLinkButton({tooltipStyle}) {
  const [showCopiedMessage, setShowCopiedMessage] = useState(false);

  const handleCopy = () => {
    // Copy current URL to clipboard
    navigator.clipboard.writeText(window.location.href)
      .then(() => {
        // Show copied message
        setShowCopiedMessage(true);
        // Hide message after 3 seconds
        setTimeout(() => setShowCopiedMessage(false), 3000);
      })
      .catch(err => console.error('Failed to copy link: ', err));
  };

  return (
    <div className="relative z-50" >
      <button 
        className={`w-6 h-6 flex items-center justify-center transition-all rounded-lg hover:scale-110 text-base-700 
        hover:bg-base-100
        group relative`}
        onClick={handleCopy}
      >
        {showCopiedMessage ? <Check /> : <Link />}
        {!showCopiedMessage && 
        <span className={` ${tooltipStyle} right-0 transform translate-y-full  `}
        style={{ zIndex: 1001 }}>
          Copy link
        </span>}
      </button>
    </div>
  );
};
