import { DndContext, closestCenter, PointerSensor, useSensor, useSensors, DragOverlay, closestCorners } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import FolderTab from "./FolderTab";
import { act, useContext, useState } from 'react';
import { EditorContext } from '../../EditorContext';

export default function Folders({sidebarOpen, tooltipStyle}) {
    const { project, pages, handleAction } = useContext(EditorContext)

    const addPage = (folder_id=null) => {
        handleAction({ type: 'APPEND_PAGE', folder_id});
      }; 

    const folders = project.folders?.filter(f => !f.is_archived) || []
    
    const [dragging, setDragging] = useState(false)
    const [dragType, setDragType] = useState(null)

    const handleDragStart = (event) => {
      setDragging(true)
      const { active } = event;
      const activeType = active.data.current?.type;
      activeType && setDragType(activeType)
    }
    
    const handleDragOver = (event) => {
      const { active, over } = event;
      
      if (!over) return;
      if (active.id === over.id) return;
      
      const activeType = active.data.current?.type;
      const overType = over.data.current?.type;

      // Handle Action on Drag 
      if (activeType === 'page') {
        const movingPage = pages.find(p => p.id == active.id)
        const overFolderId = overType === 'folder' ? over.id : over.data.current.folder_id
        const newSiblings = pages.filter(p => p.folder_id == overFolderId).filter(p => !p.is_archived) || []
        
        if (movingPage.folder_id !== overFolderId) {
          const action = {
            type: 'RELOCATE_PAGE', 
            page: movingPage, 
            newIndex: newSiblings.length + 1, 
            newFolderId: overFolderId
          }
          handleAction(action)
        }
      }

    }

    
    function handleDragEnd(event) {
      
      const { active, over } = event;
      if (!over) { 
        setDragging(false)
        setDragType(null)
        return;
      }
      const activeType = active.data.current?.type;
      const overType = over.data.current?.type;
      
      if (activeType === 'page') {
        // if we dragged page, relocate page
          const movingPage = pages.find(p => p.id == active.id)
          const folder_id = movingPage.folder_id
          let newIndex = pages.filter(p => p.folder_id == folder_id).filter(p => !p.is_archived).length + 1
          newIndex = overType === 'folder' ? newIndex : pages.find(p => p.id == over.id)?.index
          if (movingPage) {
            const action = {
              type: 'RELOCATE_PAGE', 
              page: movingPage, 
              newIndex: newIndex,
            }
            handleAction(action)
          }
        } else {
          const movingFolder = folders.find(f => f.id == active.id)
          let overFolder = null
          if (overType === 'folder') {
            overFolder = folders.find(f => f.id == over.id)
          } else {
            overFolder = folders.find(f => f.id == over.data.current.folder_id)
          }

          if (movingFolder && overFolder && movingFolder != overFolder) {
            // console.log('relocate folder')
            const action = {
              type: 'RELOCATE_FOLDER', 
              folder: movingFolder, 
              newIndex: overFolder.index
            }
            handleAction(action)
          }
          // if we dragged folder, relocate folder
        }
      
      setDragging(false)
      setDragType(null)
    }
    
      const sensors = useSensors(
        useSensor(PointerSensor, {
          activationConstraint: {
            distance: 8,
          },
        })
      )
      //${sidebarOpen ? 'hidden md:flex' : 'flex'}
    return (
    
    <div className={`flex flex-col gap-1 overflow-visible`}
    style={{height: '100%', maxHeight: '100%', maxWidth: '100%'}}
    >


        <DndContext 
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd} 
          collisionDetection={closestCorners} 
          sensors={sensors}
        >
            <SortableContext 
              items={folders} 
              strategy={verticalListSortingStrategy}
              >
            
            {folders.length > 0 && 
              folders
              .sort((a, b) => a.index - b.index)
              .map(folder => 
                <FolderTab
                  sidebarOpen={sidebarOpen}
                  tooltipStyle={tooltipStyle}
                  key={folder.id}
                  folder={folder}
                  onAddPage={addPage}
                  dragging={dragging}
                  dragType={dragType}
                  isOnlyFolder={folders.length === 1}
                  pages={pages.filter(p => p.folder_id == folder.id).filter(p => !p.is_archived)}
                />
              )
            }
            

            </SortableContext>
          </DndContext>
      </div>
)
}