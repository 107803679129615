import React, { isValidElement, ReactNode } from 'react';
import { spacingMap } from '../helpers';

type AppShellProps = {
    pageBackground?: 'base-0' | 'base-50' | 'base-100' | 'base-200' | 'base-300' | 'primary' | 'secondary' | 'accent',
    maxWidth?: 'stretch' | '960' | '1200' | '1440' | '1920',
    justifyContent?: 'center' | 'start' | 'end',
    paddingX?: "2px" | "4px" | "6px" | "8px" | "10px" | "12px" | "16px" | "24px" | "32px" | "48px",
    paddingY?: "2px" | "4px" | "6px" | "8px" | "10px" | "12px" | "16px" | "24px" | "32px" | "48px",
    children: React.ReactNode,
    backgroundImg?: string,
    __juno?: any
}

export default function AppShell({ 
        pageBackground='base-0', 
        maxWidth='stretch',
        justifyContent='center',
        paddingX,
        paddingY,
        backgroundImg,
        children, 
        __juno = {}
    }: AppShellProps) {

    const paddingStyles = `${paddingX ? ` px-${spacingMap[paddingX]}` : ''}${paddingY ? ` py-${spacingMap[paddingY]}` : ''}`;
    const fontColor = pageBackground?.startsWith('base') ? 'base-content' : `${pageBackground}-content`;
    const pageBgColor = `bg-${pageBackground}`;
    const fontColorValue = `text-${fontColor}`;
    
    // Ensure that parent has h-screen or replace h-full to h-screen in the classes below
    const outerClasses = `relative flex flex-col w-full h-full min-h-ful flex-grow ${pageBgColor} ${fontColorValue}`;
    const innerClasses = `relative flex flex-row w-full items-stretch flex-grow min-h-full self-${justifyContent} ${paddingStyles}`
    const mainClasses = `relative flex flex-col w-full items-stretch flex-grow min-h-full`

    // Define the groupedChildren object with string keys and arrays of ReactNode
    const groupedChildren: Record<string, ReactNode[]> = {
        Header: [],
        Hero: [],
        IconBar: [],
        Sidebar: [],
        FeaturePanel: [],
        MainArea: [], // sidepanel + main
        Footer: [],
    };
    
    /* Iterate through the children and group them accordingly */
    /* need to rewrite according to update in prod */
    React.Children.forEach(children, (child) => {
        if (isValidElement(child)) {
        const { type } = child;
    
        // Determine the default name of the component
        let typeName: string =
            typeof type === 'string' // For intrinsic elements like 'div', 'span'
            ? type
            : (type.name || 'Unknown'); // For function or class components
    
        
        // Override with props.self.componentAPIName if it exists
        typeName = child.props?.self?.componentAPIName || typeName;
        
        // Push the child to the appropriate group or default to MainArea
        if (groupedChildren[typeName]) {
            groupedChildren[typeName].push(child);
        } else {
            groupedChildren.MainArea.push(child);
        }
        }
    });

    const { Header, Hero, Footer, MainArea, IconBar, Sidebar, FeaturePanel } = groupedChildren;
    
    const noImage = !backgroundImg;
    const imageStyles = { 
        background: `url(${backgroundImg}) no-repeat center center / cover`
    };

    return (
        <div
        className={`${outerClasses} ${__juno?.outlineStyle}`}
        {...__juno?.attributes}
        style={noImage ? {} :  imageStyles} 
        >
        <div 
        className={`${innerClasses} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
        data-tag={__juno?.attributes?.['data-tag']}   
        style={{ width: '100%', maxWidth: maxWidth != 'stretch' ? `${maxWidth}px` : '100%'}}
        >
            {IconBar}
            {Sidebar}
            {FeaturePanel}
            
            {/* Main Block */}
            <div className={mainClasses} style={{minHeight: '100%'}}>
                {Header}
                {Hero}
                <div className={`flex flex-row flex-grow w-full h-full  justify-${justifyContent}`}>
                    {MainArea}
                </div>
                {Footer}
            </div>
        </div>
        </div>
    );
}


/* ignore rest */


AppShell.definitions = {
    apiName: 'AppShell',
    displayName: 'Application Shell',
    description: 'A flexible and foundational component of an application layout, offering customizable options for background color and maximum width. It provides a structure for arranging header, footer, and main content areas.',
    ai_instructions: 'app shell contains page sections. cannot be deleted or moved.',
    type: 'layout',
    acceptedChildren: 'only sections: Main, Header, Footer, FeaturePanel, Sidebar, Iconbar',
    relativeSize: 'large',
    package: null,
    propDefinitions: {
        
        maxWidth: {
            type: 'oneOf',
            options: ['stretch', '960', '1200', '1440', '1920'],
            displayName: 'Page Width',
            default: 'stretch'
        }, 
        pageBackground: {
            type: 'colors',
            options: ['base-0', 'base-50', 'base-100', 'base-200', 'base-300', 'primary', 'secondary', 'accent'],
            displayName: 'Background',
            default: 'base-0', 
            ai_instructions: 'updating pageBackground will also update default font colors on the page'
        },
        justifyContent: {
            type: 'justifyContent',
            options: ['center', 'start', 'end'],
            displayName: 'Inner Justify',
            default: 'center', 
            ai_instructions: 'app shell is flex-row so justify content works horizontally'
        }, 
        paddingX: {
            type: "spacing",
            options: ["2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Padding X",
            ai_instructions: 'adds padding on left and right. in addition to overall padding property',
            default: 'base', 
            tile: '1/2',
        },
        paddingY: {
            type: "spacing",
            options: ["2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"],
            displayName: "Padding Y",
            ai_instructions: 'adds padding on top and bottom. in addition to overall padding property',
            default: null,
            tile: '1/2',
        },
        backgroundImg: {
            type: 'imageURL',
            displayName: 'Background Image',
            default: null
        },
    }
};

/*AppShell.propTypes = {
    pageBackground: PropTypes.oneOfType([
        PropTypes.oneOf(['base-0',  'base-50', 'base-100', 'base-200', 'base-300', 'primary', 'secondary', 'accent']),
        PropTypes.string]),
    maxWidth: PropTypes.oneOf(['stretch', '960', '1200', '1440', '1920']),
    justifyContent: PropTypes.oneOf(['center', 'start', 'end']), 
    paddingX: PropTypes.oneOf(["2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"]),
    paddingY: PropTypes.oneOf(["2px", "4px", "6px", "8px", "10px", "12px", "16px", "24px", "32px", "48px"]),
    children: PropTypes.node
};*/