import { Icon } from '../';
import { IconType, allIconNames } from '../iconMap';

type InputWithoutSize = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'size'>;
type InputTextProps = InputWithoutSize & {
    state?: 'default' | 'disabled' | 'error' | 'success',
    bgColor?: 'base-0' | 'base-50' | 'base-100' | 'current-5' | 'current-10' | 'transparent',
    size?: 'small' | 'medium' | 'large',
    label?: string,
    helperText?: string,
    rightIcon?: IconType,
    leftIcon?: IconType,
    prefix?: string,
    suffix?: string,
    textAlign?: 'left' | 'right' | 'center',
    width?: 'auto' | '1/2' | 'full',
    hasOutline?: boolean,
    isPill?: boolean,
    __juno?: any, 

    /* React Input Props (subset) */
    defaultValue?: string,
    value?: string,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void,
    onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void,
    placeholder?: string,    
}

export default function InputText({
    state = 'default',

    bgColor = 'current-5',
    size = 'medium',
    label = '',
    helperText = '',
    rightIcon,
    leftIcon,
    prefix = '',
    suffix = '',
    textAlign = 'left',
    width = 'auto',
    isPill = false,
    hasOutline = false,        
    __juno = {},

    /* React Input Props (subset) */
    defaultValue,
    value,
    onChange,
    onBlur,
    onFocus,
    placeholder = '',
    ...extraProps

  }: InputTextProps) {
    
    let reactProps = {...extraProps, defaultValue, value, onChange, onBlur, onFocus, placeholder}
    delete reactProps.children
    
    const iconSize = size == 'small' ? '16px' : size == 'large' ? '24px' : '20px'
    const iconSizeEms = size == 'small' ? 4 : size == 'large' ? 6 : 5
    const iconStyle = `w-${iconSizeEms}`

    const spacingUnit = size == 'small' ? 2.5 : size == 'large' ? 4 : 3.5
    const gapUnit = size == 'small' ? 2 : size == 'large' ? 3 : 2.5
    // left-1.5 left-2 left-2.5 left-3 left-3.5 left-4 right-1.5 right-2 right-2.5 right-3 right-3.5 right-4
    const LeftIconComponent = leftIcon ? <Icon icon={leftIcon} size={iconSize} className={`absolute left-${spacingUnit} top-1/2 -translate-y-1/2 scale-90 ${iconStyle}`}/> : null;
    const RightIconComponent = rightIcon ? <Icon icon={rightIcon} size={iconSize} className={`absolute right-${spacingUnit} top-1/2 -translate-y-1/2 scale-90 ${iconStyle}`}/> : null;

    // pl-2 pl-2.5 pl-3 pl-3.5 pl-4 pl-5 pl-6 pl-7 pl-8 pl-9 pl-10 pl-11 pl-12 pl-13
    // pr-2 pr-2.5 pr-3 pr-3.5 pr-4 pr-5 pr-6 pr-7 pr-8 pr-9 pr-10 pr-11 pr-12 pr-13
    const paddingLeft = LeftIconComponent ? `pl-${gapUnit + iconSizeEms + gapUnit}` : `pl-${spacingUnit}`;
    const paddingRight = RightIconComponent ? `pr-${gapUnit + iconSizeEms + gapUnit}` : `pr-${spacingUnit}`;
    const paddingX = `${paddingLeft} ${paddingRight}`;
    
    const textSize = size == 'small' ? 'text-xs' : size == 'large' ? 'text-base' : 'text-sm';
    const cornerStyles = isPill ? 'rounded-full' : size == "small" ? "rounded" : size == "large" ? "rounded-lg" : "rounded-md"

    const ringSize = size == 'small' ? '1' : size == 'large' ? '2' : '1.5'
    // focus-within:outline-[1px] focus-within:outline-[2px] focus-within:outline-[1.5px]

    // default
    let stateStyles = hasOutline ? `outline-1 outline-current-10 outline -outline-offset-1 focus-within:outline-[${ringSize}px] focus-within:outline-primary` : `focus-within:outline-[${ringSize}px] focus-within:-outline-offset-1 focus-within:outline focus-within:outline-primary`;
    switch (state) {
        case 'disabled':
            stateStyles = `bg-base-100 opacity-70 cursor-not-allowed ${hasOutline ? 'outline outline-1 outline-current-10 -outline-offset-1' : ''}`
            break;
        case 'error':
            stateStyles = `text-warning ${hasOutline ? 'outline outline-1 outline-current-10 -outline-offset-1 outline-warning' : ''}`
            break;
        case 'success':
            stateStyles = `text-success ${hasOutline ? 'outline outline-1 outline-current-10 -outline-offset-1 outline-success' : ''}`
            break;
    }
    
    const bgStyles = bgColor ? `bg-${bgColor} ${!hasOutline && ''}` : '';
    
    const heightStyle = size == 'small' ? 'h-7' : size == 'large' ? 'h-12' : 'h-9';
    let inputWrapper = `relative flex !overflow-hidden flex-row items-center ${heightStyle}  ${textSize} ${cornerStyles} ${bgStyles} ${stateStyles} `

    
    const labelTextSize = size == 'small' ? `text-xs` :  size == 'large' ? `text-lg`: `text-sm`;
    const labelClasses = `${labelTextSize} font-normal`

    const messageTextColor = state == 'error' ? stateStyles = 'text-warning' : state == 'success' ? stateStyles = 'text-success' : ''
    const messageClasses = size == 'large' ? `text-base  ${messageTextColor}` : `text-sm ${messageTextColor}`
    const widthStyle = width != 'auto' ? `w-${width}` : size == 'small' ? '' : size == 'large' ? 'min-w-[200px]' : 'min-w-[160px]'

    const gapStyles = size == 'small' ? 'gap-0.5' : size == 'large' ? 'gap-1.5' : 'gap-1'
    const textColor = (state == 'disabled' || state == 'default') ? bgColor?.startsWith('base') ? 'text-base-content' : 'text-current' : `text-${state}-content`
    
    const classes = `flex flex-col ${widthStyle} ${gapStyles} ${textColor}`

    const inputPaddingX = `${(prefix || LeftIconComponent) ? 'pl-'+gapUnit : ''} ${(suffix || RightIconComponent) ? 'pr-'+gapUnit : ''}`

    
    
    return (
        <div 
        className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
        {...__juno?.attributes}
        >
            {label && ( <label className={labelClasses}>{label}</label>)}
            <div className={inputWrapper} >
            {LeftIconComponent}
            {/*<span className={`flex-shrink-0 ${LeftIconComponent && prefix ? `pl-${gapUnit}` : ''}`}>{prefix}</span*/}
            <input
            {...reactProps}
            disabled={state == 'disabled'}
            className={`text-${textAlign} h-full focus:outline-none focus:ring-0 ${paddingX} bg-transparent 
            placeholder-current-70 ${state == 'disabled' && 'cursor-not-allowed'} `}
            />
            {/*<span className={`flex-shrink-0 ${RightIconComponent && suffix ? `pr-${gapUnit}` : ''}`}>{suffix}</span> */}
            {RightIconComponent}
            </div>
            {helperText && <span className={messageClasses}>{helperText}</span>}    
        </div>
        
    );
}




