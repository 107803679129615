import { convertJSXToObjectsSync } from "../../../utilities/AIhelpers";
import { getDescendants } from "./helpers";

export function handleLayoutDragOver({dndState, frame, setDnDState}) {
    
    const dropParent = frame
    if (dropParent != dndState?.draggingOverItem) {
        setDnDState({
            ...dndState,
            draggingOverItem: dropParent, 
            offFrame: false
        })  
    }
}

export function handleLayoutDragEnd({layouts, dndState, handleAction, frameObjects, }) {
            
    const currentLayout = frameObjects.find(obj => layouts.includes(obj.componentAPIName))
    let action = null
    const draggingItem = dndState?.draggingItem;
    let objects = [];
    if (draggingItem.objects && draggingItem.objects.length > 0) {
        objects = draggingItem.objects;
    } else {
        const jsx = draggingItem.jsx;
        objects = convertJSXToObjectsSync(jsx) || [];
    }
    if (objects.length === 0) return;
    const rootObj = objects.find((obj) => obj.parent === "rootObject");
    if (!rootObj) return;
    const descendants = objects.filter((obj) => obj.id !== rootObj.id) || [];
    const newParent = dndState?.draggingOverItem;
          
          if (currentLayout) {
            if (window.confirm("Are you sure you want to replace the current layout?")) {
                action = {
                    type: 'REPLACE_OBJECT',
                    oldObject: currentLayout,
                    oldDescendants: getDescendants(currentLayout.id, frameObjects) || [], 
                    newObject: rootObj,
                    newDescendants: descendants
                };
                // Execute the action here or call another function to handle it
            } 

          } else {
            action = {
              type: "INSERT_OBJECT",
              object: rootObj,
              parent: newParent,
              index: 2, // DIALOG IS ALWAYS ON SECOND LAYER
              descendants,}
          }
          
          handleAction(action)
}