import { Icon } from '../';
import { useMemo } from 'react';
import { IconType, allIconNames } from '../iconMap';

type FileCardProps = {
    description?: string,
    thumbnailAspectRatio?: '2 / 1' | '1 / 1' | '3 / 2' | '4 / 3',
    textSize?: 'small' | 'medium',
    thumbnailImageSrc?: string,
    title?: string,
    icon?: Extract<IconType, 'page' | 'star' | 'folder'>;
    corners?: 'none' | 'sm' | 'md' | 'lg' | 'xl',
    width?: '100%' | '200px' | '320px',
    __juno?: any
}

export default function FolderCard({
        description = "Folder • 4 files",
        thumbnailAspectRatio = '2 / 1',
        textSize = 'small',
        thumbnailImageSrc = null,
        title = "Folder Name",
        icon = 'folder',
        corners = "none",
        width = '100%',
        __juno = {}
    } : FileCardProps) {
      
    const sizeStyles = `w-full h-auto ${textSize == 'small' ? 'text-sm' : 'text-base'}`
    const classes = `flex flex-col items-stretch justify-start gap-3 ${sizeStyles}`
    

    const contentClasses = `flex flex-col flex-grow`
    const truncateStyle = { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}

    const noImage = !thumbnailImageSrc;
    const imageStyles = useMemo(() => ({
        background: !noImage && `linear-gradient(rgba(0, 0, 0, 0.04), rgba(0, 0, 0, 0.12)), url(${thumbnailImageSrc}) no-repeat center center / cover`, 
        backgroundSize: 'cover', 
        aspectRatio: thumbnailAspectRatio
    }), [thumbnailImageSrc, noImage, thumbnailAspectRatio]);

    const cornerStyles = corners === 'none' ? '' : `rounded-${corners}`;
    const titleFont = textSize == 'small' ? 'text-base' : 'text-lg';
    const smallerFont = textSize == 'small' ? 'text-xs' : 'text-sm';

    const IconComponent = icon ? <Icon icon={icon} className='flex-shrink-0' /> : null;

    return (
        <div 
        className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
        {...__juno?.attributes}
        style={{maxWidth: width}}
        >
        
        {/* THUMBNAIL */}
        <div className={`relative group w-full aspect-square flex items-center justify-center bg-current-10 ${cornerStyles}`} style={imageStyles}>
            {noImage && <Icon icon={'folder'}  className='flex-shrink-0' />}
        </div>
        
        {/* CONTENT BLOCK */}
        <div className={contentClasses}>
            
            {/* Title */}
            <div className={`flex flex-row justify-between gap-2 items-start ${titleFont} group `}>
                {IconComponent}
                <div className='flex-grow flex flex-col gap-1' style={truncateStyle}>
                    <h3 className={`font-semibold`}>
                        {title}
                    </h3>
                    {description && <span className={`${smallerFont} opacity-70`} style={truncateStyle}>
                        {description}
                    </span>}
                </div>
                <Icon icon='star' className='flex-shrink-0 transition-all cursor-pointer opacity-0 group-hover:opacity-100 scale-75' />
            </div>
        </div>
        </div>
    );
}




/* ignore rest */

FolderCard.definitions = {
    apiName: 'FolderCard',
    displayName: 'Folder Card',
    description: 'Card representing a folder in a file management enviroment.',
    ai_instructions: `This card is used to represent a folder in a file management environment. It has a thumbnail, title, description and an icon in the top right corner. The icon can be used to add the folder to favorites or similar. The description can be used to show the number of files in the folder or similar.`,
    type: 'cards',
    relativeSize: 'medium',
    acceptedChildren: 'none',
    status: 'prototype',
    package: 'Pro',
    propDefinitions: {
        title: {
            type: 'string',
            default: "Folder Name", 
            displayName: 'Title',
            required: true, 
            editable: true
        }, 
        description: {
            type: 'string',
            default: "Folder • 4 files",
            displayName: 'Description', 
            editable: true
        },

        width: {
            type: 'oneOf',
            options: ['100%', '200px', '320px'],
            default: '100%', 
            displayName: 'Width',
            tile: '1/2'
        },
        textSize: {
            type: 'size',
            options: ['small', 'medium'],
            ai_instructions: 'small sets title, price to text-sm and description to text-xs, medium sets title and price to text-base and description to text-sm',
            default: 'small',
            displayName: 'Text Size', 
            tile: '1/2'
        },

        
        icon: {
            type: 'oneOf',
            options: ['none', ...allIconNames],
            displayName: 'Icon',
            ai_instructions: 'icon that appears left of title, not clickable',
            default: 'Page', 
        },
        corners: {
            type: "corners",
            options: ["none", "sm", "md", "lg", 'xl'],
            displayName: "Corners",
            default: "none", 
            tile: '1/2'
        },

        thumbnailAspectRatio: {
            type: 'oneOf',
            options: ['2 / 1', '3 / 1', '4 / 1','1 / 1', '3 / 2', '4 / 3'],
            default: '2 / 1', 
            displayName: 'Thumbnail Aspect',
            tile: '1/2'
        },
        thumbnailImageSrc: {
            type: 'imageURL',
            ai_instructions: 'mini preview of the file as thumbnail, leave null unless user is asking for a preview. if null, a generic file icon will be shown',
            default: null
        },

    }
};

/*FolderCard.propTypes = {
    thumbnailAspectRatio: PropTypes.oneOf(['2 / 1', '3 / 1', '4 / 1', '1 / 1', '3 / 2', '4 / 3']),
    thumbnailImageSrc: PropTypes.string,
    width: PropTypes.oneOf(['100%', '200px', '320px']),
    textSize: PropTypes.oneOf(['small', 'medium']),
    title: PropTypes.string.isRequired,
    icon: PropTypes.oneOf(['none', ...allIconNames]),
    description: PropTypes.string,
    corners: PropTypes.oneOf(["none", "sm", "md", "lg", 'xl']),
    children: PropTypes.node
};*/