import { useMediaQuery } from "react-responsive";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Button } from "ui-kit/exports/react";

export default function Hero({mainWidth}) {
    
    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
    const handleScrollToForm = () => {
      const element = document.getElementById('contact-form');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' }); 
      }
      setTimeout(() => {
        const emailInput = document.getElementById('email-input');
        if (emailInput) {emailInput.focus();} 
      }, 500)
    };
    //bg-clip-text text-transparent bg-gradient-to-r from-primary to-primary-focus
    return (
          <div className="flex flex-col h-full flex-shrink-0 gap-3 md:gap-4 items-start justify-center md:justify-end md:px-5 mt-0 text-left py-8 w-full"
          style={{maxWidth: mainWidth}}
          >
              
              <AnimatedText />
              <h1 className="text-xl font-light md:text-2xl w-full text-gray-500">
                  while you raise, cust dev, or scale
              </h1>
              <div className="flex flex-row gap-3 items-center mt-2 md:mt-4">
              <Button 
                color={'primary'}
                size={isMobile ? 'large' : 'medium'}
                style={'filled'}
                text={'Get In Touch'}
                isPill={true}
                rightIcon={'arrow-right'}
                onClick={handleScrollToForm}
              />
              {/*<Button 
                size={'medium'}
                style={'ghost'}
                color={'base-700'}
                text={'services'}
                isPill={true}

                onClick={handleScrollToForm}
              />*/}
              
              </div>  
          </div>      
    )
  }

  const AnimatedText = () => {
    const startAnimation = true
  
  
    return (
      <h1 className="flex 
      overflow-hidden
      flex-row gap-2 md:gap-4 text-4xl md:text-8xl brightness-110 text-base-content font-semibold md:font-medium">
        <motion.span
          initial={{ x: '-300%' }}
          animate={startAnimation ? { x: 0 } : {}}
          transition={{
            duration: 0.8,
            ease: [0.42, 0, 0.58, 1], // Custom easing for smooth motion
          }}
          className="block"
        >
          We
        </motion.span>
        <motion.span
          initial={{ y: '-500%' }}
          animate={startAnimation ? { y: 0 } : {}}
          transition={{ 
            duration: 0.7,
            delay: 0.1,
            ease: [0.42, 0, 0.58, 1], // Custom easing for smooth motion
          }}
          className="block"
        >
          make
        </motion.span>
        <motion.span
          initial={{ y: '500%' }}
          animate={startAnimation ? { y: 0 } : {}}
          transition={{ 
            duration: 0.8,
            delay: 0.2,
            ease: [0.42, 0, 0.58, 1], // Custom easing for smooth motion
          }}
          className="block"
        >
          apps
        </motion.span>
      </h1>
    );
  };
