import React, { useContext } from "react";
import { EditorContext } from "../../EditorContext";
import UserFlow from "./flow/UserFlow";

export default function FlowDisplay({draggedFrame, refreshKey}) {
    const { project, view, setView, selector, setSelector,handleAction } = useContext(EditorContext);
  
    const columnWidth = view.columnWidth
    const folder = selector.folder
    const page = selector.page

    if (folder && page) return (
        <div className="overflow-x-auto">
                <UserFlow
                      key={page.id}
                      frames={page.frames}
                      page={page}
                      folder={folder}
                      isMobile={false}
                      columnWidth={columnWidth}
                      draggedFrame={draggedFrame}
                      refreshKey={refreshKey}
                    />
      </div>
    );
    }  

