export const SET_PROJECT = 'SET_PROJECT'; // knows what's in the project
export const SET_SELECTOR = 'SET_SELECTOR'; // knows what user is currently looking at / has selected. page, frame, object, style (deprecated)
export const SET_VIEW = 'SET_VIEW'; // what the UI looks like, e.g. show sidebar or no
export const SET_DESIGN_SYSTEM = 'SET_DESIGN_SYSTEM'; // keeps track of design system & themes that are applied to the project
export const SET_HISTORY = 'SET_HISTORY';  // keeps track of actions for undo / redo
export const SET_DND_STATE = 'SET_DND_STATE'; // when drag & drop starts, keep track of custom variables

export function editorReducer(state, action) {
  // console.log(action)
  switch (action.type) {
    case SET_PROJECT:
      return { ...state, project: action.payload };
    case SET_SELECTOR:
      return { ...state, selector: action.payload };
    case SET_VIEW:
      return { ...state, view: { ...state.view, ...action.payload } };
    case SET_DESIGN_SYSTEM:
      return { ...state, designSystem: action.payload };
    case SET_HISTORY:  
      return { ...state, actionsHistory: action.payload };
    case SET_DND_STATE:
      return {
        ...state,
        dndState: {
          ...state.dndState,
          ...action.payload
        }
      };
    default:
      return state;
  }
}
