
// ADD FOLDERS
export function add(body, token) {
    return fetch('/api/project/folders/add', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        body: JSON.stringify(body)
    })
    .then(response => response.json());
}

// UPDATE FOLDERS
export function update(body, token) {
    
    return fetch('/api/project/folders/update/', {
        method: 'POST', 
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        body: JSON.stringify(body)
    })
    .then(response => response.json());
}


// DELETE FOLDERS
export function remove(folderId, token) {
    return fetch('/api/project/folders/remove', {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` },
        body: JSON.stringify({ folder_id: folderId })
    })
    .then(response => response.json());
}
