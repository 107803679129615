// BASE IMPORTS
import { useParams, useSearchParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { getChildren, getDescendants } from "../../utilities/helpers";
import { mergeThemeVariables } from "../editor/sidebar/theme-editor/helpers";
import ShareFrameHeader from "./ShareFrameHeader";
import CodePreview from "./CodePreview";
import ShowNotes from "./ShowNotes";
import { convertThemeVariablesToStyles } from "../editor/canvas/helpers";
import LightRouter from "../../components/LightRouter";

// ADD DROPDOWN FOR SELECTING FOLDER / PAGE / FRAME FROM SUBLINGS
// ADD MOBILE VIEW

export default function ShareFrame() {
  const { frameId } = useParams();  // Retrieve frameId from URL
  const [searchParams] = useSearchParams();
  const canvasRef = useRef(null);
  const [ isMobile, setIsMobile ] = useState(false)
  const [ showCode, setShowCode ]= useState(false)
  const [ showNotes, setShowNotes ]= useState(false)

  const [ narrowViewport, setNarrowViewport] = useState(false)

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const { width } = entries[0].contentRect;
      if (width < 600) {
        setNarrowViewport(true)
      } else {setNarrowViewport(false)}
      
    });
  
    const currentRef = canvasRef.current;
  
    if (currentRef) {
      resizeObserver.observe(currentRef);
    }
  
    return () => {
      if (currentRef) {
        resizeObserver.unobserve(currentRef);
      }
      resizeObserver.disconnect();
    };
  }, [canvasRef]); 

  const [project, setProject] = useState(null);
  const [designSystem, setDesignSystem] = useState(null);
  const [frameWithObjects, setFrameWithObjects] = useState(null);
  const [assets, setAssets] = useState(null);
  
  const objectId = searchParams.get("objectId");
  const width = searchParams.get("width");
  const height = searchParams.get("height");

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch frame data
        const frameResponse = await axios.get(`/api/share/frame/${frameId}`);
        const frameData = frameResponse.data;
  
        setProject(frameData.project);
        setDesignSystem(frameData.designSystem);
        setFrameWithObjects(frameData.frameWithObjects);
        setAssets(frameData.assets);  // Set the assets state
  
        // If designSystem is linked, fetch its data
        if (frameData.project && frameData.project.ds) {
          const response = await axios.post(`/design-system/${frameData.project.ds}`, {
            userId: null, 
            projectId: frameData.project.id || null
          })

          setDesignSystem(response.data);
        }
      } catch (error) {
        console.error("Error fetching frame data:", error);
      }
    };
  
    fetchData();
  }, [frameId]);
  
  const children = getChildren(frameWithObjects?.id, frameWithObjects?.objects) || [];
  
  const currentThemeId = project?.current_theme_id || designSystem?.default_theme_id;
  const defaultTheme = designSystem?.themes?.find(t => t.id == designSystem.designSystem.default_theme_id)
  const currentTheme = designSystem?.themes?.find(t => t.id == currentThemeId)
  const effectiveVariables = currentTheme && defaultTheme ? mergeThemeVariables(defaultTheme, currentTheme) : defaultTheme?.variables
  
  const timeoutRef = useRef(null);


  // Clear the timeout on unmount
  useEffect(() => {
      return () => {
          if (timeoutRef.current) {
              clearTimeout(timeoutRef.current);
          }
      };
  }, []);
  
  const themeStyles = convertThemeVariablesToStyles(effectiveVariables);
  return (
    <div
    className='flex relative flex-col w-screen items-center justify-stretch overflow-y-scroll bg-base-50 pb-2 pt-1 px-2 gap-1'
      ref={canvasRef}
      style={{
        ...themeStyles,
        overflow: 'scroll', 
        fontFamily: 'Inter', 
        height: '100dvh', 
    }}
      >
        {/* HEADER */}
        <ShareFrameHeader
          showCode={showCode} 
          setShowCode={setShowCode}
          isMobile={isMobile}
          setIsMobile={setIsMobile}
          project={project}
          selectedFrame={frameWithObjects}
          narrowViewport={narrowViewport}
          setShowNotes={setShowNotes}
          showNotes={showNotes}
          assets={assets}

          />
        
      {/* FRAME */}
      <div className="flex flex-col overflow-hidden rounded-sm relative ring-[0.5px] ring-base-300 w-full h-full">
      <div className="flex flex-col w-full bg-base-100 relative items-center justify-start overflow-y-scroll relative" 
              style={{
                color: 'var(--base-content)',
                height: '100%',
                fontFamily: `var(--mainFont), ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`,
               
            }}>
            {children.length != 0 &&
                children
                    .sort((a, b) => a.index - b.index)
                    .map((object) => (
                        
                        <LightRouter
                            key={object.id} 
                            self={object} 
                            assets={assets} 
                            descendants={getDescendants(object.id, frameWithObjects.objects)}
                        />
                            
                    ))}
      </div>
      </div> 
      {!narrowViewport && <CodePreview showCode={showCode} selectedFrame={frameWithObjects} setShowCode={setShowCode}/>}
      {!narrowViewport && <ShowNotes showNotes={showNotes} selectedFrame={frameWithObjects} setShowNotes={setShowNotes}/>}
    </div>
  );
}
