import React, { useContext } from 'react';
import Icon from '../../../../../components/icons/Icon';

export default function SelectHeight({ propKey, heightOptions, currentHeight, onHeightSelect }) {

  const handleHeightSelect = (value) => {
    onHeightSelect(propKey, value);
  };

  
  return (
    <div className='flex flex-row items-center w-full gap-2'>
    <div
    className='py-1 px-2 border-r border-slate-400'
    ><Icon name={'height'} width={16} />
    </div>

    <div 
        className='items-center flex flex-row flex-grow w-full rounded-lg p-1'
        style={{userSelect: 'none'}}
      >
        {heightOptions.map((height) => (
          <button
            key={height}
            className='w-full pointer w-full text-sm flex items-center justify-center rounded-md py-1'
            style={{
              fontWeight: 500,
              flexGrow: 1,
              backgroundColor: currentHeight === height && 'white', 
              zIndex: 2
            }}
            onClick={() => handleHeightSelect(height)}
          >
            {height}
          </button>
        ))}
      </div></div>
  );
};
