import React, { useState } from 'react';
import { Alert } from 'ui-kit/exports/react';
import { Image, Button } from 'ui-kit/exports/react';

export default function DatabaseSetup({ project }) {
    const baasProviders = [
      { 
        label: 'Pocketbase', 
        value: 'pocketbase', 
        logo: "https://storage.googleapis.com/juno-v1.appspot.com/logos--pocket-base.svg?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=zrZhGMf0%2Fp8aSJR3oWQdI7DVM8zgO9h2Gk7aBLxq%2F11WglQ8UI0enTwLi%2B27U%2F3lSqPyKTnwAUtb3%2FUiGIMo2hpc0Q2%2FwEBl%2F4hWrPaXBL0KUlQ91GyO3DJpvHDUYuzCXpvNLLuQ0R%2FycPa4X30ZUZKOGEbe6KBjsVEQE%2FgA%2B25C6FXFd2vT6kwyrdB%2BECWGI3kuxXw2MvNBIQe%2BxuiEB%2BDqbZ3JlwZ9l0T4%2Byp%2Fmd7wzo1n40enK8JLgQnBP56gEEhrKyvMQedhGHwXpqWnAX1ZBfCP3gumZfdZAjcbEziZAs%2BJNY0LnoeeFnLSPuzTOc5Ehzze0c7%2B1UBxL33Lew%3D%3D"
      },
      { 
        label: 'Supabase', 
        value: 'supabase', 
        logo: "https://storage.googleapis.com/juno-v1.appspot.com/supabase-logo-icon.svg?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=yKaCqnS803ekutggqKKBxCQuGoFW0mi1qpkd9LwJ4o40o99zsvRr9pr%2BWBppsUojDEDptGO4pJxZSVssTqQTiIxi70JvfAMySoVYCaN70b9GXnbyKxsLOmQ7S2x1j6NAGZ4f%2F%2FY2AjuLWsBIDRK8Hsmff8wWOR0JuP9ZMHDFb%2Fmn4JuU5YQ1MSljcPifLwt9bSeAiQ3wADRXcj4Gzxc1pksZ5V4okVy9mb17vRDN1HlNjBcNJi9a7ov03xxlkyQjx1hHoxnGERgfYX3RU8pBUtLuqJQZBzcreHP0lJI9EAzOJ%2FVdKi9BMO3%2FF3Mwdvy%2BejG3HcHHObhjXtrhfG5%2FVw%3D%3D" 
      },
      { 
        label: 'Firebase', 
        value: 'firebase', 
        logo: "https://storage.googleapis.com/juno-v1.appspot.com/Logomark_Full%20Color.svg?GoogleAccessId=firebase-adminsdk-ihqnb%40juno-v1.iam.gserviceaccount.com&Expires=4102444800&Signature=GEm7OZyBCZlz80yU2rNnVnbaKruv%2FHb9nwMyO4NhjfY8i2LCbQvgzWZ4QZYPJUetaiyJ8ZOIu9NjYKR8g265OgssCseq2njBvDGnOeOz2oLAh7O5UC713ELKt%2FAr6QkJ94Do377fxyeYGt9zaYrTNiupcob%2FKrJelb0BY%2FwgkwXIVMFy1fhEdC7A1mppb%2F3RfeJVUZvz3ONumZZ4Yiu7KXYhErl5yI6fpw0zQCqApnEmqtkF%2By%2Br83UCBDnGNBmvlWcU3e0svW4yX1HKUXgRmDQ3d1Etk3%2FaYRqSm5YSb7zOqexp3zsMtFWxm5Qb3ChnPoRtyAbADrxz4GQGdqDFgg%3D%3D" 
      },

    ]

    
    const [connectedDatabase, setConnectedDatabase] = useState(baasProviders[0]?.value);

    function connectDatabase(provider) {
      console.log(`Connecting to ${provider}`);
    }

    return (
        <div className="pl-5 pr-3 w-full max-w-full h-full gap-5 overflow-hidden items-start justify-start flex flex-col py-3">
          <div className="grid w-full gap-6 items-start grid-cols-3">
          
          
          {baasProviders.map((provider, index) => (
          <div 
          key={index}
          className={`flex flex-row flex-nowrap w-full text-base-content 
          ${connectedDatabase === provider.value ? 'ring-[1.5px] ring-primary bg-base-0' : 'bg-base-50  hover:bg-base-0 hover:ring-1 hover:ring-current-10'}
           transition-all duration-150
          gap-6 px-4 py-4 rounded-lg items-start justify-start`}>
          <Image width="36px" height="36px" src={provider.logo} objectFit="contain" />
            <div className="flex flex-col flex-nowrap w-full gap-3 items-start justify-start">
              <h1 className="text-ellipsis whitespace-pre-wrap text-lg font-semibold text-left">
                {provider.label}
              </h1>
              <Button 
                style="filled" 
                text="Connect" 
                size="small" 
                onClick={() => setConnectedDatabase(provider.value)}
                rightIcon="chevron-right"
                />
            </div>
          </div>  
          
          ))}
        </div>
        <Alert 
          type='info'
          width='full'
          text='Database connection is read-only and allows JUNO to look up table schemas and data types. This enables more accurate code generation.'
        />
        </div>
    );
  }
  