import { HomeSimpleDoor, NavArrowRight, Notes } from "iconoir-react";
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import CopyLinkButton from "./CopyLinkButton";
import DesktopNav from "./DesktopNav";
import { Logo } from "../../ui-kit/local/index";

export default function ShareFeatureHeader({ assets, setShowCode, showNotes, setShowNotes, project, selectedFrame, setSelectedFrame, narrowViewport, selectedFolder, selectedPage }) {
  
  
  const allFolders = project?.folders.filter(folder => !folder.is_archived).sort((a, b) => a.index - b.index);  
  const allFlows = project?.pages.filter(page => !page.is_archived && allFolders.map(f => f.id).includes(page.folder_id)).sort((a, b) => a.index - b.index);
  const allFrames = allFlows?.flatMap(f => f.frames).filter(frame => !frame.isArchived).sort((a, b) => a.index - b.index);
  

  const framesByOrder = allFolders?.flatMap(folder => allFlows.filter(flow => flow.folder_id === folder.id).flatMap(flow => allFrames.filter(frame => frame.page_id === flow.id))) || []
  
  const currentIndex = framesByOrder.findIndex(frame => frame.id === selectedFrame?.id);
  
    let previousFrame = null;
    let nextFrame = null;
    
    if (currentIndex > 0) {
        previousFrame = framesByOrder[currentIndex - 1];
    }
    
    if (currentIndex < framesByOrder.length - 1) {
        nextFrame = framesByOrder[currentIndex + 1];
    }

    function selectNextFrame() {if (nextFrame) {setSelectedFrame(nextFrame)}}
    function selectPreviousFrame() {if (previousFrame) {setSelectedFrame(previousFrame)}}
    
    useEffect(() => {
      const handleKeyPress = (event) => {
        if (event.key === 'ArrowRight') {
          selectNextFrame();
        } else if (event.key === 'ArrowLeft') {
          selectPreviousFrame();
        }
      };
  
      window.addEventListener('keydown', handleKeyPress);
      return () => {
        window.removeEventListener('keydown', handleKeyPress);
      };
    }, [nextFrame, previousFrame]); 
      
    const tooltipStyle = `absolute opacity-0 group-hover:opacity-100 whitespace-nowrap
    w-auto bg-base-700 text-base-50 font-medium rounded px-2 py-1 text-2xs invisible group-hover:visible
    -bottom-5 mb-2 transition-all`
    const navigate = useNavigate();
    return (
      <div className="flex flex-row justify-between gap-2 py-1 w-full text-xs">
        {/* Left Side */}
        <div className="flex flex-row items-center gap-2 w-full md:w-1/2 relative z-50">
        
        <div className="group relative bg-base-100 rounded-md w-6 h-6 flex items-center justify-center
          hover:bg-base-200 transition-all cursor-pointer flex-shrink-0"
          title="JUNO"
          //ring-[0.5px] ring-base-300
          onClick={() => navigate('/')}
          >
            <HomeSimpleDoor />
          </div>
          <div className="group relative rounded-md w-5 h-5 flex items-center justify-center">
          <Logo size={'20px'} type={'symbol'} assets={assets}/>
          </div>
          {!narrowViewport && 
          <DesktopNav 
              currentIndex={currentIndex}
              allFrames={framesByOrder}
              selectNextFrame={selectNextFrame}
              selectPreviousFrame={selectPreviousFrame}
              nextFrame={nextFrame}
              previousFrame={previousFrame}
          />}
          <div className="relative flex flex-row gap-2 py-1 h-6 items-center cursor-default select-none font-medium">
            {/* Dropdown for selectedFolder */}
            <h1 className="whitespace-nowrap">
            {selectedPage?.name}  
            </h1>
            <NavArrowRight width={12} height={12} />
            <h1 className="whitespace-nowrap">
            {selectedFrame?.name}
            </h1>
          </div>
        </div>

        {/* Middle Part */}
        <div className="flex flex-row justify-center gap-2 items-center font-medium">
        </div>
        
        {/* Right Side */}
        {!narrowViewport && (<div className="flex flex-row justify-end items-center gap-2  w-1/2">
            <button
              className={`${showNotes && "bg-base-200"} w-6 h-6 flex items-center justify-center transition-all rounded-md hover:scale-110 text-slate-700 group relative z-50 hover:bg-base-100`}
              onClick={() => { setShowNotes(!showNotes); setShowCode(false)}}
            >
              <Notes />
              <span className={` ${tooltipStyle} right-0 transform translate-y-full  `}
              style={{ zIndex: 1001 }}>
                Notes
              </span>
            </button>
            
            <CopyLinkButton tooltipStyle={tooltipStyle}/>
          </div>
        )}
      </div>
    );
  }
