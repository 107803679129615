type InstanceProps = {
    rootObject: string,
    objects: any [];
    __juno?: any;

}
export default function Instance({
    rootObject,
    objects = [],
    __juno = {},
}: InstanceProps) {

  // get the rootObject
  // attach listeners and attributes to the root object
  // render children with Router but no 

  return (
    <div
      className={`${__juno?.outlineStyle} ${__juno?.tagStyle}`}
      {...__juno?.attributes}
    >
      <h1>Instance</h1>
    </div>
  );
}

