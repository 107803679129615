import React, { createContext, useContext, useRef } from 'react';
import { EditorContext } from '../EditorContext';
import { convertJSXToObjectsSync } from '../../../utilities/AIhelpers';
import { canPaste } from '../../../utilities/pasteRules';
import { handleSectionDragEnd, handleSectionDragOver } from './SectionDnD';
import { handleModuleDragEnd, handleModuleDragOver } from './ModuleDnD';
import { handlePrimitiveDragEnd, handlePrimitiveDragOver } from './PrimitivesDnD';
import { handleDialogDragEnd, handleDialogDragOver } from './DialogsDnD';
import { handleLayoutDragEnd, handleLayoutDragOver } from './LayoutDnD';

const DnDContext = createContext({
    handleDragStart: () => {},
    handleDragOver: () => {},
    handleDragEnd: () => {}
});

export const DnDProvider = ({ children, setInsert }) => {
    
    const { setDnDState, dndState, handleAction, view, selector } = useContext(EditorContext);
    const disableDnD = view.disableDnD
    const sections = ['Main', 'Header', 'Sidebar', 'IconBar', 'Footer', 'FeaturePanel', 'SidePanel', 'Hero', 'CollapsibleSidebar', 'AppWrapper']
    const dialogs = ['Drawer', 'Popover', 'Modal', 'Toast', 'Banner']
    const layouts = ['AppShell', 'Email']
    
    const dragRefs = useRef({
        refs: []
      })
    
    function collectRefs(refObject) {
        // ref object is usually {id, index, nodeRef}
        dragRefs.current.refs.push(refObject);
    }
    

    function handleDragStart(event, object, objectRef) {
      
        
        const draggingSection = sections.includes(object?.componentAPIName) || false
        const draggingDialog = dialogs.includes(object?.componentAPIName) || false        
        const draggingLayout = layouts.includes(object?.componentAPIName) || false


        dragRefs.current.draggingItemRef = objectRef;
        
        // INITIALIZE DND STATE
        if (object) {
            dragRefs.current.dropIndex = object.index
            
            setDnDState({
                ...dndState,
                isDragging: true,
                isDraggingFromLibrary: object?.source == "library",
                draggingItem: object, 
                draggingSection, 
                draggingDialog, 
                draggingLayout,
                // draggingModule,
                
            })
        }
    
        // MAKE PREVIEW
        if (objectRef.current) {
          
          // Create a copy of the element for drag preview
          const dragPreview = objectRef.current.cloneNode(true);
          const { width, height } = objectRef.current.getBoundingClientRect();

          dragPreview.style.opacity = '0';
          // Assign a unique ID to the cloned node
          dragPreview.id = 'drag-preview';
          // Append it to the body temporarily
          document.body.appendChild(dragPreview); 
        
          // Set it as the drag image
          event.dataTransfer.setDragImage(dragPreview, 0, 0)
        }

        /*/ PREVIEW NODE
        if (objectRef.current) {
          // Measure the dimensions of the original element
          
          
          // Create a new element for drag preview
          const dragPreview = document.createElement('div');
          dragPreview.style.width = `${width}px`;
          dragPreview.style.height = `${height}px`;
          dragPreview.style.border = '1px dashed '; // Example: dashed black outline


          // Assign a unique ID to the new node
          dragPreview.id = 'drag-preview';

          // Append it to the body temporarily
          document.body.appendChild(dragPreview);

          // Set it as the drag image
          event.dataTransfer.setDragImage(dragPreview, 0, 0);

        }
        

        // PREVIEW NODE
        /*    */
        
        
    }
    
    

  function handleDragOver(event, object,  objectRef) {
      event.stopPropagation()
      event.preventDefault()

      const frameObjects = selector?.frame?.objects
      const frame = selector?.frame
      
      if (object.onCanvas) {
        // we the only object picking up over is canvas -> clean up dnd
        setDnDState({
          ...dndState, 
          draggingOverItem: null, 
          offFrame: true
        })
        setInsert(null)
      } else if (dndState.draggingLayout) {
        console.log('layout drag over')
        handleLayoutDragOver({
          dndState,
          frame, 
          setDnDState
        })
      } else if (dndState.draggingSection) { // DRAG ITEM = SECTION 
        // console.log('section drag over', dndState)
        console.log('section drag over')
        handleSectionDragOver({
            dndState,
            frameObjects, 
            setDnDState
          })
      /*} else if (dndState.draggingModule) { // DRAG ITEM = MODULE
          handleModuleDragOver({
            frameObjects, 
            object, 
            dndState, 
            setDnDState, 
            setInsert, 
            dragRefs, 
            event
          }) */
      } else if (dndState.draggingDialog) { // DRAG ITEM = DIALOG OR LAYOUT
        handleDialogDragOver({
          frameObjects, 
          dndState, 
          setDnDState
        })
      } else { // DRAG ITEM = EVERYTHING ELSE
        // console.log('primitive drag over')
        handlePrimitiveDragOver({
          dndState, 
          setDnDState, 
          dragRefs, 
          setInsert, 
          frameObjects, 
          event, 
          sections, 
          object
        })
      }
      
  }
    
    
    function handleDragEnd(event, object, objectRef) {
        // console.log('drag end', dndState)
        event.stopPropagation();
        
        const frameObjects = selector?.frame?.objects
        const lastOverItem = dndState.draggingOverItem
        
        
        const canDrop = canPaste(dndState.draggingOverItem, dndState.draggingItem)
        
        // console.log('dropping')
        if (dndState.offFrame) {
          // straight to cleanup
          // console.log('nowhere to drop')
        } else if (dndState.draggingSection) {
            // console.log('dropping section')
            handleSectionDragEnd({dndState, dragRefs, handleAction, frameObjects, lastOverItem})
        // DROP DIALOG BOX - EITHER ADD OR REPLACE CURRENT
        } else if (dndState.draggingDialog) {
          console.log('dropping dialog')
          handleDialogDragEnd({
            selector, 
            dndState, 
            handleAction, 
            frameObjects
          })
        } else if (dndState.draggingLayout) {
          handleLayoutDragEnd({
            layouts, dndState, handleAction, frameObjects,
          })
        } else {
          
          handlePrimitiveDragEnd({
            dndState, dragRefs, handleAction, lastOverItem, canDrop
          })
        }/* else {
            if (lastOverItem && canDrop) {
                let action = null;
                const newParent = lastOverItem; // or its parent
                const draggingItem = dndState?.draggingItem;
      
                if (canDrop) {
                  if (draggingItem.source == "library") {
                    
                    let objects = [];
                    if (draggingItem.objects && draggingItem.objects.length > 0) {
                        objects = draggingItem.objects;
                    } else {
                        const jsx = draggingItem.jsx;
                        objects = convertJSXToObjectsSync(jsx) || [];
                    }
                    if (objects.length === 0) return;
                    const rootObj = objects.find((obj) => obj.parent === "rootObject");
                    if (!rootObj) return;
                    const descendants = objects.filter((obj) => obj.id !== rootObj.id) || [];
      
                    action = canDrop && {
                      type: "INSERT_OBJECT",
                      object: rootObj,
                      parent: newParent,
                      index: dragRefs.current.dropIndex,
                      descendants,
                    };
                    
                  } else {
                    action = canDrop && {
                      type: "RELOCATE_OBJECT",
                      object: dndState?.draggingItem,
                      newParent,
                      newIndex: dragRefs.current.dropIndex,
                    };
                  }
                  
                  handleAction(action);
                } else {
                  console.log('cant drop here')
                }
              }    
        }*/

        // CLEANUP
        const dragPreview = document.getElementById('drag-preview');
        if (dragPreview) {document.body.removeChild(dragPreview);}
        
        dragRefs.current.dropIndex = null
        dragRefs.current.insert = null
        dragRefs.current.refs = []
        
        setDnDState({
            isDragging: false,
            isDraggingFromLibrary: false,
            draggingOverItem: null,
            draggingItem: null, 
            draggingDialog: false,
            draggingModule: false,
            draggingSection: false, 
            draggingLayout: false,
            offFrame: false,
            collectRefs: true
          })
        setInsert(null)
}



    return (
        <DnDContext.Provider
      value={{
        handleDragStart: !disableDnD ? handleDragStart : () => {},
        handleDragOver: !disableDnD ? handleDragOver : () => {},
        handleDragEnd: !disableDnD ? handleDragEnd : () => {},

        
        collectRefs
      }}
    >
            {children}
        </DnDContext.Provider>
    );
};


export default DnDContext;
