import { spacingMap } from '../helpers';

type Props = {
    text?: string,
    URL?: string,
    openInNewWindow?: boolean,
    textSize?: 'auto' | 'xs' | 'sm' | 'base' | 'lg' | 'xl' | '2xl',
    textColor?: 'primary' | 'primary-content' | 'accent' | 'accent-content' | 'base-0' | 'base-50' | 'base-100' | 'base-content' | 'base-500' | 'base-700' | 'base-900' | 'success-content' | 'warning-content' | 'error-content' | 'info-content' | string,
    lineHeight?: 'auto' | 'tight' | 'normal' | 'relaxed' | 'loose',
    fontWeight?: 'auto' | 'hairline' | 'thin' | 'light' | 'normal' | 'medium' | 'semibold' | 'bold' | 'extrabold' | 'black',
    underline?: 'always' | 'onlyOnHover' | 'never',
    marginTop?: '4px' | '6px' | '8px' | '12px' | '16px' | '24px' | '32px',
    marginBottom?: '4px' | '6px' | '8px' | '12px' | '16px' | '24px' | '32px',
    onClick?: any,
    __juno?: any
}

export default function Link({
        text = 'Link',
        URL = '#',
        openInNewWindow = false,
        onClick,
        textSize = 'auto',
        textColor = null,
        lineHeight = 'auto',
        fontWeight = 'auto',
        underline = 'onlyOnHover',
        marginBottom = null, 
        marginTop = null, 
        __juno = {}
      }: Props) {
    const textSizeStyles = textSize != 'auto' && `text-${textSize}`;
    const textColorStyles = (textColor == 'none' || !textColor) ? `` : `text-${textColor}`
    const lineHeightStyles = lineHeight != 'auto' ? `leading-${lineHeight}` : '';
    const fontWeightStyles = fontWeight != 'auto' ? `font-${fontWeight}` : '';
    const marginBottomStyles = marginBottom ? `mb-${spacingMap[marginBottom]}` : '';
    const marginTopStyles = marginTop ? `mt-${spacingMap[marginTop]}` : '';
    
    const underlineStyles = underline == 'always' ? 'underline' : underline == 'onlyOnHover' ? 'hover:underline' : 'no-underline';

    const classes = `inline-flex ${textSizeStyles} ${textColorStyles} ${lineHeightStyles} ${fontWeightStyles} ${underlineStyles}  ${marginBottomStyles} ${marginTopStyles} cursor-pointer`

    return (
        <span /* replace to '        <a' */
            className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
            {...__juno?.attributes}
            href={URL}
            target={openInNewWindow ? "_blank" : "_self"}
            rel={openInNewWindow ? "noopener noreferrer" : ""}
            /* replace to '            onClick={(e)=> onClick(e)}' */
        >
            {text} 
        </span> /* replace to '        </a>' */
    );
}


/* ignore rest */


Link.definitions = {
    apiName: 'Link',
    displayName: 'Link',
    description: 'A link component with editable text, customizable URL, font size, color, line height, font weight, and underline style. Supports opening in a new window and has hover effects.',
    ai_instructions: 'link component represents <a> element',
    type: 'typography',
    relativeSize: 'small',
    acceptedChildren: 'none',
    package: 'Starter',
    propDefinitions: {
        text: {
            type: 'string',
            displayName: 'Text',
            default: 'Link', 
            editable: true,
        },
        URL: {
            type: 'string',
            displayName: 'URL',
            default: '#'
        },
        openInNewWindow: {
            type: 'bool',
            displayName: 'Open in New Window',
            default: false
        },
        
        textColor: {
            type: 'colors',
            options: ['primary', 'primary-content', 'accent', 'accent-content', 'base-0', 'base-50', 'base-100', 'base-content', 'base-500', 'base-700', 'base-900','success-content', 'warning-content', 'error-content', 'info-content'],
            displayName: 'Color',
            default: null,
        },
        textSize: {
            type: 'oneOf',
            options: ['auto', 'xs', 'sm', 'base', 'lg', 'xl', '2xl'],
            displayName: 'Font Size',
            default: 'auto', 
            tile: `1/2`
        },
        lineHeight: {
            type: 'oneOf',
            options: ['auto', 'tight', 'normal', 'relaxed', 'loose'],
            displayName: 'Line Height',
            default: 'auto', 
            tile: `1/2`
        },
        fontWeight: {
            type: 'oneOf',
            options: ['hairline', 'thin', 'light', 'normal', 'medium', 'semibold', 'bold', 'extrabold', 'black'],
            displayName: 'Font Weight',
            default: 'auto', 
            tile: `1/2`
        },
        marginTop: {
            type: 'spacing',
            options: ['4px', '6px', '8px', '12px', '16px', '24px', '32px'],
            displayName: 'Top Margin',
            default: null,
            tile: '1/2'
        },
        marginBottom: {
            type: 'spacing',
            options: ['4px', '6px', '8px', '12px', '16px', '24px', '32px'],
            displayName: 'Bottom Margin',
            default: null,
            tile: '1/2'
        },
        underline: {
            type: 'oneOf',
            options: ['always', 'onlyOnHover', 'never'],
            displayName: 'Underline',
            default: 'onlyOnHover', 
            tile: `1/2`
        }
    }
};