import { useState, useEffect, useRef } from 'react';
import { ButtonIcon } from '../';

type Props = {
  file: Blob | string; // Audio file as Blob or URL
  color?: 'primary' | 'accent' | 'warning' | 'info' | 'success' | 'error' | 'current';
  style?: 'filled' | 'ghost' | 'light';
  state?: 'default' | 'disabled' | 'active';
  size?: 'small' | 'medium' | 'large';
  stopBehavior?: 'pause' | 'stop';
  loop?: boolean;
  __juno?: any;
};

export default function PlaybackButton({
  file,
  color = 'current',
  style = 'light',
  state = 'default',
  loop = false,
  stopBehavior = 'pause',
  size = 'medium',
  __juno = {},
}: Props) {
  const [progress, setProgress] = useState<number | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  
  const progressColor = style == 'filled' ? 'var(--base-0)' : color == 'current' ? 'currentColor' : `var(--${color})`

  const strokeWidth = size === 'small' ? 8 : size === 'large' ? 10 : 10;
  const circlePadding = size == 'small' ? '[1px]' : size == 'large' ? '[2px]' : '[1.5px]'
  // top-[1px] top-[1.5px] top-[2px] left-[1px] left-[1.5px] left-[2px] right-[1px] right-[1.5px] right-[2px] bottom-[1px] bottom-[1.5px] bottom-[2px]


  const audioRef = useRef<HTMLAudioElement | null>(null);
  const endedRef = useRef(false); // Track if audio has ended
  const intervalRef = useRef<number | null>(null);

  useEffect(() => {
    if (typeof file === 'string' || file instanceof Blob) {
      const audio = new Audio(typeof file === 'string' ? file : URL.createObjectURL(file));
      audioRef.current = audio;

      audio.addEventListener('ended', handleEnd);

      return () => {
        audio.removeEventListener('ended', handleEnd);
        audioRef.current = null;
      };
    }
  }, [file]);

  useEffect(() => {
    // Clear existing interval if any
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    // If playing, set up an interval to update progress every 50ms
    if (isPlaying && audioRef.current) {
      intervalRef.current = window.setInterval(() => {
        updateProgress();
      }, 50);
    }

    // Cleanup on unmount or when isPlaying changes
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [isPlaying]);

  const updateProgress = () => {
    const audio = audioRef.current;
    if (!audio) return;

    // If ended, don't update progress
    if (endedRef.current) return;

    if (audio.currentTime >= audio.duration) {
      // If we've reached or passed the end, handle that here.
      handleEnd();
      return;
    }

    const progressValue = (audio.currentTime / audio.duration) * 100;
    setProgress(progressValue);
  };

  const handleEnd = () => {
    if (loop) {
      restartAudio();
    } else {
      handleStop();
      endedRef.current = true; // Mark audio as ended
      setProgress(null); // Clear progress when playback ends
    }
  };

  const handleStart = () => {
    const audio = audioRef.current;
    if (audio) {
      endedRef.current = false; // Reset ended state when starting again
      audio.play();
      setIsPlaying(true);
    }
  };

  const handlePause = () => {
    const audio = audioRef.current;
    if (audio) {
      audio.pause();
      setIsPlaying(false);
    }
  };

  const handleStop = () => {
    const audio = audioRef.current;
    if (audio) {
      audio.pause();
      audio.currentTime = 0;
      setIsPlaying(false);
      endedRef.current = true; // Mark audio as ended
      setProgress(null);
    }
  };

  const restartAudio = () => {
    const audio = audioRef.current;
    if (audio) {
      endedRef.current = false;
      audio.currentTime = 0;
      audio.play();
      // setProgress(0);
      setIsPlaying(true);
    }
  };

  const handleClick = () => {
    if (isPlaying) {
      if (stopBehavior === 'stop') {
        handleStop();
      } else {
        handlePause();
      }
    } else {
      handleStart();
    }
  };


  const sizeStyles = size == 'small' ? `h-7 w-7` : size == 'large' ? `w-12 h-12` : `h-9 w-9`;

  return (
    <div
      className={`relative flex items-center ${sizeStyles} justify-center ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
      {...__juno?.attributes}
    >
      {/* Background Circle */}
      <svg className={`absolute 
        pointer-events-none
        top-${circlePadding} bottom-${circlePadding} left-${circlePadding} right-${circlePadding} z-10`}
        viewBox="0 0 100 100"
      >
        <circle cx="50" cy="50" r="45" stroke={progressColor} strokeWidth={strokeWidth}
          className={`${progress || (isPlaying && loop) ? 'opacity-20' : 'opacity-0'} transition-al duration-150`}
          fill="none" />
          <circle cx="50" cy="50" r="45" stroke={progressColor} strokeWidth={strokeWidth}
            className={`${progress > 0 ? 'opacity-70' : 'opacity-0 transition-all duration-150'}`}
            fill="none" transform="rotate(-90 50 50)" strokeLinecap="round"
            strokeDasharray={`${2 * Math.PI * 45}`}
            strokeDashoffset={`${2 * Math.PI * 45 - (progress / 100) * 2 * Math.PI * 45}`}
            style={{ transition: 'stroke-dashoffset 0.1s ease, opacity 0.1s ease'}}
          />
      </svg>

      {/* Button */}
      <ButtonIcon
        icon={isPlaying ? 'pause' : 'play'} 
        color={color} style={style} state={state} size={size} isPill
onClick={handleClick}
      />
    </div>
  );
}
    
  