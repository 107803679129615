import React from 'react';
import { useNavigate } from 'react-router-dom'
import logo from "./logo_text.svg";
import UserMenu from './UserMenu';

import AddProject from './AddProject';

export default function Header({
  sets, user, addProject, 
  view, setView
}) {
  
  const navigate = useNavigate();
  
  return (
    <div className="w-full h-[52px] flex flex-row items-center justify-center sticky top-0 z-10 bg-white
    shadow-lg shadow-black/[0.02] flex-shrink-0
    ">
      <div className='flex flex-row h-full w-full justify-between items-center max-w-[1240px] mx-auto px-9'>
      <div className='flex flex-row h-full w-1/4 items-stretch gap-2'>
      <div  className='flex flex-row rounded items-center text-sm select-none transition-all relative'
      onClick={() => navigate('/')}>
            <img src={logo} alt="Logo" width={72} onClick={() => navigate(`/`)} />
            </div>
      </div>


      <div className="flex flex-row h-full w-1/4 items-center justify-end gap-3" >
          {/*<AddProject sets={sets} addProject={addProject} userId={user.id} />*/}
          <UserMenu setView={setView} view={view}/>
      </div>
      </div>
    </div>
  );
}    


