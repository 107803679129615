import { Avatar } from "../";

type Props = {
    name?: string,
    time?: string,
    avatar?: string,
    avatarPosition?: 'top' | 'bottom',
    children?: any,
    maxWidth?: '75%' | '80%' | '100%',
    position?: 'left' | 'right',
    __juno?: any
}

export default function MessageGroup({
        name,
        time,
        avatar,
        children,
        maxWidth = '80%',
        position = 'left',
        __juno = {}

    }: Props) {

    
    const selfAlign = position === 'left' ? 'self-start' : 'self-end'
    const sizeStyles = `w-full h-auto text-base `
    const classes = `flex flex-row items-stretch justify-start ${sizeStyles} ${selfAlign} gap-2`

    //${style != 'bubble' ? 'bg-'+bgColor : ''}//
    // rounded-bl-sm rounded-br-sm rounded-bl-xs rounded-br-xs
    //  ${style === 'bubble' ? 'pl-2' : ''} - for padding

    return (
        <div  
            className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`} {...__juno?.attributes} 
            style={{maxWidth}}
        >
        {avatar &&
        <div className='flex-shrink-0 items-end flex' style={{order: position === 'left' ? 0 : 2}}>
            <Avatar size='28px' bgColor='base-500'
            imageSrc={avatar}
            onClick={(e) => console.log('Avatar clicked')}
            />
        </div>}
        <div className={`flex flex-col flex-grow gap-1 select-none`}>
            {(name || time) &&
            <div className={`flex flex-row gap-1.5 text-xs items-baseline ${selfAlign}`} 
            style={{maxWidth: '100%', order: !name ? 1 : -1}}>
                
                {name && <span className='font-normal truncate '>
                    {name}
                </span>}
                {time && <div className='flex flex-row items-baseline gap-2 opacity-60 font-light flex-shrink-0'>
                    <span className='truncate '>
                        {time}
                    </span>
                </div>}
            </div>}
            
            
            {/* Actual Content */}
            <div className={`flex flex-col gap-1.5`}>
                {children}
            </div>
            
        </div>
        
        </div>
    );
}

/* ignore rest */

MessageGroup.definitions = {
    apiName: 'MessageGroup',
    displayName: 'Message Group',
    description: 'Special wrapper used im chat UI to wrap messages from one sender.',
    ai_instructions: 'Special wrapper used im chat UI to wrap messages from one sender. Can have message bubble or other things as children',
    type: 'chat',
    relativeSize: 'medium',
    acceptedChildren: ['MessageBubble'],
    status: 'stable',
    package: 'Starter',
    propDefinitions: {
        name: {
            type: 'string',
            displayName: 'Name',
            default: null
        },
        time: {
            type: 'string',
            displayName: 'Time',
            default: null
        },
        avatar: {
            type: 'imageURL',
            displayName: 'Avatar',
            description: 'Optional avatar of the sender',
            default: null
        },
        maxWidth: {
            type: 'oneOf',
            displayName: 'Max Width',
            options: ['75%', '80%', '100%'],
            description: 'Maximum width of the message group',
            tile: '1/2',
            default: '80%'
        },
        position: {
            type: 'oneOf',
            displayName: 'Position',
            options: ['left', 'right'],
            description: 'Position of the message group',
            default: 'left',
            tile: '1/2'
        }
    }
}