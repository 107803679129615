import BaseColorPicker from "./BaseColorPicker";
import FunctionColorsPicker from "./FunctionColorsPicker";
import MainColorPicker from "./MainColorPicker";

export default function ColorPicker({ theme, setTheme, baseColorPresets, functionColorsPresets }) {
    
    function handleColorChange(colorsToUpdate) {
        
        const newTheme = {
            ...theme,
            colors: {
                ...theme.colors,
                ...colorsToUpdate
            }
        }
        setTheme(newTheme);
    }
    
    
    return (
        <div className='flex flex-col gap-2 w-full'>
        
        {/* Color Array */}
        <div className="flex flex-row gap-1 items-end text-xs w-full mb-4 justify-between">
        <div className="flex flex-row gap-0 text-xs w-full">
            <MainColorPicker theme={theme} onColorChange={handleColorChange} colorName={'primary'} />
            <MainColorPicker theme={theme} onColorChange={handleColorChange} colorName={'accent'}/>
            </div>
        <div className="flex flex-col gap-2 items-end">
            <BaseColorPicker baseColorPresets={baseColorPresets} setTheme={setTheme} theme={theme} functionColorsPresets={functionColorsPresets}/>
            <FunctionColorsPicker theme={theme} setTheme={setTheme} functionColorsPresets={theme.isDark ? functionColorsPresets.dark : functionColorsPresets.light} />
        </div>
        </div>
        
        {/* Optional Selector */}

        {/*        
        <span className='absolute top-1/2 -right-4 group-hover:translate-x-full translate-x-3/4 -translate-y-1/2 z-50 opacity-0 group-hover:opacity-100 transition-all 
        px-3 pl-1 py-1 flex gap-1 bg-white rounded-lg text-sm font-medium text-slate-800 shadow-sm'>
            <ArrowLeft className="scale-50" style={{strokeWidth: 3}} />manage colors
    </span>*/}
        </div>
    )
}




