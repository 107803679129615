import { MessageBubble, MessageGroup } from "ui-kit/exports/react";

export default function TestPage() {
  
  const avatarURL = "https://avatars.githubusercontent.com/u/1?v=4";
  return (
    <div className="w-screen h-screen flex items-center justify-center gap-4">
      <div className="grid grid-cols-2 gap-4">      
      <div className="flex flex-col gap-4 w-80 border p-5">
        <MessageGroup avatar={avatarURL} position="left" time="12:00">
          <MessageBubble bgColor="info" content="Hello, how can I help you today?"/>
          <MessageBubble bgColor="info" content="how are you feeling?"/>
        </MessageGroup>
        
        <MessageGroup position="right">
          <MessageBubble bgColor="base-100" content="Bye bye bye" position="right"/>
          <MessageBubble bgColor="base-100" isTyping position="right"/>
        </MessageGroup>
      </div>

      <div className="flex flex-col gap-4 w-80 border p-5">
        <MessageGroup avatar={avatarURL} position="left" time="12:00">
          <MessageBubble bgColor="info" content="Hello, how can I help you today?" tailStyle="mouse"  />
          <MessageBubble bgColor="info" content="how are you feeling?" tailStyle="mouse" />
        </MessageGroup>
        
        <MessageGroup position="right">
          <MessageBubble bgColor="base-100" content="Bye bye bye" position="right" tailStyle="mouse"/>
          <MessageBubble bgColor="base-100" isTyping position="right" tailStyle="mouse"/>
        </MessageGroup>
      </div>

      <div className="flex flex-col gap-4 w-80 border p-5">
        <MessageGroup avatar={avatarURL} position="left" time="12:00">
          <MessageBubble bgColor="info" content="Hello, how can I help you today?" tailStyle="simple"  />
          <MessageBubble bgColor="info" content="how are you feeling?" tailStyle="simple"  />
        </MessageGroup>
        
        <MessageGroup position="right">
          <MessageBubble bgColor="base-100" content="Bye bye bye" position="right" tailStyle="simple"  />
          <MessageBubble bgColor="base-100" isTyping position="right" ttailStyle="simple"  />
        </MessageGroup>
      </div>

      <div className="flex flex-col gap-4 w-80 border p-5">
        <MessageGroup avatar={avatarURL} position="left" time="12:00">
          <MessageBubble bgColor="info" content="Hello, how can I help you today?" tailStyle="bubble"  />
          <MessageBubble bgColor="info" content="how are you feeling?" tailStyle="bubble" />
        </MessageGroup>
        
        <MessageGroup position="right">
          <MessageBubble bgColor="base-100" content="Bye bye bye" position="right" tailStyle="bubble"/>
          <MessageBubble bgColor="base-100" isTyping position="right" tailStyle="bubble"/>
        </MessageGroup>
      </div>
      </div>
      
    </div>
  );
}