import { useEffect } from 'react';

export const usePanning = ({ ref, panState, setPanState, mode }) => {
  
  useEffect(() => {
    // console.log('canvas fit: ', panState?.fitCanvas)
    if (panState.fitCanvas) return // break if fitCanvas is true
    
    // console.log('panning active')
    const canvasNode = ref.current;

    const minimimZoom = 25
    const maximumZoom = 200
    const zoomSensitivity = 1; 
    const panSensitivity = 2;

    let pointerX;
    let pointerY;
    let movableScale = panState.zoom;
    let movableX = panState.translate.x;
    let movableY = panState.translate.y;
    
    function render() {
        setPanState((prevState) => ({
          ...prevState,
          translate: { x: Math.round(movableX), y: Math.round(movableY) },
          zoom: Math.round(movableScale)
        }));
      }

    const handleWheel = (event) => {
        event.preventDefault();
        if (event.metaKey || event.ctrlKey) {
          let _movableScale = movableScale - event.deltaY * zoomSensitivity;
          _movableScale = Math.max(_movableScale, minimimZoom);
          _movableScale = Math.min(_movableScale, maximumZoom);
          movableScale = _movableScale;
        } else {
          movableX -= event.deltaX * panSensitivity;
          movableY -= event.deltaY * panSensitivity;
        }
        render();
      };

    const handlePointerDown = (event) => {
      event.preventDefault();
      pointerX = event.clientX;
      pointerY = event.clientY;

      window.addEventListener("pointermove", handlePointerMove);
    };

    const handlePointerUp = (event) => {
      event.preventDefault();
      window.removeEventListener("pointermove", handlePointerMove);
    };

    const handlePointerMove = (event) => {
        event.preventDefault();
  
        if (event.metaKey) {
          let _movableScale = movableScale - (event.clientY - pointerY) * zoomSensitivity;
          _movableScale = Math.max(_movableScale, minimimZoom);
          _movableScale = Math.min(_movableScale, maximumZoom);
          movableScale = _movableScale;
        } else {
          const deltaX = event.clientX - pointerX;
          const deltaY = event.clientY - pointerY;
          pointerX = event.clientX;
          pointerY = event.clientY;
  
          movableX += deltaX * panSensitivity;
          movableY += deltaY * panSensitivity;
        }
        render();
      };

    canvasNode.addEventListener('wheel', handleWheel);
    if (mode === 'eye') {
        canvasNode.addEventListener("pointerdown", handlePointerDown);
        canvasNode.addEventListener("pointerup", handlePointerUp);
    }

    return () => {
      canvasNode.removeEventListener('wheel', handleWheel);
      canvasNode.removeEventListener("pointermove", handlePointerMove);

      if (mode === 'eye') {
        canvasNode.removeEventListener("pointerdown", handlePointerDown);
        canvasNode.removeEventListener("pointerup", handlePointerUp);
      }
    };
  }, [ref, panState, setPanState]);
};