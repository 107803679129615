import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList, ScatterChart, Scatter, ZAxis,} from 'recharts';
import { Icon } from '../';
  
const dummyData = [
{
    keys: ['x', 'y', 'z'],
    color: 'primary',
    shape: 'circle',
    values: [
    [1, 150, 400],
    [2, 190, 120],
    [3, 180, 140],
    [4, 210, 160],
    [5, 205, 180],
    [6, 220, 200],
    [7, 240, 220],
    ],
},
{
    keys: ['x', 'y', 'z'],
    color: 'accent',
    shape: 'circle',
    values: [
    [1, 130, 90],
    [2, 170, 110],
    [3, 160, 130],
    [4, 200, 150],
    [5, 195, 170],
    [6, 210, 190],
    [7, 230, 210],
    ],
},
] as Dataset[];

type DataPoint = {
[key: string]: string | number;
};

type Dataset = {
keys: string[];
values: (string | number)[][];
shape?: 'circle' | 'cross' | 'diamond' | 'square' | 'star' | 'triangle' | 'wye';
color?: string;
};
  
function transformData(keys: string[], values: (string | number)[][]): DataPoint[] {
return values.map((valueArray) => {
    const dataPoint: DataPoint = {};
    keys.forEach((key, index) => {
    dataPoint[key] = valueArray[index];
    });
    return dataPoint;
});
}

type ScatterPlotProps = {
    title?: string;
    width?: 'auto' | 'full' | '1/2';
    height?: '92px' | '120px' | '160px' | '200px' | '240px' | '360px' | '100%';
    showGrid?: boolean;
    showLabels?: boolean;
    showYAxis?: boolean;
    showXAxis?: boolean;
    corners?: 'none' | 'sm' | 'md' | 'lg';
    emptyState?: boolean;
    emptyMessage?: string;
    bottomDomain?: number | 'auto';
    topDomain?: number | 'auto';
    data?: Dataset[]; // Now accepts an array of datasets
    __juno?: any;
};
  
export default function ScatterPlotComponent({
    width = 'full',
    height = '120px',
    showLabels,
    showYAxis = true,
    showXAxis = true,
    showGrid,
    data = dummyData,
    bottomDomain = 'auto',
    topDomain = 'auto',
    emptyState,
    emptyMessage = 'Data may take up to 24 hrs to show',
    __juno = {},
}: ScatterPlotProps) {
    const widthStyles = `w-${width}`;
    const heightStyles = height === '100%' ? 'h-full' : `h-auto`;
    const classes = `flex flex-col items-stretch ${heightStyles} relative ${widthStyles}`;
  
    const emptyStyles =
      'flex flex-col justify-center items-center px-sm text-sm font-medium gap-2 rounded-md';
  
    const sideMargins = !showYAxis && !showLabels && !showXAxis ? 0 : 20;
  
    // Determine the range for ZAxis based on data
    const allZValues = data.flatMap((dataset) =>
      dataset.values.map((vals) => vals[dataset.keys.indexOf('z')] as number),
    );
    const minZ = Math.min(...allZValues);
    const maxZ = Math.max(...allZValues);
  
    return (
      <div
        className={`${classes} ${__juno?.outlineStyle || ''} ${__juno?.tagStyle || ''}`}
        {...__juno?.attributes}
      >
        {/* CHART */}
        {!emptyState && data?.length ? (
          <div
            className="flex flex-row"
            style={{
              width: '100%',
              pointerEvents: 'none',
              height: height,
            }}
          >
            <ResponsiveContainer width="100%" height="100%">
              <ScatterChart
                margin={{ top: 20, right: sideMargins, bottom: 0, left: sideMargins }}
              >
                {showGrid && <CartesianGrid strokeDasharray="1 3" />}
  
                <XAxis
                  type="number"
                  dataKey="x"
                  name="X"
                  hide={!showXAxis}
                  tick={{ fontSize: '12px' }}
                />
                <YAxis
                  type="number"
                  dataKey="y"
                  name="Y"
                  hide={!showYAxis}
                  tick={{ fontSize: '12px' }}
                  domain={[bottomDomain, topDomain]}
                />
                <ZAxis type="number" dataKey="z" range={[minZ, maxZ]} />
  
                <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                {data.map((dataset, index) => {
                  const transformedData = transformData(dataset.keys, dataset.values);
                  
                  return (
                    <Scatter
                      key={index}
                      name={`Data ${index + 1}`}
                      data={transformedData}
                      fill={`var(--${dataset.color || 'primary'})`}
                      shape={dataset?.shape || 'circle'}
                      isAnimationActive={false}
                      
                    >
                      {showLabels && (
                        <LabelList
                          dataKey={dataset.keys[1]}
                          position="top"
                          style={{ fill: `var(--${dataset.color || 'primary'})`, fontSize: 10 }}
                        />
                      )}
                    </Scatter>
                  );
                })}
              </ScatterChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <div
            className={emptyStyles}
            style={{
              height: height,
              backgroundColor: 'color-mix(in srgb, currentColor 8%, transparent)',
            }}
          >
            <Icon icon="chart-up" />
            <span className="font-normal text-sm">{emptyMessage}</span>
          </div>
        )}
      </div>
    );
}

