
import {v1 as uuidv1 } from 'uuid' // for everything else
import { convertJSXToObjectsSync } from '../../utilities/AIhelpers';
import { createNewIds } from '../../utilities/helpers';

export function prepareProject(userId, set, setName, projectName) {
  
  const project = {
    id: uuidv1(),
    name: projectName || 'New Project',
    userId: userId,
    dsId: '4cf707d6-317b-11ee-a595-ea12fce6e513',
    setName: setName == 'empty' ? 'betaPageEditor' : setName
  };

  let folderIndex = 1;
  let pageIndex = 1;
  let frameIndex = 1;
  const folders = [];
  const pages = [];
  const frames = [];
  const objects = []; // Global objects array

  for (const folderDetail of set.folders) {
    // Create a folder entry
    const folder = {
      id: uuidv1(),
      projectId: project.id,
      name: folderDetail.name, // Folder name
      index: folderIndex++
    };
    folders.push(folder);

    for (const flow of folderDetail.flows) {
      let frameIndex = 1; // Initialize frameIndex here for each flow

      // Create a page for each flow
      const page = {
        id: uuidv1(),
        projectId: project.id,
        name: flow.name, // Page name is the flow name
        index: pageIndex++,
        folderId: folder.id
      };
      pages.push(page);

      // Iterate over the frames in the flow
      for (const frameDetail of flow.frames) {
        // console.log(frameDetail)
        const frame = {
          id: uuidv1(),
          name: formatString(frameDetail.name)|| 'Untitled',
          pageId: page.id,
          notes: frameDetail.notes,
          thumbnail: frameDetail.thumbnail, 
          index: frameIndex++ // frameIndex will start from 1 for each flow
        };

        // Generate objects for this specific frame
        let frameObjects = convertJSXToObjectsSync(frameDetail.jsx); // Convert frame's JSX to objects
        
        frameObjects = frameObjects.map(o => ({
          ...o,
          parent: o.parent === 'rootObject' ? frame.id : o.parent,
          frame: frame.id
        }));
        objects.push(...frameObjects); // Add objects to the global array

        frames.push(frame);
      }
    }
  }
  
  const payload = {
    project: project,
    folders,
    pages,
    frames,
    objects // All objects are in this array
  };
  console.log(payload)
  // console.log(objects)
  return payload;
}

export function prepareProjectCopy(userId, project) {
  
  const projectObject = project.project
  
  const newProject = {
    id: uuidv1(),
    name: `${projectObject.name} (Copy)`, // Use project.name instead of project.id
    userId: userId,
    dsId: projectObject.ds, // Assuming dsId should come from the original project
    setName: projectObject.setName
  };

  const folders = [];
  const pages = [];
  const frames = [];
  const objects = [];

  // Assuming each folder has an array of pages as `pages`
  // and each page has an array of frames as `frames`
  for (const folderDetail of project.folders) {
    const newFolderId = uuidv1();
    folders.push({
      id: newFolderId,
      name: folderDetail.name,
      projectId: newProject.id,
      index: folderDetail.index // Assuming there's an index field
    });

    for (const pageDetail of folderDetail.pages) {
      const newPageId = uuidv1();
      pages.push({
        id: newPageId,
        name: pageDetail.name,
        index: pageDetail.index, // Assuming there's an index field
        projectId: newProject.id,
        folderId: newFolderId
      });

      for (const frameDetail of pageDetail.frames) {
        const newFrameId = uuidv1();
        frames.push({
          id: newFrameId,
          name: frameDetail.name,
          pageId: newPageId,
          index: frameDetail.index // Assuming there's an index field
        });
        
        let newObjects = [...frameDetail.objects]; // copy existing objects
    
        newObjects = createNewIds(newObjects, frameDetail.id, newFrameId); // create new Ids for these objects
        newObjects = newObjects.map(obj => ({...obj,frame: newFrameId})); // update frame property
        
        objects.push(...newObjects);
      }
    }
  }

  const payload = {
    project: newProject,
    folders,
    pages,
    frames, 
    objects
  };

  return payload;
}


export function prepareCustomSet(setOutline, pages) {
  // Clone the set outline to avoid modifying the original
  const customSet = JSON.parse(JSON.stringify(setOutline));

  customSet.folders.forEach(folder => {
    folder.flows.forEach(flow => {
      flow.frames = flow.pages.map(pageName => {
        // Find the page in the pages array
        const page = pages.find(p => p.name === pageName);

        // Return a frame object with the page name and JSX
        return {
          name: pageName,
          jsx: page ? page.jsx : null
        };
      });

      // Remove the original pages array as we have replaced it with frames
      delete flow.pages;
    });
  });

  return customSet;
}

function formatString(str) {
  // Replace underscores with ' - '
  let formattedStr = str.replace(/_/g, ' - ');

  // Add a space before capital letters, considering the newly added spaces from the previous step
  formattedStr = formattedStr.replace(/([A-Z])/g, ' $1');

  // Trim any leading space and capitalize the first letter
  formattedStr = formattedStr.trim();
  formattedStr = formattedStr.charAt(0).toUpperCase() + formattedStr.slice(1);

  return formattedStr;
}