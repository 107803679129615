import { getDescendants } from "../../../../../utilities/helpers";
import HtmlToReact from 'html-to-react';
import * as UIKit from '../../../../../ui-kit/local/index'

const htmlToReactParser = new HtmlToReact.Parser();


const HTMLElements = [
    'FlexBox', 'Grid', 'Form',
    'AppShell', 'Email',
    'Main', 'Header', 'Footer', 'Sidebar', 'FeaturePanel', 'IconBar', 'SidePanel', 'Hero', 
    'Heading', 'Link', 'Text', 'Paragraph'
]

const containers = [
  
  'FlexBox', 'Grid', 'Form',
  'IconBar', 'SidePanel', 'FeaturePanel', 'Sidebar', 
]

const textElements = [
  'Heading', 'Link', 'Text', 'Paragraph'
]




export function generateNode({object, indentation = 0, descendants = []}) {
    const tag = object.componentAPIName;
    const propsString = generateProps(object.object_props || {}, object.mobile_props);
    const indent = '  '.repeat(indentation);

    let childObjects = getChildren(object.id, descendants).sort((a, b) => a.index - b.index);

    if (childObjects.length === 0) {
      return `${indent}<${tag}${propsString ? ` ${propsString}` : ''} />\n`;
    } else {
      let nodeHTML = `${indent}<${tag}${propsString ? ` ${propsString}` : ''}>\n`;

      for (let child of childObjects) {
        nodeHTML += generateNode({object: child, indentation: indentation + 1, descendants});
      }

      nodeHTML += `${indent}</${tag}>\n`;
      return nodeHTML;
    }
  }

export function prepareHybridJSX(selector) {
    const frameObjects = selector.frame.objects || [];
    
    const selectedObject = selector.object ? selector.object : frameObjects.length > 0 ? frameObjects.find(obj => obj.parent == obj.frame) : null;
    
    function generateNode(object, indentation = 0) {
      const tag = object.componentAPIName;
      const propsString = generateProps(object.object_props || {}, object.mobile_props);
      const indent = '  '.repeat(indentation);
  
      let childObjects = getChildren(object.id, frameObjects).sort((a, b) => a.index - b.index);
      const htmlElements = [...textElements, ...containers];
      const isHTMLString = htmlElements.includes(tag);
      
      const htmlElement = isHTMLString ? makeHtmlString(object.id) : null


      const useHTMLString = isHTMLString && htmlElement;
      const htmlString = htmlElement?.wrapperHTML || '';
      
      
      const findComponentIgnoreCase = (tag, moduleObj) => {
        const normalizedComponentName = tag.toLowerCase();
        const componentNameKey = Object.keys(moduleObj)
          .find(key => key.toLowerCase() === normalizedComponentName);
      
        return moduleObj[componentNameKey];
      };
      const Component = findComponentIgnoreCase(tag, UIKit) || null;  
      const definitions = Component?.definitions
      
      if (!definitions || !Component) return '';
      const defaultProps = getDefaultProps(definitions)
      

      if (textElements.includes(tag)) {
        const defaultText = defaultProps.text || ``
        let returnString = `${indent}${htmlString}\n`; // get the html string
        const closingTag = `</${htmlElement?.htmlTag}>`; // get the closing tag
        returnString = returnString.slice(0, -closingTag.length - 1) + '\n' // remove the closing tag
        returnString = returnString + `${indent}${object.object_props.text || defaultText}\n`; // add the text
        returnString += `${indent}${closingTag}\n`; // add the closing tag
        
        // useDefault prop where text is undefined / null
        return returnString


      } else if (childObjects.length === 0) {
        const returnString = useHTMLString ? 
            `${indent}${htmlString}\n` : 
            `${indent}<${tag}${propsString ? ` ${propsString}` : ''} />\n`;
        return returnString
      } else {
        let returnString = useHTMLString ? 
            `${indent}${htmlString}\n` : 
            `${indent}<${tag}${propsString ? ` ${propsString}` : ''}>\n`;
        
        const closingTag = useHTMLString ? `</${htmlElement?.htmlTag}>` : `</${tag}>`;
        // tag == 'Main' && console.log(returnString);
        returnString = useHTMLString ? returnString.slice(0, -closingTag.length - 1) + '\n' : returnString;
        // tag == 'Main' && console.log(returnString);

        for (let child of childObjects) {
            returnString += generateNode(child, indentation + 1);
        }
  
        returnString += `${indent}${closingTag}\n`;
        return returnString;
      }
    }
  
    if (!selectedObject) {
      return '';
    } else {
      return generateNode(selectedObject);
    }
  }

  
function makeHtmlString(elementId) {
  // Get the element by ID
  
  let element = document.getElementById(`canvas-`+elementId);

  if (element) {
      
      // Clone the element to avoid modifying the original one
      let clone = element.cloneNode(true); 
      
      // Remove children
      while (clone.firstChild) {
          clone.removeChild(clone.firstChild);
      }

      // Remove ID attribute
      clone.removeAttribute('id');
      clone.removeAttribute('draggable');
      clone.removeAttribute('data-tag');
      clone.removeAttribute('target');
      clone.removeAttribute('rel');

      // Get the outerHTML of the element
      let htmlString = clone.outerHTML

      const reactElement = htmlToReactParser.parse(htmlString);

        // Convert React element to JSX string
      let jsxString = reactElementToJSXString(reactElement);
      
      // Convert the HTML string to a React element
      // const jsxString = htmlToJsx(htmlString);
      // console.log(jsxString);

      const result = {
          wrapperHTML: jsxString.replace(/@/g, ''), // removes @ from the string
          htmlTag: clone.tagName.toLowerCase(), // Tag name in lower case
      };

      // console.log(result);
      // Return the result object
      return result;
  }
  
  return null;
}

function reactElementToJSXString(element) {
  if (typeof element === 'string' || typeof element === 'number') {
      return element.toString();
  }

  const { type, props } = element;

  let children = '';

  if (props.children) {
      if (Array.isArray(props.children)) {
          children = props.children.map(child => reactElementToJSXString(child)).join('');
      } else {
          children = reactElementToJSXString(props.children);
      }
  }

  const tagName = typeof type === 'function' ? type.name : type;

  let propsString = '';

  for (const [key, value] of Object.entries(props)) {
      if (key === 'children') continue;
      if (key === 'className') {

        const junoClasses = ['selected-tag-above', 'selected-tag-inside', 'selected-outline', 'hovered-outline', 'dotted-outline']
        
        let cleanedValue = value;
        junoClasses.forEach(junoClass => {const regex = new RegExp(`\\b${junoClass}\\b`, 'g'); cleanedValue = cleanedValue.replace(regex, '');});
        cleanedValue = cleanedValue.replace(/\s+/g, ' ').trim();
        
        propsString += ` className="${cleanedValue}"`;

      } else if (key === 'style') {
          const styleObject = Object.entries(value)
              .map(([styleKey, styleValue]) => {
                  const camelCaseKey = styleKey.replace(/-([a-z])/g, (match, p1) => p1.toUpperCase());
                  const formattedStyleValue = typeof styleValue === 'string' ? `'${styleValue}'` : styleValue;
                  return `${camelCaseKey}: ${formattedStyleValue}`;
              })
              .join(', ');
          propsString += ` style={{ ${styleObject} }}`;
      } else if (typeof value === 'boolean' && value) {
          propsString += ` ${key}`;
      } else {
          propsString += ` ${key}="${value}"`;
      }
  }

  return `<${tagName}${propsString}>${children}</${tagName}>`;
}

function generateProps(objectProps, mobileProps) {
  let propsArray = Object.entries(objectProps).map(([key, value]) => {
    // If the key is also present in mobileProps, generate a conditional prop
    if (mobileProps && mobileProps.hasOwnProperty(key)) {
      const mobileValue = mobileProps[key];
      const formattedMobileValue =
        typeof mobileValue === "string"
          ? `"${mobileValue}"`
          : JSON.stringify(mobileValue);

      const formattedValue =
        typeof value === "string" ? `"${value}"` : JSON.stringify(value);

      return `${key}={isMobile ? ${formattedMobileValue} : ${formattedValue}}`;
    }

    // Otherwise, format the prop normally
    if (typeof value === "string") {
      return `${key}="${value}"`;
    } else if (typeof value === "boolean") {
      return value ? key : '';
    } else if (typeof value === "number") {
      return `${key}={${value}}`;
    } else if (typeof value === "object") {
      const jsonString = JSON.stringify(value);
      return `${key}={${jsonString}}`;
    } else {
      return `${key}={${JSON.stringify(value)}}`;
    }
  }).filter(Boolean);

  // Add props for mobileProps that are not in objectProps
  if (mobileProps) {
    const additionalMobilePropsArray = Object.entries(mobileProps)
      .filter(([key]) => !objectProps.hasOwnProperty(key))
      .map(([key, value]) => {
        if (typeof value === "string") {
          return `${key}={isMobile ? "${value}" : undefined}`;
        } else if (typeof value === "boolean") {
          return value ? `${key}={isMobile ? true : undefined}` : '';
        } else if (typeof value === "number") {
          return `${key}={isMobile ? ${value} : undefined}`;
        } else {
          return `${key}={isMobile ? ${JSON.stringify(value)} : undefined}`;
        }
      });

    propsArray = propsArray.concat(additionalMobilePropsArray);
  }
  // console.log(propsArray.join(' '))
  return propsArray.length > 2 ? propsArray.join('\n  ') : propsArray.join(' ');
}


function generatePropsOld(objectProps, mobileProps) {
    let propsArray = Object.entries(objectProps).map(([key, value]) => {
      
      if (typeof value === "string") {
        return `${key}="${value}"`;
      } else if (typeof value === "boolean") {
        return value ? key : '';
      } else if (typeof value === "number") {
        return `${key}={${value}}`;
      } else if (typeof value === "object") {
        const jsonString = JSON.stringify(value);
        // console.log(jsonString)
        const escapedString = jsonString
            //.replace(/\\/g, '\\\\') // Escape backslashes first
            //.replace(/"/g, '\\"'); // Escape double quotes
        
        return `${key}={${escapedString}}`;
      } else {
        
        return `${key}={${JSON.stringify(value)}}`;
      }
    }).filter(Boolean);

    if (mobileProps) {
      const mobilePropsArray = Object.entries(mobileProps).map(([key, value]) => {
        if (typeof value === "string") {
          return `mob_${key}="${value}"`;
        } else if (typeof value === "boolean") {
          return value ? `mob_${key}` : '';
        } else if (typeof value === "number") {
          return `mob_${key}={${value}}`;
        } else {
          return `mob_${key}={${JSON.stringify(value)}}`;
        }
      });
      propsArray = propsArray.concat(mobilePropsArray);
    }

    console.log(propsArray)
    if (propsArray.length > 2) {
      return propsArray.join('\n  ');
    } else {
      return propsArray.join(' ');
    }
  }

function getChildren(parentId, frameObjects) {
  return frameObjects.filter(obj => obj.parent === parentId);
}
  

export function makeImportString(selector, hydrid=false) {
    const frameObjects = selector.frame?.objects || [];
    const selectedObject = selector.object ? selector.object : frameObjects.length > 0 ? frameObjects.find(obj => obj.parent == obj.frame) : null;
    
    const descendants = selectedObject ? getDescendants(selectedObject?.id, frameObjects) : [];
    
    const componentList = !selectedObject ? [] : [...descendants.map(obj => obj.componentAPIName), selectedObject?.componentAPIName];
    
    // exclude hybrid components & make unique
    let uniqueComponents = []
    if (hydrid) {
        const excludeGroup = [...containers, ...textElements];
        uniqueComponents = [...new Set(componentList.filter(component => !excludeGroup.includes(component)))];
    } else {
        uniqueComponents = [...new Set(componentList)];
    }

    let importString = 
    uniqueComponents.length > 0 ?
    `import { ${uniqueComponents.join(', ')} } from 'junokit';`
    : '';
  
    return importString
  }
export function listUniqueComponents(selector) {
    const frameObjects = selector.frame?.objects || [];
    const selectedObject = selector.object ? selector.object : frameObjects.length > 0 ? frameObjects.find(obj => obj.parent == obj.frame) : null;
    
    const descendants = selectedObject ? getDescendants(selectedObject?.id, frameObjects) : [];
    
    const componentList = !selectedObject ? [] : [...descendants.map(obj => obj.componentAPIName), selectedObject?.componentAPIName];
    
    // exclude hybrid components & make unique
    let uniqueComponents = [...new Set(componentList)];

    return uniqueComponents

}


  const getDefaultProps = (definitions) => {
        
    return Object.keys(definitions?.propDefinitions)?.reduce((acc, key) => {
        acc[key] = definitions?.propDefinitions[key]?.default;
        
        return acc;
    }, {});
};