import React from 'react';
import { ArrowLeft } from 'iconoir-react';

export default function CornersPicker({
    theme, 
    setTheme,
    cornersPresets
}) {
    
    const currentSetName = theme.cornersSet || 'base';
    

    function handleSelect(setName) {
        setTheme({
            ...theme,
            cornersSet: setName,
            corners: {
                ...cornersPresets[setName] // Update the corners with the selected set's values
            }
        });
    }


    return (
        
        <div className='w-full flex flex-row justify-start gap-3 group relative'>

            {Object.keys(cornersPresets).map((setName) => (
            <div className={`flex flex-col h-14 flex-grow items-start relative overflow-hidden border
            hover:scale-110 transition-all rounded-md 
            
            ${currentSetName === setName ? 'bg-slate-100 border-slate-400 !bg-base-100 !border-primary' : 'bg-slate-100 bg-base-100 !bg-base-100 border-transparent hover:border-slate-400 hover!boder-base-400'}
            `}
            key={setName} 
            onClick={()=>handleSelect(setName)}
            >
            <div
                className={`absolute w-full h-full cursor-pointer transition-all w-full left-1/3 top-1/2 border 
                ${currentSetName === setName ? ' bg-slate-400 border-slate-500 !bg-primary !border-primary-focus' : 'bg-slate-300 border-slate-400 !bg-base-300 !border-base-400'}
                `}
                style={{borderRadius: cornersPresets[setName]['border-radius-lg']}}
                >
            </div>
            </div>
        ))}

        <span className='absolute top-1/2 -right-4 group-hover:translate-x-full translate-x-3/4 -translate-y-1/2 z-50 opacity-0 group-hover:opacity-100 transition-all 
        px-3 pl-1 py-1 flex gap-1 rounded-lg text-sm font-medium shadow-sm
        bg-white text-slate-800 !bg-base-0 !text-base-content'>
            <ArrowLeft className="scale-50" style={{strokeWidth: 3}} /> {currentSetName} corners
        </span>
        </div>
    );
}