type InstanceProps = {
    rootObject: string,
    objects: any [];
    __juno?: any;

}
export default function Instance({
    rootObject,
    objects = [],
    __juno = {},
}: InstanceProps) {

  // get the rootObject
  // attach listeners and attributes to the root object
  // render children with Router but no 

  return (
    <div
      className={`${__juno?.outlineStyle} ${__juno?.tagStyle}`}
      {...__juno?.attributes}
    >
      <h1>Instance</h1>
    </div>
  );
}

/* ignore rest */ 
Instance.definitions = {
    apiName: 'Instance',
    displayName: 'Instance',
    description: '',
    ai_instructions: '',
    type: 'system',
    relativeSize: 'any',
    acceptedChildren: 'none',
    status: 'prototype',
    package: 'Base',
    propDefinitions: {}
};
