import { ButtonIcon } from "ui-kit/exports/react"
import { prepareHybridJSX } from "../inspect/code/helpers"
import { useState } from "react"

export default function PropsCode({ selector }) {
    const modifiedSelector = {...selector, frame: {...selector.frame, objects: [selector.object]}}
    const propsJSX = prepareHybridJSX(modifiedSelector)

    const [copied, setCopied] = useState(false)
    function handleCopy() {
        navigator.clipboard.writeText(propsJSX)
        setCopied(true)
        setTimeout(() => setCopied(false), 2000)
        
    }

    return (
        
        <ButtonIcon
        size={'small'} 
        icon={'code'} 
        style="light" 
        onClick={handleCopy}
        />
            
    )
    }


    /*export default function PropsCode({ selector }) {
    const modifiedSelector = {...selector, frame: {...selector.frame, objects: [selector.object]}}
    const propsJSX = prepareHybridJSX(modifiedSelector)
    return (
        <>
        <MiniSnippet
                whiteSpace={'pre'}
                text={propsJSX}
            />
            </>
    )
    }
*/