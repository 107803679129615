import * as objectsDB from "../../api/ObjectsAPI";
import * as foldersDB from "../../api/FoldersAPI";
import * as framesDB from "../../api/FramesAPI";
import * as pagesDB from "../../api/pagesAPI";
import * as templatesDB from "../../api/templatesAPI";
import * as themesDB from "../../api/ThemesAPI";
import * as projectsDB from "../../api/projectsAPI";
import * as UIKit from '../../ui-kit/local/index'

import { nanoid } from 'nanoid'; // for objects
import {v1 as uuidv1 } from 'uuid' // for everything else

import { getDescendants } from "../../utilities/helpers";
import * as helpers from "../../utilities/helpers";
import {  act, useState } from 'react'; 
import cloneDeep from 'lodash/cloneDeep';
import { debounce } from 'lodash'
import cleanUpObjectProps from "./objectCleanUp";
import { convertJSXToObjectsSync } from "../../utilities/AIhelpers";

const idCount = 10

export function useExecute(token) {
  const [ history, setHistory ] = useState({
    past: [],
    future: []
  });
  

const emptyFrame = `
<AppShell pageBackground="base-50">
<Main gap="24px" width="1200" corners="md" marginX="12px" marginY="12px" paddingX="24px" paddingY="24px" textSize="base" direction="flex-col" selfAlign="center" alignItems="start" background="base-0" justifyContent="start" />
</AppShell>`

return async function (project, selector, designSystem, action) {
  
  // We first define our future variables, copy them, but we'll manipulate them
  let newProject = {...project};
  let newSelector = {...selector};
  let newDesignSystem = {...designSystem};
  let newHistory = {past: [], future:[]}
  // console.log(action)
  
  let isUndo = false
  let isRedo = false
  
  if (action.type === 'UNDO') {
    // Ensure there is an action to undo
    if (history.past.length === 0) {
      console.log('nothing to undo');
    } else {
      // GET THE ACTION FROM HISTORY
      const lastAction = history.past[history.past.length - 1];
      action = lastAction; // Set the last action in history as the current action to undo
      // console.log('undo action', action)
      isUndo = true
    }
  } else if (action.type === 'REDO') {
    // Ensure there is an action to redo
    if (history.future.length === 0) {
      console.log('nothing to redo');
    } else {
      const nextAction = history.future[history.future.length - 1];
      action = nextAction; 
      isRedo = true
      console.log('redo action', action)
    }
  }

  // DONE
  if (action.type === 'DELETE_OBJECT') { 
    // GET OBJECT AND ITS CURRENT FRAME
    const object = action.object
    const keepSelector = action.keepSelector
    
    
    
    const frame = action.frame ? action.frame :
      project.pages.reduce((acc, page) => {
        const foundFrame = page.frames.find(frame => frame.objects.some(obj => obj.id === object.id))
        return foundFrame ? foundFrame : acc;
      }, null);
      
    const newObjects = helpers.removeObject(object, frame.objects);
    
    const databaseUpdate = helpers.getChanges(frame.objects, newObjects)
    
    databaseUpdate.remove.length > 0 && objectsDB.remove(databaseUpdate.remove, token)
    databaseUpdate.update.length > 0 && objectsDB.update(databaseUpdate.update, token)

    const newFrame = { ...frame, objects: newObjects };
      const pageToUpdate = project.pages.find((p) => p.id === newFrame.page_id);
      const newFrames = pageToUpdate.frames.map((f) =>f.id === newFrame.id ? newFrame : f);
      const newPage = { ...pageToUpdate, frames: newFrames };
      const newPages = project.pages.map((p) =>p.id === newPage.id ? newPage : p);  
      
      const objectsToUpdate = databaseUpdate.update || []
      const objectsToRemove = databaseUpdate.remove || []
      
      newProject = { ...project, pages: newPages };
      
      const objectSelect = objectsToRemove.map(obj => obj.id)?.includes(selector.object?.id) ? null : selector.object
      
      newSelector = { ...selector, page: newPage, frame: {...newFrame}, object: objectSelect,  style: null}
      newDesignSystem = newDesignSystem ? JSON.parse(JSON.stringify(newDesignSystem)) : designSystem;
      
      
      /* REVERSE ACTION - INSERT_OBJECT
      When we delete an object, we also delete its descendants & update its siblings (index)
      Reverse action in this case is insert_object. We need to force all extra params */
      // requires action.object {}, optional: action.parent {}, action.index INT, action.descendants [], action.frame {}
      
      const reverseAction = cloneDeep({
        type: 'INSERT_OBJECT',
        object: object, 
        parent: object.parent == object.frame ? newFrame : frame.objects.find(o => o.id == object.parent), 
        index: object.index, 
        descendants: getDescendants(object.id, frame.objects),
        frame: newFrame // the insert frame would have been updated.
      })
      // console.log(reverseAction)

      if (isUndo) {
        // If we're undoing something, add reverse action to redo list
        newHistory = {
          past: history.past.slice(0, history.past.length - 1), // remove last action
          future: [...history.future, reverseAction] // add reverse Action to future array
        };
        
      } else if (isRedo) {
        // If we're redoing something, add reverse action to past and remove the first action from future
        newHistory = {
          past: [...history.past, reverseAction],
          future: history.future.slice(0, -1) // remove last item (it saves in reverse)
        };
      } else {
        // Normal action -> just add it to past actions
        newHistory = {
          past: [...history.past, reverseAction],
          future: [] // Clear the future actions
        };
      }
      
      setHistory(newHistory);
      
  }

  if (action.type === 'DELETE_OBJECTS') { 
    // requires just an array of objects
    const frame = selector.frame
    const frameObjects = frame.objects
    let updatedObjects = [...frameObjects];
    
    action.objects.forEach(objectToDelete => {
      updatedObjects = helpers.removeObject(objectToDelete, updatedObjects);
  });
    
    const databaseUpdate = helpers.getChanges(frameObjects, updatedObjects);
    
    databaseUpdate.remove.length > 0 && objectsDB.remove(databaseUpdate.remove, token);
    databaseUpdate.update.length > 0 && objectsDB.update(databaseUpdate.update, token);
  
    const newFrame = { ...frame, objects: updatedObjects };
    const pageToUpdate = project.pages.find((p) => p.id === newFrame.page_id);
  
    const newFrames = pageToUpdate.frames.map((f) =>f.id === newFrame.id ? newFrame : f);
    const newPage = { ...pageToUpdate, frames: newFrames };
    const newPages = project.pages.map((p) =>p.id === newPage.id ? newPage : p);
    
    newProject = { ...project, pages: newPages };

    newSelector = {
        ...selector, 
        page: newPage, 
        frame: { ...newFrame }
      };
    
      
    newDesignSystem = designSystem ? JSON.parse(JSON.stringify(designSystem)) : designSystem;
  
    const parent = newFrame.objects.find(obj => obj.id == databaseUpdate.remove[0].parent)
    const index = newFrame.objects.find(obj => obj.id == databaseUpdate.remove[0].index)
    
    // REVERSE ACTION + HISTORY
    const reverseAction = cloneDeep({
      type: 'INSERT_OBJECTS',
      objects: databaseUpdate.remove,
      frame: newFrame,
      parent, 
      index
    })
    
    // STANDARD HISTORY THING

    if (action.skipHistory) {
      // If skipHistory is true, keep the current history unchanged
      newHistory = { ...history };
    } else if (isUndo) {
      // If we're undoing something, add reverse action to redo list
      newHistory = {
        past: history.past.slice(0, history.past.length - 1), // remove last action
        future: [...history.future, reverseAction] // add reverse Action to future array
      };
    } else if (isRedo) {
      // If we're redoing something, add reverse action to past and remove the first action from future
      newHistory = {
        past: [...history.past, reverseAction],
        future: history.future.slice(0, -1) // remove last item (it saves in reverse)
      };
    } else {
      // Normal action -> just add it to past actions
      newHistory = {
        past: [...history.past, reverseAction],
        future: [] // Clear the future actions
      };
    }
    
    setHistory(newHistory);

  }  
  
  // DONE
  else if (action.type === 'INSERT_OBJECT') {
    
    // requires action.object {}, 
      // optional: action.parent {} or will use selector, 
      // action.index INT or will append
      // action.descendants or will assume none [], 
      // action.frame {}, will use selector?
    // inserts object, will create NEW IDS if useId != true
    
    // can also have newSelect
    const newSelect = action.newSelect
    const keepSelector = action.keepSelector
    let newObject = {...action.object};

    
    // Assuming UIKit and propDefinitions are defined and accessible in this scope
    
    
    
    const Component = findComponentIgnoreCase(newObject.componentAPIName, UIKit);
    
    
    const definitions = Component?.definitions;
    const propDefinitions = definitions.propDefinitions;

    // Clean up newObject properties
    // cleanUpObjectProps(null, newObject, propDefinitions);
    
    // find the frame we're adding into
    const frame = action.frame ? action.frame : selector.frame // if provided, use it

    // TOO VERBOSE, move this logic to props
    let parent = action.parent // if provided
      ? action.parent // use provided parent
      : selector.object // otherwise - if an object is selected, paste as its child
        ? selector.object.id != newObject.id // but make sure we're not inserting into itself 
          ? selector.object // if we're not inserting into itself, insert into the selected object
          : frame.objects.find(obj => selector.object.parent == obj.id) // if we are find the parent of movingObject within objects
            ? frame.objects.find(obj => selector.object.parent == obj.id) // and insert into it
            : frame // if we can't find a parent in the objects, the parent is the frame, select newFrame
        : frame // insert into frame if no object is selected
    
    /*const dialogObjects = ['Modal', 'Popover', 'Banner','Toast', 'Drawer'] // we always add these on top of Frame
    parent = dialogObjects.includes(newObject.componentAPIName) ? frame : parent*/
    
    const siblings = frame.objects.filter((o) => o.parent == parent.id); // sharing the same parent
    const index = action.index ? action.index : siblings.length + 1 // if provided use it, otherwise append to the end
    
    newObject = {
      ...newObject, 
      id: action.useId ? newObject.id : nanoid(idCount),
      index: index,
      frame: frame.id, 
      parent: parent.id,
    };
    
  
    // CREATE NEW IDS FOR DESCENDANTS AND UPDATE FRAME
    let descendants = action.descendants ? action.descendants : [] 
    
    let newDescendants = action.useId ? [...descendants] : createNewIds(descendants, action.object?.id, newObject?.id) 
    
    newDescendants = newDescendants.map(obj => ({...obj, frame: frame.id, component_id: parent.component_id}))
    // UPDATE FRAME: insert new objects, shift siblings, add descendants
    const updatedObjects = helpers.insertObject(newObject, frame.objects).concat(newDescendants);
    // console.log(updatedObjects)
    const updatedFrame = { ...frame, objects: updatedObjects };
    const page = project.pages.find((p) => p.id == frame.page_id);
    const updatedPage = { ...page, frames: page.frames.map((f) => (f.id == updatedFrame.id ? updatedFrame : f)) };
    const updatedPages = project.pages.map((p) => p.id == updatedPage.id ? updatedPage : p);
    
    // DATABASE: add new objects and update siblings
    const databaseUpdate = helpers.getChanges(frame.objects, updatedFrame.objects)
    
    // console.log(databaseUpdate)
    databaseUpdate.update.length > 0 && objectsDB.update(databaseUpdate.update, token);
    databaseUpdate.add.length > 0 && objectsDB.add(databaseUpdate.add, token);
    

    
    newSelector = {
        ...selector,
        page: updatedPage, // contains new frame
        frame: updatedFrame, // contains new objects
        object: keepSelector ? newSelector.object : newSelect ? newSelect : parent.type != 'frame' ? parent : null,
        style: null
    } 
    newProject = { ...project, pages: updatedPages };
    newDesignSystem = newDesignSystem ? JSON.parse(JSON.stringify(newDesignSystem)) : designSystem;
      
    /* REVERSE ACTION - DELETE_OBJECT
    // requires action.object {} */
      const reverseAction = cloneDeep({
        type: 'DELETE_OBJECT',
        object: newObject, 
        frame: updatedFrame
      })
      
      // STANDARD HISTORY THING
      if (isUndo) {
        // If we're undoing something, add reverse action to redo list
        newHistory = {
          past: history.past.slice(0, history.past.length - 1), // remove last action
          future: [...history.future, reverseAction] // add reverse Action to future array
        };
      } else if (isRedo) {
        // If we're redoing something, add reverse action to past and remove the first action from future
        newHistory = {
          past: [...history.past, reverseAction],
          future: history.future.slice(0, -1) // remove last item (it saves in reverse)
        };
      } else {
        // Normal action -> just add it to past actions
        newHistory = {
          past: [...history.past, reverseAction],
          future: [] // Clear the future actions
        };
      }
      setHistory(newHistory);
  }
  
  else if (action.type === 'INSERT_OBJECTS') {
    // frame & parent MUST BE PROVIDED
    // find the frame we're adding into
    
    const frame = selector.frame
    const parent = frame.id == action.parentId ? frame : frame.objects.find(obj => obj.id == action.parentId)

    const siblings = frame.objects.filter((o) => o.parent == parent.id); // sharing the same parent
    const index = action.index ? action.index : siblings.length + 1
    let updatedObjects = cloneDeep(frame.objects);

    for (let obj of action.objects) {
    let newObject = {
        ...obj,
        id: nanoid(idCount),
        index: index,
        frame: frame.id,
        parent: parent.id
    };

    // Handle descendants
    let newDescendants = [];
    if (obj.descendants) {
        newDescendants = createNewIds(obj.descendants, obj.id, newObject.id);
        newDescendants = newDescendants.map(descendant => ({
            ...descendant,
            frame: frame.id
        }));
    }

    updatedObjects = helpers.insertObject(newObject, updatedObjects).concat(newDescendants);
    }
    
    console.log('updated objects',updatedObjects)
    const updatedFrame = { ...frame, objects: updatedObjects };    
    const page = project.pages.find((p) => p.id == frame.page_id);
    const updatedPage = { ...page, frames: page.frames.map((f) => (f.id == updatedFrame.id ? updatedFrame : f)) };
    const updatedPages = project.pages.map((p) => p.id == updatedPage.id ? updatedPage : p);
    
    const databaseUpdate = helpers.getChanges(frame.objects, updatedObjects)  
    // console.log('db update',databaseUpdate)

    databaseUpdate.update.length > 0 && objectsDB.update(databaseUpdate.update, token);
    databaseUpdate.add.length > 0 && objectsDB.add(databaseUpdate.add, token);

    newProject = { ...project, pages: updatedPages };
    const newObjectSelect = !selector?.object || selector?.object == null ? null : updatedFrame.objects.find(obj => obj.id == selector?.object?.id)
  
    newSelector = {
        ...selector,
        page: updatedPage, // contains new frame
        frame: updatedFrame, 
        object: newObjectSelect
    } 
    
    newDesignSystem = newDesignSystem ? JSON.parse(JSON.stringify(newDesignSystem)) : designSystem;

    // REVERSE ACTION + HISTORY
    const reverseAction = cloneDeep({
      type: 'DELETE_OBJECTS',
      objects: databaseUpdate.add
    })
    
    // STANDARD HISTORY THING

    if (action.skipHistory) {
      // If skipHistory is true, keep the current history unchanged
      newHistory = { ...history };
    } else if (isUndo) {
      // If we're undoing something, add reverse action to redo list
      newHistory = {
        past: history.past.slice(0, history.past.length - 1), // remove last action
        future: [...history.future, reverseAction] // add reverse Action to future array
      };
    } else if (isRedo) {
      // If we're redoing something, add reverse action to past and remove the first action from future
      newHistory = {
        past: [...history.past, reverseAction],
        future: history.future.slice(0, -1) // remove last item (it saves in reverse)
      };
    } else {
      // Normal action -> just add it to past actions
      newHistory = {
        past: [...history.past, reverseAction],
        future: [] // Clear the future actions
      };
    }
    
    setHistory(newHistory);
  }

  // DONE
  else if (action.type === 'APPEND_OBJECT') {
    // console.log('appending an object', action.object, 'to', action.object.parent);
    if (selector.object || selector.frame ) {
    
    // prepare the object
    let newObject = {...action.object};

    // Assuming UIKit and propDefinitions are defined and accessible in this scope
    
    const Component = findComponentIgnoreCase(newObject.componentAPIName, UIKit);
    
    const definitions = Component?.definitions;
    const propDefinitions = definitions.propDefinitions;

    // Clean up newObject properties
    // cleanUpObjectProps(null, newObject, propDefinitions);

    // find the frame we're adding into
    const frame = selector.frame
    const parent = selector.object // if object is selected, paste as child
      ? selector.object.id == newObject.id // but make sure we're not inserting into itself 
        ? frame.objects.find(obj => selector.object.parent == obj.id) // and if we are find the parent
          ? frame.objects.find(obj => selector.object.parent == obj.id) // and insert into it
          : frame // unless there is no parent, then insert into frame
        : selector.object // if we're not inserting into itself, insert into the selected object
      : frame // or frame if no object is selected
    
    const siblings = frame.objects.filter((o) => o.parent == parent.id); // sharing the same parent
    
    newObject = {
        ...newObject, 
        id: nanoid(idCount),
        index: siblings.length + 1, 
        frame: frame.id, 
        parent: parent.id
    };

    const updatedFrame = { ...frame, objects: [...frame.objects, newObject] };
    const page = project.pages.find((p) => p.id == frame.page_id);
    const updatedPage = { ...page, frames: page.frames.map((f) => (f.id == updatedFrame.id ? updatedFrame : f)) };
    
    const updatedPages = project.pages.map((p) => p.id == updatedPage.id ? updatedPage : p);

    // add to database
    objectsDB.add(newObject, token)
    

    newProject = { ...project, pages: updatedPages };
    
    // console.log(newObject) 
    
    // not as obvious
    newSelector = {
      ...selector,
        page: updatedPage, 
        frame: updatedFrame, 
        object: parent.type == 'object' ? parent : null,
        style: null
    };

    newDesignSystem = newDesignSystem ? JSON.parse(JSON.stringify(newDesignSystem)) : designSystem;
      
    /* REVERSE ACTION - DELETE_OBJECT
    // requires action.object {} */
    const reverseAction = cloneDeep({
      type: 'DELETE_OBJECT',
      object: newObject, 
      frame: updatedFrame
    })
    
    // STANDARD HISTORY THING
    if (isUndo) {
      // If we're undoing something, add reverse action to redo list
      newHistory = {
        past: history.past.slice(0, history.past.length - 1), // remove last action
        future: [...history.future, reverseAction] // add reverse Action to future array
      };
    } else if (isRedo) {
      // If we're redoing something, add reverse action to past and remove the first action from future
      newHistory = {
        past: [...history.past, reverseAction],
        future: history.future.slice(0, -1) // remove last item (it saves in reverse)
      };
    } else {
      // Normal action -> just add it to past actions
      newHistory = {
        past: [...history.past, reverseAction],
        future: [] // Clear the future actions
      };
    }
    setHistory(newHistory);    
    }
  }

  // DONE
  else if (action.type === 'RELOCATE_OBJECT') {
    // takes action.object {}, action.newParent {}, action.newIndex INT, action.newFrame {} or ID
    // if no newParent or newIndex provided, assumes they are in selector
    // handles descendants
    const cantRelocate = ['Main', 'AppShell', 'Email']
    // OBJECT 
    const movingObject = action.object
    
    if (cantRelocate.includes(movingObject.componentAPIName)) {
      return
    }
    
    // FROM WHERE
    const currentFrame = project.pages.reduce((acc, page) => {
      const frame = page.frames.find(frame => frame.objects.some(obj => obj.id === movingObject.id))
      return frame ? frame : acc
    }, null)
    
    const descendants = getDescendants(movingObject.id, currentFrame.objects) // ALSO MOVING DESCENDANTS
    const currentParent = movingObject.parent == currentFrame.id ? currentFrame : currentFrame.objects.find(o => o.id == movingObject.parent)
    const currentSiblings = currentFrame.objects.filter((o) => o.parent == movingObject.parent)
    const currentPage = project.pages.find(page => page.id == currentFrame.page_id)

    // TO WHERE: parameters can be provided in action (DragNDrop) or assumed from selector (e.g. CTRL+X -> CTRL+V)
    const newFrame = action.newFrame ? action.newFrame : selector.frame
    const newParent = action.newParent // if provided, use it
      ? action.newParent // use provided parent
      : selector.object // otherwise - if an object is selected, paste as its child
        ? selector.object.id != movingObject.id // but make sure we're not inserting into itself 
          ? selector.object // if we're not inserting into itself, insert into the selected object
          : newFrame.objects.find(obj => selector.object.parent == obj.id) // if we are find the parent of movingObject within objects
            ? newFrame.objects.find(obj => selector.object.parent == obj.id) // and insert into it
            : newFrame // if we can't find a parent in the objects, the parent is the frame, select newFrame
        : newFrame // insert into frame if no object is selected
    
    let newSiblings = newFrame.objects.filter((o) => o.parent == newParent.id)
    // newSiblings.sort((a, b) => a.index - b.index);
    // newSiblings.forEach((sibling, idx) => sibling.index = idx + 1);

    const newIndex = action.newIndex 
      ? action.newIndex //if provided, use it
      : newParent.id == movingObject.parent // otherwise, append to the end. check if the move is within the same parent
        ? newSiblings.length 
        : newSiblings.length + 1 
    
    // UPDATE OBJECT PARAMS
    const updatedObject = {...movingObject, parent: newParent.id, index: newIndex, frame: newFrame.id}
    
    // WITHIN THE SAME FRAME? -> REMOVE AND INSERT WITHIN ONE FRAME AND RETURN UPDATED FRAME
    if (newFrame.id == currentFrame.id) {
      let newObjects = []
      
      newObjects = helpers.removeObject(movingObject, currentFrame.objects) // REMOVE IT
      
      newObjects = helpers.insertObject(updatedObject, newObjects) // INSERT IT

      const updatedFrame = {...currentFrame, objects: newObjects.concat(descendants)}
      const updatedPage = {...currentPage, frames: currentPage.frames.map(frame => frame.id == updatedFrame.id ? updatedFrame : frame)}
      const updatedPages = project.pages.map((p) => p.id == updatedPage.id ? updatedPage : p);

      // UPDATE DATABASE: OBJECT AND SIBLINGS
      const databaseUpdate = helpers.getChanges(currentFrame.objects, updatedFrame.objects); // need only affected components to minimize API load

      !action.localUpdate && databaseUpdate.update.length > 0 && objectsDB.update(databaseUpdate.update, token);
      
      
      newProject = { ...project, pages: updatedPages };

      newSelector = {
        ...selector,
        page: updatedPage, // contains new frame
        frame: updatedFrame, // contains new objects
        object: updatedObject,
        style: null
      }

      
      /* REVERSE ACTION
      // requires action.object {} */
      const reverseAction = cloneDeep({
        type: 'RELOCATE_OBJECT',
        object: updatedObject,
        newParent: currentParent, 
        newIndex: movingObject.index,
        newFrame: currentFrame
      })
      
      // STANDARD HISTORY THING
      if (isUndo) {
        // If we're undoing something, add reverse action to redo list
        newHistory = {
          past: history.past.slice(0, history.past.length - 1), // remove last action
          future: [...history.future, reverseAction] // add reverse Action to future array
        };
      } else if (isRedo) {
        // If we're redoing something, add reverse action to past and remove the first action from future
        newHistory = {
          past: [...history.past, reverseAction],
          future: history.future.slice(0, -1) // remove last item (it saves in reverse)
        };
      } else {
        // Normal action -> just add it to past actions
        newHistory = {
          past: [...history.past, reverseAction],
          future: [] // Clear the future actions
        };
      }
      setHistory(newHistory);    

    } 
    // MOVING FRAMES -> REMOVE AND INSERT 
    else {      
      
      const oldObjects = currentFrame.objects.concat(newFrame.objects)
      
      // DOM UPDATE
      currentFrame.objects = helpers.removeObject(movingObject, currentFrame.objects) // remove from current frame
      newFrame.objects = helpers.insertObject(updatedObject, newFrame.objects) // insert updatedObject into new frame
      const updatedDescendants = descendants.map(desc => ({...desc, frame: newFrame.id}))
      newFrame.objects = newFrame.objects.concat(updatedDescendants) // they will not interfere with the siblings so we can simply append them
      
      const newObjects = currentFrame.objects.concat(newFrame.objects)
    
      // DATABASE UPDATE
      const databaseUpdate = helpers.getChanges(oldObjects, newObjects); // need only affected components to minimize API load
      databaseUpdate.update.length > 0 && objectsDB.update(databaseUpdate.update, token);
      
      console.log('updates', databaseUpdate)

      // UPDATE PAGE 
      // TODO: handle moving objects between pages
      
      const updatedPage = {
        ...currentPage, 
        frames: currentPage.frames.map((frame) => 
          frame.id == newFrame.id 
            ? newFrame 
            : frame.id == currentFrame.id
              ? currentFrame
              : frame
        )}
        
      const updatedPages = project.pages.map((p) => p.id == updatedPage.id ? updatedPage : p);
      newProject = { ...project, pages: updatedPages };
      
      newSelector = {
        ...selector,
        page: updatedPage, // contains new frame
        frame: newFrame, // contains new objects
        object: newParent.type == 'object' ? newParent : null,
        style: null
      }

    }
    /* REVERSE ACTION
      // requires action.object {} */
      const reverseAction = cloneDeep({
        type: 'RELOCATE_OBJECT',
        object: updatedObject,
        newParent: currentParent, 
        newIndex: movingObject.index,
        newFrame: currentFrame
      })
      
      // STANDARD HISTORY THING
      if (isUndo) {
        // If we're undoing something, add reverse action to redo list
        newHistory = {
          past: history.past.slice(0, history.past.length - 1), // remove last action
          future: [...history.future, reverseAction] // add reverse Action to future array
        };
      } else if (isRedo) {
        // If we're redoing something, add reverse action to past and remove the first action from future
        newHistory = {
          past: [...history.past, reverseAction],
          future: history.future.slice(0, -1) // remove last item (it saves in reverse)
        };
      } else {
        // Normal action -> just add it to past actions
        newHistory = {
          past: [...history.past, reverseAction],
          future: [] // Clear the future actions
        };
      }
      setHistory(newHistory);
  }
  
  // DONE
  else if (action.type === 'REPLACE_OBJECT') {

    // Requires action.oldObject, action.newObject, action.oldDescendants, action.newDescendants
    
    // VARIABLES
    const oldObject = action.oldObject;
      //console.log('Old Object:', oldObject);

    const newObject = { ...action.newObject, 
        frame: oldObject.frame, 
        parent: oldObject.parent, 
        index: oldObject.index 
      };

      //console.log('New Object:', newObject);

      const oldDescendants = action.oldDescendants || [];
      //console.log('Old Descendants:', oldDescendants);

      const newDescendants = action.newDescendants ? action.newDescendants.map(desc => ({ ...desc, frame: oldObject.frame })) : [];
      //console.log('New Descendants:', newDescendants);


    // GET THE FRAME
    const frame = project.pages.flatMap(p => p.frames).find(f => f.id === oldObject.frame);
    // console.log('old frame', frame)
    const objectsToRemove = [oldObject, ...oldDescendants]
    const objectsToAdd = [newObject, ...newDescendants].map(object => {
      return {
        ...object,
        draggable: true,
        mediaprops: object.mediaprops ? object.mediaprops : null,
        instanceprops: object.instanceprops ? object.instanceprops : null,
      };
    });
    
    // UPDATE THE OBJECTS IN THE FRAME
    const updatedFrame = {
      ...frame,
      objects: frame.objects
        .filter(obj => !objectsToRemove.map(obj => obj.id).includes(obj.id)) // Remove old objects
        .concat(objectsToAdd) // Add new objects
    };

    // console.log('updated frame', updatedFrame)

    const page = project.pages.find((p) => p.id == updatedFrame.page_id);
    const updatedPage = { ...page, frames: page.frames.map((f) => (f.id == updatedFrame.id ? updatedFrame : f)) };
    const updatedPages = project.pages.map((p) => p.id == updatedPage.id ? updatedPage : p);
    // UPDATE DATABASE
    
    objectsToRemove.length > 0 && objectsDB.remove(objectsToRemove, token);
    objectsToAdd.length > 0 && objectsDB.add(objectsToAdd, token);
  
    newProject = { ...project, pages: updatedPages };
  
    newSelector = {
      ...selector,
        page: updatedPage, // contains new frame
        frame: updatedFrame, // contains new objects
        object: newObject,
        style: null
    }


    /* REVERSE ACTION - DELETE_OBJECT
    // requires action.object {} */
    const reverseAction = cloneDeep({
      type: 'REPLACE_OBJECT',
      oldObject: newObject,
      oldDescendants: newDescendants,
      newObject: oldObject,
      newDescendants: oldDescendants
    })
    
    // STANDARD HISTORY THING
    if (isUndo) {
      // If we're undoing something, add reverse action to redo list
      newHistory = {
        past: history.past.slice(0, history.past.length - 1), // remove last action
        future: [...history.future, reverseAction] // add reverse Action to future array
      };
    } else if (isRedo) {
      // If we're redoing something, add reverse action to past and remove the first action from future
      newHistory = {
        past: [...history.past, reverseAction],
        future: history.future.slice(0, -1) // remove last item (it saves in reverse)
      };
    } else {
      // Normal action -> just add it to past actions
      newHistory = {
        past: [...history.past, reverseAction],
        future: [] // Clear the future actions
      };
    }
    setHistory(newHistory);    
  }
  
  // DONE
  else if (action.type === 'REPLACE_FRAME_OBJECTS') {
    // requires currentFrame (including objects array) and newFrame (including objects array)
    const currentFrame = action.currentFrame
    const objectsToDelete = currentFrame.objects || []
    
    const newFrame = action.newFrame
    const newObjects = newFrame.objects || []

    const page = project.pages.find((p) => p.id == newFrame.page_id); 
    const updatedPage = { ...page, frames: page.frames.map((f) => (f.id == newFrame.id ? newFrame : f)) };
    const updatedPages = project.pages.map((p) => p.id == updatedPage.id ? updatedPage : p);

    // DB UPDATES
    // framesDB.update(newFrame) 
    objectsToDelete.length > 0 && objectsDB.remove(objectsToDelete, token);
    newObjects.length > 0 && objectsDB.add(newObjects, token)
    console.log(newObjects)

    // update DOM
    newProject = { ...project, pages: updatedPages };
    newSelector = {
      ...selector,
      page: updatedPage, // contains updated frame
      frame: newFrame, // contains new objects
      object: null,
      style: null
    } 

    newDesignSystem = newDesignSystem ? JSON.parse(JSON.stringify(newDesignSystem)) : designSystem;

    
    /* REVERSE ACTION
      // requires action.object {} */
      const reverseAction = cloneDeep({
        type: 'REPLACE_FRAME_OBJECTS',
        currentFrame: newFrame, 
        newFrame: currentFrame
      })
      
      // STANDARD HISTORY THING
      if (isUndo) {
        // If we're undoing something, add reverse action to redo list
        newHistory = {
          past: history.past.slice(0, history.past.length - 1), // remove last action
          future: [...history.future, reverseAction] // add reverse Action to future array
        };
      } else if (isRedo) {
        // If we're redoing something, add reverse action to past and remove the first action from future
        newHistory = {
          past: [...history.past, reverseAction],
          future: history.future.slice(0, -1) // remove last item (it saves in reverse)
        };
      } else {
        // Normal action -> just add it to past actions
        newHistory = {
          past: [...history.past, reverseAction],
          future: [] // Clear the future actions
        };
      }
      setHistory(newHistory);
  }
  
  // DONE
  else if (action.type === 'UPDATE_OBJECT') {
    // takes in new object in action.newObject and action.currentObject (for reverse)
    const currentObject = {...action.currentObject}
    let newObject = {...action.newObject};

    // Get component definitions
    const Component = findComponentIgnoreCase(newObject.componentAPIName, UIKit);
    const definitions = Component?.definitions;
    const propDefinitions = definitions.propDefinitions;
    
    // Clean up new object properties
    // cleanUpObjectProps(currentObject, newObject, propDefinitions);

    // Use the cleaned new object as updatedObject
    const updatedObject = newObject;

    // console.log(project.pages)
    const frame = project.pages.reduce((acc, page) => {
        const frame = page.frames.find(frame => frame.objects.some(obj => obj.id === updatedObject.id))
        return frame ? frame : acc
      }, null)
    
    const updatedFrame = {...frame, objects: frame.objects.map(obj => obj.id === updatedObject.id ? updatedObject : obj)}
    const page = project.pages.find(page => page.id === frame.page_id)
    const updatedPage = {...page, frames: page.frames.map(f => f.id === updatedFrame.id ? updatedFrame : f)}


    // update database 
    const debouncedUpdate = debounce((updatedObject) => {
      objectsDB.update(updatedObject, token);
    }, 300);
    
    debouncedUpdate(updatedObject);

    // send new data to editor
    newProject = {...project, 
      pages: project.pages.map(p => p.id === updatedPage.id ? updatedPage : p), 
    }
    // console.log(updatedObject)
    
    newSelector = {
        ...selector,
        page: updatedPage,
        frame: updatedFrame,
        object: selector?.object?.id === currentObject.id ? updatedObject : selector.object
    };
  
    
    // requires action.object {} */
    const reverseAction = cloneDeep({
      type: 'UPDATE_OBJECT',
      currentObject: updatedObject,
      newObject: currentObject
    })
    
    newDesignSystem = newDesignSystem ? JSON.parse(JSON.stringify(newDesignSystem)) : designSystem;

    if (action.skipHistory) {
      // If skipHistory is true, keep the current history unchanged
      newHistory = { ...history };
      // console.log(newHistory)
    } else if (isUndo) {
      // If we're undoing something, add reverse action to redo list
      newHistory = {
        past: history.past.slice(0, history.past.length - 1), // remove last action
        future: [...history.future, reverseAction] // add reverse Action to future array
      };
    } else if (isRedo) {
      // If we're redoing something, add reverse action to past and remove the first action from future
      newHistory = {
        past: [...history.past, reverseAction],
        future: history.future.slice(0, -1) // remove last item (it saves in reverse)
      };
    } else {
      // Normal action -> just add it to past actions
      newHistory = {
        past: [...history.past, reverseAction],
        future: [] // Clear the future actions
      };
    }
    
    setHistory(newHistory);
  }

  // DONE
  else if (action.type === 'UPDATE_FRAME') {
    // requires currentFrame {} and newFrame {}
    const oldFrame = {...action.currentFrame};
    
    const updatedFrame = {...action.newFrame};
    const page = project.pages.find(page => page.id === updatedFrame.page_id)
    //console.log('pages', project.pages)
    const updatedPage = {...page, frames: page.frames.map(f => f.id === updatedFrame.id ? updatedFrame : f)}
    if (updatedFrame.name === '') {
      updatedFrame.name = 'Untitled Frame';
  }
    // update database
    framesDB.update(updatedFrame, token);

    // send new data to editor
    newProject = {...project, pages: project.pages.map(p => p.id === updatedPage.id ? updatedPage : p)}
    
    
    newSelector = {
        ...selector,
        page: updatedPage, 
        frame: selector.frame.id === updatedFrame.id ? updatedFrame : selector.frame
    }
    
    // console.log(newSelector)
    newDesignSystem = newDesignSystem ? JSON.parse(JSON.stringify(newDesignSystem)) : designSystem;



    /* REVERSE ACTION
    // requires action.object {} */
      const reverseAction = cloneDeep({
        type: 'UPDATE_FRAME',
        currentFrame: updatedFrame,
        newFrame: oldFrame
      })
      
      // STANDARD HISTORY THING
      if (isUndo) {
        // If we're undoing something, add reverse action to redo list
        newHistory = {
          past: history.past.slice(0, history.past.length - 1), // remove last action
          future: [...history.future, reverseAction] // add reverse Action to future array
        };
      } else if (isRedo) {
        // If we're redoing something, add reverse action to past and remove the first action from future
        newHistory = {
          past: [...history.past, reverseAction],
          future: history.future.slice(0, -1) // remove last item (it saves in reverse)
        };
      } else {
        // Normal action -> just add it to past actions
        newHistory = {
          past: [...history.past, reverseAction],
          future: [] // Clear the future actions
        };
      }
      setHistory(newHistory); 
  } 
  
  else if (action.type === 'RELOCATE_FRAME') {
    // requires currentFrame {} and newFrame {}
    const oldFrame = {...action.currentFrame};
    const updatedFrame = {...action.newFrame};

    const currentPage = project.pages.find(p => p.id === oldFrame.page_id);
    const newPage = project.pages.find(p => p.id === updatedFrame.page_id);

    if (currentPage.id === newPage.id) {
        let currentSiblings = currentPage.frames || [];
        console.log('same parent');

        // Prepare a list of updated siblings
        // Increment or decrement index depending on whether the frame was moved up or down
        let updatedSiblings = currentSiblings.map(sibling => {
            if (sibling.index > oldFrame.index && sibling.index <= updatedFrame.index) {
                return { ...sibling, index: sibling.index - 1 };
            } else if (sibling.index < oldFrame.index && sibling.index >= updatedFrame.index) {
                return { ...sibling, index: sibling.index + 1 };
            }
            return sibling;
        });

        updatedSiblings = updatedSiblings.map(sibling => sibling.id === updatedFrame.id ? updatedFrame : sibling);

        // Sort frames by index and reassign indexes to avoid duplicates
        updatedSiblings.sort((a, b) => a.index - b.index);
        updatedSiblings = updatedSiblings.map((sibling, idx) => ({ ...sibling, index: idx + 1}));

        const updatedPage = {...currentPage, frames: updatedSiblings};

        // Update the frame and its siblings in the database
        framesDB.update([updatedFrame, ...updatedSiblings], token);
        const selectFolder = project.folders.find(f => f.id === updatedPage.folder_id);

        // Update the project state
        newProject = {...project, pages: project.pages.map(p => p.id === updatedPage.id ? updatedPage : p)};
        newSelector = {
            ...selector,
            folder: selectFolder,
            page: updatedPage,
            frame: updatedFrame,
            object: null,
            style: null
        };
      } else {
        // console.log('from', currentPage.id, 'to', newPage.id);
    
        // REMOVE FROM CURRENT PAGE
        const currenSiblings = currentPage.frames.filter(f => f.id !== oldFrame.id);
    
        // Update indices for current page frames
        const updatedCurrentSiblings = currenSiblings.map(sibling =>
            sibling.index > oldFrame.index
                ? { ...sibling, index: sibling.index - 1 }
                : sibling
        );
    
        // Sort and re-index current page siblings
        updatedCurrentSiblings.sort((a, b) => a.index - b.index);
        updatedCurrentSiblings.forEach((sibling, idx) => sibling.index = idx + 1);
    
        // Get frames of new page
        const newSiblings = newPage.frames || [];
        const updatedNewSiblings = [
            ...newSiblings.map(sibling =>
                sibling.index >= updatedFrame.index
                    ? { ...sibling, index: sibling.index + 1 }
                    : sibling
            ),
            {...updatedFrame, index: updatedFrame.index} // Ensure updatedFrame is included with its new index
        ];

        // Sort and re-index new page siblings
        updatedNewSiblings.sort((a, b) => a.index - b.index);
        updatedNewSiblings.forEach((sibling, idx) => sibling.index = idx + 1);
        
        const framesToUpdate = [...updatedCurrentSiblings, ...updatedNewSiblings];
        // console.log(framesToUpdate)
        framesDB.update(framesToUpdate, token); // make all updates
    
        const updatedCurrentPage = {...currentPage, frames: updatedCurrentSiblings};
        const updatedNewPage = {...newPage, frames: updatedNewSiblings};
        const selectFolder = project.folders.find(f => f.id === updatedNewPage.folder_id); // select new folder
        
        newProject = {
            ...project,
            pages: project.pages.map(p => 
                p.id === updatedCurrentPage.id ? updatedCurrentPage : 
                p.id === updatedNewPage.id ? updatedNewPage : 
                p
            )
        };
    
        newSelector = {
            ...selector,
            folder: selectFolder,
            page: updatedNewPage,
            frame: updatedFrame,
            object: null,
            style: null
        };
    }
    
    
    // ADD LOGIC TO UPDATE SIBLINGS
      /* REVERSE ACTION
      // requires action.object {} */
      const reverseAction = cloneDeep({
        type: 'RELOCATE_FRAME',
        currentFrame: updatedFrame,
        newFrame: oldFrame
      });
  
      
      // STANDARD HISTORY THING
      if (isUndo) {
        // If we're undoing something, add reverse action to redo list
        newHistory = {
          past: history.past.slice(0, history.past.length - 1), // remove last action
          future: [...history.future, reverseAction] // add reverse Action to future array
        };
      } else if (isRedo) {
        // If we're redoing something, add reverse action to past and remove the first action from future
        newHistory = {
          past: [...history.past, reverseAction],
          future: history.future.slice(0, -1) // remove last item (it saves in reverse)
        };
      } else {
        // Normal action -> just add it to past actions
        newHistory = {
          past: [...history.past, reverseAction],
          future: [] // Clear the future actions
        };
      }
      setHistory(newHistory); 

  } 


  // DONE
  else if (action.type === 'INSERT_FRAME') {
    // needs frame {} that should contain its own id, page_id and index + objects [] prepared for DB insertion (all objects have correct frame id)
    
    
    let newFrame = {...action.frame}
    const useProject = action.currentProject || project
    
    //console.log('inserting frame', newFrame);
    const currentPage = useProject.pages.find(p => p.id === newFrame.page_id)
    
    const currentSiblings = currentPage.frames || []
    
    if (newFrame.index == null) { newFrame.index = currentSiblings.length + 1} 

    const updatedSiblings = currentSiblings.map(sibling =>
      sibling.index >= action.frame.index
        ? { ...sibling, index: sibling.index + 1 }
        : sibling
    );
    
    const siblingsToUpdate = currentSiblings
      .filter(sibling => sibling.index >= action.frame.index)
      .map(sibling => ({ ...sibling, index: sibling.index + 1 }));
    
    let objectsToAdd = newFrame.objects || []
    // if no objects provided we use default layout - appshell_main
    if (objectsToAdd.length == 0 && !action.isEmpty) {
      const jsx = emptyFrame
      
      if (!jsx) return
      const objs = convertJSXToObjectsSync(jsx) || [];
      let rootObject = objs.find((obj) => obj.parent === "rootObject");
      rootObject = { ...rootObject, frame: newFrame.id, parent: newFrame.id, index: 1 };
      let descendants = objs.filter((obj) => obj.id !== rootObject.id) || [];
      descendants = descendants.map((desc) => ({ ...desc, frame: newFrame.id }));
      objectsToAdd = [rootObject, ...descendants];
      newFrame.objects = objectsToAdd
    }

    const newFrames = [...updatedSiblings, newFrame]
    const updatedPage = {...currentPage, frames: newFrames}
    
    framesDB.add(newFrame, token) // add frame
    siblingsToUpdate.length > 0 && framesDB.update(siblingsToUpdate, token) // update its siblings (if any got affected)
    objectsToAdd.length > 0 && objectsDB.add(objectsToAdd, token) // add objects
  
    newProject = {...useProject, pages: useProject.pages.map(p => p.id === updatedPage.id ? updatedPage : p)}
    
    newSelector = {
        ...selector,
        folder: useProject.folders.find(f => f.id == updatedPage.folder_id),
        page: updatedPage, 
        frame: newFrames.find(f => f.id == newFrame.id),
        object: null, 
        style: null
    }

    
  
    const reverseAction = cloneDeep({
      type: 'DELETE_FRAME',
      frame: newFrame,
    })
    // STANDARD HISTORY THING
    if (isUndo) {
      // If we're undoing something, add reverse action to redo list
      newHistory = {
        past: history.past.slice(0, history.past.length - 1), // remove last action
        future: [...history.future, reverseAction] // add reverse Action to future array
      };
    } else if (isRedo) {
      // If we're redoing something, add reverse action to past and remove the first action from future
      newHistory = {
        past: [...history.past, reverseAction],
        future: history.future.slice(0, -1) // remove last item (it saves in reverse)
      };
    } else {
      // Normal action -> just add it to past actions
      newHistory = {
        past: [...history.past, reverseAction],
        future: [] // Clear the future actions
      };
    }
    setHistory(newHistory); 
  } 
  else if (action.type === 'APPEND_FRAME') {
    // needs frame {} that should contain its own id, page_id and index + objects [] prepared for DB insertion (all objects have correct frame id)
    
    let newFrame = {...action.frame}
    const useProject = action.currentProject || project
    const currentPage = useProject.pages.find(p => p.id === newFrame.page_id)
    
    const currentSiblings = currentPage.frames || []
    
    newFrame.index = currentSiblings.length + 1
    
    const newFrames = [...currentSiblings, newFrame]
    const updatedPage = {...currentPage, frames: newFrames}
    let objectsToAdd = newFrame.objects
    // if no objects provided we use default layout - appshell_main
    if (objectsToAdd.length == 0 && !action.isEmpty) {
      const jsx = emptyFrame
      
      if (!jsx) return
      const objs = convertJSXToObjectsSync(jsx) || [];
      let rootObject = objs.find((obj) => obj.parent === "rootObject");
      rootObject = { ...rootObject, frame: newFrame.id, parent: newFrame.id, index: 1 };
      let descendants = objs.filter((obj) => obj.id !== rootObject.id) || [];
      descendants = descendants.map((desc) => ({ ...desc, frame: newFrame.id }));
      objectsToAdd = [rootObject, ...descendants];
      newFrame.objects = objectsToAdd
    }

    framesDB.add(newFrame, token) // add frame
    objectsToAdd.length > 0 && objectsDB.add(objectsToAdd, token) // add objects
  
    newProject = {...useProject, pages: useProject.pages.map(p => p.id === updatedPage.id ? updatedPage : p)}
    
    newSelector = {
      ...selector,
        folder: useProject.folders.find(f => f.id == updatedPage.folder_id),
        page: updatedPage, 
        frame: newFrame,
        object: null, 
        style: null
    }}

  else if (action.type === 'INSERT_FRAMES') {
    // This action expects an array of frames in action.frames

    const newFrames = [...action.frames];
    const currentPage = project.pages.find(p => p.id === newFrames[0].page_id); // Assuming all frames belong to the same page
    const currentSiblings = currentPage.frames || [];

    // Adjust indices for existing frames
    const updatedSiblings = currentSiblings.map(sibling => {
        const framesBeforeThis = newFrames.filter(newFrame => newFrame.index <= sibling.index).length;
        return { ...sibling, index: sibling.index + framesBeforeThis };
    });

    // Identify siblings that need to be updated in the database
    const siblingsToUpdate = updatedSiblings.filter(sibling => {
        const originalSibling = currentSiblings.find(s => s.id === sibling.id);
        return originalSibling.index !== sibling.index;
    });

    // Extract all objects from the new frames
    const objectsToAdd = newFrames.flatMap(frame => frame.objects);

    // Merge the new frames with the updated siblings
    const allFrames = [...updatedSiblings, ...newFrames];

    const updatedPage = { ...currentPage, frames: allFrames };

    framesDB.add(newFrames, token); // Add all new frames
    siblingsToUpdate.length > 0 && framesDB.update(siblingsToUpdate, token); // Update affected siblings
    objectsToAdd.length > 0 && objectsDB.add(objectsToAdd, token); // Add all new objects

    newProject = { ...project, pages: project.pages.map(p => p.id === updatedPage.id ? updatedPage : p) };
    newSelector = {
      ...selector,
        page: updatedPage,
        frame: allFrames.find(f => f.index === newFrames[0].index), // Select the first new frame
        object: null,
        style: null
    };
    /* NEEDS REVERSE DELETE FRAMES
    const reverseActions = newFrames.map(frame => ({
        type: 'DELETE_FRAME',
        frame: frame
    }));

    // STANDARD HISTORY THING
    if (isUndo) {
        newHistory = {
            past: history.past.slice(0, history.past.length - reverseActions.length),
            future: [...history.future, ...reverseActions]
        };
    } else if (isRedo) {
        newHistory = {
            past: [...history.past, ...reverseActions],
            future: history.future.slice(0, -reverseActions.length)
        };
    } else {
        newHistory = {
            past: [...history.past, ...reverseActions],
            future: [] // Clear the future actions
        };
    }
    setHistory(newHistory);*/
  }
  
  // DONE
  else if (action.type === 'DELETE_FRAME') {
    // needs action.frame
    
    //console.log('deleting frame', action.frame.id);
    const frameToDelete = {...action.frame}
    const autoSelect = action.autoSelect || false

    const currentPage = project.pages.find(p => p.id === frameToDelete.page_id)
    const currentSiblings = currentPage.frames.filter(f => f.id != frameToDelete.id)
    
    //console.log('current siblings', currenSiblings)
    // Update indices of siblings (if we remove one frame all consecutive frames get index - 1)
    const updatedSiblings = currentSiblings.map(sibling =>
        sibling.index > frameToDelete.index
            ? { ...sibling, index: sibling.index - 1 }
            : sibling
    );
    
    // Re-sort and re-index the siblings
    updatedSiblings.sort((a, b) => a.index - b.index);
    updatedSiblings.forEach((sibling, idx) => sibling.index = idx + 1);
    
    const updatedPage = {...currentPage, frames: updatedSiblings};
    
    framesDB.remove(frameToDelete, token); // remove frame
    framesDB.update(updatedSiblings, token);

    newProject = {...project, pages: project.pages.map(p => p.id === updatedPage.id ? updatedPage : p)};

    const selectFrame = autoSelect ? 
      updatedSiblings.find(f => f.index === frameToDelete.index) || updatedSiblings.find(f => f.index === frameToDelete.index - 1)
      : null
    
    newSelector = {
        ...selector,
        page: updatedPage, 
        frame: autoSelect && selectFrame || null, // Select the last frame or null if none
        object: null, 
        style: null
    };

    const reverseAction = cloneDeep({
      type: 'INSERT_FRAME',
      frame: frameToDelete,
    })
    
    // STANDARD HISTORY THING
    if (isUndo) {
      // If we're undoing something, add reverse action to redo list
      newHistory = {
        past: history.past.slice(0, history.past.length - 1), // remove last action
        future: [...history.future, reverseAction] // add reverse Action to future array
      };
    } else if (isRedo) {
      // If we're redoing something, add reverse action to past and remove the first action from future
      newHistory = {
        past: [...history.past, reverseAction],
        future: history.future.slice(0, -1) // remove last item (it saves in reverse)
      };
    } else {
      // Normal action -> just add it to past actions
      newHistory = {
        past: [...history.past, reverseAction],
        future: [] // Clear the future actions
      };
    }
    setHistory(newHistory); 
  }


  // DONE
  else if (action.type === 'UPDATE_PAGE') {
    // requires currentPage {} and newPage {}
    // cannot update page index via this function, use relocate page
    const oldPage = {...action.currentPage};
  
    const updatedPage = {...action.newPage};
    const updatedPages = project.pages.map((p) => p.id == updatedPage.id ? updatedPage : p );
  
    // Update database, debounced
    pagesDB.update(updatedPage, token)
    
  
    let newSelectedPage = updatedPage;
    if (updatedPage.is_archived) {
      const currentIndex = project.pages.findIndex(p => p.id === oldPage.id);
      // Try to select the next page in the list
      newSelectedPage = project.pages[currentIndex + 1] || 
                        // If there is no next page, try to select the previous one
                        project.pages[currentIndex - 1] || 
                        // If there are no pages left, set to null
                        null;
    }

    // Update the state with the new project data
    newProject = {...project,pages: updatedPages};
    newSelector = {...newSelector, page: newSelectedPage || null, frame: newSelectedPage?.frames[0] || null, object: null};
    
  
    // REVERSE ACTION
    // To revert the update when needed, e.g., for undo functionality
    const reverseAction = {
      type: 'UPDATE_PAGE',
      currentPage: updatedPage,
      newPage: oldPage
    };
  
    // STANDARD HISTORY THING
    let newHistory;
    if (isUndo) {
      newHistory = {
        past: history.past.slice(0, history.past.length - 1), // remove last action
        future: [...history.future, reverseAction] // add reverse Action to future
      };
    } else if (isRedo) {
      newHistory = {
        past: [...history.past, reverseAction],
        future: history.future.slice(1) // remove the first action
      };
    } else {
      newHistory = {
        past: [...history.past, reverseAction],
        future: [] // Clear the future actions if it's a new action
      };
    }
    setHistory(newHistory);
  }

  // NEEDS REVERSE ACTION
  else if (action.type === 'APPEND_PAGE') {
    const folder_id = action.folder_id || null
    const name = action.name || 'New Feature'
    const newPage = {
      id: uuidv1(),
      project: project.project.id,
      name,
      index: project.pages.length + 1, 
      folder_id: folder_id,
      frames: []
    };
    
    const updatedPages = [...project.pages, newPage];
 
    pagesDB.add(newPage, token);
    newProject = {...project, pages: updatedPages};
  
    // REVERSE ACTION
    // DELETE PAGE
  }
  
  else if (action.type === 'INSERT_PAGE') {
    
    // ADD NEW PAGE
    let newPage = action.newPage
    const useProject = action.currentProject || project
    const newIndex = newPage.index != null ? newPage.index : useProject.pages.length + 1
    newPage.index = newIndex
    pagesDB.add(newPage, token);

    let projectPages = [...useProject.pages]
    // RE-INDEX SIBLINGS
    let siblings = [...projectPages].filter(p => p.folder_id == newPage.folder_id) || []
    siblings = siblings.map(sibling => 
      sibling.index >= newIndex
        ? { ...sibling, index: sibling.index + 1 } 
        : sibling
    );

    // Update the database
    pagesDB.update(siblings, token); // update indices of affected frames

    let updatedPages = useProject.pages.map(page => {
      const updatedSibling = siblings.find(s => s.id === page.id);
      return updatedSibling || page;
    });
    updatedPages.push(newPage);
    // Sort the array based on index
    updatedPages.sort((a, b) => a.index - b.index);
    
    // need re-index function
    const currentFolder = project.folders.find(f => f.id === newPage.folder_id)
    const updatedFolder = {...currentFolder, pages: updatedPages}
    const updatedFolders = project.folders.map(f => f.id === updatedFolder.id ? updatedFolder : f)
    newProject = {...useProject, 
      pages: updatedPages, 
      folders: updatedFolders
    };
    
    newSelector = {
      ...selector,
      folder: updatedFolder,
      page: newPage, 
      frame: null, 
      object: null
    }

  }
  
  // NOT DONE
  else if (action.type === 'DELETE_PAGE') {
    
    // REMOVE THE PAGE
    const pageToRemove = action.page;
    const pageIdToRemove = pageToRemove.id
    pagesDB.remove(pageIdToRemove, token);

    // UPDATE SIBLINGS
    let projectPages = [...project.pages]
    let siblings = [...projectPages].filter(p => p.folder_id == pageIdToRemove) || []
    console.log('siblings', siblings)
    siblings = siblings.map(sibling => 
      sibling.index >= pageToRemove.index
        ? { ...sibling, index: sibling.index - 1 } 
        : sibling);
    pagesDB.update(siblings, token);

    // UPDATE PAGES IN PROJECT
    let updatedPages = project.pages.map(page => {
      const updatedSibling = siblings.find(s => s.id === page.id);
      return updatedSibling || page;
    });
    updatedPages = updatedPages.filter(p => p.id !== pageIdToRemove).sort((a, b) => a.index - b.index);

    const nextSelect = updatedPages.find(p => p.index === pageToRemove.index) || updatedPages.find(p => p.index === pageToRemove.index - 1)
    // Update the project with the new pages array
    newProject = {...project, pages: updatedPages};
    newSelector = {...selector, page: nextSelect, frame: null, object: null};
  }




// NEEDS REVERSE ACTION
else if (action.type === 'INSERT_FOLDER') {
  let { newFolder, currentProject, keepSelector=false } = action;
  const useProject = currentProject || project
  const newFolders = [...useProject.folders]; // Clone the existing folders array
  if (newFolder.index == null) { newFolder.index = project.folders?.length + 1 || 1 }
  // Add the new folder to the database (assuming foldersDB.add returns the added folder with its id)
  foldersDB.add(newFolder, token);

  // Determine if there's a need to shift other folders
  const shouldShiftFolders = newFolders.some(folder => folder.index >= newFolder.index);

  if (shouldShiftFolders) {
    // Shift the index of existing folders
    newFolders.forEach(folder => {
      if (folder.index >= newFolder.index) {
        folder.index += 1;
        // Update the folder in the database
        foldersDB.update(folder, token);
      }
    });
  }

  // Add the new folder to the project's folders array
  newFolders.push(newFolder);
  newFolders.sort((a, b) => a.index - b.index); // Ensure the folders are sorted by index

  // Update the project with the new folders array
  newProject = { ...useProject, folders: newFolders };

  // Update the selector to select the new folder
  newSelector = keepSelector ? selector : { ...selector, folder: newFolder, page: null, frame: null, object: null }
}

else if (action.type === 'UPDATE_FOLDER') {
  const { folder } = action;

  // Add the new folder to the database (assuming foldersDB.add returns the added folder with its id)
  foldersDB.update(folder, token);

  // Determine if there's a need to shift other folders
  let updatedFolders = project.folders.map(f => f.id == folder.id ? folder : f)

  // Update the project with the new folders array
  newProject = { ...project, folders: updatedFolders };

  const currentFolderSelected = selector.folder?.id == folder?.id
  // Update the selector to select the new folder
  newSelector = currentFolderSelected ? { ...selector, folder} : selector
}

else if (action.type === 'ARCHIVE_FOLDER') {
  const { folder } = action;
  const updatedFolder = { ...folder, is_archived: true };
  const folderIndex = updatedFolder.index;
  // Add the new folder to the database (assuming foldersDB.add returns the added folder with its id)
  foldersDB.update(updatedFolder, token);

  // Determine if there's a need to shift other folders
  let updatedFolders = project.folders.map(f => f.id == folder.id ? updatedFolder : f)

  // Update the project with the new folders array
  newProject = { ...project, folders: updatedFolders };
  const selectFolder = updatedFolders.find(f => f.index === folderIndex + 1) || updatedFolders.find(f => f.index === folderIndex - 1) || null
  // Update the selector to select the new folder
  
  newSelector = { ...selector, folder: selectFolder, page: null, frame: null, object: null };
}

else if (action.type === 'RELOCATE_PAGE') {
  const { page, newIndex, newFolderId } = action;
  const currentPage = {...page}
  let updatedPage = {...page, index: newIndex}
  updatedPage = newFolderId ? { ...updatedPage, folder_id: newFolderId } : updatedPage
  
  let pagesToUpdate = []
  // WITHIN SAME FOLDER
  if (currentPage.folder_id === updatedPage.folder_id) {
    let siblings = project.pages.filter(p => p.folder_id == page.folder_id) || []
    // remove from array
    siblings = siblings.filter(sibling => sibling.id !== page.id) // filter out itself
            .map(sibling => sibling.index > page.index 
          ? { ...sibling, index: sibling.index - 1 } 
        : sibling
      )
    // add back in a new position
    siblings = siblings.map(sibling => 
      sibling.index >= newIndex
        ? { ...sibling, index: sibling.index + 1 } 
        : sibling
    );
    pagesToUpdate = [...siblings, updatedPage]

    // Re-Index
    pagesToUpdate.sort((a, b) => a.index - b.index);
    pagesToUpdate.forEach((sibling, idx) => sibling.index = idx + 1);
  }

  // NEW FOLDER
  else {
    let currentSiblings = project.pages.filter(p => p.folder_id == currentPage.folder_id) || []
    let newSiblings = project.pages.filter(p => p.folder_id == updatedPage.folder_id) || []

    // remove page from old folder
    currentSiblings = currentSiblings.filter(sibling => sibling.id !== page.id) // filter out itself
            .map(sibling => sibling.index > page.index 
          ? { ...sibling, index: sibling.index - 1 } 
        : sibling
      )

    // add page to new folder
    newSiblings = newSiblings.map(sibling => 
      sibling.index >= newIndex
        ? { ...sibling, index: sibling.index + 1 } 
        : sibling
    );
    newSiblings.push(updatedPage)
    
    // Re-Index
    currentSiblings.sort((a, b) => a.index - b.index);
    currentSiblings.forEach((sibling, idx) => sibling.index = idx + 1);

    newSiblings.sort((a, b) => a.index - b.index);
    newSiblings.forEach((sibling, idx) => sibling.index = idx + 1);

    pagesToUpdate = [...currentSiblings, ...newSiblings]
  }
  
  pagesDB.update(pagesToUpdate, token); // update indices of affected frames  

  const updatedPages = project.pages
      .filter(p => p.folder_id !== currentPage.folder_id && p.folder_id !== updatedPage.folder_id) // Remove all siblings from the original array
      .concat(pagesToUpdate) // Add the updated siblings back
      .sort((a, b) => a.index - b.index) // Sort by index for proper ordering
  

  // Update project and selector
  newProject = { ...project, pages: updatedPages }; // TODO all project.pages, but update siblings
  newSelector = {
      ...newSelector
  };
}

else if (action.type === 'RELOCATE_FOLDER') {
  const { folder, newIndex } = action;
  const updatedFolder = {...folder, index: newIndex}
  
  let siblings = project.folders || []

  // remove folder from array
  siblings = siblings.filter(sibling => sibling.id !== folder.id) // filter out itself
          .map(sibling => sibling.index > folder.index 
        ? { ...sibling, index: sibling.index - 1 } 
      : sibling
    )

  // add folder in a new position
  siblings = siblings.map(sibling => 
    sibling.index >= newIndex
      ? { ...sibling, index: sibling.index + 1 } 
      : sibling
  );

  siblings = [...siblings, updatedFolder]
  
  // Update the database
  foldersDB.update(siblings, token); // update indices of affected frames

  // Update project and selector
  newProject = { ...project, folders: siblings };
  newSelector = {
      ...newSelector,
      folder: updatedFolder
  };
}



  if (action.type === 'EXTEND_CHANGES') {
    // VARIABLES
    const object = action.object;
    const descendants = action.descendants || [];
    const scope = action.scope
    const updateDescendants = action.updateDescendants || false

    const currentFolder = selector.folder
    const currentPage = selector.page

    const allObjects = project.pages.flatMap(page =>
      page.frames.flatMap(frame => frame.objects)
    );

    const allFrames = project.pages.flatMap(page => page.frames.flatMap(frame => frame));
    const allPages = project.pages || [];

    let filteredFrames = allFrames
    if (scope === 'folder') {
      const filteredPages = allPages.filter(page => page.folder_id === currentFolder?.id) || []
      const pageIds = filteredPages.map(page => page.id)
      filteredFrames = filteredFrames.filter(frame => pageIds.includes(frame.page_id))
    } else if (scope === 'page') {
      filteredFrames = filteredFrames.filter(frame => frame.page_id === currentPage?.id)
    }
    
    // twins filtered by scope
    const selectTwins = allObjects
      .filter(o => o.componentAPIName === object.componentAPIName && o.id !== object.id)
      .filter(o => filteredFrames.find(f => f.id === o.frame)) 
       || [];

    let objectsToAdd = [];
    let objectsToUpdate = [];
    let objectsToRemove = []

    let updatedFrames = [];

    selectTwins.forEach(twin => {
      
      // update twin props
      const updatedTwin = {
        ...twin,
        object_props: object.object_props,
        mobile_props: object.mobile_props
      };
      objectsToUpdate.push(updatedTwin);

      // change content
      
      const twinDescendants = getDescendants(twin.id, allObjects);
      let newDescendants = createNewIds(descendants, object.id, twin.id);
      // optionally update descendants
      if (updateDescendants) {
        objectsToRemove.push(...twinDescendants);
        newDescendants = newDescendants.map(obj => obj && { ...obj, frame: twin.frame });      
        objectsToAdd.push(...newDescendants);
      }   

      // Find the frame for this twin and update it
      const twinFrame = allFrames.find(obj => obj.id === twin.frame);
      if (twinFrame) {
        let frameObjects = twinFrame.objects.map(obj => obj.id == twin.id ? updatedTwin : obj); // update twin

        if (updateDescendants) {
          frameObjects = frameObjects.filter(obj => !twinDescendants.find(d => d.id === obj.id)) // remove descendants
          frameObjects = [...frameObjects, ...newDescendants]; // add new descendants and updated twin
        }
        updatedFrames.push({ ...twinFrame, objects: frameObjects });
      }
    });

    const confirmMessage = `${selectTwins.length} objects ${updateDescendants ? '(and their contents)' : ''} will be updated across ${filteredFrames.length - 1} frames. Do you want to proceed?`;
    const userConfirmed = window.confirm(confirmMessage); // confirm - significant changes, need to be confirmed before extending to project
    if (!userConfirmed) {
        return;
    }

    // Database operations
    objectsToRemove.length > 0 && objectsDB.remove(objectsToRemove.flat(), token);
    objectsToUpdate.length > 0 && objectsDB.update(objectsToUpdate, token);
    objectsToAdd.length > 0 && objectsDB.add(objectsToAdd, token);

    // Update pages with the updated frames
    let updatedPages = project.pages.map(page => {
      const pageFrames = page.frames.map(frame => {
        const updatedFrame = updatedFrames.find(uf => uf.id === frame.id);
        return updatedFrame || frame;
      });

      return { ...page, frames: pageFrames };
    });

    newProject = { ...project, pages: updatedPages };

    let updatedPage = project.pages.find(page =>
      page.frames.some(frame => frame.id === object.frame)
    );

    // Update the current page with updated frames
    updatedPage = {
      ...updatedPage,
      frames: updatedPage.frames.map(frame => {
        const updatedFrame = updatedFrames.find(uf => uf.id === frame.id);
        return updatedFrame || frame;
      })
    }

    // Update the selector with the updated page
    newSelector = updatedPage ? { ...selector, page: updatedPage } : { ...selector };
    
}

  if (action.type === 'REPLACE_OBJECTS') {
    const oldObjects = action.oldObjects;
    const newObjects = action.newObjects;

    let objectsToUpdate = [];
    let objectsToAdd = [];
    let objectsToRemove = [];

    // for each object match -> make arrays to update objects, add/remove descendants
    newObjects.forEach(newObj => {
      const oldObj = oldObjects.find(old => old.id === newObj.id);
      if (oldObj) {
        objectsToUpdate.push(newObj);
        objectsToAdd.push(...newObj.descendants);
        objectsToRemove.push(...oldObj.descendants);
      }
    });

    // Database operations
    objectsToRemove.length > 0 && objectsDB.remove(objectsToRemove.flat(), token);
    objectsToUpdate.length > 0 && objectsDB.update(objectsToUpdate, token);
    objectsToAdd.length > 0 && objectsDB.add(objectsToAdd, token);

    const allFrames = project.pages.flatMap(page =>
      page.frames.flatMap(frame => frame)
    );

    objectsToUpdate.forEach(updatedObj => {
      // Find the frame that contains the updated object
      const frameIndex = allFrames.findIndex(frame => frame.id === updatedObj.frame);
      if (frameIndex !== -1) {
        let frame = allFrames[frameIndex];

        // Update the object in the frame's objects array
        const objectIndex = frame.objects.findIndex(obj => obj.id === updatedObj.id);
        if (objectIndex !== -1) {
          frame.objects[objectIndex] = updatedObj;

          // Find old and new descendants
          const oldDescendants = oldObjects.find(old => old.id === updatedObj.id).descendants;
          const newDescendants = newObjects.find(newObj => newObj.id === updatedObj.id).descendants;

          // Remove old descendants and add new descendants
          frame.objects = frame.objects.filter(obj => !oldDescendants.some(d => d.id === obj.id));
          frame.objects.push(...newDescendants);

          // Update the frame in allFrames
          allFrames[frameIndex] = frame;
        }
      }
    });

    // Update pages with the updated frames
    const updatedPages = project.pages.map(page => {
      const pageFrames = page.frames.map(frame => {
        const updatedFrame = allFrames.find(uf => uf.id === frame.id);
        return updatedFrame || frame;
      });

      return { ...page, frames: pageFrames };
    });

    newProject = { ...project, pages: updatedPages };
    const currentPage = project.pages.find(page =>
        page.id == selector.page.id)
    
    // Find the updated current page in updatedPages
    const updatedPage = updatedPages.find(up => up.id === currentPage.id);
    
    // Update the selector with the updated page
    newSelector = updatedPage ? { ...selector, page: updatedPage } : { ...selector };
    
    newProject = { ...project, pages: updatedPages };
  }

// add component
// adds component updates project.components
if (action.type === 'SAVE_TEMPLATE') {
    
    const { apiname, jsx }  = action.template
    
    
    const dbTemplates = newProject.dbTemplates || []
    console.log(dbTemplates)
    const currentTemplate = dbTemplates.find(t => t.apiname == apiname)
    console.log(currentTemplate)
    
    if (currentTemplate) {
      // UPDATE
      const updatedTemplate = {
        ...currentTemplate,
        jsx
      }
      console.log('update', updatedTemplate)
      
      const dbResponse = await templatesDB.update(updatedTemplate);
      console.log(dbResponse);
      newProject = {...project, sets: dbResponse, dbTemplates: dbTemplates.map(t => t.id == updatedTemplate.id ? updatedTemplate : t)}
      newSelector = {...selector}

    } else {
      // CREATE
      const newTemplate = {
        id: uuidv1(),
        project_id: project.project.id, 
        apiname,
        jsx
      }
      console.log('create', newTemplate)
      const dbResponse = await templatesDB.add(newTemplate);
      console.log(dbResponse);
      newProject = {...project, sets: dbResponse, dbTemplates: dbTemplates.push(newTemplate)}
      newSelector = {...selector}

    }

    
}

// APPEND FOLDER

// DELETE THEME
else if (action.type === 'DELETE_THEME') {
    
  const themeToDelete = action.themeToDelete

  // add to database
  themesDB.remove(themeToDelete, token)
  
  // remove
  newDesignSystem = {...newDesignSystem, themes: newDesignSystem.themes.filter(t => t.id != themeToDelete.id)}
  newProject = {...project, project: {...project.project, current_theme_id: null}}

}

else if (action.type === 'ADD_THEME') {
    
  const newTheme = action.newTheme
  const updatedProject = {...project.project, current_theme_id: newTheme.id}
  
  // update project
  projectsDB.update(updatedProject)

  // NOTE: we don't update the database here, since when we generate new themes they're added by backend
  // BUT WE NEED TO FIX THIS
  
  // add 
  newDesignSystem = {...newDesignSystem, themes: [...newDesignSystem.themes, newTheme]}
  newProject = {...project, project: updatedProject}

  // console.log('new ds',newDesignSystem)
}

  return { project: newProject, selector: newSelector, designSystem: newDesignSystem, history: newHistory };
}




}


/* LOCAL HELPERS */

// takes CSSJSON and deletes all props that don't need to be saved
function removeEmptyProps(cssJSON) {
  console.log('removing empty strings', cssJSON)
  const newCssJSON = {};
  for (const [key, value] of Object.entries(cssJSON)) {
    if (
      value !== ""
      && value !== null
      && value !== undefined
      && value.toUpperCase() !== "PX"
      && value.toUpperCase() !== "VW"
      && value.toUpperCase() !== "VH"
      && value.toUpperCase() !== "EM"
      && value.toUpperCase() !== "%"
      && value.toUpperCase() !== "NULL"
    ) {
      newCssJSON[key] = value;
    }
  }
  console.log('new css json', newCssJSON)
  return newCssJSON;
}

function clearEmptyValues(property) {
  let newProperty = property;
  if (
    newProperty == undefined
    || newProperty === "" 
    || newProperty.toLowerCase() === "null"
    ) {
    newProperty = null;
  }
  return newProperty;
}

function createNewIds(objectArray, mainParentOldId, mainParentNewId) {
  
  let updatedArray = [...objectArray]
  updatedArray = updatedArray.map(obj => ({
    ...obj,
    newId: nanoid(idCount),
  }));

  updatedArray = updatedArray.map(obj => ({
    ...obj, 
    newParent: obj.parent == mainParentOldId // if it's the newObject
      ? mainParentNewId // then assign the new id 
      : updatedArray.find(d => d.id == obj.parent).newId // otherwise find its parent and assign its new id in parent prop
  }));

  updatedArray.forEach((obj) => {
    obj.id = obj.newId;
    obj.parent = obj.newParent;
    delete obj.newId;
    delete obj.newParent;
  });
  
  return updatedArray;
}

const findComponentIgnoreCase = (componentName, moduleObj) => {
  const normalizedComponentName = componentName.toLowerCase();

  const componentNameKey = Object.keys(moduleObj)
    .find(key => {
      return key.toLowerCase() === normalizedComponentName;
    });

  return moduleObj[componentNameKey] || {};
};