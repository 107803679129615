import { Code, Laptop, Link, NavArrowLeft, NavArrowRight, Notes, Phone, SmartphoneDevice } from "iconoir-react";
import { useEffect, useState, useRef } from "react";
import symbol from "./symbol.svg";
import { useNavigate, useLocation } from 'react-router-dom';
import CopyLinkButton from "./CopyLinkButton";
import DesktopNav from "./DesktopNav";

export default function ShareProjectHeader({ showCode, setShowCode, isMobile, setIsMobile, 
  showNotes, setShowNotes,
  project, selectedFrame, setSelectedFrame, narrowViewport, selectedFolder, selectedPage }) {
  
  
  const allFolders = project?.folders.filter(folder => !folder.is_archived).sort((a, b) => a.index - b.index);  
  const allFlows = project?.pages.filter(page => !page.is_archived && allFolders.map(f => f.id).includes(page.folder_id)).sort((a, b) => a.index - b.index);
  const allFrames = allFlows?.flatMap(f => f.frames).filter(frame => !frame.isArchived).sort((a, b) => a.index - b.index);
  

  const framesByOrder = allFolders?.flatMap(folder => allFlows.filter(flow => flow.folder_id === folder.id).flatMap(flow => allFrames.filter(frame => frame.page_id === flow.id))) || []
  
  const currentIndex = framesByOrder.findIndex(frame => frame.id === selectedFrame?.id);
  
    let previousFrame = null;
    let nextFrame = null;
    
    if (currentIndex > 0) {
        previousFrame = framesByOrder[currentIndex - 1];
    }
    
    if (currentIndex < framesByOrder.length - 1) {
        nextFrame = framesByOrder[currentIndex + 1];
    }

    function selectNextFrame() {if (nextFrame) {setSelectedFrame(nextFrame)}}
    function selectPreviousFrame() {if (previousFrame) {setSelectedFrame(previousFrame)}}
    
    useEffect(() => {
      const handleKeyPress = (event) => {
        if (event.key === 'ArrowRight') {
          selectNextFrame();
        } else if (event.key === 'ArrowLeft') {
          selectPreviousFrame();
        }
      };
  
      window.addEventListener('keydown', handleKeyPress);
      return () => {
        window.removeEventListener('keydown', handleKeyPress);
      };
    }, [nextFrame, previousFrame]); 
      
    const tooltipStyle = `absolute opacity-0 group-hover:opacity-100 invisible group-hover:visible whitespace-nowrap
    w-auto bg-gray-700 text-gray-50 font-medium rounded px-2 py-1 text-xs
    -bottom-5 mb-2 transition-all`
    const navigate = useNavigate();
    return (
      <div className="flex flex-row justify-between gap-2 py-1 w-full text-xs">
        {/* Title & Logo */}
        <div className="flex flex-row items-center gap-2 w-full md:w-1/4 relative z-50">
        <div className="group relative bg-accent rounded-md w-6 h-6 flex items-center justify-center transition-all hover:scale-110">
          <img src={symbol} alt="Logo" width={24} onClick={() => navigate(`/`)} />
          </div>
          {!narrowViewport && <DesktopNav 
          currentIndex={currentIndex}
          allFrames={framesByOrder}
          selectNextFrame={selectNextFrame}
          selectPreviousFrame={selectPreviousFrame}
          nextFrame={nextFrame}
          previousFrame={previousFrame}

        />}
          <BreadCrumbs
            selectedPage={selectedPage}
            selectedFrame={selectedFrame}
            setSelectedFrame={setSelectedFrame}
          />
        </div>

        {/* Middle Part */}
        <div className="flex flex-row justify-center gap-2 items-center font-medium">
        </div>

        {!narrowViewport && (<div className="flex flex-row justify-end items-center gap-2  w-1/4">
            <button
              className={`${showNotes && "bg-accent"} w-6 h-6 flex items-center justify-center transition-all rounded-md hover:scale-110 text-slate-700 group relative z-50`}
              onClick={() => { setShowNotes(!showNotes); setShowCode(false)}}
            >
              <Notes />
              <span className={` ${tooltipStyle} right-0 transform translate-y-full  `}
              style={{ zIndex: 1001 }}>
                Notes
              </span>
            </button>
            
            <CopyLinkButton tooltipStyle={tooltipStyle}/>
          </div>
        )}
      </div>
    );
  }

function BreadCrumbs({setSelectedFolder, selectedPage, selectedFrame, selectedFolder, setSelectedFrame}) {
    const [showFrames, setShowFrames] = useState(false);
    const [showPages, setShowPages] = useState(true);
    const dropdownRef = useRef(null);
    const pageDropdownRef = useRef(null);

    
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
          setShowFrames(false);
        }
        if (pageDropdownRef.current && !pageDropdownRef.current.contains(event.target)) {
          setShowPages(false);
        }
      };
    
      document.addEventListener('mouseup', handleClickOutside);
      return () => {
        document.removeEventListener('mouseup', handleClickOutside);
      };
    }, []);

    function handlePageSelect(page) {
      setSelectedFrame(page.frames[0])
    }
    
    return (
      <div className="relative flex flex-row gap-3 py-1 px-1 items-center cursor-default" style={{zIndex: 2000}}>
            {/* Dropdown for selectedFolder */}
            <div onClick={()=>setShowPages(!showPages)} ref={pageDropdownRef} className='relative select-none'>
              <span className=" whitespace-nowrap ellipsis truncate hover:underline">{selectedPage?.name || 'n/a'}</span>
              {showPages && 
              <ul className="absolute -bottom-2 bg-white translate-y-full rounded-md border border-neutral-200 -left-2 overflow-hidden">
                <div
                className="px-2 py-1 bg-neutral-100 border-b border-bg-neutral-200 whitespace-nowrap overflow-hidden ellipsis truncate font-medium"
                >Features</div>
                {selectedFolder?.pages.map(page => 
                <li 
                className="px-2 py-1 hover:bg-neutral-100 overflow-hidden whitespace-nowrap ellipsis truncate "
                key={page.id}
                onMouseDown={(e)=> {e.stopPropagation(); handlePageSelect(page); setShowPages(false)}}>
                  {page.name}
                </li>)}
              </ul>}
            </div>
            <NavArrowRight width={12} height={12}/>
            
            {/* Dropdown for selectedPage */}
            <div onClick={()=>setShowFrames(!showFrames)} ref={dropdownRef} className='relative select-none '>
            
            <span className=" whitespace-nowrap ellipsis truncate hover:underline">{selectedFrame?.name || 'n/a'}</span>
              {showFrames && <ul className="absolute -bottom-2 bg-white translate-y-full rounded-md border border-neutral-200 -left-2" style={{zIndex: 1000}}>
              <div
                className="px-2 py-1 bg-neutral-100 border-b border-bg-neutral-200 overflow-hidden whitespace-nowrap ellipsis truncate  font-medium"
                >Pages</div>
                {selectedPage?.frames.map(frame => 
                <li 
                className="px-2 py-1 hover:bg-neutral-100 whitespace-nowrap overflow-hidden ellipsis truncate "
                key={frame.id}
                onMouseDown={(e)=> {e.stopPropagation(); setSelectedFrame(frame); setShowFrames(false)}}>
                  {frame.name}
                </li>)}
              </ul>}
            </div>

            

            
          </div>
    )
}
