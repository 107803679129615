import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  LabelList,
} from "recharts";
import { Icon } from "../";

const dummyData = {
  keys: ["name", "temperature", "humidity", "pressure"],
  colors: ["info-content", "info-focus", "info", "info-surface"],
  values: [200, 100, 60, 20],
};

type DataPoint = {
  [key: string]: string | number;
};

function transformData(keys: string[], values: number[]): DataPoint[] {
  return keys.map((key, index) => ({
    name: key,
    value: values[index],
  }));
}

type PieChartProps = {
  width?: "auto" | "full" | "1/2";
  height?: "92px" | "120px" | "160px" | "200px" | "240px" | "360px" | string;
  isDonut?: boolean;
  showLabels?: boolean;
  emptyState?: boolean;
  emptyMessage?: string;
  data?: {
    keys: string[];
    values: number[];
    colors?: string[];
  };
  __juno?: any;
};

export default function PieChartComponent({
  width = "full",
  height = "200px",
  isDonut = false,
  showLabels = true,
  data = dummyData,
  emptyState = false,
  emptyMessage = "empty message",
  __juno = {},
}: PieChartProps) {
  const widthStyles = `w-${width}`;
  const classes = `flex flex-col items-stretch relative ${widthStyles} text-base-content`;

  const emptyStyles = `flex flex-col justify-center items-center px-sm text-sm font-medium gap-2 rounded-md`;

  const sampleData = data
    ? transformData(data?.keys, data?.values)
    : [];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const percentValue = Number((percent * 100).toFixed(0));
    
    return (
        <text x={x} y={y} fill="var(--base-0)" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {percentValue > 10 && `${percentValue}%`}
        </text>
    );
};
  return (
    <div
      className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
      {...__juno?.attributes}
    >
      {/* CHART */}
      {!emptyState && data?.keys?.length && data?.values?.length ? (
        <div
          className="flex flex-row"
          style={{
            width: "100%",
            pointerEvents: "none", // remove line
            height: height,
          }}
        >
          <ResponsiveContainer width={"100%"} height="100%">
            <PieChart>
              <Pie
                data={sampleData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius="100%"
                innerRadius={isDonut ? "50%" : 0}
                //label={showLabels}
                label={renderCustomizedLabel}
                labelLine={false}
                
              >
                {sampleData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={`var(--${data.colors?.[index] || "primary"})`}
                  />
                ))}
              </Pie>
              <Tooltip />
            </PieChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <>
          <div
            className={emptyStyles}
            style={{
              height: height,
              backgroundColor: `color-mix(in srgb, currentColor 8%, transparent)`,
            }}
          >
            <Icon icon="chart-up" />
            <span className="font-normal text-sm">{emptyMessage}</span>
          </div>
        </>
      )}
    </div>
  );
}




