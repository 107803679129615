import { Avatar, ButtonIcon, Icon } from '../';
import { useMemo, useState } from 'react';

type SocialPostProps = {
    posted_at?: string,
    author?: string | JSX.Element,
    children?: any,
    likes?: number,
    comments?: number,
    onLike?: Function,
    onComment?: Function,
    onClick?: Function,
    onAuhor?: Function,
    __juno?: any
}

export default function SocialPost({
        author = "Author",
        posted_at = "2 hrs",
        children,
        likes, 
        comments, 
        onLike,
        onComment,
        onClick, 
        onAuhor, 
        __juno = {}

    }: SocialPostProps) {

    
    const sizeStyles = `w-full h-auto text-base`

    const classes = `flex flex-row items-stretch justify-start gap-3 ${sizeStyles}`

    const [showAll, setShowAll] = useState(false);

    return (
        <div  className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`} {...__juno?.attributes} 
onClick={(e) => {e.stopPropagation(); onClick?.()}}
        >
        <div className='flex-shrink-0'>
            <Avatar size='32px' bgColor='base-500'/>
        </div>
        <div className={`flex flex-col flex-grow gap-1.5`}
onClick={(e) => {e.stopPropagation(); onAuhor?.()}}
        >
            <div className={`flex flex-row w-full gap-2 items-baseline`} style={{maxWidth: '100%'}}>
            <span className='font-semibold truncate hover:underline truncate'
onClick={(e) => {e.stopPropagation(); onAuhor?.()}}
            >
                {author}
            </span>
            {posted_at && 
            <div className='flex flex-row items-baseline gap-2 opacity-60 font-medium flex-shrink-0'>
            <span>•</span>
            <span className='truncate '>
                {posted_at}
            </span></div>
            }</div>
            {/* Description Lines */}
            <div className='flex flex-col w-full gap-3'>
                {children}
            </div>
            <div className='flex flex-row items-center justify-start gap-10 -ml-1.5 mt-3'>
                <div className='flex flex-row items-center gap-1'>
                    <ButtonIcon icon='heart' size='small' style='ghost' 
onClick={(e) => {e.stopPropagation(); onLike?.()}}
                    isPill/> {likes || 0}
                </div>
                <div className='flex flex-row items-center gap-1'>
                    <ButtonIcon icon='message' size='small' style='ghost' 
onClick={(e) => {e.stopPropagation(); onComment?.()}}
                    isPill/> {comments || 0}
                </div>
                
            </div>
        
        </div>
        
        </div>
    );
}


