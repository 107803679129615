import { Arrows } from "../Arrows";
import * as UIKit from "../../../../../ui-kit/exports/react";
import { useEffect, useState } from "react";

export default function StoryDisplay ({stories=[], displayComponent}) {
    
    const [index, setIndex] = useState(0);
    useEffect(() => {
        setIndex(0);
    }, [displayComponent]);
    
    function renderStory(story) {
        const Component = UIKit[displayComponent];
        const storyProps = story?.props || {};
    
        // Check if the story type is 'combo' and requires rendering multiple components with different props
        if (story?.type === 'combo' && Array.isArray(storyProps)) {
            return (
                <div className={`relative ${story?.wrapperClasses}`}>
                    <ComponentDecoration color="var(--base-300)" offset={3} />
                    {storyProps.map((props, index) => (
                        <Component key={index} {...props} />
                    ))}
                </div>
            );
        } else {
            // Standard story rendering for a single set of props
            return (
                <div className={`relative ${story?.wrapperClasses}`}>
                    <ComponentDecoration color="var(--base-300)" offset={3} />
                    <Component {...storyProps} />
                </div>
            );
        }
    }
    
        function renderComponent(component) {
            const Component = UIKit[component];
            if (Component) return (
                <div className="flex flex-col items-center justify-center p-4 w-3/4 h-auto max-h-3/4 bg-base-0 relative">
                <ComponentDecoration color="var(--base-300)" offset={3} />
                <Component />
            </div>
            )       
        }
    
        
        return (
            <div className="flex flex-col items-center justify-center w-full h-full overflow-auto relative group  bg-base-50
            border border-base-100 rounded-lg
            ">
                {stories.length > 1 &&
                <Arrows 
                    index={index} 
                    length={stories.length} 
                    onPrev={() => setIndex(Math.max(index - 1, 0))} 
                    onNext={() => setIndex(Math.min(index + 1, stories.length - 1))} 
                />}
                {stories.length > 0 ? renderStory(stories[index]): renderComponent(displayComponent)}
            </div>
        )
    }

function ComponentDecoration({color='var(--base-300)', offset=3}) {
    
        return (
            <>
                <div className="absolute top-0 left-0 z-50" 
                    style={{
                        width: `calc(100% + ${offset * 2}rem)`, marginLeft: `-${offset}rem`, height: 1, 
                        background: `linear-gradient(to right, transparent, ${color} 10%, ${color} 90%, transparent)`
                    }} />
                <div className="absolute bottom-0 left-0 z-50" 
                    style={{
                        width: `calc(100% + ${offset * 2}rem)`, marginLeft: `-${offset}rem`, height: 1, 
                        background: `linear-gradient(to right, transparent, ${color} 10%, ${color} 90%, transparent)`
                    }} />
                <div className="absolute top-0 left-0 z-50" 
                    style={{
                        height: `calc(100% + ${offset * 2}rem)`, marginTop: `-${offset}rem`, width: 1, 
                        background: `linear-gradient(to bottom, transparent, ${color} 10%, ${color} 90%, transparent)`
                    }} />
                <div className="absolute top-0 right-0 z-50" 
                    style={{
                        height: `calc(100% + ${offset * 2}rem)`, marginTop: `-${offset}rem`, width: 1, 
                        background: `linear-gradient(to bottom, transparent, ${color} 10%, ${color} 90%, transparent)`
                    }} />
            </>
        );   
    }