import Examples from "./Examples";
import ContactForm from "./ContactForm";
import Hero from "./Hero";
import Header from "./Header";
import { motion } from "framer-motion";
import { useMediaQuery } from "react-responsive";

export default function StudioPage() {
    const scrollbarHeight = 64;
    const mainWidth = 1280;
    return (
        <div className='w-screen flex flex-col relative h-dvh relative bg-base-0' 
        style={{ minHeight: '100dvh', fontFamily: 'Onest, Inter, system-ui, -apple-system, BlinkMacSystemFont, Roboto, sans-serif'}}>
                <Header mainWidth={mainWidth}/>   
                <div id='hero' className={`flex flex-col items-center justify-center p-5 `}>
                  <Hero mainWidth={mainWidth}/>
                </div>

                {/*<ScrollArrow  scrollTo='examples' scrollbarHeight={scrollbarHeight} /> */}
                
                <div className="flex flex-col items-center justify-center" id='examples'>
                  <Examples mainWidth={mainWidth}/>
                </div>

                {/*<ScrollArrow  scrollTo='about' scrollbarHeight={scrollbarHeight} />*/}
                <div className="flex flex-col items-end justify-center pt-12 md:pt-0 min-h-[50dvh] md:min-h-[calc(100vh_-_200px)] md:max-h-[1320px]"
                
                id='about'
                >
                  
              <Services />
               

                </div>
                <div
                  className="flex flex-col items-center justify-center h-[60dvh] md:min-h-[calc(100vh_-_120px)] md:max-h-[1320px] bg-gradient-to-b from-transparent to-white"
                  id='contact-form'
                >
                  <ContactForm />
                </div>
                
        </div>
    )
}


const Services = () => {
  const classes = 'block select-none md:hover:scale-105 md:hover:text-primary md:transition-all md:duration-150 md:ease-in-out'
  const motionStyles = {
    initial: { opacity: 0, filter: 'blur(10px)' },
    whileInView: { opacity: 1, filter: 'blur(0px)' },
  }
  return (
    <h1
      className="flex 
    leading-loose items-end pr-5 md:pr-[calc((100vw_-_1240px)/2)]
    flex-col gap-0 md:gap-4 text-4xl md:text-8xl brightness-110 text-base-content font-semibold md:font-medium"
    >
      <motion.span
        {...motionStyles}
        transition={{
          duration: 0.4,
          ease: [0.42, 0, 0.58, 1], // Custom easing for smooth motion
        }}
        className={classes}
      >
        design
      </motion.span>
      <motion.span
        {...motionStyles}
        transition={{
          duration: 0.4,
          ease: [0.42, 0, 0.58, 1], // Custom easing for smooth motion
        }}
        className={classes}
      >
        development
      </motion.span>
      <motion.span
        {...motionStyles}
        transition={{
          duration: 0.4,
          ease: [0.42, 0, 0.58, 1], // Custom easing for smooth motion
        }}
        className={classes}
      >
        product strategy
      </motion.span>
    </h1>
  );
};