import { ArrowRight, EditPencil } from "iconoir-react";
import { convertThemeVariablesToStyles } from "../../canvas/helpers";

export default function ThemePreview({
  theme,
  tileHeight,
  isSelected = false,
  onSelect,
}) {
  
  const variables = theme?.variables || [];
  const themeStyles = convertThemeVariablesToStyles(variables);
  
  return (
    <div
      className={`w-full flex flew-row items-center mb-1 justify-start font-medium text-sm  gap-2
        transition-all text-base-content overflow-hidden hover:scale-105 relative group
        ${isSelected ? 'border-2 shadow' : 'border border-slate-300 hover:border-slate-300 hover:shadow '}
        bg-base-0 text-base-content
        `}
      style={{
        ...themeStyles,
        minHeight: tileHeight,
        boxSizing: "border-box",
        fontFamily: 'var(--mainFont)',
        maxHeight: tileHeight,
        borderRadius: '0.5em',
        borderColor: isSelected && '#E04C18',
      }}
      onClick={() => onSelect(theme.id)}
    >
      <div className="flex flex-row w-[88px] flex-shrink-0 flex-grow-0 h-full items-start"
      
      >
        <div
          className="rounded-lg bg-base-100 flex flex-col items-center justify-center border border-base-200 flex-shrink-0 flex-grow-0"
          style={{
            transform: "translateX(-50%) translateY(-3%) rotate(15deg)",
            height: 132,
            width: 132,
            padding: 24,

          }}
        >
          <div
            className="w-full h-full rounded-xl bg-primary flex items-center justify-center  border border-primary-focus"
            style={{ padding: 12 }}
          >
            <div className="w-full h-full rounded-2xl bg-accent border border-accent-focus" />
          </div>
        </div>
      </div>
      <div className="flex flex-row w-full h-full pr-3 py-1 items-center justify-between overflow-hidden truncate whitespace-nowrap text-ellipsis">
        <span className="flex-grow truncate"
        >{theme.display_name}</span>
        </div>

    </div>
  );
}
