import { nanoid } from 'nanoid';
import {v1 as uuidv1 } from 'uuid'
const idCount = 10

// compares two lists of components and 
export function getChanges (oldL, newL) {
    const oldList = JSON.parse(JSON.stringify(oldL))
    const newList = JSON.parse(JSON.stringify(newL))

    const updates = {
        add: [],
        update: [],
        remove: [],
        
        reverse: [] // we need this array to reverse the update. thank me later
    }
    
    updates.add = newList.filter(newItem => 
        oldList.find(i => i.id === newItem.id) === undefined) // can't find it in the old list)

    updates.update = newList.filter(newItem => 
        oldList.find(i => newItem.id === i.id) !== undefined // exists in the old list
        && (newItem.index !== oldList.find(i => i.id === newItem.id).index // and has a different index        
            || newItem.parent !== oldList.find(i => i.id === newItem.id).parent // or different parent
                || newItem.frame !== oldList.find(i => i.id === newItem.id).frame) // or different frame
    )

    updates.reverse = oldList.filter(oldItem => // same as update, just reverse the lists
        newList.find(i => oldItem.id === i.id) !== undefined // exists in the new list
        && (oldItem.index !== newList.find(i => i.id === oldItem.id).index // and has a different index        
            || oldItem.parent !== newList.find(i => i.id === oldItem.id).parent // or different parent
                || oldItem.frame !== newList.find(i => i.id === oldItem.id).frame) // or different frame
    )

    updates.remove = oldList
        .filter(oldItem => newList.find(i => i.id === oldItem.id) === undefined) // we can't find it in the new list
    
    return updates
}

export function getDescendants(componentId, componentsArray=[]) {
    // returns a list of children and their children for a component
    let list = []
    for (let item of componentsArray ) {
            item.parent === componentId && list.push(item, ...getDescendants(item.id, componentsArray))
    }
    return list
}

export function addCoordinates(array, bounds) {
    //console.log(array)
    //console.log('helpers bounds', bounds)
    let newArray = array.map((obj) => ({
        ...obj,
        xLeft: bounds.find((i) => i.id === obj.id)?.left,
        xRight: bounds.find((i) => i.id === obj.id)?.right,
        yTop: bounds.find((i) => i.id === obj.id)?.top,
        yBottom: bounds.find((i) => i.id === obj.id)?.bottom,
        xCenter: bounds.find((i) => i.id === obj.id)?.x, 
        yCenter: bounds.find((i) => i.id === obj.id)?.y,
        isClosest: false,       
      }));
    //console.log('updated',newArray)
    return newArray
}

export function findClosestObject(array, cursorX, cursorY) {
    if (!array || array.length === 0) return null;

    // console.log('hi')
    // make an array of all objects and their distance to cursor
    const arrayWithDistance = array.map((obj) => ({
        ...obj,
        distance: getShortestDistance(
          cursorX,
          cursorY,
          obj.xLeft,
          obj.xRight,
          obj.yTop,
          obj.yBottom,
        ),
      }));

    // find the object with the shortest distance
    const closestObject = arrayWithDistance.reduce(function (prev, curr) {
        return prev.distance < curr.distance ? prev : curr;
      })
    // console.log('closest object', closestObject)
    
    return closestObject
}

export function findSecondClosestObject(array, cursorX, cursorY) {
  if (!array || array.length < 2) return null; // Need at least two objects to find the second closest

  // make an array of all objects and their distance to cursor
  const arrayWithDistance = array.map((obj) => ({
    ...obj,
    distance: getShortestDistance(
      cursorX,
      cursorY,
      obj.xLeft,
      obj.xRight,
      obj.yTop,
      obj.yBottom,
    ),
  }));

  // sort the objects by distance
  const sortedArray = arrayWithDistance.sort((a, b) => a.distance - b.distance);

  // the second element in the sorted array is the second closest object
  const secondClosestObject = sortedArray[1];

  return secondClosestObject;
}


export function getInternalDirection(node) {
    const display = getComputedStyle(node).getPropertyValue("display");
      if (display === "block") {
        return "column";
      } else if (display === "inline") {
        return "row";
      } else {
        return getComputedStyle(node).getPropertyValue("flex-direction");
      }
}

export function getPseudoIndex(direction, closestObject, cursorX, cursorY) {
    
    const index = direction === "row"
          ? cursorX >= closestObject.xCenter
            ? closestObject.index + 0.5
            : closestObject.index - 0.5
          : cursorY >= closestObject.yCenter
          ? closestObject.index + 0.5
          : closestObject.index - 0.5
    return index 
}

export function getNewIndex(pseudoIndex, closestObject, draggedItem) {
    const moveUp =
        closestObject.parent === draggedItem.parent
            ? draggedItem.index < pseudoIndex
              ? true
              : false
            : false;
        
    const newIndex =
          closestObject.id === draggedItem.id
            ? closestObject.index
            : pseudoIndex + 0.5 - moveUp;
    
    return newIndex
}


export function getInsertParameters(direction, closestObject, secondClosestObject, pseudoIndex) {
  let style = {}

  const drawBetweenObjects = (
    secondClosestObject &&
    ((pseudoIndex > closestObject.index && pseudoIndex < secondClosestObject.index) ||
    (pseudoIndex < closestObject.index && pseudoIndex > secondClosestObject.index))
  );

  if (direction === "row") {
    // vertical line
    style.position = "absolute";
    style.side = "left";
    style.top = closestObject.yTop;
    style.height = closestObject.yBottom - closestObject.yTop;
    style.width = 3;

    if (drawBetweenObjects) {
      if (closestObject.index < secondClosestObject.index) {
        style.left = (closestObject.xRight + secondClosestObject.xLeft) / 2 - 1.5;
      } else {
        style.left = (closestObject.xLeft + secondClosestObject.xRight) / 2 - 1.5;
      }
    } else {
      style.left = pseudoIndex > closestObject.index ? closestObject.xRight - 1.5 : closestObject.xLeft - 1.5;
    }
  } else {
    // horizontal line
    style.position = "absolute";
    style.side = "top";
    style.left = closestObject.xLeft;
    style.width = closestObject.xRight - closestObject.xLeft;
    style.height = 3;

    if (drawBetweenObjects) {
      if (closestObject.index < secondClosestObject.index) {
        style.top = (closestObject.yBottom + secondClosestObject.yTop) / 2 - 1.5;
      } else {
        style.top = (closestObject.yTop + secondClosestObject.yBottom) / 2 - 1.5;
      }
    } else {
      style.top = pseudoIndex > closestObject.index ? closestObject.yBottom - 1.5 : closestObject.yTop - 1.5;
    }
  }

  return style;
}




export function getObjectBounds(node, scrollX, scrollY, id=null, index=null, ) {
    let bounds = {}
    
    bounds.id = id
    bounds.index = index 
    bounds.left = Math.round(node.getBoundingClientRect().left + scrollX);
    bounds.right = Math.round(node.getBoundingClientRect().right + scrollX);
    bounds.top = Math.round(node.getBoundingClientRect().top + scrollY);
    bounds.bottom = Math.round(node.getBoundingClientRect().bottom + scrollY);
    bounds.x = Math.round((node.getBoundingClientRect().left + node.getBoundingClientRect().right)/2 + scrollX)
    bounds.y = Math.round((node.getBoundingClientRect().top + node.getBoundingClientRect().bottom)/2 + scrollY)
    bounds.width = Math.round(node.getBoundingClientRect().width);
    bounds.height = Math.round(node.getBoundingClientRect().height);


    return bounds

}

export function getNamePlateBounds(node, scrollX, scrollY) {
    let bounds = {}
    
    bounds.left = node.getBoundingClientRect().left + scrollX;
    bounds.top = node.getBoundingClientRect().top + scrollY - 16;

    return bounds

}

export function getDescendantIds(componentId, componentsArray=[]) {
    // returns a list of Ids of children and their children for a component
    let list = []
    for (let item of componentsArray ) {
            item.parent === componentId && list.push(item.id, ...getDescendantIds(item.id, componentsArray))
    }
    return list
}

export function getChildren(parentId, componentsArray=[]) {
    // returns an array of components from componentsArray that belong to this parent. same fields
    //console.log(componentsArray)
    let output = []
    if (componentsArray !== null && componentsArray.length>0) {
        output = componentsArray.filter(item => parentId === item.parent)
    }
    return output
}

export function getAllParents(componentId, componentsArray) {
    // returns a list of Ids of children and their children for a component
    let list = []
    
    while (componentId) {
        const component = componentsArray.find(i => componentId === i.id)
        list.push(component.parent)
        componentId = component.parent
    }
        
    return list
}

export function getShortestDistance(x, y, left, right, top, bottom) {
    let distance = null
    if (x >= left && x <= right) { distance = Math.min(Math.abs(y-top), Math.abs(y-bottom)) }
    else if (y >= bottom && y <= top) { distance = Math.min(Math.abs(x-left), Math.abs(x-right)) }
    else {
        function getDistance (x1, y1, x2, y2) {
            var a = x1 - x2;
            var b = y1 - y2;
            var c = Math.sqrt(a*a + b*b);
            return c
        }
        distance = Math.min(
            getDistance(x,y,left,top),
            getDistance(x,y,left,bottom),
            getDistance(x,y,right,top),
            getDistance(x,y,right,bottom)
        )}
    return Math.round(distance)
} 

export function createNewFrame(parent, styles, name, viewport, is_ui_frame=false) {
    let newFrame = {
        id: uuidv1(), 
        name: name ? name : 'Untitled', 
        component_id: parent.type == 'component' ? parent.id : null,
        page_id: parent.type == 'page' ? parent.id : null,
        isDefault: false,
        APIName: 'frame',
        state: 'default',
        styles: styles ? styles : [],
        type: 'frame', 
        objects: [],
        is_archived: false,
        viewport
        
    }
    
    return newFrame
}

export function convertCSStoJSON(cssString) {
    const cssJSON = {};
    cssString.split(';').forEach((s) => {
        const parts = s.split(':', 2);
        if (parts.length > 1) {
            cssJSON[parts[0].trim().replace(/-([a-z])/ig, (_, l) => l.toUpperCase())] = parts[1].trim();
        }
    });
    return cssJSON;
}

export function convertJSONtoCSS(json) {
  const cssProps = Object.entries(json)
    .map(([key, value]) => `${key}: ${value};`)
    .join('\n');
  return cssProps;
}


export function formatDate(date) {
    const formattedDate = new Date(date);
    const dateString = formattedDate.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

    return `${dateString}`
}

export function timeAgo(dateTime) {
    const now = new Date();
    const date = new Date(dateTime);
  
    if (date > now) {
      return null;
    }
  
    const seconds = Math.floor((now - date) / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
  
    if (days > 0) {
      return `${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `${hours} ${hours > 1 ? 'hrs' : 'hour'} ago`;
    } else if (minutes > 1) {
      return `${minutes} ${minutes > 1 ? 'mins' : 'min'} ago`;
    } else {return `just now`}
  }
  
export function updateObjectsOfPage(currentPage, newObjectList) {
    const newPage = {...currentPage}
    
    // loop over all new objects and then attach them to page frames
    newObjectList.forEach((obj) => {

        const frame = newPage.frames.find((f) => f.id === obj.frame); // find the fram it's on
        const index = frame.objects.findIndex((o) => o.id === obj.id);

        if (index > -1) {
          // Object already exists, update it
          frame.objects[index] = obj;
        } else {
          // Object doesn't exist, add it to the frame
          frame.objects.push(obj);
        }
      });
    
    return newPage
}
        

export function removeObject (component, componentsArray) {
    
    const copy = {...component}
    const componentsCopy = JSON.parse(JSON.stringify(componentsArray))

    let newComponents = []
    
    // rearrange siblings and retain non-adjacent components
    newComponents = componentsCopy.map(item => {
            if (item.parent === copy.parent) {
                if (item.index > copy.index) {
                    item.index = item.index - 1
                    return item
                } else {
                    return item
                }
            } else { return item }
    })
    
    let descendants = getDescendantIds(copy.id, componentsCopy)
    newComponents = newComponents.filter(i => i.id !== copy.id && !descendants.includes(i.id))
    
    // console.log('before reindex', newComponents)
    newComponents = reindexComponents(newComponents);
    // console.log('after reindex', newComponents)
    return newComponents
}

export function insertObject (newComponent, componentsArray) {
    
    // copy the original component so its properties remain intact while this function runs
    const copy = {...newComponent}
    
    const componentsCopy = JSON.parse(JSON.stringify(componentsArray))

    // console.log(copy)

    let newComponents = []
    newComponents = componentsCopy.map(item => {
            // Update new siblings
            if (item.parent === copy.parent) {
                if (item.index >= copy.index) {
                    item.index = item.index + 1
                    //console.log('new sibling updated',item)
                    return item
                } else {
                    //console.log('new sibling remained intact',item)
                    return item
                }
            } else { return item }
    })
    newComponents.push(copy)
    //console.log('updated components', newComponents)
    
    newComponents = reindexComponents(newComponents);
    
    return newComponents
}

function reindexComponents(components) {
  // Group components by their parent
  const groupedByParent = components.reduce((acc, cur) => {
      (acc[cur.parent] = acc[cur.parent] || []).push(cur);
      return acc;
  }, {});

  // Process each group
  let reindexedComponents = [];
  for (const parent in groupedByParent) {
      const group = groupedByParent[parent];

      // Sort by index
      group.sort((a, b) => a.index - b.index);

      // Reassign index based on order in the array
      group.forEach((item, idx) => {
          item.index = idx + 1; // Adding 1 because indices start at 1
      });

      // Merge back into the main array
      reindexedComponents = reindexedComponents.concat(group);
  }

  return reindexedComponents;
}

function parseCSS(css) {
    const styleSheet = new CSSStyleSheet();
    styleSheet.replaceSync(css);
    return Array.from(styleSheet.cssRules);
  }


export function convertHTMLToObjects(input) {
    const [html, ...cssLines] = input.split(/\r?\n/);
    const css = cssLines.join('\n');
    
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
  
    const cssRules = parseCSS(css);
    const objects = [];
    const styles = []; 
  
    function processNode(node, parent, index) {
      if (node.nodeType === Node.ELEMENT_NODE) {
        const object = {
          id: node.getAttribute('id'),
          APIName: node.tagName,
          parent: parent ? parent.id : null,
          index: index,
          text: node.textContent,
          styles: "",
        };
  
        const classNames = node.getAttribute('class') ? node.getAttribute('class').split(' ') : [];
        const styleIds = [];
        classNames.forEach((className) => {
          const index = cssRules.findIndex((rule) => rule.selectorText === `.${className}`);
          if (index !== -1) {
            const cssRule = cssRules[index];
            const styleId = className;
            let style = styles.find((s) => s.name === styleId);
  
            if (!style) {
              style = {
                name: className,
                cssString: cssRule.cssText,
              };
  
              styles.push(style);
            }
  
            styleIds.push(style.name);
          }
        });
  
        object.styles = styleIds.join(',');
        objects.push(object);
        node.childNodes.forEach((childNode, childIndex) => processNode(childNode, object, childIndex + 1));
      }
    }
  
    doc.body.childNodes.forEach((childNode, index) => processNode(childNode, null, index + 1));
  
    return { objects, styles };
  }

  export function extractCodeFromGPT(response) {
    const codeRegex = /```([\s\S]*?)```/g;
    const codeBlocks = [];
    let match;
  
    while ((match = codeRegex.exec(response)) !== null) {
      const code = match[1].trim();
      const codeType = identifyCodeType(code);
      codeBlocks.push({ type: codeType, contents: code });
    }
  
    return codeBlocks;
  }
  
  function identifyCodeType(code) {
    const trimmedCode = code.trim();
  
    if (/<(?:html|head|body)[^>]*>/i.test(trimmedCode)) {
      return 'HTML';
    }
    if (/<style[^>]*>/i.test(trimmedCode) || /^\s*(?:\.[\w-]+|#[\w-]+)\s*\{/.test(trimmedCode)) {
      return 'CSS';
    }
    if (/<script[^>]*>/i.test(trimmedCode) || /\b(?:function|var|let|const)\b/.test(trimmedCode)) {
      return 'JavaScript';
    }
  
    return 'Unknown';
  }

  export function cssStringToJson(cssString) {
    const regex = /\.([\w-]+)\s*{([\s\S]*?)}/g;
    const cssObjects = [];
  
    let match;
    while ((match = regex.exec(cssString)) !== null) {
      const name = match[1];
      const propertiesString = match[2];
      const properties = propertiesString.split(";").reduce((acc, property) => {
        const [key, value] = property.split(":").map((s) => s.trim());
        if (key && value) {
          const camelCaseKey = key.replace(/-([a-z])/g, (match, letter) => letter.toUpperCase());
          acc[camelCaseKey] = value;
        }
        return acc;
      }, {});
  
      cssObjects.push({
        name,
        css: properties,
      });
    }
  
    if (cssObjects.length === 0) {
      throw new Error("Invalid CSS string");
    }
  
    return cssObjects;
  }
  

export function findFrameIndex(page, frameId) {
    return page.frames.findIndex((frame) => frame.id === frameId);
  }
  

  export function createNewIds(objectArray, mainParentOldId, mainParentNewId) {
    
    let updatedArray = [...objectArray]
    updatedArray = updatedArray.map(obj => ({
      ...obj,
      newId: nanoid(idCount),
    }));
  
    updatedArray = updatedArray.map(obj => ({
      ...obj, 
      newParent: obj.parent == mainParentOldId // if it's the newObject
        ? mainParentNewId // then assign the new id 
        : updatedArray.find(d => d.id == obj.parent)?.newId // otherwise find its parent and assign its new id in parent prop
    }));
  
    updatedArray.forEach((obj) => {
      obj.id = obj.newId;
      obj.parent = obj.newParent;
      delete obj.newId;
      delete obj.newParent;
    });
  
    return updatedArray;
  }
  
  export function canAcceptChildren(type) {
    const noChildrenElements = [
      'h1', 'text', 'p', 'col',
      'img', 'input', 'video', 'placeholder', 'ion-icon','textarea'
    ];
  
    return !noChildrenElements.includes(type.toLowerCase());
  }