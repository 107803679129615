import React from 'react';

export default function SelectPadding({ propKey, paddingOptions, currentPadding, onPaddingSelect }) {

  const handlePaddingSelect = (value) => {
    onPaddingSelect(propKey, value);
  };

  const paddingOptionsIcons = {
    'none': 'none',
    'xs': '2XS',
    'sm': 'XS',
    'base': 'S',
    'md': 'M',
    'lg': 'L',
    'xl': 'XL',
    '2xl': '2XL',
    '3xl': '3XL',
  };

  return (
    <div 
        className='items-center flex flex-row flex-grow w-full rounded-lg p-1 bg-base-100 hover:bg-base-200 text-sm transition-all duration-150'
        style={{userSelect: 'none'}}
      >
        {paddingOptions.map((padding) => (
          <button
            key={padding}
            className={`w-full pointer text-sm flex items-center justify-center rounded-md py-1  transition-all duration-150 
            ${currentPadding === padding ? `bg-base-0 ring-1 ring-base-100 `
            : `opacity-80 hover:opacity-100`}`}
            style={{
              flexGrow: 1,
              zIndex: 2
            }}
            onClick={() => handlePaddingSelect(padding)}
          >
            {paddingOptionsIcons[padding]}
          </button>
        ))}
      </div>
  );
};
