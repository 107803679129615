import * as IconoirIcons from 'iconoir-react';
import { allIconNames } from 'ui-kit/local/components/iconMap';

export function preparePrimitives(UIKit) {
    

    const iconoirIconNames = Object.keys(IconoirIcons);
    const iconoirIconNamesString = JSON.stringify(iconoirIconNames).replace(/[[\]]/g, '');
    
    const containers = ['Modal', 'Drawer', 'Main', 'Footer', 'IconBar', 'Sidebar', 'Header', 'FeaturePanel', 'FlexBox', 'Grid', 'Form', 'div'];
    const excludeComponents = ['Vimeo', 'Video', 'Slide', 'RichText', 'NotFound'];

    
    const transformedComponents = Object.entries(UIKit)
      .filter(([name, Component]) => !excludeComponents.includes(Component?.definitions?.apiName))
      .map(([name, Component]) => {
        
        const { apiName, displayName, type, ai_instructions, propDefinitions } = Component?.definitions;
        let propDefinitionsString = JSON.stringify(propDefinitions);

        // name == 'Button' && console.log(propDefinitionsString);
        
        // Convert single quotes to double quotes
        const allIconNamesWithDoubleQuotes = allIconNames.map(name => `"${name}"`).join(',');
        // console.log(allIconNamesWithDoubleQuotes)

        // Trim icon lists
        propDefinitionsString = propDefinitionsString.replace(new RegExp(`\\["none","auto",${allIconNamesWithDoubleQuotes}\\]`, 'g'), '"none, auto or select icon from library"');
        propDefinitionsString = propDefinitionsString.replace(new RegExp(`\\["none",${allIconNamesWithDoubleQuotes}\\]`, 'g'), '"none or select icon from library"');
        propDefinitionsString = propDefinitionsString.replace(new RegExp(`\\[${allIconNamesWithDoubleQuotes}\\]`, 'g'), '"select icon from library"');

        const acceptsChildren = containers.includes(apiName);

        return {
          apiName,
          displayName,
          type,
          ai_instructions,
          propDefinitions: JSON.parse(propDefinitionsString), 
          acceptsChildren
        };
      });

    
    return transformedComponents;
}
