import Template from './Template';
import { NavArrowLeft, NavArrowRight, Plus } from 'iconoir-react';
import { canPasteSimplified } from '../../../../../utilities/pasteRules';
import { createNewIds } from '../../../../../utilities/helpers';
import React from 'react';
import { isEqual } from 'lodash';

function compareState(prevProps, nextProps) {
  
  // console.log('checking prop changes')  

  if (!isEqual(prevProps.view, nextProps.view)) { 
    // console.log('view changed')  
    return false; 
  }

  if (!isEqual(prevProps.dndState, nextProps.dndState)) { 
    // console.log('dndState changed')  
    return false; 
  }

  if (!isEqual(prevProps.selector, nextProps.selector)) {return false }
  
  if (!isEqual(prevProps.category, nextProps.category)) {return false }

  if (!isEqual(prevProps.effectiveVariables, nextProps.effectiveVariables)) { return false}

  return true;
}


const Category = React.memo(({
  category, onBack, frameId, dndState, 
  selector, view, effectiveVariables, handleAction, 
  handleDragStart, handleDragEnd
}) => {

  const templates = category.items;
  
  function getPastable(template) {
    const parentAPIName = selector?.object?.componentAPIName;
    const templateObjects = [...template?.objects] || [] // copy from template
    const rootObject = templateObjects.find(obj => obj.id === template?.root_object_id);
    const childAPIName = rootObject?.componentAPIName;
    
    let pastable = canPasteSimplified(parentAPIName, childAPIName) || false
    
    return pastable
  }

  function paste(template) {
    const parent = selector?.object
    const frame = selector?.frame;
    const templateObjects = [...template?.objects] || [] 
    const rootObject = templateObjects.find(obj => obj.id === template?.root_object_id);
    let objects = rootObject ? createNewIds(templateObjects, rootObject?.parent, 'rootObject') : []// create new ids
    // update frame id for all objects and detach rootObject (set parenе = 'rootObject')
    objects = objects.map((obj) => obj.id === rootObject?.id ? { ...obj, parent: 'rootObject', frame: frame.id } : { ...obj, frame: frame.id });
    const rootObj = objects.find((obj) => obj.parent === 'rootObject');
    const descendants = objects.filter((obj) => obj.id !== rootObj.id) || [];
    
    const siblings = frame.objects.filter((o) => o.parent == parent.id);
    const action = {
      type: "INSERT_OBJECT",
      object: rootObj,
      parent: parent,
      index: siblings.length + 1,
      descendants,
    };
    handleAction(action);
  }

  
  return (
    
    <>
      <div className='flex flex-row gap-1  items-center justify-start text-sm py-1 font-medium capitalize -ml-1'>
        <NavArrowLeft onClick={onBack} className='cursor-pointer hover:scale-125 stroke-[1.8px] transition-all duration-75' width={16} height={16}/>
        <h1>{category.name}</h1>
      </div>
        <div className='w-full flex flex-col gap-3 overflow-scroll p-1.5'>
      {templates.map(template => (
            <div key={template.id} className="flex flex-col w-full gap-1 hover:bg-base-200 hover:ring-4 hover:ring-base-200 rounded relative group">
              <div className='flex flex-col gap-2 w-full bg-base-50 group-hover:bg-base-0 text-center rounded-md p-2 text-xs items-center justify-center
              border border-base-100 hover:border-base-200 transition-all duration-75 text-base-500'
              >
              <Template 
                dndState={dndState}
                template={template} 
                effectiveVariables={effectiveVariables} 
                frameId={frameId} 
                view={view} 
                handleDragEnd={handleDragEnd}
                handleDragStart={handleDragStart}
                
                />
              </div>
  
              <div className='flex flex-row gap-1 justify-between w-full font-normal text-xs '>
                <h4 className='capitalize flex-grow truncate pl-0.5 opacity-50 group-hover:opacity-100'>
                  {template.name}
                </h4>
                {getPastable(template) &&
                  <Plus width={16} height={16} 
                  className="opacity-0 group-hover:opacity-70 hover:opacity-100 hover:scale-125 hover:stroke-[2px]
                  transition-all duration-75 cursor-copy" 
                  onClick={()=>paste(template)}
                  />
                }
              </div>
            </div>
          ))}
        
      </div>
      </>
  )
}, compareState)

export default Category;