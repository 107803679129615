export async function add(body) {
    const token = localStorage.getItem("token");

    try {
        const response = await fetch('/templates/add', {
            method: 'POST', 
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, 
            body: JSON.stringify(body)
        });
        return await response.json();
    } catch (error) {
        console.error('Error:', error);
    }
}

export async function update(body) {
    const token = localStorage.getItem("token");

    try {
        const response = await fetch('/templates/update', {
            method: 'POST', 
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${token}` }, 
            body: JSON.stringify(body)
        });
        return await response.json();
    } catch (error) {
        console.error('Error:', error);
    }
}
