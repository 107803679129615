export default function Checkbox({ checked, onChange, label }) {
    
    return (
        <label className="flex items-center">
            <input
                type="checkbox"
                className="hidden"
                checked={checked}
                onChange={e => onChange && onChange(e)}
            />
            <span
                className={`w-5 h-5 border rounded-md flex items-center justify-center ${checked ? 'bg-primary text-base-0' : 'bg-base-100 border-base-300'}`}>
                {checked && (
                    <svg className="w-5 h-5 text-white" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path fillRule="evenodd" d="M6.293 9.293a1 1 0 011.414 0L10 11.586l2.293-2.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd" />
                    </svg>
                )}
            </span>
            {label && <span className="ml-2 text-sm">{label}</span>}
        </label>
    );
}
