import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    Bar,
    BarChart,
    LabelList,
    
  } from "recharts";
  import { Icon } from "../";
  
  const dummyData = {
    keys: ["name", "nps"],
    values: [
      ["Apple", 72],
      ["Netflix", 68],
      ["Amazon", 62],
    ["Google", 58],
    ["Facebook", 55],
    ["Microsoft", 52],
    ],
  };
  
  function transformData(keys: string[], values: any[][]) {
    return values?.map((values) =>
      values?.reduce((obj, val, index) => {
        obj[keys[index]] = val;
        return obj;
      }, {})
    );
  }
  
  type RowChartProps = {
    width?: "auto" | "full" | "1/2";
    height?: '92px' | '120px' | '160px' | '200px' | '240px' | '360px' | string,
    barColor?: "primary" | "accent" | "base-content" | "base-0" | "primary-surface" | "info" | "info-surface" | "base-300" | "accent-surface",
    barWidth?: 24 | 32 | 36 | 48 | number;
    barCorners?: "none" | "sm" | "md" | "lg";
    backgroundColor?: "base-0" | "base-100" | "base-200" | "none" | "base-50";
    emptyState?: boolean;
    emptyMessage?: string;
    showGrid?: boolean;
    showDots?: boolean;
    showLabels?: boolean;
    showYAxis?: boolean;
    YWidth?: number;
    showXAxis?: boolean;
    data?: {
      keys: string[];
      values: any[][];
    };
    __juno?: any;
  };
  
  export default function RowChart({
    backgroundColor,
    width = "full",
    height = "240px",
    barColor = "primary",
    barWidth = 32,
    barCorners = "sm",
    showLabels = true,
    showYAxis = true,
    YWidth = 80,
    showXAxis,
    showGrid,
    data = dummyData,
    emptyState = false,
    emptyMessage = "Data may take up to 24 hrs to show",
    __juno = {},
  }: RowChartProps) {
    const widthStyles = `w-${width}`;
    const bgStyles =
      backgroundColor && backgroundColor != "none"
        ? `bg-${backgroundColor}`
        : "";
    const fontColorStyles =
      backgroundColor && backgroundColor != "none"
        ? `text-base-content`
        : "text-inherit";
  
    const classes = `flex flex-col items-stretch relative ${widthStyles} ${bgStyles} ${fontColorStyles}`;
  
    const emptyStyles = `flex flex-col justify-center items-center px-sm text-sm font-medium gap-2 rounded-md`;
  
    
    const sampleData = data ? transformData(data?.keys, data?.values) : [];
  
    const sideMargins =
      !showYAxis && !showLabels && !showXAxis ? 0 : 20;
  
    return (
      <div
        className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
        {...__juno?.attributes}
      >
        {/* CHART */}
        {!emptyState && data?.keys?.length && data?.values?.length ? (
          <div
            className="flex flex-row"
            style={{
              width: "100%",
              height: height,
            }}
          >
            <ResponsiveContainer width={"100%"} height="100%">
              <BarChart
                layout="vertical" // This makes the bars horizontal
                data={sampleData}
                barSize={barWidth}
                
              >
                {showGrid && <CartesianGrid strokeDasharray="1 3" />}
                <XAxis type="number" tick={{ fontSize: "12px" }} hide={!showXAxis} />
                <YAxis type="category" dataKey={data?.keys[0]}  tickLine={false}
                tickMargin={8}
                axisLine={false}
                width={YWidth}
                hide={!showYAxis}/>
                Hello
                <Bar 
                    dataKey={data?.keys[1]} 
                    fill={`var(--${barColor})`}
                  style={{ clipPath: barCorners != "none" ? `inset(0 0 0 0 round var(--border-radius-${barCorners}))`  : ""}}
                >
                  {showLabels && 
                    <LabelList 
                    dataKey={data?.keys[1]} 
                    offset={12}
                    position="right" 
                    />}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          </div>
        ) : (
          <>
            <div
              className={emptyStyles}
              style={{
                height: height,
                backgroundColor: `color-mix(in srgb, currentColor 8%, transparent)`,
              }}
            >
              <Icon icon="chart-up" />
              <span className="font-normal text-sm">{emptyMessage}</span>
            </div>
          </>
        )}
      </div>
    );
  }


