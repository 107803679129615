import * as IconoirIcons from 'iconoir-react';

type StarRatingsProps = {
    label?: string,
    ratingValue?: '10%' | '20%' | '30%' | '40%' | '50%' | '60%' | '70%' | '80%' | '90%' | '100%',
    labelPosition?: 'left' | 'right',
    color?: 'base-300' | 'primary' | 'accent' | 'info' | 'success' | 'warning' | 'error',
    size?: 'xs' | 'sm' | 'base' | 'lg',
    __juno?: any
}

export default function StarRatings({
        label = '4.5',
        ratingValue = '90%', // This should be considered whether it should be a percentage string or a numeric value depending on your implementation.
        labelPosition = 'right',
        color = 'error',
        size = 'sm', // consider making this px value
        __juno = {},
      }: StarRatingsProps) {
    
    const classes = `flex flex-row gap-2 items-center text-${size}`
    const starClasses = `flex flex-row gap-1.5 flex-shrink-0 text-${color}`
    /* text-orange-500 */

    return (
        <div 
        className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`} 
        {...__juno?.attributes}
        >
            <div className='relative flex flex-row gap-1.5 opacity-100' style={{ width: 'max-content' }}>
                    <div className='flex flex-row gap-1.5 opacity-10'>
                        {Array.from({ length: 5 }, (_, index) => <IconoirIcons.StarSolid key={index} className='flex-shrink-0' />)}
                    </div>
                    <div className={starClasses} style={{ position: 'absolute', width: ratingValue, overflow: 'hidden'}}>
                        {Array.from({ length: 5 }, (_, index) => <IconoirIcons.StarSolid key={index} className='flex-shrink-0' />)}
                    </div>
            </div>
            {label && <div className={`flex-shrink-0 leading-none font-normal`} style={{order: labelPosition === 'left' ? '-1' : 1}}>
             {label}
            </div>}
        </div>
    ); 
}



/* ignore rest */

  StarRatings.definitions = {
    apiName: 'StarRatings',
    displayName: 'StarRatings',
    description: 'An icon-only button component, utilizing icons from the Ionic Icon library. Supports various sizes and styles, with an optional loading state where the icon is replaced with an animated spinner.',
    ai_instructions: 'icon-only button. icons are from the iconoir library.',
    type: 'indicators',
    acceptedChildren: 'none',
    relativeSize: 'small',
    status: 'in review',
    package: 'Pro',
    propDefinitions: {
        ratingValue: {
            type: 'oneOf',
            displayName: 'Rating',
            options: ['10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%'],
            default: '90%',
        },
        color: {
            type: 'colors',
            options: ['base-300', 'primary', 'accent', 'info',  'success',  'warning', 'error'],
            displayName: 'Color',
            default: 'error'
        },
        label: {
            type: 'string',
            displayName: 'Label',
            default: '4.5', 
            tile: '1/2', 
            editable: true
        },
        labelPosition: {
            type: 'oneOf',
            displayName: 'Label Position',
            options: ['left', 'right'],
            default: 'left', 
            tile: '1/2'
        },
        
        size: {
            type: 'oneOf',
            options: ['xs', 'sm', 'base', 'lg'],
            displayName: 'Size (like font)',
            default: 'sm', 

        },
    }
};

/*
StarRatings.propTypes = {
    ratingValue: PropTypes.oneOf(['10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%', '100%']),
    label: PropTypes.string,
    labelPosition: PropTypes.oneOf(['left', 'right']),
    color: PropTypes.oneOfType([
        PropTypes.oneOf(['base-300', 'primary', 'accent', 'info',  'success',  'warning', 'error']),
        PropTypes.string]),
    size: PropTypes.oneOf(['xs', 'sm', 'base', 'lg', ]),
};
*/