import { XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList, AreaChart, Area } from 'recharts';
import { Icon } from '../';

const dummyData = {
    keys: ['week', 'sales', 'expenses'],
    colors: ["primary", "accent"],
    values: [
        ['Aug 12', 150, 100],
        ['Aug 19', 190, 120],
        ['Aug 26', 180, 130],
        ['Sep 2', 210, 150],
        ['Sep 9', 205, 160],
        ['Sep 16', 220, 180],
        ['Sep 23', 240, 200],
    ]
};


type DataPoint = {
    [key: string]: string | number;
}

function transformData(keys: string[], values: (string | number)[][]): DataPoint[] {
    return values.map((values) =>
      values.reduce<DataPoint>((obj, val, index) => {      
        obj[keys[index]] = val;
        return obj;
      }, {}),
    );
  }

type AreaChartProps = {
    title?: string,
    type?: 'layered' | 'stacked',
    width?: 'auto' | 'full' | '1/2',
    height?: '92px' | '120px' | '160px' | '200px' | '240px' | '360px' | '100%',
    lineColor?: 'primary' | 'accent' | 'base-content' | 'base-0',
    lineWidth?: '1' | '2' | '3' | '4' | '0',
    lineType?: 'wavy' | 'linear',
    showGrid?: boolean,
    showDots?: boolean,
    showLabels?: boolean,
    showYAxis?: boolean,
    showXAxis?: boolean,
    backgroundColor?: 'base-0' | 'base-100' | 'base-200' | string,
    corners?: 'none' | 'sm' | 'md' | 'lg',
    emptyState?: boolean,
    emptyMessage?: string,
    bottomDomain?: number | 'auto',
    topDomain?: number | 'auto',
    data?: {
        keys: string[],
        values: any[],
        colors?: string[],
    },
    __juno?: any
}

export default function AreaChartComponent({
        type = 'stacked',
        width = 'full',
        height = '120px',
        lineColor = 'primary',
        lineWidth = '2',
        lineType = 'wavy',
        showDots = true,
        showLabels = true,
        showYAxis,
        showXAxis = true,
        showGrid = true,
        data = dummyData,
        bottomDomain = 'auto',
        topDomain = 'auto',

        emptyState = false,
        emptyMessage = 'Data may take up to 24 hrs to show',
        __juno = {}
      }: AreaChartProps) {

    const widthStyles = `w-${width}`;
    const heightStyles = height === '100%' ? 'h-full' : `h-auto`;
    const classes = `flex flex-col items-stretch ${heightStyles} relative ${widthStyles}`

    // ${paddingStyles} ${cornerStyles}
    
    const emptyStyles = `flex flex-col justify-center items-center px-sm text-sm font-medium gap-2 rounded-md`
    
    const sampleData = data ? transformData(data?.keys, data?.values) : []    
    const sideMargins = (!showYAxis && !showLabels && !showXAxis) ? 0 : 20

    return (
        <div
            className={`${classes} ${__juno?.outlineStyle} ${__juno?.tagStyle}`}
            {...__juno?.attributes}
        >
            {/* CHART */}
            {(!emptyState && data?.keys?.length && data?.values?.length) ?
            <div className='flex flex-row' style={{ 
                    width: '100%', 
                    height: height,  }}>
                <ResponsiveContainer width={'100%'} height="100%" >
                    <AreaChart data={sampleData} margin={{ top: 20, right: sideMargins, bottom: 0, left: sideMargins }}>
                    {showGrid && <CartesianGrid strokeDasharray="1 3" />}
                    {showXAxis && <XAxis dataKey={data.keys[0]} tick={{ fontSize: '12px'}}/>}
                    <YAxis width={20} tick={{ fontSize: '12px', color: `red` }} domain={[bottomDomain, topDomain]} hide={!showYAxis} />
                    <Tooltip />
                    {data.keys.slice(1).map((key, index) => (
                        <Area
                        key={key}
                        type={lineType === 'wavy' ? 'monotone' : 'linear'}
                        dataKey={key}
                        stackId={type === 'stacked' ? 'a' : undefined}
                        fill={data.colors?.[index] ? `var(--${data.colors[index]}` : `var(--${lineColor})`}
                        stroke={data.colors?.[index] ? `var(--${data.colors[index]}` : `var(--${lineColor})`}
                        strokeWidth={lineWidth}
                        dot={showDots} 

                        activeDot={{ r: 4 }}
                    >
                        {showLabels && (
                            <LabelList 
                                dataKey={data?.keys[1]} 
                                position="top" 
                                style={{ fill: `var(--${lineColor})`, fontSize: 10 }} 
                            />
                        )}
                    </Area>))}
                </AreaChart>
            </ResponsiveContainer>
            </div>
        :
        <>
        <div className={emptyStyles} style={{ height: height, backgroundColor: `color-mix(in srgb, currentColor 8%, transparent)`}}>
            <Icon icon='chart-up' />
            <span className='font-normal text-sm'>
                {emptyMessage}
            </span>
        </div>
        </>
        }
        </div>
    );
}


