import { addCoordinatesV2, findClosestObject, getBounds, getDescendantIds, getInsertParameters2, getNewIndex, getPseudoIndex } from "./helpers";
import { canPaste } from "../../../utilities/pasteRules";
import { convertJSXToObjectsSync } from "../../../utilities/AIhelpers";

export function handlePrimitiveDragOver({dndState, setDnDState, dragRefs, setInsert, frameObjects, event, sections, object}) {
  // determine the parent that can accept this primitive
  // evaluate drop conditions (index, insert, etc.)
  // set the insert state
  
  const dragItem = dndState.draggingItem
  
  const dropParent = canPaste(object, dndState.draggingItem)
    ? object
    : frameObjects.find((obj) => obj.id == object.parent);

  let descendants = getDescendantIds(dragItem.id, frameObjects) || [];
  descendants = [...descendants, dragItem.id];

  // STOP OVER IF THERE IS NO DROP PARENT OR DROP PARENT IS INSIDE DRAG ITEM
  if (!dropParent || descendants.includes(dropParent?.id)) {
    return;
  }

  let dropTargetChildren = frameObjects.filter(
    (obj) =>
      obj.parent == dropParent.id && !sections.includes(obj.componentAPIName)
  );

  // NEW APPROACH WITH REFS
  const dropParentRef = dragRefs.current.refs.find(
    (item) => item.id === dropParent.id
  );
  const dropParentBounds = getBounds(
    dropParentRef.node,
    dropParent.id,
    dropParentRef.index
  );

  let direction = dropParentBounds?.direction || "column";
  dropTargetChildren = addCoordinatesV2(
    dropTargetChildren,
    dragRefs.current.refs
  );

  // IF Drop target is somewhere on itself
  if (
    dropTargetChildren.length == 1 &&
    dropTargetChildren[0]?.id == dndState.draggingItem?.id
  ) {
    dragRefs.current.dropIndex = null;
    setInsert(null);
  } else if (dropTargetChildren.length == 0) {
    // console.log('dropping in empty container')
    dragRefs.current.dropIndex = null;
    setInsert(null);
    const canDrop = canPaste(dropParent, dndState.draggingItem);

    canDrop &&
      setDnDState({
        ...dndState,
        draggingOverItem: dropParent,
        offFrame: false
      });
  } else {
    const closestChild = findClosestObject(
      dropTargetChildren,
      event.pageX,
      event.pageY
    );

    const neighboringChildren =
      closestChild &&
      dropTargetChildren.filter(
        (obj) =>
          obj.index === closestChild.index - 1 ||
          obj.index === closestChild.index + 1
      );

    const secondClosestChild =
      closestChild &&
      findClosestObject(
        neighboringChildren,
        event.pageX, // cursor x
        event.pageY // cursor y
      );
    const pseudoIndex = closestChild
      ? getPseudoIndex(direction, closestChild, event.pageX, event.pageY)
      : 1;

    const draggingItemRef = dragRefs.current.draggingItemRef;
    const insert = getInsertParameters2(
      direction,
      closestChild,
      secondClosestChild,
      pseudoIndex,
      draggingItemRef,
      dropParentBounds
    );

    if (JSON.stringify(dragRefs.current.insert) != JSON.stringify(insert)) {
      // console.log('setting insert')
      setInsert(insert);
      dragRefs.current.insert = insert;
    }

    // newInsert != insert && setInsert(insert)
    dragRefs.current.dropIndex = getNewIndex(
      pseudoIndex,
      closestChild,
      dndState.draggingItem
    );
  }

  if (dropParent != dndState?.draggingOverItem) {
    setDnDState({
      ...dndState,
      draggingOverItem: dropParent,
      offFrame: false,
      collectRefs: false, // do we need to close this?
    });
  }
}


export function handlePrimitiveDragEnd({
  dndState, dragRefs, handleAction, lastOverItem, canDrop
}) {
  
  if (lastOverItem && canDrop) {
    let action = null;
    const newParent = lastOverItem; // or its parent
    const draggingItem = dndState?.draggingItem;

    if (canDrop) {
      if (draggingItem.source == "library") {
        
        let objects = [];
        if (draggingItem.objects && draggingItem.objects.length > 0) {
            objects = draggingItem.objects;
        } else {
            const jsx = draggingItem.jsx;
            objects = convertJSXToObjectsSync(jsx) || [];
        }
        if (objects.length === 0) return;
        const rootObj = objects.find((obj) => obj.parent === "rootObject");
        
        if (!rootObj) return;
        const descendants = objects.filter((obj) => obj.id !== rootObj.id) || [];

        action = canDrop && {
          type: "INSERT_OBJECT",
          object: rootObj,
          parent: newParent,
          index: dragRefs.current.dropIndex,
          descendants,
        };
        
      } else {
        action = canDrop && {
          type: "RELOCATE_OBJECT",
          object: dndState?.draggingItem,
          newParent,
          newIndex: dragRefs.current.dropIndex,
        };
      }
      
      handleAction(action);
    } else {
      console.log('cant drop here')
    }
  }    
}